define("abcloud-ui/services/doughnut-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    utils: Ember.inject.service('utils'),
    setup() {
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000000';

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 1.75;
            // -- removing ctx.font = fontSizeToUse+"px " + fontStyle;, scales and I don't want it to
            ctx.font = 1.48 + "vmax " + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
      });
    },
    getChartData(data, labels, colorScheme) {
      var colors = [];
      colors.push('#15570d', '#4e8248', '#86a982', '#bfd3bd', '#e9f1e8');
      var chartData = {
        datasets: [{
          data: data,
          backgroundColor: colors
        }],
        borderWidth: 0,
        // -- array: These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      };
      return chartData;
    },
    // -- removed title from args list as we are no longer using it
    getChartOptions(centerText) {
      const _this = this;
      var opts = {
        cutoutPercentage: 85,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animationRotate: true,
          duration: 750
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.labels[tooltipItem.index] || '-';
              if (label.length > 15) {
                label = label.slice(0, parseInt(15)) + '...';
              }
              let labelData = data.datasets[0].data[tooltipItem.index];
              // -- label = ( labelItem + ' ' + data.datasets[0].data[tooltipItem.index] ) || '-'; may need more space on labels due to dates so.. VV
              label = isNaN(labelData) ? label : label + '\r\n' + _this.utils.formatNumber(labelData);
              return label;
            }
          }
        },
        title: {
          display: false
        },
        elements: {
          center: {
            text: _this.utils.formatNumber(centerText),
            color: '#4a4A4a',
            //Default black
            fontStyle: 'Helvetica',
            //Default Arial
            sidePadding: 5 //Default 20 (as a percentage)
          }
        },

        rotation: 0.75 * Math.PI,
        circumference: 1.5 * Math.PI
      };
      return opts;
    }
  });
  _exports.default = _default;
});