define("abcloud-ui/services/import-all-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //-- Please add all your newly generated services here in ALPHABETICAL ORDER and add the line
  // -- `services: service('import-all-services')` as a property in the file you wish to import the services
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service("auth"),
    bar_chart: Ember.inject.service("bar-chart"),
    build_policy_object_for_server: Ember.inject.service("build-object-for-server/policy"),
    build_profile_object_for_server: Ember.inject.service("build-object-for-server/profile"),
    doughnut_chart: Ember.inject.service("doughnut-chart"),
    download_file: Ember.inject.service("download-file"),
    encrypt: Ember.inject.service("encrypt"),
    notifications: Ember.inject.service(),
    inject_notification: Ember.inject.service("inject-notification"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    map: Ember.inject.service("map"),
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    query_params: Ember.inject.service("query-params"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    string_modifiers: Ember.inject.service("string-modifiers"),
    toggle_element: Ember.inject.service("toggle-element"),
    urls: Ember.inject.service("urls"),
    utils: Ember.inject.service("utils")
  });
  _exports.default = _default;
});