define("abcloud-ui/routes/system/hosted/localbay/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      try {
        return this.store.query('localcomponent', {
          component_type: 'localbay'
        });
      } catch (noLocalBays) {
        return {};
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        'localcomponents': model,
        'showPreloader': false
      });
    },
    renderTemplate() {
      this.render("system.hosted.localbay.index", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
      }
    }
  });
  _exports.default = _default;
});