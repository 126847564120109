define("abcloud-ui/templates/components/im-design-system/im-skeleton/skeleton-multi-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4XnCLGPo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[1,[27,\"im-design-system/im-skeleton/skeleton\",null,[[\"isLoading\",\"class\"],[[23,[\"is_processing\"]],\"col-12 mb-3\"]]],false],[0,\"\\n\\n  \"],[1,[27,\"im-design-system/im-skeleton/skeleton\",null,[[\"isLoading\",\"class\"],[[23,[\"is_processing\"]],\"col-8 mb-3\"]]],false],[0,\"\\n\\n  \"],[1,[27,\"im-design-system/im-skeleton/skeleton\",null,[[\"isLoading\",\"class\"],[[23,[\"is_processing\"]],\"col-6 mb-3\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"col-4\"],[9],[10],[0,\"\\n\\n  \"],[1,[27,\"im-design-system/im-skeleton/skeleton\",null,[[\"isLoading\",\"class\"],[[23,[\"is_processing\"]],\"col-10 mb-3\"]]],false],[0,\"\\n\\n  \"],[1,[27,\"im-design-system/im-skeleton/skeleton\",null,[[\"isLoading\",\"class\"],[[23,[\"is_processing\"]],\"col-4 mb-3\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/im-design-system/im-skeleton/skeleton-multi-line.hbs"
    }
  });
  _exports.default = _default;
});