define("abcloud-ui/controllers/manage/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    actions: {
      editUser(admin_user) {
        admin_user.save().then(() => {
          this.notifications.success(this.constants.USER_UPDATED_SUCCESSFULLY);
          this.set("error", "");
          this.transitionToRoute("manage.users.list");
        }).catch(() => {
          this.set("error", this.constants.SOMETHING_WENT_WRONG);
        });
      },
      navigateBackToUserList() {
        this.transitionToRoute("manage.users.list");
      }
    }
  });
  _exports.default = _default;
});