define("abcloud-ui/components/policies-tenant/policy-components/edit-protected-aws-s3-file-downloads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      if (this.disabledStatus) {
        document.getElementById('nameDisabled').disabled = true;
        document.getElementById('descriptionDisabled').disabled = true;
      }
      if (this.changed_labels) {
        this.changed_labels.forEach(label_id => {
          if (this.disabled) {
            Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
          } else {
            Ember.$('#' + label_id + '').addClass('draft_label_changed');
          }
        });
      }
    },
    actions: {
      chooseLocalBay(localcomponentId, chosen_option, index) {
        this.chooseLocalBay(localcomponentId, chosen_option, index);
      },
      chooseUserSessionProfile(user_session_id, chosen_option) {
        this.chooseUserSessionProfile(user_session_id, chosen_option);
      },
      chooseUserGroupProfile(user_group_id, chosen_option) {
        this.chooseUserGroupProfile(user_group_id, chosen_option);
      },
      chooseActivitySessionProfile(activity_session_id, chosen_option) {
        this.chooseActivitySessionProfile(activity_session_id, chosen_option);
      },
      chooseDplet(dplet_id, chosen_option) {
        this.chooseDplet(dplet_id, chosen_option);
      },
      collectInput(controller) {
        this.collectInput(controller);
      },
      generateRestApiAuthToken(controller) {
        this.generateRestApiAuthToken(controller);
      },
      unAuthUserSeverity(chosenOption, newSet) {
        this.unAuthUserSeverity(chosenOption, newSet);
      },
      expiredTokenSeverity(chosenOption, newSet) {
        this.expiredTokenSeverity(chosenOption, newSet);
      },
      userNotInFileGroupSeverity(chosenOption, newSet) {
        this.userNotInFileGroupSeverity(chosenOption, newSet);
      },
      optForAlertEvent(severity, event) {
        this.optForAlertEvent(severity, event);
      },
      cancel() {
        this.transitionToListView();
      },
      saveS3FileDownloads(action) {
        this.saveS3FileDownloads(action);
      },
      updateSet(newSet) {
        this.updateSet(newSet);
      },
      resetToRecommendedSettings() {
        this.resetToRecommendedSettings();
      }
    }
  });
  _exports.default = _default;
});