define("abcloud-ui/controllers/admin/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    init() {
      this._super(...arguments);
      this.setProperties({
        didUserSessionTimeOut: this.auth.didUserSessionTimeOut(),
        email: null,
        password: null,
        unauthorizedUser: false,
        isAdminLoginLoading: false,
        validations: {
          isFormValid: false,
          email: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          },
          password: {
            isValid: null,
            required: true
          }
        }
      });
    },
    actions: {
      adminLogin: function () {
        /* 
          Reset form states
        */
        this.setProperties({
          unauthorizedUser: false,
          didUserSessionTimeOut: false,
          isAdminLoginLoading: true
        });
        const request_object = {
          email: this.email,
          password: this.password
        };
        var url = this.urls.URL_ADMIN_SIGN_IN();
        return this.ajax.request(url, {
          method: "POST",
          data: request_object
        }).then(response => {
          this.setProperties({
            unauthorizedUser: false,
            didUserSessionTimeOut: false,
            isAdminLoginLoading: false
          });
          this.auth.setAuthToken(response.auth_token, "admin");
          window.location = "/admin/customers";
        }, error => {
          if ([this.constants.UNAUTHORIZED_ACCESS, this.constants.REQUEST_REJECTED].includes(error.message)) {
            this.setProperties({
              unauthorizedUser: true,
              didUserSessionTimeOut: false,
              isAdminLoginLoading: false
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});