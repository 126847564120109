define("abcloud-ui/controllers/manage/customers/customer-tenants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    elementId: "",
    tenantId: "",
    elementHtml: "",
    notifications: Ember.inject.service(),
    renderTenantUserDetails(email, pw) {
      return "<b>email:</b> " + email + '<br /><br /><b>pw:</b> <span style="background: #cacfc9;">' + pw + "</span>";
    },
    actions: {
      createSupportUser(customer, tenant, elementId, transitionText) {
        let focusEl = Ember.$("#" + elementId + "" + tenant.id);
        let elementHtml = focusEl.html();
        let customerId = customer.id;
        let tenantId = tenant.id;
        let URL = this.urls.URL_CREATE_TENANT_SUPPORT_USER(customerId, tenantId);
        focusEl.css({
          opacity: "0.35",
          background: "#cacfc9"
        });
        focusEl.html("").text(transitionText + " ...");
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("adminUser")}`
          }
        });
        this.ajax.request(URL, {
          method: "POST",
          data: {
            data: {
              attributes: {
                customer_id: customer.id,
                tenant_unique_id: tenant.tenant_unique_id
              }
            }
          }
        }).then(response => {
          let email = response.data.attributes.email;
          let password = response.data.attributes.plain_text_password;
          focusEl.css({
            opacity: "1",
            background: "transparent"
          });
          focusEl.html("").html(this.renderTenantUserDetails(email, password));
        });
      },
      generateNewPassword(customer, tenant, elementId, transitionText) {
        let focusEl = Ember.$("#" + elementId + "" + tenant.id);
        let elementHtml = focusEl.html();
        let customerId = customer.id;
        let tenantId = tenant.id;
        let URL = this.urls.URL_UPDATE_SUPPORT_USER_PASSWORD(customerId, tenantId);
        focusEl.css({
          opacity: "0.35",
          background: "#cacfc9"
        });
        focusEl.html("").text(transitionText + " ...");
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("adminUser")}`
          }
        });
        this.ajax.request(URL, {
          method: "PUT",
          data: {
            data: {
              attributes: {
                customer_id: customer.id,
                tenant_unique_id: tenant.tenant_unique_id
              }
            }
          }
        }).then(response => {
          let email = response.data.attributes.email;
          let password = response.data.attributes.plain_text_password;
          focusEl.css({
            opacity: "1",
            background: "transparent"
          });
          focusEl.html("").html(this.renderTenantUserDetails(email, password));
        });
      },
      deleteCustomerTenant(tenantId) {
        const tenant = this.store.peekRecord("customer_tenant", tenantId);
        tenant.set("is_deleted", true);
        this.store.findRecord("customer_tenant", tenant.id, {
          backgroundReload: false
        }).then(tenant => {
          tenant.destroyRecord().then(() => {
            this.notifications.success(this.constants.TENANT_DELETED_SUCCESSFULLY);
          }).catch(() => {
            this.notifications.error(this.constants.DELETE_FAILED);
            tenant.rollbackAttributes();
          });
        });
      },
      navigateToAddTenant(customerId) {
        this.transitionToRoute("manage.customers.add-tenant", customerId);
      }
    }
  });
  _exports.default = _default;
});