define("abcloud-ui/components/download-file-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    tagName: "div",
    classNames: ["download_button", "abs"],
    isRenderingFile: false,
    model: "",
    // fileDownloadElementId: '',
    downloadLink: null,
    didReceiveAttrs() {
      // this.set(model, model);
    },
    didInsertElement() {},
    willDestroyElement() {
      this.set("isRenderingFile", false);
      this.set("downloadLink", null);
    },
    actions: {
      downloadFile() {
        this.set("isRenderingFile", true);
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
            "X-CV-Trace-ID": this.urls.generate_trace_id()
          }
        });
        let url = this.urls.URL_DOWNLOAD_RECORD_AS_FILE("event", this.model.id, "csv");
        this.ajax.request(url, {
          method: "GET"
        }).then(response => {
          this.set("isRenderingFile", false);
          if (response["data"] && response["data"]["attributes"]) {
            this.set("downloadLink", response["data"]["attributes"]["download_link"]);
          }
        }).catch(error => {
          this.set("isRenderingFile", false);
          this.debug.log("error", error);
          this.set("downloadLink", null);
        });
      }
    }
  });
  _exports.default = _default;
});