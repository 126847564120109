define("abcloud-ui/routes/insights/dashboard/discovery/new-apis", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service("ajax"),
    urls: Ember.inject.service("urls"),
    doughnutChart: Ember.inject.service("doughnut-chart"),
    lineChart: Ember.inject.service("line-chart"),
    filterParams: "",
    queryParams: Ember.inject.service("query-params"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    model() {
      this.set("filterParams", this.queryParams.getParams());
    },
    setupController(controller, model) {
      this._super(controller, model);
      var urls = this.urls;
      var params = this.filterParams;
      var self = this;
      this.doughnutChart.setup();
      this.lineChart.setup();
      controller.setProperties({
        from_time: this.queryParams.getParam("from_time"),
        to_time: this.queryParams.getParam("to_time"),
        timeSelectedByUser: this.queryParams.getParam("timeSelectedByUser"),
        page: this.queryParams.getParam("page"),
        showR0C0Preloader: true,
        showR1C1Preloader: true,
        showR1C2Preloader: true,
        showR1C3Preloader: true,
        showR2C1Preloader: true,
        showR2C2Preloader: true,
        showR2C3Preloader: true,
        showR3C1Preloader: true,
        showR3C2Preloader: true,
        showR3C3Preloader: true
      });

      // -- isInsightsConfigured?
      var isConfiguredRequest = Ember.RSVP.hash({
        isConfigured: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_VERIFY_INSIGHTS_CONFIGURATION(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      isConfiguredRequest.then(() => {
        // -- ._result.dart => reference to Promise above
        var insightsConfigured = isConfiguredRequest._result.isConfigured.configured;
        controller.setProperties({
          insightsConfigured: insightsConfigured,
          showR0C0Preloader: false
        });
      });

      // -- new
      var apis_request = Ember.RSVP.hash({
        new_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_request.then(() => {
        var new_apis = apis_request._result.new_data.data[0];
        controller.setProperties({
          newApi: new_apis,
          newApiData: self.get("doughnutChart").getChartData(new_apis["attributes"]["chart_data"], new_apis["attributes"]["chart_labels"]),
          newApiOpts: self.get("doughnutChart").getChartOptions(new_apis["attributes"]["chart_center_text"], new_apis["attributes"]["chart_date_label_aggregation_level"], new_apis["attributes"]["chart_data"] !== 0),
          showR1C1Preloader: false,
          newApiDataAvailable: new_apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by time
      var apis_by_time_request = Ember.RSVP.hash({
        time_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_BY_TIME(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_by_time_request.then(() => {
        var apis = apis_by_time_request._result.time_data.data[0];
        controller.setProperties({
          apisByTime: apis,
          apisByTimeData: self.get("lineChart").getChartData(apis["attributes"]["chart_data"], apis["attributes"]["chart_labels"]),
          apisByTimeOpts: self.get("lineChart").getChartOptions(apis["attributes"]["chart_center_text"], apis["attributes"]["chart_date_label_aggregation_level"], apis["attributes"]["chart_data"] !== 0),
          showR2C1Preloader: false,
          apisByTimeDataAvailable: apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by volume
      var apis_by_volume_request = Ember.RSVP.hash({
        volume_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_BY_VOLUME(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_by_volume_request.then(() => {
        var apis = apis_by_volume_request._result.volume_data.data[0];
        controller.setProperties({
          apisByVolume: apis["attributes"]["chart_center_text"],
          apisByVolumeData: self.get("lineChart").getChartData(apis["attributes"]["chart_data"], apis["attributes"]["chart_labels"]),
          apisByVolumeOpts: self.get("lineChart").getChartOptions(apis["attributes"]["chart_center_text"], apis["attributes"]["chart_date_label_aggregation_level"], apis["attributes"]["chart_data"] !== 0),
          showR3C1Preloader: false,
          apisByVolumeDataAvailable: apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by params
      var apis_with_params_request = Ember.RSVP.hash({
        params_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_PARAMS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_params_request.then(() => {
        var apis_with_params = apis_with_params_request._result.params_data.data[0];
        controller.setProperties({
          apisWithParams: apis_with_params,
          apisWithParamsData: self.get("doughnutChart").getChartData(apis_with_params["attributes"]["chart_data"], apis_with_params["attributes"]["chart_labels"]),
          apisWithParamsOpts: self.get("doughnutChart").getChartOptions(apis_with_params["attributes"]["chart_center_text"], apis_with_params["attributes"]["chart_date_label_aggregation_level"], apis_with_params["attributes"]["chart_data"] !== 0),
          showR1C2Preloader: false,
          apisWithParamsDataAvailable: apis_with_params["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // // -- by params timeseries
      var apis_with_params_by_time_request = Ember.RSVP.hash({
        timeseries_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_PARAMS_TIMESERIES(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_params_by_time_request.then(() => {
        var apis_with_params_by_time = apis_with_params_by_time_request._result.timeseries_data.data[0];
        controller.setProperties({
          apisWithParamsTimeseries: apis_with_params_by_time,
          apisWithParamsTimeseriesData: self.get("lineChart").getChartData(apis_with_params_by_time["attributes"]["chart_data"], apis_with_params_by_time["attributes"]["chart_labels"]),
          apisWithParamsTimeseriesOpts: self.get("lineChart").getChartOptions(apis_with_params_by_time["attributes"]["chart_center_text"], apis_with_params_by_time["attributes"]["chart_date_label_aggregation_level"], apis_with_params_by_time["attributes"]["chart_data"] !== 0),
          showR2C2Preloader: false,
          apisWithParamsTimeseriesDataAvailable: apis_with_params_by_time["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by params/volume timeseries
      var apis_with_params_vol_by_time_request = Ember.RSVP.hash({
        vol_timeseries_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_PARAMS_BY_VOLUME_TIMESERIES(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_params_vol_by_time_request.then(() => {
        var apis_with_params_by_vol = apis_with_params_vol_by_time_request._result.vol_timeseries_data.data[0];
        controller.setProperties({
          apisWithParamsVolTimeseries: apis_with_params_by_vol,
          apisWithParamsVolTimeseriesData: self.get("lineChart").getChartData(apis_with_params_by_vol["attributes"]["chart_data"], apis_with_params_by_vol["attributes"]["chart_labels"]),
          apisWithParamsVolTimeseriesOpts: self.get("lineChart").getChartOptions(apis_with_params_by_vol["attributes"]["chart_center_text"], apis_with_params_by_vol["attributes"]["chart_date_label_aggregation_level"], apis_with_params_by_vol["attributes"]["chart_data"] !== 0),
          showR3C2Preloader: false,
          apisWithParamsVolTimeseriesDataAvailable: apis_with_params_by_vol["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // // -- by methods
      var apis_with_methods_request = Ember.RSVP.hash({
        methods_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_METHODS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_methods_request.then(() => {
        var apis_with_methods = apis_with_methods_request._result.methods_data.data[0];
        controller.setProperties({
          apisWithMethods: apis_with_methods,
          apisWithMethodsData: self.get("doughnutChart").getChartData(apis_with_methods["attributes"]["chart_data"], apis_with_methods["attributes"]["chart_labels"]),
          apisWithMethodsOpts: self.get("doughnutChart").getChartOptions(apis_with_methods["attributes"]["chart_center_text"]),
          showR1C3Preloader: false,
          apisWithMethodsDataAvailable: apis_with_methods["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // // -- by methods timeseries
      var apis_with_methods_by_time_request = Ember.RSVP.hash({
        methodstime_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_METHODS_TIMESERIES(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_methods_by_time_request.then(() => {
        var apis_with_methods_by_time = apis_with_methods_by_time_request._result.methodstime_data.data[0];
        controller.setProperties({
          apisWithMethodsTimeseries: apis_with_methods_by_time,
          apisWithMethodsTimeseriesData: self.get("lineChart").getChartData(apis_with_methods_by_time["attributes"]["chart_data"], apis_with_methods_by_time["attributes"]["chart_labels"]),
          apisWithMethodsTimeseriesOpts: self.get("lineChart").getChartOptions(apis_with_methods_by_time["attributes"]["chart_center_text"], apis_with_methods_by_time["attributes"]["chart_date_label_aggregation_level"], apis_with_methods_by_time["attributes"]["chart_data"] !== 0),
          showR2C3Preloader: false,
          apisWithMethodsTimeseriesDataAvailable: apis_with_methods_by_time["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by methods/volume timeseries
      var apis_with_methods_vol_by_time_request = Ember.RSVP.hash({
        vol_timeseries_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_METHODS_BY_VOLUME_TIMESERIES(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_methods_vol_by_time_request.then(() => {
        var apis_with_methods_by_vol = apis_with_methods_vol_by_time_request._result.vol_timeseries_data.data[0];
        controller.setProperties({
          apisWithParamsVolTimeseries: apis_with_methods_by_vol,
          apisWithParamsVolTimeseriesData: self.get("lineChart").getChartData(apis_with_methods_by_vol["attributes"]["chart_data"], apis_with_methods_by_vol["attributes"]["chart_labels"]),
          apisWithParamsVolTimeseriesOpts: self.get("lineChart").getChartOptions(apis_with_methods_by_vol["attributes"]["chart_center_text"], apis_with_methods_by_vol["attributes"]["chart_date_label_aggregation_level"], apis_with_methods_by_vol["attributes"]["chart_data"] !== 0),
          showR3C3Preloader: false,
          apisWithParamsVolTimeseriesDataAvailable: apis_with_methods_by_vol["attributes"]["chart_data"] === 0 ? false : true
        });
      });
      // }
    },

    renderTemplate() {
      this.render("insights.dashboard.discovery.newApis", {
        into: "insights.dashboard.discovery",
        outlet: "dashboard_data"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
          to_time: parseInt(_moment.default.utc().valueOf() / 1000),
          timeSelectedByUser: "86400",
          showR0C0Preloader: true,
          showR1C1Preloader: true,
          showR1C2Preloader: true,
          showR1C3Preloader: true,
          showR2C1Preloader: true,
          showR2C2Preloader: true,
          showR2C3Preloader: true,
          showR3C1Preloader: true,
          showR3C2Preloader: true,
          showR3C3Preloader: true,
          alertTotal: 0
        });
      }
    }
  });
  _exports.default = _default;
});