define("abcloud-ui/routes/policies/profiles/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    server: Ember.inject.service('ab-ajax-requests/server'),
    profile_id: '',
    current_profile: '',
    diffLenth: 0,
    model(params) {
      this.set('profile_id', params.profile_id);
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var request1 = Ember.$.getJSON(this.urls.URL_POLICY_PROFILE(params.profile_id));
      return Ember.RSVP.hash({
        profile: request1
      });
    },
    setupController(controller, model) {
      model = model.profile.data;
      var profile_data = model.attributes.profile_data;
      var current_file = profile_data[profile_data.length - 1].spec_file;
      if (model.attributes.sub_type === 'api_parameter_pin') {
        if (profile_data.length > 1) {
          if (profile_data[1].json.parameter_pin_information.hasOwnProperty('static_values')) {
            controller.set('pin_static_values', profile_data[1].json.parameter_pin_information.static_values);
          }
        } else {
          if (profile_data[0].json.parameter_pin_information.hasOwnProperty('static_values')) {
            controller.set('pin_static_values', profile_data[0].json.parameter_pin_information.static_values);
          }
        }
      } else if (model.attributes.sub_type === 'data_export') {
        Ember.$.getJSON(this.urls.URL_GET_DATA_FOR_PROFILES_BY_SUB_TYPE('data_export')).then(localconsoles => {
          Ember.run(() => {
            controller.setProperties({
              'localconsoles_list': localconsoles.data,
              'localconsoles_present': localconsoles.data.length
            });
          });
        });
        if (profile_data.length > 1) {
          if (profile_data[1].json.data_receiver_information.hasOwnProperty('localconsole')) {
            controller.setProperties({
              'current_localconsole': profile_data[1].json.data_receiver_information.localconsole.title,
              'current_localconsole_id': profile_data[1].json.data_receiver_information.localconsole.id,
              'current_localconsole_hashed_passphrase': profile_data[1].json.data_receiver_information.localconsole.hashed_passphrase
            });
          } else {
            controller.setProperties({
              'current_localconsole': 'N/A'
            });
          }
          if (profile_data[0].json.data_receiver_information.hasOwnProperty('localconsole')) {
            controller.setProperties({
              'published_current_localconsole': profile_data[0].json.data_receiver_information.localconsole.title,
              'published_current_localconsole_id': profile_data[0].json.data_receiver_information.localconsole.id
            });
          } else {
            controller.setProperties({
              'published_current_localconsole': 'N/A'
            });
          }
        } else {
          if (profile_data[0].json.data_receiver_information.hasOwnProperty('localconsole')) {
            controller.setProperties({
              'current_localconsole': profile_data[0].json.data_receiver_information.localconsole.title,
              'current_localconsole_id': profile_data[0].json.data_receiver_information.localconsole.id,
              'current_localconsole_hashed_passphrase': profile_data[0].json.data_receiver_information.localconsole.hashed_passphrase
            });
          } else {
            controller.setProperties({
              'current_localconsole': 'N/A'
            });
          }
        }
      } else if (model.attributes.sub_type === 'out_of_spec_api') {
        Ember.$.getJSON(this.urls.URL_MINIMUM_API_RECORDINGS()).then(apiRecordings => {
          const apiRecordingsList = apiRecordings.data;
          var current_api_recording = apiRecordingsList.length > 0 ? apiRecordingsList[0].attributes.name : this.constants.NO_API_RECORDINGS_FOUND;
          if (profile_data.length > 1) {
            controller.setProperties({
              'api_recordings_list': apiRecordingsList,
              'current_api_recording': profile_data[1].json.hasOwnProperty('spec_info') ? profile_data[1].json.spec_info.api_recording.name : current_api_recording,
              'current_api_recording_id': profile_data[1].json.hasOwnProperty('spec_info') ? profile_data[1].json.spec_info.api_recording.id : '',
              'published_current_api_recording': profile_data[0].json.hasOwnProperty('spec_info') ? profile_data[0].json.spec_info.api_recording.name : 'NA'
            });
          } else {
            controller.setProperties({
              'api_recordings_list': apiRecordingsList,
              'current_api_recording': profile_data[0].json.hasOwnProperty('spec_info') ? profile_data[0].json.spec_info.api_recording.name : current_api_recording,
              'current_api_recording_id': profile_data[0].json.hasOwnProperty('spec_info') ? profile_data[0].json.spec_info.api_recording.id : ''
            });
          }
        });
      }
      controller.setProperties({
        'model': model,
        'sub_type': model.attributes.sub_type,
        'profile_data': model.attributes.profile_data,
        'diff': model.attributes.diff,
        'profile_id': this.profile_id,
        'current_file': current_file,
        'file_id': '',
        'changed_labels': model.attributes.changed_labels,
        'diffLength': this.setDiffLength(model),
        'file_validated': false
      });
    },
    setDiffLength(model) {
      if (model.attributes.diff !== null) {
        return Object.keys(model.attributes.diff).length;
      } else {
        return 0;
      }
    }
  });
  _exports.default = _default;
});