define("abcloud-ui/controllers/policies/profiles/api-session-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    errorMessage: "",
    actions: {
      confirmPublish() {
        this.send("saveProfile", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      },
      saveProfile(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var location_of_token = {};
        var input_ok = new Set();
        var http_request_header_checkbox = document.getElementById("http_request_header_checkbox");
        var http_header_checkbox = document.getElementById("http_header_checkbox");
        var http_request_cookie_checkbox = document.getElementById("http_request_cookie_checkbox");
        var http_request_api_payload_checkbox = document.getElementById("http_request_api_payload_checkbox");
        var http_request_header_input = document.getElementById("http_request_header_input");
        var http_header_input = document.getElementById("http_header_input");
        var http_request_cookie_input = document.getElementById("http_request_cookie_input");
        var http_request_api_payload_input = document.getElementById("http_request_api_payload_input");
        if (http_request_header_checkbox.checked) {
          if (http_request_header_input.value) {
            location_of_token["http_request_header"] = http_request_header_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        if (http_header_checkbox.checked) {
          if (http_header_input.value) {
            location_of_token["http_header"] = http_header_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        if (http_request_cookie_checkbox.checked) {
          if (http_request_cookie_input.value) {
            location_of_token["http_request_cookie"] = http_request_cookie_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        if (http_request_api_payload_checkbox.checked) {
          if (http_request_api_payload_input.value) {
            location_of_token["http_request_api_payload"] = http_request_api_payload_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        var request_obj = {
          data: {
            type: "profile",
            attributes: {
              sub_type: "api_session_token",
              name: name,
              description: description,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Request Header": location_of_token["http_request_header"] ? location_of_token["http_request_header"] : "--Unchecked--",
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Header": location_of_token["http_header"] ? location_of_token["http_header"] : "--Unchecked--",
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Request Cookie": location_of_token["http_request_cookie"] ? location_of_token["http_request_cookie"] : "--Unchecked--",
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Request API Payload": location_of_token["http_request_api_payload"] ? location_of_token["http_request_api_payload"] : "--Unchecked--"
              },
              json: {
                location_of_token: location_of_token
              }
            }
          }
        };
        if (name && description && !input_ok.has(false)) {
          let url = this.urls.URL_POLICY_PROFILE(this.profile_id);
          this.publish.profile(action, "POST", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          // -- if there was a file validation error and yet user tries to save/publish profile,
          //inject correct notification against file upload instead of the usual Mandatory field error
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            tokenFilledValue: !input_ok.has(false)
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.AT_LEAST_ONE_OPTION);
        }
      }
    }
  });
  _exports.default = _default;
});