define("abcloud-ui/templates/system/hosted/localconsole/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uEAWN3bd",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/localcomponent-form\",null,[[\"togglePassphraseView\",\"toggleConfigPanel\",\"cancel\",\"createLocalcomponent\",\"localcomponent\",\"editView\",\"localcomponentType\"],[[27,\"action\",[[22,0,[]],\"togglePassphraseView\"],null],[27,\"action\",[[22,0,[]],\"toggleConfigPanel\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"onUpdateLocalconsole\"],null],[23,[\"localconsole\"]],true,\"localconsole\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/localconsole/edit.hbs"
    }
  });
  _exports.default = _default;
});