define("abcloud-ui/components/policies-tenant/policies-helper-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      toggleJsonView() {
        var displayStatus = Ember.$(".policies_expandable_json_div").css('display');
        if (displayStatus === 'none') {
          Ember.$(".policies_expandable_json_div").css('display', 'block');
          Ember.$("#expandable_div_caret").attr('class', 'fa fa-caret-down');
        } else {
          Ember.$(".policies_expandable_json_div").css('display', 'none');
          Ember.$("#expandable_div_caret").attr('class', 'fa fa-caret-right');
        }
      }
    }
  });
  _exports.default = _default;
});