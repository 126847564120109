define("abcloud-ui/controllers/policies/profiles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    profileHeadings: ["Status", "Type", "Name", "Description", "Committed by", "Committed on", "Actions"],
    actions: {
      transitionToEditView(profile_id, subType) {
        switch (subType) {
          case "session_termination":
            this.transitionToRoute("policies.profiles.session-termination.edit", profile_id);
            break;
          default:
            this.transitionToRoute("policies.profiles.edit", profile_id);
        }
      },
      deleteProfile(profile_id) {
        var CLEAR_DURATION = this.constants.CLEAR_DURATION;
        var url = this.urls.URL_POLICY_PROFILE(profile_id);
        if (confirm("Are you sure you want to delete this Profile?")) {
          this.ajax.request(url, {
            method: "DELETE"
          }).then(data => {
            if (data && data.errors && data.errors[0].detail) {
              this.notifications.error(data.errors[0].detail, {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            } else {
              this.notifications.success("Deleted successfully!", {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            }
            url = this.urls.URL_POLICY_PROFILE();
            this.ajax.request(url, {
              method: "GET"
            }).then(profiles => {
              this.set("model", profiles);
            });
          }).catch(() => {
            this.notifications.error("Failed to delete!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
          });
        }
      }
    }
  });
  _exports.default = _default;
});