define("abcloud-ui/components/im-design-system/im-skeleton/skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ["loading-skeleton"]
  });
  _exports.default = _default;
});