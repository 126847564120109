define("abcloud-ui/routes/deployments/stand-alone-controller/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    redirect: function () {
      this.transitionTo("deployments.stand-alone-controller.list");
    }
  });
  _exports.default = _default;
});