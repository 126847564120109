define("abcloud-ui/constants/notification-texts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NOTIFICATIONS = void 0;
  const NOTIFICATIONS = {
    CONTROLLER_CREATION_SUCCESS: "Controller successfully created",
    COPIED_SUCCESSFFULLY: "Copied to clipboard successfully.",
    USER_CREATION_SUCCESSFULL: "User successfully created",
    DELETE_FAILED: "Sorry, something went wrong and we couldn't delete the record.",
    CUSTOMER_UPDATED_SUCCESSFULLY: "Customer details were updated successfully",
    USER_UPDATED_SUCCESSFULLY: "User details were updated successfully",
    CUSTOMER_ADDED_SUCCESSFULLY: "Customer was added successfully",
    USER_ADDED_SUCCESSFULLY: "User was added successfully",
    CUSTOMER_DELETED_SUCCESSFULLY: "Customer was deleted successfully",
    USER_DELETED_SUCCESSFULLY: "User was deleted successfully",
    TENANT_ADDED_SUCCESSFULLY: "Tenant was added successfully",
    TENANT_UPDATED_SUCCESSFULLY: "Tenant was updated successfully",
    TENANT_DELETED_SUCCESSFULLY: "Tenant was deleted successfully",
    ACCOUNT_UPDATED_SUCCESSFULLY: "Your account was successfully updated.",
    SSO_UPDATED_SUCCESFULLY: "SSO Details updated successfully",
    ADMIN_PASSWORD_RESET_SUCCESSFULLY: "Password reset successfully",
    SUCCESSFUL_USER_CREATION(username, email) {
      return ["You successfully created a new user account for ", username, ".\nAn email with login instructions has been sent to ", email, "."].join(" ");
    }
  };
  _exports.NOTIFICATIONS = NOTIFICATIONS;
});