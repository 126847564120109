define("abcloud-ui/routes/policies/profiles/session-termination/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    server: Ember.inject.service('ab-ajax-requests/server'),
    profile_id: '',
    current_profile: '',
    diffLenth: 0,
    model(params) {
      this.set('profile_id', params.id);
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-CV-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var request1 = Ember.$.getJSON(this.urls.URL_POLICY_PROFILE(params.id));
      return Ember.RSVP.hash({
        profile: request1
      });
    },
    setupController(controller, model) {
      model = model.profile.data;
      controller.setProperties({
        'model': model,
        'sub_type': model.attributes.sub_type,
        'profile_data': model.attributes.profile_data,
        'diff': model.attributes.diff,
        'profile_id': this.profile_id,
        'changed_labels': model.attributes.changed_labels,
        'diffLength': this.setDiffLength(model)
      });
    },
    setDiffLength(model) {
      if (model.attributes.diff !== null) {
        return Object.keys(model.attributes.diff).length;
      } else {
        return 0;
      }
    }
  });
  _exports.default = _default;
});