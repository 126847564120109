define("abcloud-ui/templates/policies/profiles/session-termination/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BCE7LDFD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 policy_card_view\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"pure-u-17-24\"],[9],[0,\"\\n          Session Termination Profile\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"policies-tenant/profile-components/session-termination/profile-form\",null,[[\"saveProfile\",\"transitionToListView\",\"confirmPublish\",\"profile\",\"disabled\",\"errorMessage\"],[[27,\"action\",[[22,0,[]],\"saveProfile\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[23,[\"model\"]],\"\",[23,[\"errorMessage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/session-termination/new.hbs"
    }
  });
  _exports.default = _default;
});