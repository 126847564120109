define("abcloud-ui/services/query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    query_params: {},
    init() {
      this._super(...arguments);
      this.set('query_params', {
        dart_category: localStorage.getItem('dart_category'),
        from_time: localStorage.getItem('from_time'),
        to_time: localStorage.getItem('to_time'),
        timeSelectedByUser: localStorage.getItem('timeSelectedByUser'),
        page: localStorage.getItem('page'),
        alert_severity: localStorage.getItem('alert_severity'),
        type: localStorage.getItem('type'),
        is_api: localStorage.getItem('is_api'),
        recording_complete: localStorage.getItem('recording_status')
      });
    },
    setParams(params) {
      for (let param in params) {
        localStorage.setItem(param, params[param]);
      }
    },
    setParam(key, val) {
      localStorage.setItem(key, val);
    },
    getParams() {
      let query_params = {};
      query_params = {
        'dart_category': localStorage.getItem('dart_category'),
        'from_time': localStorage.getItem('from_time'),
        'to_time': localStorage.getItem('to_time'),
        'timeSelectedByUser': localStorage.getItem('timeSelectedByUser'),
        'page': localStorage.getItem('page'),
        'alert_severity': localStorage.getItem('alert_severity'),
        'type': localStorage.getItem('type'),
        'is_api': localStorage.getItem('is_api'),
        'recording_status': localStorage.getItem('recording_status')
      };
      return query_params;
    },
    getParam(param) {
      return localStorage.getItem(param);
    },
    resetParams() {
      let default_query_params = this.query_params;
      for (let param in default_query_params) {
        localStorage.setItem(param, default_query_params[param]);
      }
    }
  });
  _exports.default = _default;
});