define("abcloud-ui/helpers/increment-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function incrementBy(_ref) {
    let [_incrementBy, number] = _ref;
    return +number + _incrementBy;
  });
  _exports.default = _default;
});