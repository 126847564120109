define("abcloud-ui/components/policies-tenant/policies-api-recorder/schedule-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      chooseScheduleInfo(event) {
        if (event.target.id === 'auto_stop') {
          Ember.$('#manualTime').addClass('light_text');
          Ember.$('#autoTime').removeAttr('disabled');
        } else if (event.target.id === 'manual_stop') {
          Ember.$('#autoTime').attr('disabled', 'true');
          Ember.$('#manualTime').removeClass('light_text');
        }
      }
    }
  });
  _exports.default = _default;
});