define("abcloud-ui/components/password-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      isValidPassword(input_id) {
        if (input_id === "newPassword") {
          let enteredPassword = document.getElementById(input_id).value;
          if (enteredPassword.match(/^(?=[A-Za-z0-9!@#$%^&*_+-]{8,64}$).*$/)) {
            Ember.$("#pwdLength").removeClass("defaultPwdColor");
            Ember.$("#pwdLength").addClass("pwdCriteriaMatch");
          } else {
            Ember.$("#pwdLength").removeClass("pwdCriteriaMatch");
            Ember.$("#pwdLength").addClass("defaultPwdColor");
          }
          if (enteredPassword.match(/^(?=.*[A-Z]).*$/)) {
            Ember.$("#pwdUppercaseChar").removeClass("defaultPwdColor");
            Ember.$("#pwdUppercaseChar").addClass("pwdCriteriaMatch");
          } else {
            Ember.$("#pwdUppercaseChar").removeClass("pwdCriteriaMatch");
            Ember.$("#pwdUppercaseChar").addClass("defaultPwdColor");
          }
          if (enteredPassword.match(/^(?=.*\d).*$/)) {
            Ember.$("#pwdNumericChar").removeClass("defaultPwdColor");
            Ember.$("#pwdNumericChar").addClass("pwdCriteriaMatch");
          } else {
            Ember.$("#pwdNumericChar").removeClass("pwdCriteriaMatch");
            Ember.$("#pwdNumericChar").addClass("defaultPwdColor");
          }
          if (enteredPassword.match(/^(?=[A-Za-z0-9!@#$%^&*_+-]{8,64}$)(?=.*[A-Z])(?=.*\d)(?=.*[A-Za-z]).*$/)) {
            this.set("validPassword", true);
          } else {
            this.set("validPassword", false);
          }
        } else if (input_id === "confirmNewPassword") {
          let enteredPassword = document.getElementById(input_id).value;
          if (enteredPassword === document.getElementById("newPassword").value) {
            this.set("passwordMatching", true);
          } else {
            this.set("passwordMatching", false);
          }
        }
      },
      togglePasswordVisibility() {
        this.send("togglePasswordVisibility");
      }
    }
  });
  _exports.default = _default;
});