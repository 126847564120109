define("abcloud-ui/components/exceptions/advanced-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showToolTip(element_id, tooltip_title) {
      Ember.$("#" + element_id).tooltip("dispose");
      Ember.$("#" + element_id).tooltip({
        trigger: "manual",
        title: tooltip_title
      });
      Ember.$("#" + element_id).tooltip("show");
      Ember.$("#" + element_id).addClass("invalid_input");
    },
    actions: {
      isValidNumber(element) {
        if (element.target.id === "port_input" && element.target.value > 65535) {
          this.showToolTip(element.target.id, this.constants.VALID_PORT_NUMBER);
        } else if (element.target.value && (element.target.value <= 0 || isNaN(element.target.value))) {
          this.showToolTip(element.target.id, this.constants.VALID_POSITIVE_NUMBER);
        } else {
          Ember.$("#" + element.target.id).tooltip("hide");
          Ember.$("#" + element.target.id).removeClass("invalid_input");
        }
      },
      toggleAdvancedSearch() {
        if (Ember.$(".advanced_options").css("display") === "block") {
          //-- reset all the input search fields once the advanced search is closed
          this.ADVANCED_SEARCH_OPTIONS.forEach(input_search_element => {
            document.getElementById(input_search_element.input_id).value = "";

            //-- reset the negation buttons for all search fields
            Ember.$("#" + input_search_element.negation_id).removeClass("activated");
            if (this.constants.NUMBER_INPUT_NEGATION_BUTTONS.has(input_search_element.negation_id)) {
              Ember.$("#" + input_search_element.negation_id).addClass("deactivated number");
            } else {
              Ember.$("#" + input_search_element.negation_id).addClass("deactivated text");
            }
          });

          //-- flipping this boolean also flips the pagination and help text under time filter
          this.set("isAdvancedSearch", false);
          Ember.$(".advanced_options").hide();
          Ember.$(".standAloneTimeFilter").show();
          Ember.$("#search_icon").show();
          this.selectTimeRange(this.originalTimeSelectedByUser, "", "resetDataStore");
        } else {
          this.set("isAdvancedSearch", true);
          Ember.$(".standAloneTimeFilter").hide();
          Ember.$(".advanced_options").show();
          Ember.$("#search_icon").hide();
        }
      },
      selectNegation(element) {
        if (element.target.value === "false") {
          Ember.$("#" + element.target.id).removeClass("deactivated text");
          Ember.$("#" + element.target.id).removeClass("deactivated number");
          Ember.$("#" + element.target.id).addClass("activated");
          Ember.$("#" + element.target.id).val("true");
        } else if (element.target.value === "true") {
          Ember.$("#" + element.target.id).removeClass("activated");
          if (this.constants.NUMBER_INPUT_NEGATION_BUTTONS.has(element.target.id)) {
            Ember.$("#" + element.target.id).addClass("deactivated number");
          } else {
            Ember.$("#" + element.target.id).addClass("deactivated text");
          }
          Ember.$("#" + element.target.id).val("false");
        }
      },
      selectTimeRange(from_time, to_time) {
        this.selectTimeRange(from_time, to_time, "advanced");
      },
      advancedSearch() {
        this.advancedSearch();
      }
    }
  });
  _exports.default = _default;
});