define("abcloud-ui/routes/insights/dashboard/discovery", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    qp: Ember.inject.service('query-params'),
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    doughnutChart: Ember.inject.service('doughnut-chart'),
    barChart: Ember.inject.service('bar-chart'),
    lineChart: Ember.inject.service('line-chart'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    filterParams: '',
    model(params) {
      var urls = this.urls;
      params = this.qp.getParams();
      this.set('filterParams', params);
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`
        },
        'X-AB-Trace-ID': this.urls.generate_trace_id()
      });
      var request = Ember.$.getJSON(urls.URL_GET_USERS(), params);
      this.manage_requests.add_request(this.routeName, request);
      return Ember.RSVP.hash({
        users: request
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        'showR1C1Preloader': true,
        'showR1C2Preloader': true,
        'showR1C3Preloader': true,
        'showR2C1Preloader': true,
        'showR2C2Preloader': true,
        'showR2C3Preloader': true,
        'from_time': this.qp.getParam('from_time'),
        'to_time': this.qp.getParam('to_time'),
        'timeSelectedByUser': this.qp.getParam('timeSelectedByUser')
      });
      var urls = this.urls;
      var params = this.filterParams;
      // var self = this;
    },

    renderTemplate() {
      this.render("insights.dashboard.discovery", {
        into: "insights.dashboard",
        outlet: "security"
      });
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          'from_time': parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
          'to_time': parseInt(_moment.default.utc().valueOf() / 1000),
          'showPreloader': true,
          'showAlertsRequestPreloader': true,
          'showAlertsByNameRequestPreloader': true,
          'showAlertsByPolicyNameRequestPreloader': true,
          'showAlertsByUserRequestPreloader': true,
          'showAlertsByActionRequestPreloader': true,
          'showSummaryTransactionsRequestPreloader': true,
          'showApisRequestPreloader': true,
          'showSummaryTransactionsByTypeRequestPreloader': true,
          'showSummaryEventsByApiPreloader': true,
          'showSummaryEventsByClientIpPreloader': true,
          'showSummaryEventsByDestIpPreloader': true,
          'showSummaryEventsByDestPortPreloader': true,
          'timeSelectedByUser': "86400",
          'showSummaryApiEventsByClientIpPreloader': true,
          'showSummaryApiEventsByDestPortPreloader': true,
          'showSummaryApiEventsByDestIpPreloader': true,
          'showSummaryRawEventsPreloader': true,
          'showDartRequestPreloader': true,
          'showDartDetectionsRequestPreloader': true,
          'showAlertTotalPreloader': true,
          'showDartTotalPreloader': true,
          'showEventTotalPreloader': true,
          'showApiTotalPreloader': true,
          'showTransactionTotalPreloader': true
        });
      }
    }
  });
  _exports.default = _default;
});