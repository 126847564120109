define("abcloud-ui/components/policies-tenant/policies-protected-aws-s3-file-downloads/user-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    restApiAuthTokenGenerated: false,
    inputAuthTokenIdSetInController: "",
    didInsertElement() {
      this.set("inputAuthTokenIdSetInController", "restApiAuthToken");
      if (this.disabledStatus) {
        this.set("inputAuthTokenIdSetInController", "restApiAuthToken" + this.disabledStatus);
        document.getElementById("restApiAuthTokenDisabled").disabled = true;
      }
    },
    actions: {
      chooseUserSessionProfile(user_session_id, chosen_option, index) {
        this.chooseUserSessionProfile(user_session_id, chosen_option, index);
      },
      generateRestApiAuthToken() {
        this.generateRestApiAuthToken();
      },
      collectInput(event) {
        this.collectInput(event.target);
      }
    }
  });
  _exports.default = _default;
});