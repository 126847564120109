define("abcloud-ui/helpers/type-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeOf = typeOf;
  function typeOf(_ref) {
    let [value] = _ref;
    var type = typeof value;
    return type;
  }
  var _default = Ember.Helper.helper(typeOf);
  _exports.default = _default;
});