define("abcloud-ui/services/string-modifiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    titlize(lowercase_string) {
      var titlized_string = lowercase_string.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
      return titlized_string;
    },
    capitalize(lowercase_string) {
      var capitalized_string = lowercase_string.charAt(0).toUpperCase() + lowercase_string.slice(1);
      return capitalized_string;
    }
  });
  _exports.default = _default;
});