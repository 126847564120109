define("abcloud-ui/routes/customer/account/self-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    beforeModel() {
      if (!this.auth.isLoggedIn("customer")) {
        this.transitionTo("/customer/login");
      }
    },
    model() {
      return this.store.findRecord("tenant_user", this.auth.getTenantUserId());
    },
    setupController(controller, model) {
      const {
        first_name,
        last_name,
        email,
        role_level
      } = model;
      controller.setProperties({
        firstName: first_name,
        lastName: last_name,
        email,
        roleLevel: role_level,
        tenant: model,
        createUserAbility: this.auth.getTenantCreateUserAbility(),
        isSuperAdmin: model.role_level === this.constants.SUPER_ADMIN
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          sAccountUpdateLoading: false,
          tenant: "",
          errorStatus: "",
          success: "",
          createUserAbility: "",
          isSuperAdmin: "",
          userWantsToEditPassword: false,
          validPassword: null,
          confirmValidPassword: null,
          passwordMatching: null,
          resetPwd: false,
          passwordResetError: this.constants.SOMETHING_WENT_WRONG,
          customerTenantLoginType: this.auth.getTenantLoginType(),
          customerFirstLogIn: !!(this.auth.getTenantIsFirstLogin() === "true"),
          customerPasswordWasReset: !!(this.auth.getTenantPasswordWasReset() === "true"),
          password: "",
          newPassword: "",
          confirmNewPassword: "",
          firstName: "",
          lastName: "",
          email: "",
          roleLevel: "",
          validations: {
            isFormValid: false,
            firstName: {
              isValid: true,
              required: true,
              format: {
                with: this.constants.NAME_REGEX,
                invalidFeedback: this.constants.INVALID_NAME
              },
              length: {
                max: this.constants.MAX_INPUT_CHARS,
                invalidFeedback: this.constants.INPUT_TOO_LONG({
                  fControlLabel: "First Name",
                  maxLen: this.constants.MAX_INPUT_CHARS
                })
              }
            },
            lastName: {
              isValid: true,
              required: true,
              format: {
                with: this.constants.NAME_REGEX,
                invalidFeedback: this.constants.INVALID_NAME
              },
              length: {
                max: this.constants.MAX_INPUT_CHARS,
                invalidFeedback: this.constants.INPUT_TOO_LONG({
                  fControlLabel: "Last Name",
                  maxLen: this.constants.MAX_INPUT_CHARS
                })
              }
            },
            password: {
              isValid: null,
              required: true
            }
          }
        });
      }
    },
    renderTemplate() {
      this.render("customer.account.self-account", {
        outlet: "account-options"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("customer.account.self-account");
        controller.set("isSelfAccountLoading", true);
        transition.promise.finally(function () {
          controller.set("isSelfAccountLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});