define("abcloud-ui/routes/system/user-authentication/sso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    utils: Ember.inject.service("utils"),
    model(params) {
      var url = this.urls.URL_GET_SSO_CONFIG();
      Ember.$.ajaxSetup({
        headers: {
          Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
          "X-AB-Trace-ID": this.urls.generate_trace_id()
        }
      });
      var request = Ember.$.getJSON(url);
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      if (model.data.length) {
        const attr = model.data[0].attributes;
        const {
          provider_name,
          provider_type,
          json
        } = attr;
        const {
          client_id,
          issuer_url,
          redirect_url,
          unauthorized_error
        } = json;
        let url = this.urls.SYSTEM_FEATURES();
        this.server.request("GET", url, null, response => {
          if (response.data && response.data.length) {
            const ssoStatus = response.data.find(feature => feature.config_key === "sso_enabled");
            const isSsoEnabled = ssoStatus ? !!(ssoStatus.config_value === "true") : false;
            let validations = {
              isFormValid: true
            };
            if (isSsoEnabled) {
              validations = {
                ...validations,
                client_id: {
                  isValid: !!client_id,
                  required: true
                },
                issuer_url: {
                  isValid: !!issuer_url,
                  required: true,
                  format: {
                    with: this.constants.ISSUER_URL_REGEX,
                    invalidFeedback: this.constants.INVALID_ISSUER_URL
                  },
                  length: {
                    min: this.constants.MIN_ISSUER_URL_CHARS,
                    invalidFeedback: this.constants.EMPTY_ISSUER_URL
                  }
                },
                redirect_url: {
                  isValid: !!redirect_url,
                  required: true
                },
                unauthorized_error: {
                  isValid: true,
                  length: {
                    max: this.constants.MAX_DESCRIPTION_CHARS,
                    invalidFeedback: this.constants.INPUT_TOO_LONG({
                      fControlLabel: this.constants.SSO_ERROR_NOTIFICATION_TEXT,
                      maxLen: this.constants.MAX_DESCRIPTION_CHARS
                    })
                  }
                }
              };
            }
            controller.setProperties({
              features: response.data,
              ssoEnabled: isSsoEnabled,
              validations: this.utils.validateForm(validations)
            });
          }
        }, error => {});
        controller.setProperties({
          provider_name,
          provider_type,
          client_id,
          issuer_url,
          redirect_url,
          unauthorized_error,
          isIssuerUrlValid: true,
          originalSsoConfig: attr
        });
      }
    },
    renderTemplate() {
      this.render("system.user-authentication.sso", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          editSso: false,
          ssoEnabled: false,
          isIssuerUrlValid: false,
          provider_name: "pn_okta",
          provider_type: "pt_oidc",
          client_id: "",
          issuer_url: "",
          redirect_url: "",
          unauthorized_error: "",
          originalSsoConfig: {}
        });
      }
    }
  });
  _exports.default = _default;
});