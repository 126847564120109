define("abcloud-ui/controllers/admin/customers/add-tenant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    utils: Ember.inject.service("utils"),
    notifications: Ember.inject.service(),
    environmentProtocol: "",
    resourceLoginPath: "",
    init() {
      this._super(...arguments);
      this.setProperties({
        environmentProtocol: this.utils.getEnvironmentWebProtocol(),
        resourceLoginPath: this.utils.getLoginPathForResource("customer"),
        hosting: "ab",
        region: "us",
        has_hosted_localbay: true,
        tenant_support_user: true,
        is_trial_tenant: false,
        is_customer_created: false,
        tenantFullName: "",
        tenantEmail: "",
        tenantPassword: "",
        tenantSubdomain: "",
        error: "",
        passwordVisibility: false,
        is_processing: false,
        tenant_support_user_email: "",
        tenant_support_user_password: "",
        tenant_subdomain: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        validations: {
          isFormValid: false,
          tenant_subdomain: {
            isValid: null,
            required: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Sub domain",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          first_name: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "First name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          last_name: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Last name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          email: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          },
          password: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.PASSWORD_REGEX,
              invalidFeedback: this.constants.PASSWORD_NOT_MET_REQUIREMENTS
            }
          }
        }
      });
    },
    actions: {
      formatTenantSubDomain() {
        this.set("tenant_subdomain", this.utils.preFormatSubDomain(this.tenant_subdomain));
      },
      togglePasswordVisibility() {
        this.set("passwordVisibility", !this.passwordVisibility);
      },
      setRegion(regionVal) {
        this.set("region", regionVal);
      },
      submit: function () {
        this.setProperties({
          is_processing: true,
          error: ""
        });
        const params = {
          tenant_subdomain: this.tenant_subdomain,
          customer_id: this.model.id,
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          is_trial_tenant: false,
          has_hosted_localbay: false,
          tenant_support_user: true,
          hosting: "ab",
          region: "us"
        };
        const customer_tenant = this.store.createRecord("customer_tenant", params);
        customer_tenant.save().then(customer_tenant => {
          this.setProperties({
            error: "",
            is_processing: false,
            is_customer_created: true,
            tenantFullName: customer_tenant.first_name + " " + customer_tenant.last_name,
            tenantEmail: customer_tenant.email,
            tenantPassword: customer_tenant.password,
            tenantSubdomain: this.environmentProtocol + "://" + customer_tenant.tenant_subdomain + "." + this.resourceLoginPath,
            tenant_support_user_email: customer_tenant.tenant_support_user.email,
            tenant_support_user_password: customer_tenant.tenant_support_user_password
          });
          this.notifications.success(this.constants.TENANT_ADDED_SUCCESSFULLY);
        }).catch(_ref => {
          let {
            errors
          } = _ref;
          this.debug.log(errors);
          if (errors[0].detail === this.constants.UNPROCESSABLE_ENTITY) {
            this.setProperties({
              is_customer_created: false,
              is_processing: false,
              error: this.constants.SUBDOMAIN_NOT_AVAILABLE
            });
          } else {
            this.setProperties({
              is_customer_created: false,
              is_processing: false,
              error: this.constants.SOMETHING_WENT_WRONG
            });
          }
        });
      },
      backToTenantList() {
        this.transitionToRoute("admin.customers.customer-tenants", this.model.id);
      }
    }
  });
  _exports.default = _default;
});