define("abcloud-ui/templates/components/transactions/data-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hlpdcYVh",
    "block": "{\"symbols\":[\"transaction\",\"hdr\"],\"statements\":[[7,\"table\"],[11,\"class\",\"pure-table pure-table-horizontal data-list-table\"],[11,\"id\",\"transactions_table\"],[9],[0,\"\\n   \"],[7,\"thead\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tableHeaders\"]]],null,{\"statements\":[[0,\"        \"],[7,\"th\"],[9],[0,\"\\n          \"],[1,[22,2,[]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"   \"],[10],[0,\"\\n   \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"transactions/transaction-list-item\",null,[[\"model\",\"transition\",\"id\",\"firstTransaction\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"goToTransactionDetail\"],null],[22,1,[\"id\"]],[23,[\"firstTransaction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"   \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/transactions/data-list-table.hbs"
    }
  });
  _exports.default = _default;
});