define("abcloud-ui/helpers/nth-element-of-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (_ref) {
    let [array, index] = _ref;
    if (Ember.typeOf(array) === 'array') {
      return array[index];
    } else {
      return undefined;
    }
  });
  _exports.default = _default;
});