define("abcloud-ui/components/policies-tenant/policies-protected-aws-s3-file-downloads/download-activity-session-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      chooseActivitySessionProfile(activity_session_id, chosen_option) {
        this.chooseActivitySessionProfile(activity_session_id, chosen_option);
      }
    }
  });
  _exports.default = _default;
});