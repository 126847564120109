define("abcloud-ui/controllers/customer/account/tenant-users/create-new-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        isAddUserLoading: false,
        tenant: "",
        errorStatus: "",
        success: "",
        createUserRole: null,
        canCreateMultipleRoles: false,
        ssoEnabled: false,
        firstName: "",
        lastName: "",
        email: "",
        userRoles: this.constants.USER_ROLES,
        accessTypes: this.constants.LOGIN_ACCESS_TYPES,
        userAccessType: this.constants.LOGIN_ACCESS_TYPES[0].type,
        validations: {
          isFormValid: false,
          firstName: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "First name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          lastName: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Last name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          email: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          }
        }
      });
    },
    actions: {
      save() {
        /* 
          set is loading state
        */
        this.setProperties({
          isAddUserLoading: true,
          errorStatus: "",
          success: ""
        });
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
          },
          "X-CV-Trace-ID": this.urls.generate_trace_id()
        });
        var tenant_obj = this.store.createRecord("tenant-user", {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          role_level: this.createUserRole,
          login_type: this.userAccessType
        });
        tenant_obj.save().then(response => {
          this.set("isAddUserLoading", false);
          if (response.meta && response.meta.errors) {
            this.set("errorStatus", response.meta.errors[0].detail);
          } else {
            this.notifications.success(this.constants.USER_CREATION_SUCCESSFULL);
            this.transitionToRoute("customer.account.tenant-users");
          }
        }).catch(error => {
          this.set("isAddUserLoading", false);
          if (error.message === this.constants.UNPROCESSABLE_ENTITY) {
            this.set("errorStatus", this.constants.EMAIL_ALREADY_EXISTS);
          } else {
            this.set("errorStatus", this.constants.SOMETHING_WENT_WRONG);
          }
        });
      },
      formCancelTransition() {
        this.transitionToRoute("customer.account.self-account");
      }
    }
  });
  _exports.default = _default;
});