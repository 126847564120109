define("abcloud-ui/templates/components/system-localbay/localbay-component-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kK9ZPREp",
    "block": "{\"symbols\":[\"item\",\"val\",\"key\"],\"statements\":[[4,\"each\",[[23,[\"componentSet\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"card component_details\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[28,[\" \",[21,\"componentTypeName\"],\" \",[27,\"lowercase\",[[22,1,[\"health\",\"health_status\"]]],null]]]],[9],[0,\"\\n      \"],[1,[27,\"system-localbay/health-status-arrow\",null,[[\"status\"],[[22,1,[\"health\",\"health_status\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[22,1,[]]],null]],null,{\"statements\":[[4,\"if\",[[27,\"not-eq\",[[22,3,[]],\"health\"],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[0,\"\\n            \"],[7,\"label\"],[9],[1,[27,\"titleize\",[[22,3,[]]],null],false],[10],[0,\"  \"],[7,\"p\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2,3]},null],[0,\"      \"],[1,[27,\"system-localbay/localbay-component-card-health\",null,[[\"componentHealth\"],[[22,1,[\"health\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/localbay-component-card.hbs"
    }
  });
  _exports.default = _default;
});