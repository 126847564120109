define("abcloud-ui/templates/components/security-alerts/alerts-map-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fhk3yMgi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"section alert \",[23,[\"alertDetails\",\"attributes\",\"alert_severity\"]]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"label\"],[9],[0,\"\\n        Alert\\n        \"],[7,\"i\"],[12,\"class\",[23,[\"alertDetails\",\"attributes\",\"alert_severity\"]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"p\"],[11,\"class\",\"alert_date\"],[9],[1,[27,\"format-date\",[[23,[\"alertDetails\",\"attributes\",\"alert_timestamp\"]]],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"alert_name\"],[9],[1,[23,[\"alertDetails\",\"attributes\",\"alert_name\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[12,\"class\",[28,[\"alert_severity \",[23,[\"alertDetails\",\"attributes\",\"alert_severity\"]]]]],[9],[1,[23,[\"alertDetails\",\"attributes\",\"alert_severity\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"section\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"label\"],[9],[0,\"\\n        Geo-location\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"alert_date\"],[9],[1,[23,[\"alertDetails\",\"attributes\",\"alert_iab_data\",\"iab_src_geo_location\",\"city\"]],false],[0,\", \"],[1,[23,[\"alertDetails\",\"attributes\",\"alert_iab_data\",\"iab_src_geo_location\",\"state\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"alert_date\"],[9],[1,[23,[\"alertDetails\",\"attributes\",\"alert_iab_data\",\"iab_src_geo_location\",\"country\"]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"location_image\"],[11,\"class\",\"location_image\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/security-alerts/alerts-map-location.hbs"
    }
  });
  _exports.default = _default;
});