define("abcloud-ui/routes/insights/dashboard/security/data-leakage", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service("ajax"),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    doughnutChart: Ember.inject.service("doughnut-chart"),
    barChart: Ember.inject.service("bar-chart"),
    filterParams: "",
    queryParams: Ember.inject.service("query-params"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    model() {
      this.set("filterParams", this.queryParams.getParams());
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        secTabActive: true,
        observeTabActive: false,
        from_time: this.queryParams.getParam("from_time"),
        to_time: this.queryParams.getParam("to_time"),
        timeSelectedByUser: this.queryParams.getParam("timeSelectedByUser"),
        page: this.queryParams.getParam("page"),
        showR1C1Preloader: true,
        showR1C2Preloader: true,
        showR1C3Preloader: true,
        showR2C1Preloader: true,
        showR2C2Preloader: true,
        showR2C3Preloader: true
      });
      var urls = this.urls;
      var params = this.filterParams;
      var self = this;
      this.doughnutChart.setup();
      this.barChart.setup();

      // -- alert totals
      var alertsTotals = Ember.RSVP.hash({
        alertsTotalsPromise: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_ALERT_DATA_TOTAL_WITH_DETECTION("data_leakage"), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      alertsTotals.then(() => {
        var totals = alertsTotals._result.alertsTotalsPromise.totals.alerts;
        controller.setProperties({
          alertTotal: totals,
          showR1C1Preloader: false
        });
      });

      // -- by alert severity
      var alerts_request = Ember.RSVP.hash({
        alerts: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_ALERTS_WITH_DETECTION("data_leakage"), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      alerts_request.then(() => {
        var alerts = alerts_request._result.alerts.data[0];
        controller.setProperties({
          alerts: alerts,
          severityData: self.get("barChart").getChartData(alerts["attributes"]["chart_data"], alerts["attributes"]["chart_labels"]),
          severityOpts: self.get("barChart").getChartOptions(alerts["attributes"]["chart_center_text"]),
          showR1C2Preloader: false,
          severityDataAvailable: alerts["attributes"]["chart_data"].length === 0 ? false : true
        });
      });

      // -- by alert policy name
      var alerts_by_policy_name_request = Ember.RSVP.hash({
        alerts_by_policy_name: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_ALERTS_BY_POLICY_NAME_WITH_DETECTION("data_leakage"), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      alerts_by_policy_name_request.then(() => {
        var alerts_by_policy_name = alerts_by_policy_name_request._result.alerts_by_policy_name.data[0];
        controller.setProperties({
          alerts_by_policy_name: alerts_by_policy_name,
          policyNameData: self.get("doughnutChart").getChartData(alerts_by_policy_name["attributes"]["chart_data"], alerts_by_policy_name["attributes"]["chart_labels"]),
          policyNameOpts: self.get("doughnutChart").getChartOptions(alerts_by_policy_name["attributes"]["chart_center_text"]),
          showR2C3Preloader: false
        });
      });

      // -- by alert name
      var alerts_by_name_request = Ember.RSVP.hash({
        alerts_by_name: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_ALERTS_BY_NAME_WITH_DETECTION("data_leakage"), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      alerts_by_name_request.then(() => {
        var alerts_by_name = alerts_by_name_request._result.alerts_by_name.data[0];
        controller.setProperties({
          alerts_by_name: alerts_by_name,
          alertNameData: self.get("doughnutChart").getChartData(alerts_by_name["attributes"]["chart_data"], alerts_by_name["attributes"]["chart_labels"]),
          alertNameOpts: self.get("doughnutChart").getChartOptions(alerts_by_name["attributes"]["chart_center_text"]),
          showR2C2Preloader: false
        });
      });

      // -- by alert user
      var alerts_by_user_request = Ember.RSVP.hash({
        alerts_by_user: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_ALERTS_BY_USER_WITH_DETECTION("data_leakage"), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      alerts_by_user_request.then(() => {
        var alerts_by_user = alerts_by_user_request._result.alerts_by_user.data[0];
        controller.setProperties({
          alerts_by_user: alerts_by_user,
          alertUserData: self.get("doughnutChart").getChartData(alerts_by_user["attributes"]["chart_data"], alerts_by_user["attributes"]["chart_labels"]),
          alertUserOpts: self.get("doughnutChart").getChartOptions(alerts_by_user["attributes"]["chart_center_text"]),
          showR2C1Preloader: false
        });
      });

      // -- by alert action
      var alerts_by_action_request = Ember.RSVP.hash({
        alerts_by_action: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_ALERTS_BY_ACTION_WITH_DETECTION("data_leakage"), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      alerts_by_action_request.then(() => {
        var alerts_by_action = alerts_by_action_request._result.alerts_by_action.data[0];
        controller.setProperties({
          alerts_by_action: alerts_by_action,
          actionData: self.get("barChart").getChartData(alerts_by_action["attributes"]["chart_data"], alerts_by_action["attributes"]["chart_labels"]),
          actionOpts: self.get("barChart").getChartOptions("horizontal"),
          showR1C3Preloader: false,
          actionDataAvailable: alerts_by_action["attributes"]["chart_data"].length === 0 ? false : true
        });
      });
    },
    renderTemplate() {
      this.render("insights.dashboard.security.data-leakage", {
        into: "insights.dashboard.security",
        outlet: "dashboard_data"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
          to_time: parseInt(_moment.default.utc().valueOf() / 1000),
          showR1C1Preloader: true,
          showR1C2Preloader: true,
          showR1C3Preloader: true,
          showR2C1Preloader: true,
          showR2C2Preloader: true,
          showR2C3Preloader: true,
          alertTotal: 0
        });
      }
    }
  });
  _exports.default = _default;
});