define("abcloud-ui/routes/admin/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    beforeModel() {
      this.transitionTo("admin.users.list");
    },
    actions: {
      destroy: function (adminUser) {
        adminUser.destroyRecord().then(() => {
          this.notifications.success("Deleted successfully!");
        }).catch(() => {
          this.notifications.error("Something went wrong, could not delete the customer record.");
          adminUser.rollbackAttributes();
        });
        this.transitionTo("admin.users.list");
      }
    }
  });
  _exports.default = _default;
});