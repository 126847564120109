define("abcloud-ui/templates/policies/profiles/user-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nzKiwj4H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 policy_card_view\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-17-24\"],[9],[0,\"\\n              User Group Profile\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-7-24\"],[9],[0,\"\\n              \"],[7,\"div\"],[11,\"class\",\"help_tip\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"caret_shadow\"],[9],[10],[0,\"\\n                User Group profile defines the information to be used by CloudVector to obtain the group information of the Users.\\n                As an example, this could relate to the Active Directory Groups of a user or the Application groiups of a user.\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"policies-tenant/profile-components/edit-user-group\",null,[[\"profile\",\"saveUserGroup\",\"disabled\",\"transitionToListView\",\"confirmPublish\",\"errorMessage\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"saveUserGroup\"],null],\"\",[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[23,[\"errorMessage\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/user-groups.hbs"
    }
  });
  _exports.default = _default;
});