define("abcloud-ui/templates/components/password-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PjmKrCfV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"id\",[21,\"custom_id\"]],[12,\"class\",[28,[\"title-validator form-control\\n      \",[27,\"if\",[[27,\"eq\",[[23,[\"validPassword\"]],null],null],\"\",[27,\"if\",[[23,[\"validPassword\"]],\"is-valid\",\"is-invalid\"],null]],null]]]],[11,\"required\",\"\"],[11,\"title\",\"8 characters minimum\"],[11,\"label\",\"New password\"],[11,\"minLength\",\"8\"],[11,\"maxLength\",\"48\"],[12,\"onkeyup\",[27,\"action\",[[22,0,[]],\"isValidPassword\",[23,[\"custom_id\"]]],null]],[11,\"autocomplete\",\"new-password\"],[11,\"type\",\"password\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"invalid-feedback\"],[9],[0,\"\\n    \"],[1,[21,\"invalidFeedback\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/password-field.hbs"
    }
  });
  _exports.default = _default;
});