define("abcloud-ui/controllers/policies/policies/data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    current_profile: "",
    current_profile_id: "",
    current_profile_object: "",
    current_localbay: "",
    current_localbay_id: "",
    profiles_list: {},
    sendToAbCloud: false,
    isExportProfileChosen: false,
    errorMessage: "",
    actions: {
      transitionToListView() {
        this.transitionToRoute("policies.policies");
      },
      savedataExportPolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var data_options = {};
        var data_export_profile = {};
        if (document.getElementById("exportOriginal").checked) {
          data_options["export_original_data_events"] = "true";
        } else if (document.getElementById("exportObfuscated").checked) {
          data_options["export_obfuscated_data_events"] = "true";
        } else if (document.getElementById("exportHeadersOnly").checked) {
          data_options["export_data_events_headers_only"] = "true";
        }
        this.profiles_list.forEach(profile => {
          if (Number(profile.data.id) === Number(this.current_profile_id)) {
            profile = profile.data.attributes;
            if (Ember.$.isEmptyObject(profile.profile_data)) {
              profile.profile_data = [];
              profile.profile_data[0] = {};
              profile.profile_data[0]["json"] = {};
              profile.profile_data[0]["json"]["api"] = "";
              profile.profile_data[0]["json"]["auth_token"] = "";
              profile.profile_data[0]["json"]["receiver_type"] = "";
              profile.profile_data[0]["json"]["source_type"] = "";
            }
            data_export_profile = {
              data: {
                id: profile.profile_data[0].profile_id,
                type: "profile",
                attributes: {
                  sub_type: profile.sub_type,
                  profile_type: profile.profile_type,
                  name: profile.name,
                  description: profile.description,
                  json: {
                    data_receiver_information: profile.profile_data[0].json.data_receiver_information
                  }
                }
              }
            };
          }
        });
        var profiles_ids = [Number(this.current_profile_id)];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: "",
          name: ""
        };
        var changeset = {
          "nameLabel.Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Installed_on": this.current_localbay,
          "dataOptionsLabel.Data_Options.Export_Original_Data_Events": document.getElementById("exportOriginal").checked ? "true" : "--Unchecked--",
          "dataOptionsLabel.Data_Options.Export_Obfuscated_Data_Events": document.getElementById("exportObfuscated").checked ? "true" : "--Unchecked--",
          "dataOptionsLabel.Data_Options.Export_Data_Events_Headers_Only": document.getElementById("exportHeadersOnly").checked ? "true" : "--Unchecked--",
          "exportOptionsLabel.Export_To_Profile": data_export_profile.data.attributes.name
        };
        var json_object = {
          data_options: data_options,
          data_export_profile: data_export_profile
        };
        this.services.build_policy_object_for_server.set_type("policy", "data_export");
        this.services.build_policy_object_for_server.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.services.build_policy_object_for_server.set_changeset(changeset);
        this.services.build_policy_object_for_server.set_json(json_object);
        var request_object = this.services.build_policy_object_for_server.get_request_object();
        if (name && description) {
          var url = this.services.urls.URL_POLICY();
          this.services.publish.policy(action, "POST", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      }
    }
  });
  _exports.default = _default;
});