define("abcloud-ui/components/policies-tenant/policies-protected-aws-s3-file-downloads/group-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      if (this.disabledStatus) {
        document.getElementById('restApiHostIpFromGroupInfoDisabled').disabled = true;
        document.getElementById('restApiAuthTokenFromGroupInfoDisabled').disabled = true;
        document.getElementById('auto_generate_for_groupDisabled').disabled = true;
      }
    },
    actions: {
      chooseUserGroupProfile(user_group_id, chosen_option) {
        this.chooseUserGroupProfile(user_group_id, chosen_option);
      },
      chooseDplet(dplet_id, chosen_option) {
        this.chooseDplet(dplet_id, chosen_option);
      },
      generateRestApiAuthToken() {
        if (document.getElementById('auto_generate_for_group').checked) {
          this.generateRestApiAuthToken('group_information');
        } else {
          this.set('restApiAuthTokenForGroupInfo', '');
        }
      },
      collectInput(event) {
        this.collectInput(event.target);
      }
    }
  });
  _exports.default = _default;
});