define("abcloud-ui/routes/exceptions/events", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    qp: Ember.inject.service('query-params'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    requestApis: '',
    queryParams: {
      is_api: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      advancedPage: {
        refreshModel: false
      }
    },
    timeSelectedByUserOnRoute: '',
    time_before_request: '',
    beforeModel() {
      this.isLoggedIn = this.auth.isLoggedIn();
      if (!this.auth.isLoggedIn('customer')) {
        this.transitionTo('/customer/login');
      }
    },
    model(params) {
      let is_api = params.is_api;
      this.qp.setParam('is_api', is_api);
      params = this.qp.getParams();
      this.set('timeSelectedByUserOnRoute', params.timeSelectedByUser);
      this.set('isLoading', true);
      return this.store.query('event', params).then(events => {
        this.setProperties({
          time_before_request: parseInt(_moment.default.utc().valueOf() / 1000),
          params: params
        });
        return events;
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      const constants = this.constants;
      controller.setProperties({
        'model': model,
        'tableHeaders': constants.EVENT_TABLE_HEADERS,
        'lastHourOptionOnly': {
          '3600': 'Last Hour'
        },
        'timeSelectedByUser': this.qp.getParam('timeSelectedByUser'),
        'advancedTimeSelectedByUser': this.qp.getParam('timeSelectedByUser')
      });
      // -- auto-select first item per page
      // -- bug here causes transitionTo to fire several times
      // -- only happens for events, maybe filterType param issue

      if (model.content.length > 0) {
        controller.set('firstEvent', model.get('firstObject'));
      } else {
        controller.set('firstEvent', '');
        this.transitionTo('exceptions.events');
      }
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    },
    resetController(controller, isExiting, transition) {
      controller.set('isPageRefresh', false);
      controller.set('isLoading', false);

      //-- hide the advanced search when navigating along the sidenav
      if (controller.isAdvancedSearch) {
        //-- reset all the input search fields once the advanced search is closed
        this.constants.ADVANCED_SEARCH_OPTIONS.forEach(input_search_element => {
          document.getElementById(input_search_element.input_id).value = '';
        });

        //-- flipping this boolean also flips the pagination and help text under time filter
        controller.set('isAdvancedSearch', false);
        Ember.$('.advanced_options').hide();
        Ember.$('.standAloneTimeFilter').show();
        Ember.$('#search_icon').show();
      }

      // -- is_api="" for Raw Events view and is_api="true" for APIs view
      var is_api = transition.queryParams.is_api;
      if (isExiting || controller.is_api != is_api) {
        // isExiting would be false if only the route's model was changing
        controller.setProperties({
          'advanced_from_time': parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
          'advanced_to_time': parseInt(_moment.default.utc().valueOf() / 1000),
          'from_time': parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
          'to_time': parseInt(_moment.default.utc().valueOf() / 1000),
          'page': 1,
          'advancedPage': 1,
          'per_page': 6,
          'timeSelectedByUser': "3600",
          'advancedTimeSelectedByUser': "3600",
          'tagName': 'tr',
          'previousPageChoice': 5,
          'firstEvent': 0,
          'lastHourOptionOnly': {
            '3600': 'Last Hour'
          }
        });
      }
    },
    renderTemplate() {
      this.render("exceptions.events", {
        into: "exceptions",
        outlet: "alerts"
      });
    }
  });
  _exports.default = _default;
});