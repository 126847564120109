define("abcloud-ui/components/buttons/radio-btn", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ["bx--radio-button__wrapper"],
    uniqueName: "",
    init() {
      this._super(...arguments);
      this.set("uniqueName", (0, _uuid.v4)());
    }
  });
  _exports.default = _default;
});