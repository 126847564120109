define("abcloud-ui/controllers/system/health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    localcomponents: [],
    successLocalbays: null,
    errorLocalbays: null,
    unavailableLocalbays: null,
    totalLocalbays: null,
    showPreloader: true,
    actions: {
      getLocalbayDetails(localbay) {
        this.transitionToRoute('system.localbay-details', localbay.id);
      }
    }
  });
  _exports.default = _default;
});