define("abcloud-ui/components/dialogue-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
      this.setProperties({
        isConfirmChangesClicked: null,
        remainingTime: 0,
        confirmChangesIntervalId: ""
      });
    },
    closeModal() {
      this.$("#" + this.target).modal("hide");
      this.setProperties({
        isConfirmChangesClicked: null,
        remainingTime: 0,
        confirmChangesIntervalId: ""
      });
    },
    actions: {
      confirmChanges() {
        this.setProperties({
          isConfirmChangesClicked: true,
          remainingTime: this.timer
        });
        if (this.timer) {
          const remainingTimeInterval = setInterval(() => {
            this.set("remainingTime", this.remainingTime - 1);
            if (this.remainingTime === 0) {
              clearInterval(remainingTimeInterval);
            }
          }, 1000);
          this.set("confirmChangesIntervalId", setTimeout(() => {
            this.onConfirm();
            this.closeModal();
          }, this.timer * 1000));
        } else {
          this.onConfirm();
        }
      },
      cancelSubmission() {
        clearInterval(this.confirmChangesIntervalId);
        this.closeModal();
      }
    }
  });
  _exports.default = _default;
});