define("abcloud-ui/helpers/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function split(_ref) {
    let [value, splitBy, returnIndex] = _ref;
    if (!value) {
      return undefined;
    }
    return value.split(splitBy)[returnIndex];
  });
  _exports.default = _default;
});