define("abcloud-ui/components/json-raw-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showTransactionJSON: false,
    jsonStyle: 'Tree',
    raw_json: '',
    tagName: 'div',
    classNames: ['col-md-12'],
    isFilterTypeApi: false,
    caretId: 'tx_karat',
    caret: '',
    didReceiveAttrs() {
      this._super(...arguments);
      let self = this;
      var replacer = (match, pIndent, pKey, pVal, pEnd) => {
        var key = '<span class=json-key>';
        var val = '<span class=json-value>';
        var str = '<span class=json-string>';
        var r = pIndent || '';
        if (pKey) {
          r = r + key + pKey.replace(/[: ]/g, '') + '</span>: ';
        }
        if (pVal) {
          r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
        }
        return r + (pEnd || '');
      };
      var prettyPrint = obj => {
        try {
          var jsonLine = /^( *)("[\w-]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
          return JSON.stringify(obj, null, 10).replace(/&/g, '&amp;').replace(/\\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(jsonLine, replacer);
        } catch (inappropriate_data) {
          return '';
        }
      };
      this.set('raw_json', prettyPrint(this.structured_data_for_raw_json));
    },
    didInsertElement() {
      Ember.$('#autoFocusDiv').focus();
      let self = this;
      this.caret = Ember.$('#' + this.caretId + '');
      // -- automatically toggle this view open for raw events
      self.send('toggleTransactionJSONView');
    },
    willDestroyElement() {
      this.set('showTransactionJSON', false);
      this.caret.attr('class', 'fa fa-caret-right');
    },
    actions: {
      toggleTransactionJSONView() {
        if (this.showTransactionJSON == false) {
          this.set('showTransactionJSON', true);
          this.caret.attr('class', 'fa fa-caret-down');
          // -- scroll to show beginning of expanded json in smaller screens
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight + document.body.clientHeight,
            behavior: 'smooth'
          });
        } else {
          this.set('showTransactionJSON', false);
          this.caret.attr('class', 'fa fa-caret-right');
          window.scrollTo(0, 0);
        }
      },
      toggle(unique_identifier) {
        //unique_identifier is a class for subDiv and an id for caret
        var divDisplayStatus = Ember.$('.' + unique_identifier).css('display');
        if (divDisplayStatus === 'none') {
          Ember.$('.' + unique_identifier).css('display', 'block');
          Ember.$('#' + unique_identifier).attr('class', 'fa fa-caret-down');
        } else {
          Ember.$('.' + unique_identifier).css('display', 'none');
          Ember.$('#' + unique_identifier).attr('class', 'fa fa-caret-right');
        }
      }
    }
  });
  _exports.default = _default;
});