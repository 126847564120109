define("abcloud-ui/controllers/admin/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        plainTextPassword: "",
        error: "",
        isAddUserLoading: false,
        firstName: "",
        lastName: "",
        email: "",
        validations: {
          isFormValid: false,
          firstName: {
            isValid: null,
            required: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "First name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          lastName: {
            isValid: null,
            required: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Last name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          email: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          }
        }
      });
    },
    actions: {
      submit: function () {
        /* 
          set is loading state
        */
        this.set("isAddUserLoading", true);
        const params = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        };
        const admin_user = this.store.createRecord("admin_user", params);
        admin_user.save().then(response => {
          this.setProperties({
            plainTextPassword: response.data.plainTextPassword,
            error: "",
            isAddUserLoading: false
          });
          this.notifications.success(this.constants.USER_ADDED_SUCCESSFULLY);
        }).catch(() => {
          this.setProperties({
            error: this.constants.SOMETHING_WENT_WRONG,
            isAddUserLoading: false
          });
        });
      },
      navigateBackToUserList() {
        this.transitionToRoute("admin.users.list");
      }
    }
  });
  _exports.default = _default;
});