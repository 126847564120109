define("abcloud-ui/components/system-localbay/dplet-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      deleteDplet(dplet_id) {
        this.deleteDplet(dplet_id);
      },
      editDplet(dplet_id, dplet_sub_type) {
        this.editDplet(dplet_id, dplet_sub_type);
      },
      createMicrosensor() {
        this.createMicrosensor();
      }
    }
  });
  _exports.default = _default;
});