define("abcloud-ui/models/admin-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    plainTextPassword: _emberData.default.attr('string'),
    encryptedPassword: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('number')
  });
  _exports.default = _default;
});