define("abcloud-ui/templates/policies/policies/api-session-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KGBT94dM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"policy_card_view\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-8\"],[9],[0,\"\\n          API Session Token Policy\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-4\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"policies-tenant/policy-components/edit-api-session-token-policy\",null,[[\"savePolicy\",\"cancel\",\"localbay_list\",\"microsensor_list\",\"availableProfilesList\",\"linkedProfilesList\",\"current_localbay\",\"current_localbay_id\",\"selected_profile_ids\",\"current_microsensor\",\"current_microsensor_id\",\"severity_options\",\"period_options\",\"chosenSeverityLevels\",\"severity_level_responses\",\"session_token_violated\",\"session_token_not_present\",\"apiSessionTokenProfilesList\",\"sessionTerminationProfilesList\",\"disabledStatus\",\"isProfilesEmpty\",\"errorMessage\"],[[27,\"action\",[[22,0,[]],\"savePolicy\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[23,[\"localbay_list\"]],[23,[\"microsensor_list\"]],[23,[\"availableProfilesList\"]],[23,[\"linkedProfilesList\"]],[23,[\"current_localbay\"]],[23,[\"current_localbay_id\"]],[23,[\"selected_profile_ids\"]],[23,[\"current_microsensor\"]],[23,[\"current_microsensor_id\"]],[23,[\"constants\",\"SEVERITY_OPTIONS\"]],[23,[\"constants\",\"PERIOD_OPTIONS\"]],[23,[\"chosenSeverityLevels\"]],[23,[\"severity_level_responses\"]],[23,[\"session_token_violated\"]],[23,[\"session_token_not_present\"]],[23,[\"apiSessionTokenProfilesList\"]],[23,[\"sessionTerminationProfilesList\"]],\"\",[23,[\"isProfilesEmpty\"]],[23,[\"errorMessage\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/policies/api-session-token.hbs"
    }
  });
  _exports.default = _default;
});