define("abcloud-ui/components/exceptions/alert-transaction-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isCollapsed: true,
    elDisplayClass: 'collapsed',
    triggerClass: 'fa fa-caret-left',
    actions: {
      toggleCollapsed() {
        if (this.isCollapsed === true) {
          this.set('isCollapsed', false);
          this.set('elDisplayClass', '');
          this.set('triggerClass', 'fa fa-caret-down');
        } else {
          this.set('isCollapsed', true);
          this.set('elDisplayClass', 'collapsed');
          this.set('triggerClass', 'fa fa-caret-left');
        }
      }
    }
  });
  _exports.default = _default;
});