define("abcloud-ui/pods/docs/doc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "l19woe2z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"doc card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"col-md-10\"],[9],[0,\"\\n      \"],[5,\"html-node\",[],[[\"@nodes\"],[[22,0,[\"model\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/pods/docs/doc/template.hbs"
    }
  });
  _exports.default = _default;
});