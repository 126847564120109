define("abcloud-ui/templates/insights/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lMzwuhkP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-3-24 dashboard_sidenav\"],[9],[0,\"\\n\\n    \"],[1,[27,\"dashboard/side-navigation\",null,[[\"role\",\"toggleChildSidenav\"],[\"tablist\",[27,\"action\",[[22,0,[]],\"toggleChildSidenav\"],null]]]],false],[0,\"\\n\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-21-24\"],[9],[0,\"\\n    \"],[1,[27,\"outlet\",[\"security\"],null],false],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/insights/dashboard.hbs"
    }
  });
  _exports.default = _default;
});