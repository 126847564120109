define("abcloud-ui/templates/components/im-design-system/im-dropdown/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uc+BC7Kk",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn-dropdown btn-block dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"false\"],[12,\"disabled\",[21,\"disabled\"]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu w-100\"],[11,\"aria-labelledby\",\"role_level\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"dropdown-item\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"updateCurrentValue\",[22,1,[]]],null]],[9],[0,\"\\n        \"],[1,[22,1,[\"display\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/im-design-system/im-dropdown/dropdown-menu.hbs"
    }
  });
  _exports.default = _default;
});