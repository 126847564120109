define("abcloud-ui/routes/policies/api-recorder/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        page: 1
      });
    },
    model() {
      const page = this.controllerFor("policies.api-recorder.index").get("page");
      Ember.$.ajaxSetup({
        headers: {
          Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
        },
        "X-CV-Trace-ID": this.urls.generate_trace_id()
      });
      var urls = this.urls;
      try {
        var request1 = Ember.$.getJSON(urls.URL_GET_API_RECORDER(), {
          page: page,
          size: 25
        });
        var request2 = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES());
        var routeName = this.routeName;
        this.manage_requests.add_requests([{
          [routeName]: request1
        }, {
          [routeName]: request2
        }]);
        return Ember.RSVP.hash({
          policies: request1,
          meta_data: request2
        });
      } catch (request_exception) {
        var errors = [];
        var error_details = [];
        error_details["text"] = "LocalBays";
        error_details["link"] = "system.hosted.localbay.add";
        error_details["type"] = "api_recorder";
        errors.push(error_details);
        error_details = [];
        error_details["text"] = "MicroSensor";
        error_details["link"] = "system.hosted.microsensor.lambda.new";
        error_details["additional_param"] = "new";
        error_details["type"] = "api_recorder";
        errors.push(error_details);
        return {
          errors: errors
        };
      }
    },
    setupController(controller, model) {
      if (model.errors) {
        controller.set("errors", model.errors);
      } else {
        controller.set("model", model);
      }
    },
    resetController: function (controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          errors: [],
          page: 1,
          currentApiRecorder: null,
          canPolicyResume: true,
          errorMessage: ""
        });
      }
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});