define("abcloud-ui/models/dplet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    sub_type: _emberData.default.attr('string'),
    install_mode: _emberData.default.attr('string'),
    hashed_passphrase: _emberData.default.attr('string'),
    localbay_ip: _emberData.default.attr('string'),
    localcomponent_id: _emberData.default.attr('number'),
    localcomponent_title: _emberData.default.attr('string'),
    localcomponent_instance_type: _emberData.default.attr('string'),
    namespace: _emberData.default.attr('string'),
    cluster_domain: _emberData.default.attr('string'),
    dns_ip: _emberData.default.attr('string'),
    instance_type: _emberData.default.attr('string'),
    instance_orch: _emberData.default.attr('string'),
    instance_mode: _emberData.default.attr('string'),
    config_link: _emberData.default.attr('string'),
    config_status: _emberData.default.attr('string'),
    dplet_type: _emberData.default.attr('string'),
    rest_api_host_ip: _emberData.default.attr('string'),
    rest_api_auth_token: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    policy: _emberData.default.attr(),
    profiles: _emberData.default.attr(),
    health: _emberData.default.attr(),
    high_level_health: _emberData.default.attr(),
    kafka_topics: _emberData.default.attr('string'),
    kafka_servers: _emberData.default.attr('string'),
    kafka_consumer_group_name: _emberData.default.attr('string')
  });
  _exports.default = _default;
});