define("abcloud-ui/controllers/policies/profiles/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    profile_id: "",
    file_id: "",
    model: "",
    activityName: "file download",
    isFileUploaded: false,
    file_error: true,
    file_validated: false,
    current_file: "",
    current_spec_info_selection: "csvFileImportDetails",
    options: {
      single_api: false,
      csv_file_import: false
    },
    options_published: {
      single_api: false,
      csv_file_import: false
    },
    pin_static_values: [{
      "": ""
    }],
    session_first_seen_values: [""],
    pinned_first_seen_values: [""],
    current_localconsole_id: "",
    current_localconsole: "",
    current_localconsole_hashed_passphrase: "",
    errorMessage: "",
    getElementValue(id) {
      return document.getElementById(id).value;
    },
    isValidApiPath(api) {
      if (!api) {
        return false;
      }
      //-- if api path has http/https as scheme, then check if host exists or not
      else if (api.substring(0, 7) === "http://") {
        if (!api.substring(7, api.length)) {
          return false;
        }
      } else if (api.substring(0, 8) === "https://") {
        if (!api.substring(8, api.length)) {
          return false;
        }
      }
      return true;
    },
    actions: {
      toggleDiffViewVisibility(viewRequest) {
        if (viewRequest === "hide") {
          var widthToHide = "-" + Ember.$(".diff_stack").css("width");
          Ember.$(".nav_stack").css("margin-left", widthToHide);
          Ember.$(".diff_stack_toggle_caret").css("visibility", "visible");
        } else if (viewRequest === "show") {
          Ember.$(".nav_stack").css("margin-left", "0");
          Ember.$(".diff_stack").css("display", "inline-block");
          Ember.$(".diff_stack_toggle_caret").css("visibility", "hidden");
        }
      },
      setFileUpload() {
        this.set("isFileUploaded", true);
      },
      confirmPublish(profile) {
        if (this.model.attributes["is_used_in_policies?"]) {
          Ember.$(".confirm_banner").removeClass("hide_confirm_banner");
          Ember.$(".save-publish").attr("disabled", "true");
          Ember.$(".save").attr("disabled", "true");
          Ember.$(".cancel").attr("disabled", "true");
          Ember.$(".save-publish").addClass("disable_button");
          Ember.$(".save").addClass("disable_button");
          Ember.$(".cancel").addClass("disable_button");
          Ember.$(".confirm_banner .save-publish").attr("disabled", false);
          Ember.$(".confirm_banner .save").attr("disabled", false);
          Ember.$(".confirm_banner .cancel").attr("disabled", false);
          Ember.$(".confirm_banner .save-publish").removeClass("disable_button");
          Ember.$(".confirm_banner .save").removeClass("disable_button");
          Ember.$(".confirm_banner .cancel").removeClass("disable_button");
        } else {
          switch (profile) {
            case "user_group":
              this.send("saveUserGroup", "publish", "");
              break;
            case "user_session":
              this.send("saveUserSession", "publish", "");
              break;
            case "activity_session":
              this.send("saveActivitySession", "publish", "");
              break;
            case "data_export":
              this.send("saveDataExport", "publish", "");
              break;
            case "out_of_spec_api":
              this.send("saveOutOfSpec", "publish", "");
              break;
            case "api_parameter_pin":
              this.send("saveParameterPinningProfile", "publish", "");
              break;
            case "api_session_token":
              this.send("saveApiSessionTokenProfile", "publish", "");
              break;
            default:
              this.debug.log("unknown profile type..");
          }
        }
      },
      cancelConfirmPublish() {
        Ember.$(".save-publish").removeClass("disable_button");
        Ember.$(".save").removeClass("disable_button");
        Ember.$(".cancel").removeClass("disable_button");
        Ember.$(".save-publish").removeAttr("disabled");
        Ember.$(".save").removeAttr("disabled");
        Ember.$(".cancel").removeAttr("disabled");
        Ember.$(".confirm_banner").addClass("hide_confirm_banner");
      },
      saveActivitySession(action, whenToPublish) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var activity_name = this.activityName;
        var api = this.getElementValue("api");
        var host = this.getElementValue("host");
        var httpHeaderChecked = document.getElementById("httpHeader").checked;
        var httpCookieChecked = document.getElementById("httpCookie").checked;
        var http_request_cookie = this.getElementValue("cookieName_");
        var http_request_header = this.getElementValue("headerName_");
        var publish_associated_policies = "";
        var data_access_mechanism = "";
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        if (document.getElementById("postRestApi").checked) {
          data_access_mechanism = {
            aws_cloudfront_lambda: "true"
          };
        } else {
          data_access_mechanism = {
            extract_from_network_data: "true"
          };
        }
        if (name && description && activity_name && api && host && (httpHeaderChecked || httpCookieChecked) && (http_request_cookie || http_request_header)) {
          var request_obj = {
            data: {
              type: "profile",
              attributes: {
                publish_associated_policies: publish_associated_policies,
                sub_type: "activity_session",
                name: name,
                description: description,
                action: action,
                changeset: {
                  "activityNameLabel.Activity_Name": activity_name,
                  "nameLabel.Name": name,
                  "descriptionLabel.Description": description,
                  "dataAccessMechanismLabel.Data_Access_Mechanism.Aws_Cloudfront_Lambda": data_access_mechanism["aws_cloudfront_lambda"],
                  "dataAccessMechanismLabel.Data_Access_Mechanism.Extract_From_Data_Network": data_access_mechanism["extract_from_network_data"],
                  "apiLabel.Activity_API_Path": api,
                  "hostLabel.Activity_HTTP_Host_Value": host,
                  "sessionInfoLabel.HTTP_Request_Cookie": http_request_cookie,
                  "sessionInfoLabel.HTTP_Request_Header": http_request_header
                },
                json: {
                  activity_name: activity_name,
                  api: api,
                  host: host,
                  data_access_mechanism: data_access_mechanism
                }
              }
            }
          };
          if (httpHeaderChecked && httpCookieChecked) {
            request_obj.data.attributes.json["location"] = {
              http_request_cookie: {
                value: http_request_cookie
              },
              http_request_header: {
                value: http_request_header
              }
            };
          } else if (httpHeaderChecked && !httpCookieChecked) {
            request_obj.data.attributes.json["location"] = {
              http_request_header: {
                value: http_request_header
              }
            };
          } else if (!httpHeaderChecked && httpCookieChecked) {
            request_obj.data.attributes.json["location"] = {
              http_request_cookie: {
                value: http_request_cookie
              }
            };
          }
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            apiPathFilledValue: api,
            httpHostValueFilledValue: host
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            sessionInfoFilledValue: httpHeaderChecked || httpCookieChecked
          };
          if (httpHeaderChecked || httpCookieChecked) {
            if (httpHeaderChecked) {
              if (!http_request_header) {
                elements["sessionInfoFilledValue"] = false;
              }
            } else if (httpCookieChecked) {
              if (!http_request_cookie) {
                elements["sessionInfoFilledValue"] = false;
              }
            }
          } else {
            elements["sessionInfoFilledValue"] = false;
          }
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, "Please choose atleast one option and fill in their respective values");
        }
      },
      saveUserGroup(action, whenToPublish) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var api = this.getElementValue("api");
        var location = "";
        var publish_associated_policies = "";
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        if (document.getElementById("restApi").checked) {
          location = {
            rest_api: "true"
          };
        } else {
          location = {
            network_data: "true"
          };
        }
        var file_path_conversion = document.getElementById("convert_file_link").checked.toString();
        var request_obj = {
          data: {
            type: "profile",
            attributes: {
              publish_associated_policies: publish_associated_policies,
              sub_type: "user_group",
              name: name,
              description: description,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "locationLabel.Data_Access_Mechanism.Get_Data_From_Rest_API": location["rest_api"],
                "locationLabel.Data_Access_Mechanism.Extract_From_Network_Data": location["network_data"],
                "apiLabel.Activity_API_Path": api,
                "fileLinkLabel.File_Path_Mapping": file_path_conversion
              },
              json: {
                location: location,
                api: api,
                file_path_conversion: file_path_conversion
              }
            }
          }
        };
        if (name && description && api && location) {
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            apiPathFilledValue: api
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      saveUserSession(action, whenToPublish) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var location = "";
        var publish_associated_policies = "";
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        if (document.getElementById("postRestApi").checked) {
          location = {
            rest_api: "true"
          };
        } else {
          location = {
            network_data: "true"
          };
        }
        var request_obj = {
          data: {
            type: "profile",
            attributes: {
              publish_associated_policies: publish_associated_policies,
              sub_type: "user_session",
              name: name,
              description: description,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "dataAccessMechanismLabel.Data_Access_Mechanism.Post_Data_To_Rest_API": location["rest_api"],
                "dataAccessMechanismLabel.Data_Access_Mechanism.Extract_From_Network_Data": location["network_data"]
              },
              json: {
                location: location
              }
            }
          }
        };
        if (name && description && location) {
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      saveDataExport(action, whenToPublish) {
        let name = this.getElementValue("name");
        let description = this.getElementValue("description");
        let api = this.getElementValue("api");
        let authToken = this.getElementValue("authToken");
        let sourceType = this.getElementValue("sourceType");
        let localconsole_host_ip = this.getElementValue("localconsoleIp");
        let receiverType = {};
        let is_valid_splunk_object = true;
        let is_valid_local_console_object = true;
        let isSendTobAbCloudSelected = document.getElementById("sendToAbCloud").checked;
        let isReceiverTypeSplunkSelected = document.getElementById("receiverTypeSplunk").checked;
        let isExportToLocalconsoleSelected = document.getElementById("sendToLocalconsole").checked;
        let publish_associated_policies = "";
        var changeLabelForLocalConsole = String(isExportToLocalconsoleSelected);
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        //-- if api entered doesn't have http or https, include it and send for validation
        if (api && !(api.substring(0, 7) === "http://" || api.substring(0, 8) === "https://")) {
          api = "http://" + api;
        }
        //-- send for validation, if validation fails, set api to be empty so we can inject error notofication later on
        if (!this.isValidApiPath(api)) {
          api = "";
        }
        if (isSendTobAbCloudSelected) {
          receiverType["abcloud"] = true;
        }
        if (isReceiverTypeSplunkSelected) {
          receiverType["splunk"] = {
            type: this.getElementValue("receiverType"),
            api: api,
            auth_token: authToken,
            source_type: sourceType
          };
          if (!api || !authToken || !sourceType) {
            is_valid_splunk_object = false;
          }
        }
        if (isExportToLocalconsoleSelected) {
          changeLabelForLocalConsole = [String(isExportToLocalconsoleSelected), "(", this.current_localconsole, ")"].join("");
          receiverType["localconsole"] = {
            id: this.current_localconsole_id,
            title: this.current_localconsole,
            ip: localconsole_host_ip,
            hashed_passphrase: this.current_localconsole_hashed_passphrase
          };
          if (!localconsole_host_ip) {
            is_valid_local_console_object = false;
          }
        }
        let request_obj = {
          data: {
            type: "profile",
            attributes: {
              sub_type: "data_export",
              profile_type: "splunk",
              name: name,
              description: description,
              action: action,
              publish_associated_policies: publish_associated_policies,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "apiLabel.Receiver_Type.API_Path": api,
                "authTokenLabel.Receiver_Type.Splunk_Auth_Token": authToken,
                "sourceTypeLabel.Receiver_Type.Source_Type": sourceType,
                "receiverTypeLabel.Receiver_Type.Splunk_HTTP_Event_Collector": String(isReceiverTypeSplunkSelected),
                "sendToLocalconsoleLabel.Receiver_Type.Export_To_Local_Console": changeLabelForLocalConsole,
                "localconsoleIpLabel.Receiver_Type.Export_To_Local_Console.Host_IP": localconsole_host_ip,
                "sendToAbCloudLabel.Receiver_Type.Sent_to_AB_Cloud": String(isSendTobAbCloudSelected)
              },
              json: {
                data_receiver_information: receiverType
              }
            }
          }
        };
        if (name && description && (isReceiverTypeSplunkSelected || isSendTobAbCloudSelected || isExportToLocalconsoleSelected) && is_valid_splunk_object && is_valid_local_console_object) {
          //-- reset error notifications
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            authTokenFilledValue: authToken,
            apiPathFilledValue: api,
            sourceTypeFilledValue: sourceType,
            localconsoleIpFilledValue: is_valid_local_console_object,
            sendToAbCloudFilledValue: isReceiverTypeSplunkSelected || isSendTobAbCloudSelected || isExportToLocalconsoleSelected
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          let element = {
            apiPathFilledValue: api
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(element, "Invalid API Path, Should be a proper URL");
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            authTokenFilledValue: authToken,
            apiPathFilledValue: api,
            sourceTypeFilledValue: sourceType,
            localconsoleIpFilledValue: is_valid_local_console_object
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            sendToAbCloudFilledValue: isReceiverTypeSplunkSelected || isSendTobAbCloudSelected || isExportToLocalconsoleSelected
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.AT_LEAST_ONE_OPTION);
        }
      },
      saveOutOfSpec(action, whenToPublish) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var file = document.getElementById("file-upload").files[0] ? document.getElementById("file-upload").files[0] : this.current_file;
        var fileName;
        var valid_spec_info = false;
        var publish_associated_policies = "";
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        var request_obj = {
          data: {
            id: this.profile_id,
            type: "profile",
            attributes: {
              sub_type: "out_of_spec_api",
              publish_associated_policies: publish_associated_policies,
              name: name,
              description: description,
              is_deleted: false,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "fileLabel.CSV_File_Import.File_Name": "",
                "swaggerLabel.API_Recording": ""
              },
              json: {
                method: ""
              }
            }
          }
        };
        if (this.current_spec_info_selection === this.constants.SPEC_INFO_OPTIONS[0]) {
          file = document.getElementById("file-upload").files[0] ? document.getElementById("file-upload").files[0] : this.current_file;
          if (file.name) {
            fileName = file.name;
          } else {
            fileName = file.file_name;
          }
          request_obj.data.attributes.json.method = "file_upload";
          request_obj.data.attributes.changeset["fileLabel.CSV_File_Import.File_Name"] = fileName;
          if (!this.file_error) {
            valid_spec_info = true;
          }
        } else if (this.current_spec_info_selection === this.constants.SPEC_INFO_OPTIONS[1]) {
          if (this.current_api_recording != this.constants.NO_API_RECORDINGS_FOUND) {
            valid_spec_info = true;
          }
          request_obj.data.attributes.json.method = "open_api";
          request_obj.data.attributes.changeset["swaggerLabel.swaggerLabel.API_Recording"] = this.current_api_recording;
          request_obj.data.attributes.json["spec_info"] = {
            api_recording: {
              name: this.current_api_recording,
              id: this.current_api_recording_id
            }
          };
        }
        if (name && description && valid_spec_info) {
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          // -- if there was a file validation error and yet user tries to save/publish profile,
          //inject correct notification against file upload instead of the usual Mandatory field error

          if (!valid_spec_info) {
            let elements = {
              fileUploadFilledValue: fileName
            };
            this.services.inject_notification.notify_unfilled_mandatory_field(elements, !fileName ? this.constants.MANDATORY_FIELD : this.constants.FILE_INVALID);
            elements = {
              apiRecordingFilledValue: this.current_api_recording === this.constants.NO_API_RECORDINGS_FOUND ? false : true
            };
            this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.NO_API_RECORDINGS_FOUND);
            elements = {
              nameFilledValue: name,
              descriptionFilledValue: description
            };
            this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          } else {
            let elements = {
              nameFilledValue: name,
              descriptionFilledValue: description,
              fileUploadFilledValue: fileName,
              apiRecordingFilledValue: true
            };
            this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          }
        }
      },
      saveParameterPinningProfile(action, whenToPublish) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var file = "";
        var fileName = "";
        var single_api = "";
        var method = "";
        var did_user_choose_atleast_one_api_list_info = false;
        var publish_associated_policies = "";
        var api_list_information = {};
        let attachmentId = 0;
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        if (this.options.csv_file_import) {
          file = document.getElementById("file-upload").files[0] ? document.getElementById("file-upload").files[0] : this.current_file;
          fileName;
          if (file.name) {
            fileName = file.name;
            attachmentId = file.id;
          } else {
            fileName = file.file_name;
            attachmentId = file.file_id;
          }
          method = "file_upload";
          api_list_information["csv_file_import"] = fileName;
          if (document.getElementById("file-upload").files[0]) {
            if (fileName && this.file_validated) {
              did_user_choose_atleast_one_api_list_info = true;
              this.set("file_error", false);
            }
          } else if (fileName) {
            did_user_choose_atleast_one_api_list_info = true;
            this.set("file_error", false);
          }
        } else if (this.options.single_api) {
          // no file error in this use case
          this.set("file_error", false);
          single_api = this.getElementValue("single_api");
          method = "single_api";
          api_list_information["single_api"] = single_api;
          if (single_api) {
            did_user_choose_atleast_one_api_list_info = true;
          }
        }

        // check if all the key-val in parameter pin information are filled and are not empty

        var check_if_object_key_and_vals_are_valid = (object, array_type) => {
          var flag = true;
          if (array_type === "pure_array") {
            object.forEach(obj => {
              if (!obj && object.length > 1) {
                flag = false;
              }
            });
            return flag;
          } else if (array_type === "array_of_objects") {
            object.forEach(obj => {
              for (var key in obj) {
                if (!key || !obj[key]) {
                  flag = false;
                }
              }
            });
            return flag;
          }
        };
        var request_obj = {
          data: {
            id: this.profile_id,
            type: "profile",
            attributes: {
              publish_associated_policies: publish_associated_policies,
              sub_type: "api_parameter_pin",
              name: name,
              description: description,
              is_deleted: false,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "fileLabel.CSV_File_Import.File_Name": fileName,
                "singleApiLabel.An_API.Single_API": single_api
              },
              json: {
                method: method,
                api_list_information: api_list_information,
                attachment_id: attachmentId
              }
            }
          }
        };
        var parameter_pin_information = {};
        var valid_parameter_pin_information = "";
        if (document.getElementById("static_values").checked) {
          parameter_pin_information["static_values"] = this.pin_static_values;
          valid_parameter_pin_information = check_if_object_key_and_vals_are_valid(this.pin_static_values, "array_of_objects");
          request_obj.data.attributes.changeset["staticValuesLabel.Pin_Static_Values"] = {
            key: this.pin_static_values
          };
        } else {
          request_obj.data.attributes.changeset["staticValuesLabel.Pin_Static_Values"] = {
            key: [""]
          };
        }
        if (document.getElementById("first_seen_values").checked) {
          parameter_pin_information["pin_set_to_first_seen_values"] = {
            session_first_seen_values: this.session_first_seen_values,
            pinned_first_seen_values: this.pinned_first_seen_values
          };
          valid_parameter_pin_information = check_if_object_key_and_vals_are_valid(this.session_first_seen_values, "pure_array") && check_if_object_key_and_vals_are_valid(this.pinned_first_seen_values, "pure_array");
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Session_Parameters"] = {
            key: this.session_first_seen_values
          };
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Pinned_Parameters"] = {
            key: this.pinned_first_seen_values
          };
        } else {
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Session_Parameters"] = {
            key: [""]
          };
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Pinned_Parameters"] = {
            key: [""]
          };
        }
        request_obj.data.attributes.json["parameter_pin_information"] = parameter_pin_information;
        if (name && description && !this.file_error && did_user_choose_atleast_one_api_list_info && valid_parameter_pin_information) {
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, JSON.stringify(request_obj), response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          // -- if there was a file validation error and yet user tries to save/publish profile,
          //inject correct notification against file upload instead of the usual Mandatory field error
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            staticValuesFilledValue: valid_parameter_pin_information ? "ok" : ""
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          if (this.options.single_api) {
            let elements = {
              singleApiFilledValue: single_api
            };
            this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          } else if (this.options.csv_file_import) {
            var notifMessage = this.constants.FILE_INVALID;
            var fileUploadFilledValue = "";
            if (!fileName) {
              notifMessage = this.constants.MANDATORY_FIELD;
            } else if (!this.file_validated) {
              notifMessage = this.constants.VALIDATION_INCOMPLETE;
            } else {
              fileUploadFilledValue = fileName;
            }
            let elements = {
              fileUploadFilledValue: fileUploadFilledValue
            };
            this.services.inject_notification.notify_unfilled_mandatory_field(elements, notifMessage);
          }
        }
      },
      saveApiSessionTokenProfile(action, whenToPublish) {
        let name = document.getElementById("name").value;
        let description = document.getElementById("description").value;
        let location_of_token = {};
        let input_ok = new Set();
        let publish_associated_policies = "";
        if (whenToPublish === "now") {
          publish_associated_policies = true;
        } else if (whenToPublish === "later") {
          publish_associated_policies = false;
        }
        let http_request_header_checkbox = document.getElementById("http_request_header_checkbox");
        let http_header_checkbox = document.getElementById("http_header_checkbox");
        let http_request_cookie_checkbox = document.getElementById("http_request_cookie_checkbox");
        let http_request_api_payload_checkbox = document.getElementById("http_request_api_payload_checkbox");
        let http_request_header_input = document.getElementById("http_request_header_input");
        let http_header_input = document.getElementById("http_header_input");
        let http_request_cookie_input = document.getElementById("http_request_cookie_input");
        let http_request_api_payload_input = document.getElementById("http_request_api_payload_input");
        if (http_request_header_checkbox.checked) {
          if (http_request_header_input.value) {
            location_of_token["http_request_header"] = http_request_header_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        if (http_header_checkbox.checked) {
          if (http_header_input.value) {
            location_of_token["http_header"] = http_header_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        if (http_request_cookie_checkbox.checked) {
          if (http_request_cookie_input.value) {
            location_of_token["http_request_cookie"] = http_request_cookie_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        if (http_request_api_payload_checkbox.checked) {
          if (http_request_api_payload_input.value) {
            location_of_token["http_request_api_payload"] = http_request_api_payload_input.value;
            input_ok.add(true);
          } else {
            input_ok.add(false);
          }
        }
        let request_obj = {
          data: {
            type: "profile",
            attributes: {
              publish_associated_policies: publish_associated_policies,
              sub_type: "api_session_token",
              name: name,
              description: description,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Request Header": location_of_token["http_request_header"] ? location_of_token["http_request_header"] : "--Unchecked--",
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Header": location_of_token["http_header"] ? location_of_token["http_header"] : "--Unchecked--",
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Request Cookie": location_of_token["http_request_cookie"] ? location_of_token["http_request_cookie"] : "--Unchecked--",
                "tokenLocationLabel.Session Token Information.Location of Token.HTTP Request API Payload": location_of_token["http_request_api_payload"] ? location_of_token["http_request_api_payload"] : "--Unchecked--"
              },
              json: {
                location_of_token: location_of_token
              }
            }
          }
        };
        if (name && description && !input_ok.has(false)) {
          // if user chose a file then method if PUT as we already have the profile_id from file upload
          let url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
          this.services.publish.profile(action, "PUT", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          // -- if there was a file validation error and yet user tries to save/publish profile,
          //inject correct notification against file upload instead of the usual Mandatory field error
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            tokenFilledValue: !input_ok.has(false)
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.AT_LEAST_ONE_OPTION);
        }
      },
      getProfile() {
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.services.auth.getAuthToken("customer")}`,
            "X-CV-Trace-ID": this.services.urls.generate_trace_id()
          }
        });

        // if user chose
        var url = this.services.urls.URL_POLICY_PROFILE(this.profile_id);
        this.services.ajax.request(url, {
          method: "GET"
        }).then(response => {
          var profile_data = response.data.attributes.profile_data;
          var model = response.data;
          var current_file = profile_data[profile_data.length - 1].spec_file;
          if (model.attributes.sub_type === "api_parameter_pin") {
            if (profile_data.length > 1) {
              if (profile_data[1].json.parameter_pin_information.hasOwnProperty("static_values")) {
                this.setProperties({
                  pin_static_values: profile_data[1].json.parameter_pin_information.static_values,
                  file_id: profile_data[1].spec_file.file_id
                });
              }
            } else {
              if (profile_data[0].json.parameter_pin_information.hasOwnProperty("static_values")) {
                this.setProperties({
                  pin_static_values: profile_data[0].json.parameter_pin_information.static_values,
                  file_id: profile_data[0].spec_file.file_id
                });
              }
            }
          }
          this.setProperties({
            model: model,
            sub_type: model.attributes.sub_type,
            profile_data: model.attributes.profile_data,
            diff: model.attributes.diff,
            profile_id: this.profile_id,
            current_file: current_file,
            file_error: false
          });
        });
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      }
    }
  });
  _exports.default = _default;
});