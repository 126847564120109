define("abcloud-ui/routes/insights/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    redirect: function () {
      this.transitionTo('insights.dashboard.discovery.apis');
    }
  });
  _exports.default = _default;
});