define("abcloud-ui/routes/system/hosted/localbay/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render("system.hosted.localbay.add", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'configLink': false,
          'instanceDisplayChosen': 'Kubernetes',
          'instanceTypeChosen': 'container',
          'instanceOrchestrationChosen': 'kubernetes',
          'errors': ''
        });
      }
    }
  });
  _exports.default = _default;
});