define("abcloud-ui/components/policies-tenant/policies-api-recorder/completed-recording-data-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['editCurrentRow:active', 'showApiChildren:expanded'],
    editCurrentRow: false,
    showApiChildren: false,
    hasChildren: false,
    actions: {
      showApiDetails() {
        this.showApiDetails(this.record, this.index);
      },
      showApiChildren() {
        this.set('showApiChildren', !this.showApiChildren);
        if (this.showApiChildren) {
          this.toggleDisplayPane('closeDisplayPane');
        }
      },
      editApi() {
        this.set('editCurrentRow', !this.editCurrentRow);
      }
    }
  });
  _exports.default = _default;
});