define("abcloud-ui/services/ajax", ["exports", "ember-ajax/services/ajax", "abcloud-ui/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ajax.default.extend({
    urls: Ember.inject.service('urls'),
    auth: Ember.inject.service('auth'),
    host: _environment.default.apiHost

    // headers: computed('auth.authToken', function(){
    //   return {
    //     'X-CV-Trace-ID': this.urls.generate_trace_id()
    //   };
    // })
  });
  _exports.default = _default;
});