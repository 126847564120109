define("abcloud-ui/constants/config-variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONFIG_VARIABLES = void 0;
  const CONFIG_VARIABLES = {
    CLEAR_DURATION: 3000,
    PER_PAGE: 25,
    MAX_INPUT_CHARS: 64,
    MAX_DESCRIPTION_CHARS: 200,
    MIN_INPUT_CHARS: 1,
    MIN_PASSPHRASE_CHARS: 16,
    MIN_ISSUER_URL_CHARS: 9,
    LOGOUT_CONFIRM_TIMER: 5,
    EMAIL_REGEX: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$",
    PASSWORD_REGEX: "^(?=[A-Za-z0-9!@#$%^&*_+-]{8,64}$)(?=.*[A-Z])(?=.*\\d)(?=.*[A-Za-z]).*$",
    TITLE_REGEX: /[^a-zA-Z0-9]/g,
    SUB_DOMAIN_REGEX: /[^a-zA-Z0-9-]/g,
    ISSUER_URL_REGEX: "^https://",
    NAME_REGEX: "^[a-zA-Z0-9. -]+$",
    LB_CONFIG_STATUS_UNAVAILABLE: "UNAVAILABLE",
    LB_CONFIG_STATUS_IN_PROGRESS: "IN_PROGRESS",
    LB_CONFIG_STATUS_LINK_GENERATED: "LINK_GENERATED",
    LB_CONFIG_STATUS_DONE: "DONE",
    LB_CONFIG_STATUS_SUCCESS: "SUCCESS",
    LB_HEALTH_STATUS_SUCCESS: "SUCCESS",
    LB_HEALTH_STATUS_ERROR: "ERROR",
    LB_HEALTH_STATUS_UNAVAILABLE: "UNAVAILABLE",
    LB_OVERALL_HEALTH_UNAVAILABLE: "RED",
    LB_OVERALL_HEALTH_ERROR: "AMBER",
    LB_OVERALL_HEALTH_SUCCESS: "GREEN",
    DPL_CONFIG_STATUS_IN_PROGRESS: "in_progress",
    DPL_CONFIG_STATUS_SUCCESS: "success",
    DPL_HIGH_LEVEL_HEALTH_ERROR: "red",
    DPL_HIGH_LEVEL_HEALTH_WARNING: "amber",
    DPL_HIGH_LEVEL_HEALTH_SUCCESS: "green",
    KUBERNETES_INSTANCE_TYPE: "kubernetes",
    DAEMONSET: "daemonset",
    KUBERNETES_LOCALBAY_IP: "ablocalbay",
    KUBERNETES_LOCALCONSOLE_IP: "ablocalconsole",
    DEFAULT_NPS_MICROSENSOR_NAMESPACE: "default",
    DEFAULT_NPS_MICROSENSOR_CLUSTER_DOMAIN: "svc.cluster.local",
    LOGIN_TYPE_LOCAL: "lt_local",
    LOGIN_TYPE_SSO: "lt_sso",
    adminUser: "adminUser"
  };
  _exports.CONFIG_VARIABLES = CONFIG_VARIABLES;
});