define("abcloud-ui/adapters/localcomponent", ["exports", "abcloud-ui/config/environment", "ember-data", "ember-inflector"], function (_exports, _environment, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    xhr: [],
    headers: Ember.computed("auth.authToken", function () {
      return {
        Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
        "X-CV-Trace-ID": this.urls.generate_trace_id()
      };
    }),
    host: _environment.default.apiHost,
    namespace: Ember.computed("auth.tenant_id", function () {
      return `${_environment.default.version}/tenants/${this.auth.getTenantId()}/system/config`;
    }),
    pathForType: function (type) {
      var underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },
    ajax: function (url, type, hash) {
      var adapter = this;
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        hash = adapter.ajaxOptions(url, type, hash);
        hash.success = function (json) {
          if (json && json.errors) {
            Ember.run.join(null, reject, json);
          }
          Ember.run.join(null, resolve, json);
        };
        hash.error = function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.hasOwnProperty("responseJSON")) {
            Ember.run.join(null, reject, jqXHR.responseJSON);
          } else {
            var errors = {
              errors: [{
                detail: jqXHR.statusText ? jqXHR.statusText : this.constants.SOMETHING_WENT_WRONG,
                source: {
                  pointer: "localcomponent-adapter"
                },
                jqXHR: jqXHR,
                status: jqXHR.status ? jqXHR.status : 499
              }]
            };
            Ember.run.join(null, reject, errors);
          }
        };
        var charArray = url.split("/");
        var request_identifier = charArray[charArray.length - 1];
        if (!isNaN(Number(request_identifier))) {
          request_identifier = charArray[charArray.length - 2];
        }
        adapter.get("manage_requests").add_request(request_identifier, Ember.$.ajax(hash));
      }, "DS: RestAdapter#ajax " + type + " to " + url);
      return promise;
    }
  });
  _exports.default = _default;
});