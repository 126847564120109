define("abcloud-ui/templates/components/policies-tenant/profile-file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hrZu08Df",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"class\",\"col-12 col-md-5\"],[11,\"id\",\"spec_file_name\"],[12,\"value\",[21,\"file_name\"]],[11,\"readonly\",\"\"],[11,\"disabled\",\"\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"class\",\"col-12 col-md-5\"],[11,\"id\",\"spec_file_name\"],[12,\"value\",[21,\"file_name\"]],[11,\"readonly\",\"\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"current_valid_action\"]],\"select\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[11,\"for\",\"file-upload\"],[11,\"class\",\"col-12 col-md-3\"],[11,\"id\",\"file_selection_label\"],[9],[0,\"Select File\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"current_valid_action\"]],\"delete\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[11,\"class\",\"col-12 col-md-3\"],[11,\"id\",\"file_selection_label\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"delete_file\"],null]],[9],[0,\"Delete\"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[7,\"input\"],[11,\"id\",\"file-upload\"],[11,\"accept\",\"\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"selectFile\"],null]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"id\",\"validateFile\"],[11,\"class\",\"col-12 col-md-3 button validate\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"validateFile\"],null]],[11,\"disabled\",\"\"],[11,\"type\",\"button\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/policies-tenant/profile-file-upload.hbs"
    }
  });
  _exports.default = _default;
});