define("abcloud-ui/controllers/exceptions/security/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    transaction_data: {},
    displayPane: false,
    detailCellId: 'detail_pane',
    tableClass: 'data-list-table',
    securityCtrl: Ember.inject.controller('exceptions.security'),
    init() {
      this._super(...arguments);
      this.set('displayPane', true);
    },
    willDestroy() {
      this.set('displayPane', false);
    },
    actions: {
      closeModalOnEscape(event) {
        if (event.key === 'Escape') {
          this.set('displayPane', false);
          let securityCtrl = this.securityCtrl;
          let current_selection_from_list = securityCtrl.get('current_selection_from_list');
          Ember.$('#' + current_selection_from_list).removeClass('active');
          this.transitionToRoute('exceptions.security');
        }
      },
      toggleDisplayPane() {
        this.set('displayPane', !this.displayPane);
        this.send('resetColumns', this.tableClass);
        let securityCtrl = this.securityCtrl;
        let current_selection_from_list = securityCtrl.get('current_selection_from_list');
        Ember.$('#' + current_selection_from_list).removeClass('active');
        this.transitionToRoute('exceptions.security');
      },
      resetColumns(tableClass) {
        let arr = Ember.$('.' + tableClass + ' thead th');
        let headerCount = Ember.$('.' + tableClass + ' thead th.show').length;
        let headerWidth = parseInt(100 / headerCount) + '%';
        Ember.$('.' + tableClass + ' thead th').removeClass().addClass('collapse show', '').css('width', headerWidth);
        // $('.' + tableClass +' tbody tr td').removeClass().addClass('collapse show', '');
      }
    }
  });
  _exports.default = _default;
});