define("abcloud-ui/routes/exceptions/transactions/transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    geo_map_src: null,
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    map: Ember.inject.service('map'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model: function (params) {
      return this.store.findRecord('transaction', params.transaction_id, {
        reload: true
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      if (Ember.$.isEmptyObject(model.data.transaction_json)) {
        controller.set('empty_tx_json', true);
      }
      controller.setProperties({
        'transaction_data': model.data.transaction_json,
        'model_string': JSON.stringify(model, undefined, 10),
        // -- to set height of events window appropriately
        'cssHeight': 'auto',
        'displayPane': true
      });
      if (model.data.events_json) {
        controller.set('cssHeight', this.setEventWindowHeight(model.data.events_json.length));
      }
      var url = this.urls.URL_GET_MAP();
      var geoData = '';
      if (model.data.transaction_iab_data && model.data.transaction_iab_data !== undefined) {
        geoData = model.data.transaction_iab_data.iab_src_geo_location;
      }
      // -- set background image for map cell
      if (geoData && geoData !== undefined) {
        this.map.getMap(geoData, url, 'geo_map');
      }
    },
    setEventWindowHeight(length) {
      if (length <= 1) {
        return 'single_height';
      } else if (length === 2) {
        return 'double_height';
      } else {
        return 'full_height';
      }
    },
    resetController(controller, isExiting, transition) {
      controller.setProperties({
        'displayPane': false,
        'firstEvent': 0,
        'localcomponent': null,
        'dplet': null
      });
    },
    renderTemplate() {
      this.render('exceptions.transactions.transaction');
    }
  });
  _exports.default = _default;
});