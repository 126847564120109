define("abcloud-ui/helpers/recursive-get-value", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.recursiveGetValue = recursiveGetValue;
  function recursiveGetValue(_ref /*, hash*/) {
    let [object] = _ref;
    function iterateAttributesAndFormHTMLLabels(object) {
      var html = '';
      for (var a in object) {
        //an ID must start with an alphabet and UUID is a alphanumeric
        var randomClass = "random" + (0, _emberUuid.v4)().toString().replace(/-/g, "");
        if (typeof object[a] === 'object') {
          if (object.hasOwnProperty(a)) {
            html += '<div class="pure-control-group sub_div"><i id="tx_karat_' + randomClass + '" class="fa fa-caret-right"></i><label class="tx_label" onclick="toggle(' + randomClass + ',tx_karat_' + randomClass + ')" >' + a + '</label><div class= "subDivToToggle" id = "' + randomClass + '" >';
            html += iterateAttributesAndFormHTMLLabels(object[a]) + '</div></div>';
          }
        } else {
          if (object.hasOwnProperty(a)) {
            html += '<div class="pure-control-group sub_div"><label>' + a + ': </label><span class="pure-form-message-inline">' + object[a] + '</span></div>';
          }
        } //end if
      } //end for
      return html;
    } //end function

    var html = iterateAttributesAndFormHTMLLabels(object);
    return new Ember.String.htmlSafe(html);
  }
  var _default = Ember.Helper.helper(recursiveGetValue);
  _exports.default = _default;
});