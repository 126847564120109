define("abcloud-ui/services/replace-element-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    preloader(element) {
      let currentElement = Ember.$(element);
      let currentElementText = currentElement.html();
      Ember.$(document).ajaxStart(() => {
        Ember.run(() => {
          currentElement.addClass("loading-gif");
          currentElement.html("");
        });
      });
      Ember.$(document).ajaxStop(() => {
        Ember.run(() => {
          currentElement.removeClass("loading-gif");
          currentElement.html(currentElementText);
        });
        Ember.$(document).unbind("ajaxStart");
      });
    },
    mask(element) {
      let currentElement = Ember.$(element);
      currentElement.addClass("preloader_mask");
    },
    unmask(element) {
      let currentElement = Ember.$(element);
      currentElement.removeClass("preloader_mask");
    },
    left_justified_preloader(element) {
      let currentElement = Ember.$(element);
      var id = Math.floor(Math.random() * 100);
      Ember.$(document).ajaxStart(() => {
        Ember.run(() => {
          currentElement.after('<div class="loading-gif-left-justified" id="loading_gif__div_' + id + '"></div>');
        });
      });
      Ember.$(document).ajaxStop(() => {
        Ember.run(() => {
          Ember.$("#loading_gif__div_" + id).remove();
        });
      });
    },
    inline_preloader(element) {
      let currentElement = Ember.$(element);
      var id = Math.floor(Math.random() * 100);
      Ember.$(document).ajaxStart(() => {
        Ember.run(() => {
          currentElement.after('<span class="loading-gif loading-gif-sm" id="loading_gif__span_' + id + '"></span>');
        });
      });
      Ember.$(document).ajaxStop(() => {
        Ember.run(() => {
          Ember.$("#loading_gif__span_" + id).remove();
        });
      });
    },
    table_row_preloader_mask(element) {
      Ember.$(document).ajaxStart(() => {
        Ember.run(() => {
          Ember.$("tr#" + element).addClass("table_preloader");
          Ember.$("tr#" + element + " td:last-child").html("");
        });
      });
      Ember.$(document).ajaxStop(() => {
        Ember.run(() => {
          Ember.$(document).off("ajaxStart");
          Ember.$("tr#" + element).removeClass("table_preloader");
        });
      });
    }
  });
  _exports.default = _default;
});