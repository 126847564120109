define("abcloud-ui/templates/system/hosted/localconsole/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3UC/TRQ4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g mt-3\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-3-24 subheading_padding\"],[9],[0,\"Local Consoles\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-20-24\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"divider in_line_divider\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"system-localbay/localcomponent-list\",null,[[\"localcomponents\",\"deleteLocalcomponent\",\"editLocalcomponent\",\"RESOLVE_DPLET_SUB_TYPE\",\"localcomponentType\"],[[23,[\"localconsoles\"]],[27,\"action\",[[22,0,[]],\"deleteLocalconsole\"],null],[27,\"action\",[[22,0,[]],\"editLocalconsole\"],null],[23,[\"constants\",\"RESOLVE_DPLET_SUB_TYPE\"]],\"localconsole\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/localconsole/index.hbs"
    }
  });
  _exports.default = _default;
});