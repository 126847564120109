define("abcloud-ui/components/customer/account/first-time-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
      this.setProperties({
        customerFirstLogIn: null,
        userWantsToEditPassword: null
      });
    },
    willDestroyElement() {
      this.set("customerFirstLogIn", null);
      this.set("userWantsToEditPassword", null);
    },
    actions: {
      togglePasswordVisibility() {
        this.togglePasswordVisibility();
      },
      validatePasswords() {
        this.validatePasswords();
      }
    }
  });
  _exports.default = _default;
});