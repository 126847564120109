define("abcloud-ui/templates/components/system-localbay/localbay/installation-instructions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "a8LWFvpH",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  Open a terminal\\n\\n  Create a directory \\\"cv-localbay\\\"\\n\\n  Download the setup script using the following command\\n\\n  curl -o setup.sh <link to script> (use the link generated above and also have copy button)\\n\\n  Run the setup script\\n\\n  bash setup.sh\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/localbay/installation-instructions.hbs"
    }
  });
  _exports.default = _default;
});