define("abcloud-ui/routes/manage/customers/customer-tenants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    model(params) {
      return this.store.findRecord("customer", params.customer_id, {
        include: "customer_tenants",
        filter: {
          is_deleted: false
        }
      });
    },
    setupController(controller, model) {
      const isResettingPassword = this.auth.getIsAdminUserResttingPwd();
      if (isResettingPassword) {
        window.location = this.constants.ADMIN_USER_RESET_PWD;
      }
      controller.set("model", model);
    },
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
    },
    renderTemplate() {
      this.render("manage.customers.customer-tenants", {
        into: "application",
        outlet: "admin"
      });
    }
  });
  _exports.default = _default;
});