define("abcloud-ui/services/bar-chart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    utils: Ember.inject.service("utils"),
    setup() {
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          var ctx = chart.chart.ctx;
          ctx.height = "90%";
        }
      });
      Chart.defaults.global.legend.display = false;
      Chart.defaults.global.maintainAspectRatio = false;
      Chart.defaults.doughnut.circumference = 1.5 * Math.PI;
      Chart.defaults.doughnut.cutoutPercentage = 82;
      Chart.defaults.doughnut.rotation = 0.75 * Math.PI;
      Chart.defaults.scale.gridLines.display = false;
      Chart.defaults.scale.ticks.beginAtZero = true;
      Chart.defaults.scale.ticks.userCallback = function (value, index, values) {
        if (Math.floor(value) === value) {
          return value;
        }
      };
    },
    getChartData(data, labels, colorSet) {
      var colors = [];
      if (colorSet == undefined) {
        colors.push("#15570d", "#4e8248", "#86a982", "#bfd3bd", "#e9f1e8");
      } else {
        colors = colors.concat(colorSet);
      }
      var chartData = {
        datasets: [{
          data: data,
          backgroundColor: colors
        }],
        borderWidth: 0,
        // -- array: These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      };
      return chartData;
    },
    // -- removed title from args list as we are no longer using it
    getChartOptions(labelOrientation, dateAggregationLevel) {
      const _this = this;
      var opts = {
        // barPercentage: 0.85,
        // categoryPercentage: 0.9,
        barValueSpacing: 4,
        maxBarThickness: 1,
        responsive: true,
        maintainAspectRatio: false,
        // animation: {
        //   animationRotate: true,
        //   duration: 350
        // },
        legend: {
          display: false,
          labels: {
            display: false
          }
        },
        label: {
          display: true
        },
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              let label;
              let labelItem = data.labels[tooltipItem.index];
              if (labelItem.length > 10) {
                labelItem = labelItem.slice(0, parseInt(8)) + "...";
              }
              // -- for vertical bar charts
              if (labelOrientation == undefined) {
                label = labelItem || "-";
              }
              // -- for horizontal bar charts
              else {
                let labelData = data.datasets[0].data[tooltipItem.index] || "0";
                // -- label = ( labelItem + ' ' + data.datasets[0].data[tooltipItem.index] ) || '-'; may need more space on labels due to dates so.. VV
                label = isNaN(labelData) ? labelData : _this.utils.formatNumber(labelData);
              }
              return label;
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (!isNaN(label)) {
                  if (Math.floor(label) === label) {
                    return _this.utils.formatNumber(label);
                  }
                } else {
                  return label;
                }
              }
            },
            gridLines: {
              color: "rgba(255,255,255,0)",
              display: false
            }
          }],
          xAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (!isNaN(label)) {
                  if (Math.floor(label) === label) {
                    return _this.utils.formatNumber(label);
                  }
                } else {
                  if (dateAggregationLevel == "day" || dateAggregationLevel == "week") return (0, _moment.default)(label).format("MMM D");else if (dateAggregationLevel == "hour") {
                    if (index === 0 || Number((0, _moment.default)(label).diff((0, _moment.default)(label).startOf("day"), "hour")) === 0) {
                      return (0, _moment.default)(label).format("MMM D, h a");
                    } else {
                      return (0, _moment.default)(label).format("h a");
                    }
                  } else if (dateAggregationLevel == "12 hours") return (0, _moment.default)(label).format("MMM D, h a");else if (dateAggregationLevel == "5 minutes") return (0, _moment.default)(label).format("h:mm a");else return label;
                }
              },
              autoSkip: false
            },
            gridLines: {
              color: "rgba(255,255,255,0)",
              display: false
            }
          }]
        }
      };
      return opts;
    }
  });
  _exports.default = _default;
});