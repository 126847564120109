define("abcloud-ui/templates/policies/policies/data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9eyc2Zvb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 policy_card_view\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-17-24\"],[9],[0,\"\\n              Data Export Policy\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-7-24\"],[9],[0,\"\\n              \"],[2,\"<div class=\\\"help_tip\\\">\\n                <span class=\\\"caret_shadow\\\"></span>\\n                Please provide help text for this type of policy.\\n              </div>\"],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"policies-tenant/policy-components/edit-data-export-policy\",null,[[\"savedataExportPolicy\",\"cancel\",\"profiles_list\",\"localbay_list\",\"isExportProfileChosen\",\"sendToAbCloud\",\"current_profile\",\"current_profile_id\",\"current_localbay\",\"current_localbay_id\",\"exportOriginalDataEvents\",\"exportObfuscatedDataEvents\",\"exportDataEventsHeadersOnly\",\"disabled\",\"isProfilesEmpty\",\"errorMessage\"],[[27,\"action\",[[22,0,[]],\"savedataExportPolicy\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[23,[\"profiles_list\"]],[23,[\"localbay_list\"]],[23,[\"isExportProfileChosen\"]],[23,[\"sendToAbCloud\"]],[23,[\"current_profile\"]],[23,[\"current_profile_id\"]],[23,[\"current_localbay\"]],[23,[\"current_localbay_id\"]],[23,[\"exportOriginalDataEvents\"]],[23,[\"exportObfuscatedDataEvents\"]],[23,[\"exportDataEventsHeadersOnly\"]],\"\",[23,[\"isProfilesEmpty\"]],[23,[\"errorMessage\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/policies/data-export.hbs"
    }
  });
  _exports.default = _default;
});