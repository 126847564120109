define("abcloud-ui/routes/exceptions/transactions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    qp: Ember.inject.service('query-params'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    beforeModel() {
      this.isLoggedIn = this.auth.isLoggedIn();
      if (!this.auth.isLoggedIn('customer')) {
        this.transitionTo('/customer/login');
      }
    },
    model(params) {
      let qp = this.qp;
      params = qp.getParams();
      this.set('timeSelectedByUserOnRoute', params.timeSelectedByUser);
      var request = this.store.query('transaction', params);
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      const constants = this.constants;
      controller.setProperties({
        'model': model,
        'tableHeaders': constants.TRANSACTION_TABLE_HEADERS,
        'timeSelectedByUser': this.qp.getParam('timeSelectedByUser')
      });
      if (model.content.length > 0) {
        controller.set('firstTransaction', model.content[0].id);
      } else {
        controller.set('firstTransaction', '');
        this.transitionTo('exceptions.transactions');
      }
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    },
    renderTemplate() {
      this.render("exceptions.transactions", {
        into: "exceptions",
        outlet: "alerts"
      });
    },
    resetController(controller, isExiting) {
      controller.set('isLoading', false);
      controller.set('isPageRefresh', false);
      if (isExiting) {
        controller.setProperties({
          'mc': 0,
          'from_time': parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
          'to_time': parseInt(_moment.default.utc().valueOf() / 1000),
          'page': 1,
          'per_page': 6,
          'timeSelectedByUser': "3600",
          'previousPageChoice': 5,
          'firstEvent': 0
        });
      }
    }
  });
  _exports.default = _default;
});