define("abcloud-ui/components/policies-tenant/policies-protected-aws-s3-file-downloads/localbay-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      chooseLocalBay(localcomponentId, chosen_option, index) {
        this.chooseLocalBay(localcomponentId, chosen_option, index);
      }
    }
  });
  _exports.default = _default;
});