define("abcloud-ui/templates/components/exceptions/events/localbay-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YDvkqivU",
    "block": "{\"symbols\":[\"lb_detail\",\"label\"],\"statements\":[[7,\"p\"],[11,\"class\",\"data_label\"],[11,\"alt\",\"Localbay ID\"],[11,\"title\",\"Localbay ID\"],[9],[0,\"Localbay ID\"],[10],[0,\"\\n\"],[7,\"p\"],[12,\"class\",[28,[\"data_value localbay \",[23,[\"localcomponent\",\"css\"]]]]],[9],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"localcomponent\",\"obj\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"alt\",[22,1,[]]],[12,\"title\",[22,1,[]]],[9],[0,\"\\n      \"],[7,\"b\"],[9],[1,[22,2,[]],false],[10],[0,\" : \\n\"],[4,\"if\",[[27,\"eq\",[[22,2,[]],\"id\"],null]],null,{\"statements\":[[0,\"        \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/exceptions/events/localbay-details.hbs"
    }
  });
  _exports.default = _default;
});