define("abcloud-ui/components/system-localbay/localbay-create-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showPassword: false,
    validTitle: null,
    installMode: "easy",
    injectNotification: Ember.inject.service("inject-notification"),
    dropdownInstanceOptions: "",
    didInsertElement() {
      let dropdownInstanceOptions = JSON.parse(JSON.stringify(this.constants.DROPDOWN_INSTANCE_OPTIONS));
      dropdownInstanceOptions = dropdownInstanceOptions.filter(option => option.type != "AWS Fargate");
      this.set("dropdownInstanceOptions", dropdownInstanceOptions);
    },
    actions: {
      toggleInstallMode(event) {
        if (event.target.value === "easy") {
          this.set("installMode", event.target.value);
          Ember.$("#passphrase_label").css("visibility", "hidden");
          Ember.$("#passphrase_input").css("visibility", "hidden");
          Ember.$("#passphrase_help_text").css("visibility", "hidden");
        } else {
          this.set("installMode", event.target.value);
          Ember.$("#passphrase_label").css("visibility", "visible");
          Ember.$("#passphrase_input").css("visibility", "visible");
          Ember.$("#passphrase_help_text").css("visibility", "visible");
        }
      },
      toggleConfigPanel() {
        if (Ember.$(".config_panel").hasClass("collapsed")) {
          Ember.$(".config_panel").removeClass("collapsed");
          Ember.$(".config_options").addClass("expanded");
        } else {
          Ember.$(".config_panel").addClass("collapsed");
          Ember.$(".config_options").removeClass("expanded");
        }
      },
      chooseInstance(id, instanceDisplay) {
        const {
          type,
          orch
        } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[instanceDisplay];
        this.setProperties({
          instanceDisplayChosen: instanceDisplay,
          instanceTypeChosen: type,
          instanceOrchestrationChosen: orch
        });
      },
      saveLocalbayConfiguration() {
        var title = document.getElementById("title").value;
        var description = document.getElementById("description").value;
        var passphrase = document.getElementById("passphrase").value;
        var instanceType = this.instanceTypeChosen;
        var instanceOrch = this.instanceOrchestrationChosen;
        var installMode = this.installMode;
        if (installMode === "easy") {
          passphrase = "easyinstallmode";
        }
        var localbayDetails = {
          title,
          description,
          instanceType,
          instanceOrch,
          passphrase,
          installMode
        };
        if (title && passphrase && instanceType && this.validTitle) {
          if (confirm(this.constants.CONFIRM_LB_DETAILS)) {
            this.saveLocalbayConfiguration(localbayDetails);
          }
        } else {
          const elements = {
            nameFilledValue: title,
            passphraseFilledValue: passphrase
          };
          this.injectNotification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      togglePassphraseView() {
        if (Ember.$("#passphrase").attr("type") === "password") {
          Ember.$("#passphrase").attr("type", "text");
        } else {
          Ember.$("#passphrase").attr("type", "password");
        }
      },
      cancel() {
        this.cancel();
      }
    }
  });
  _exports.default = _default;
});