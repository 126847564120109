define("abcloud-ui/routes/deployments/stand-alone-controller/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render("deployments.stand-alone-controller.new", {
        into: "deployments",
        outlet: "deploymentsOutlet"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          isCreateControllerLoading: false,
          error: ""
        });
      }
    }
  });
  _exports.default = _default;
});