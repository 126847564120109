define("abcloud-ui/components/ecn-icon-error", ["exports", "ember-cli-notifications/components/ecn-icon-error"], function (_exports, _ecnIconError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ecnIconError.default;
    }
  });
});