define("abcloud-ui/controllers/system/hosted/microsensor/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    notifications: Ember.inject.service(),
    replace_element_with: Ember.inject.service("replace-element-with"),
    dpletType: "",
    dplets: null,
    totalDplets: 0,
    unknownDplets: 0,
    localcomponents: "",
    actions: {
      deleteDplet(dplet_id) {
        var dplet_in_progress = 0,
          dplet_success = 0,
          dplet_unkown = 0,
          err_msg;
        const constants = this.constants;
        const DPL_CONFIG_STATUS_IN_PROGRESS = constants.DPL_CONFIG_STATUS_IN_PROGRESS,
          DPL_CONFIG_STATUS_SUCCESS = constants.DPL_CONFIG_STATUS_SUCCESS;
        if (confirm("Are you sure you want to delete the MicroSensor?")) {
          this.replace_element_with.mask("#dplet_" + dplet_id);
          this.store.findRecord("dplet", dplet_id, {
            backgroundReload: false
          }).then(dplet => {
            dplet.destroyRecord().then(() => {
              this.store.query("dplet", {}).then(dplets => {
                if (dplets.content.length > 0) {
                  dplets.forEach(dplet => {
                    switch (dplet.data.config_status) {
                      case DPL_CONFIG_STATUS_IN_PROGRESS:
                        dplet_in_progress++;
                        break;
                      case DPL_CONFIG_STATUS_SUCCESS:
                        dplet_success++;
                        break;
                      default:
                        dplet_unkown++;
                    }
                  });
                }
                this.setProperties({
                  totalDplets: dplets.content.length,
                  unknownDplets: dplet_unkown
                });
              });
              this.notifications.success("Deleted successfully!", {
                autoClear: true,
                clearDuration: 2000
              });
            }).catch(respData => {
              this.replace_element_with.unmask("#dplet_" + dplet_id);
              if (respData && respData.hasOwnProperty("errors")) {
                err_msg = respData.errors[0].detail;
              } else {
                err_msg = constants.SOMETHING_WENT_WRONG;
              }
              this.notifications.error(err_msg, {
                autoClear: true,
                clearDuration: 2000
              });
            });
          });
        }
      },
      editDplet(dplet_id, dplet_sub_type) {
        this.replace_element_with.mask("#dplet_" + dplet_id);
        if (dplet_sub_type === "lambda") {
          this.transitionToRoute("system.hosted.microsensor.lambda.edit", dplet_id);
        } else if (dplet_sub_type === "f5hsl") {
          this.transitionToRoute("system.hosted.microsensor.f5-hsl-consumer.edit", dplet_id);
        } else if (dplet_sub_type === "pcap") {
          this.transitionToRoute("system.hosted.microsensor.passive-sniffer.edit", dplet_id);
        } else if (dplet_sub_type === "receiver") {
          this.transitionToRoute("system.hosted.microsensor.api-receiver.edit", dplet_id);
        } else if (dplet_sub_type === "kong_api_gateway") {
          this.transitionToRoute("system.hosted.microsensor.kong-api-gateway.edit", dplet_id);
        } else if (dplet_sub_type === "kafka") {
          this.transitionToRoute("system.hosted.microsensor.kafka.edit", dplet_id);
        } else if (dplet_sub_type === "nginx") {
          this.transitionToRoute("system.hosted.microsensor.nginx.edit", dplet_id);
        }
      },
      createMicrosensor() {
        if (this.localcomponents <= 0) {
          this.notifications.warning("Please create a Local Controller first to be able to create a MicroSensor", {
            autoClear: true,
            clearDuration: 10000
          });
        } else {
          this.transitionToRoute("system.hosted.microsensor.passive-sniffer.new");
        }
      }
    }
  });
  _exports.default = _default;
});