define("abcloud-ui/templates/components/style-raw-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MTzM7hRT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"tx_json_raw \",[21,\"customClass\"]]]],[9],[0,\"\\n    \"],[7,\"pre\"],[9],[0,\"        \"],[7,\"code\"],[12,\"id\",[21,\"component_id\"]],[9],[10],[0,\"\\n    \"],[10],[0,\"\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/style-raw-json.hbs"
    }
  });
  _exports.default = _default;
});