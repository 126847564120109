define("abcloud-ui/controllers/policies/api-recorder/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        policyHeadings: ["Status", "Elapsed Time", "Name", "LocalBay", "MicroSensor", "Published by", "Published on", "Actions"],
        errors: [],
        model: "",
        page: 1,
        currentApiRecorder: null,
        canPolicyResume: true,
        errorMessage: ""
      });
    },
    getValueOfElementById(elementId) {
      return document.getElementById(elementId).value;
    },
    actions: {
      createNewApiRecorder() {
        var has_localbays = this.model.meta_data.data.attributes.api_recorder.has_localbays;
        var has_dplets = this.model.meta_data.data.attributes.api_recorder.has_dplets;
        var errors = [];
        if (has_localbays) {
          if (has_dplets) {
            this.set("errors", []);
            this.transitionToRoute("policies.api-recorder.new");
          } else {
            let error_details = [];
            error_details["text"] = "MicroSensors";
            error_details["link"] = "system.hosted.microsensor.lambda.new";
            error_details["type"] = "api_recorder";
            errors.push(error_details);
          }
        } else {
          let error_details = [];
          error_details["text"] = "LocalBays";
          error_details["link"] = "system.hosted.localbay.add";
          error_details["type"] = "api_recorder";
          errors.push(error_details);
          this.set("errors", errors);
        }
      },
      transitionToEditView(id, policy) {
        if (!policy.attributes.policy_data.is_committed) {
          this.services.query_params.setParam("recording_status", this.constants.NOT_PUBLISHED);
        } else if (policy.attributes["is_recording?"]) {
          this.services.query_params.setParam("recording_status", this.constants.RECORDING);
        } else if (policy.attributes["has_completed_recording?"]) {
          this.services.query_params.setParam("recording_status", this.constants.DONE_RECORDING);
        }
        this.transitionToRoute("policies.api-recorder.edit", id);
      },
      openResumeRecordModal(object) {
        Ember.$("#i_continueRecordingModal").modal("show");
        this.set("currentApiRecorder", object);
        // -- make a call to rails to fetch if the policy can be resumed recording or not; the response would be boolean
        const canResumeUrl = this.services.urls.URL_CAN_RESUME(object.id);
        this.services.server.request("GET", canResumeUrl, null, response => {
          if (!response.errors) {
            this.set("canPolicyResume", true);
          } else {
            this.setProperties({
              canPolicyResume: false,
              errorMessage: response.errors[0].detail
            });
          }
        }, error => {
          this.debug.log(error);
        });
      },
      record(object, action) {
        var CLEAR_DURATION = this.constants.CLEAR_DURATION;
        var url = "";
        var request_object = "";
        if (action === "start" && confirm(this.constants.API_RECORDER_START_CONFIRMATION)) {
          this.services.replace_element_with.table_row_preloader_mask(object.id);
          action = "commit";
          url = this.services.urls.URL_POLICY(object.id);
          request_object = {
            data: {
              type: "policy",
              attributes: object.attributes.policy_data
            }
          };
          request_object.data.attributes["action"] = action;
          request_object.data.attributes.json.data.attributes.action = action;
          request_object.data.attributes["sub_type"] = object.attributes.sub_type;
          this.services.publish.policy(action, "PUT", url, request_object, response => {
            if (!response.error) {
              url = this.services.urls.URL_GET_API_RECORDER();
              this.services.publish.policy(action, "GET", url, "", response => {
                var structuredModel = {
                  policies: response
                };
                this.set("model", structuredModel);
              }, error => {
                this.debug.log(error);
              });
            }
          });
        } else if (action === "stop" && confirm(this.constants.API_RECORDER_STOP_CONFIRMATION)) {
          this.services.replace_element_with.table_row_preloader_mask(object.id);
          url = this.services.urls.URL_STOP_RECORDING(object.id);
          this.services.server.request("GET", url, null, response => {
            if (!response.error) {
              var structuredModel = {
                policies: response
              };
              this.set("model", structuredModel);
            } else {
              this.services.notifications.error("Failed to resume!", {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            }
          }, error => {
            this.services.notifications.error("Failed to resume!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
          });
        } else if (action === "re_record" && confirm(this.constants.API_RECORDER_CLONE_CONFIRMATION)) {
          this.services.replace_element_with.table_row_preloader_mask(object.id);
          url = this.services.urls.URL_RE_RECORD(object.id);
          this.services.server.request("GET", url, null, response => {
            if (!response.error) {
              var structuredModel = {
                policies: response
              };
              this.set("model", structuredModel);
            }
          }, error => {
            this.debug.log(error);
          });
        } else if (action === "resume") {
          this.services.replace_element_with.table_row_preloader_mask(object.id);
          let schedule_information = {};
          let valid_schedule_info = false;
          if (document.getElementById("auto_stop").checked) {
            schedule_information = {
              auto_stop: [this.getValueOfElementById("autoTime"), "hours"].join(" ")
            };
            valid_schedule_info = 0 < this.getValueOfElementById("autoTime") && this.getValueOfElementById("autoTime") < 73 ? true : false;
          } else if (document.getElementById("manual_stop").checked) {
            schedule_information = {
              manual_stop: true
            };
            valid_schedule_info = true;
          }
          const payload = {
            data: {
              attributes: {
                json: {
                  data: {
                    attributes: {
                      schedule_information
                    }
                  }
                }
              }
            }
          };
          if (valid_schedule_info) {
            Ember.$("#i_continueRecordingModal").modal("hide");
            url = this.services.urls.URL_RESUME_RECORD(object.id);
            this.services.server.request("POST", url, payload, response => {
              if (!response.errors) {
                this.services.ajax.request(this.services.urls.URL_GET_API_RECORDER(), {
                  method: "GET"
                }).then(policies => {
                  this.services.ajax.request(this.services.urls.URL_GET_PROFILES_FOR_POLICIES(), {
                    method: "GET"
                  }).then(meta_data => {
                    var structuredModel = {
                      policies: policies,
                      meta_data: meta_data
                    };
                    this.set("model", structuredModel);
                  });
                });
              } else {
                this.services.notifications.error(response.errors[0].detail, {
                  autoClear: true,
                  clearDuration: CLEAR_DURATION
                });
              }
            }, error => {
              this.services.notifications.error("Failed to resume!", {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            });
          }
        }
      },
      deletePolicy(policyId) {
        var CLEAR_DURATION = this.constants.CLEAR_DURATION;
        var url = this.services.urls.URL_POLICY(policyId);
        if (confirm(this.constants.API_RECORDER_DELETE_CONFIRMATION)) {
          this.services.replace_element_with.table_row_preloader_mask(policyId);
          this.services.ajax.request(url, {
            method: "DELETE"
          }).then(() => {
            this.services.notifications.success("Deleted successfully!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
            url = this.services.urls.URL_GET_API_RECORDER();
            this.services.ajax.request(url, {
              method: "GET"
            }).then(policies => {
              url = this.services.urls.URL_GET_PROFILES_FOR_POLICIES();
              this.services.ajax.request(url, {
                method: "GET"
              }).then(meta_data => {
                var structuredModel = {
                  policies: policies,
                  meta_data: meta_data
                };
                this.set("model", structuredModel);
              });
            });
          }).catch(() => {
            this.services.notifications.error("Failed to delete!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
          });
        }
      },
      pageChange: function (page) {
        this.setProperties({
          page
        });
        this.send("refreshModel");
      }
    }
  });
  _exports.default = _default;
});