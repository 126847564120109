define("abcloud-ui/components/policies-tenant/profile-components/session-termination/profile-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      if (this.changed_labels) {
        this.changed_labels.forEach(label_id => {
          if (this.disabled) {
            Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
          } else {
            Ember.$('#' + label_id + '').addClass('draft_label_changed');
          }
        });
      }
    },
    actions: {
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      },
      saveProfile(action, whenToPublish) {
        this.saveProfile(action, whenToPublish);
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancel() {
        this.transitionToListView();
      }
    }
  });
  _exports.default = _default;
});