define("abcloud-ui/components/nav-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['flex-center', 'width-15', '_logo']
  });
  _exports.default = _default;
});