define("abcloud-ui/controllers/manage/reset-password", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    utils: Ember.inject.service("utils"),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    ajax: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        errorStatus: "",
        success: "",
        isUpdatingPwd: false,
        password: null,
        confirmNewPassword: null,
        passwordVisibility: false,
        validations: {
          isFormValid: false,
          password: {
            isValid: null,
            required: true
          },
          newPassword: {
            isValid: false,
            required: true,
            invalidFeedback: {
              show: false,
              message: this.constants.PASSWORDS_NOT_SAME
            },
            format: {
              with: this.constants.PASSWORD_REGEX,
              invalidFeedback: this.constants.PASSWORD_NOT_MET_REQUIREMENTS
            }
          },
          confirmNewPassword: {
            isValid: false,
            required: true
          }
        }
      });
    },
    actions: {
      validatePasswords() {
        /* 
          Validate pwds only when user wants to edit pwd, else there is no need for validation on current pwd 
        */
        let validations = (0, _lodash.cloneDeep)(this.validations);

        /* 
            if current user's password for the account is 
            equal to new password user wants to set, then set newPassword as invalid,
            as current pwd and new pwd can't be same 
          */
        if (this.newPassword === this.password) {
          validations["newPassword"].isValid = false;
          validations["newPassword"].invalidFeedback.show = true;
        } else {
          /* 
              Hide the forced invalidFeedback message first and then run invalidation 
            */
          validations["newPassword"].invalidFeedback.show = false;

          /* 
              Validate the current value of the pwd 
            */
          const {
            isValid
          } = this.utils.validateCurrentElement({
            fControlValue: this.newPassword,
            formValidations: validations,
            fControlName: "newPassword"
          });
          validations["newPassword"].isValid = isValid;
        }

        /* 
            Confirm new password must match new password in order for it to be valid 
             for every change in new password on key up event, we need to update the
            string that confirmPassword validates itself against to this new password
          */
        if (this.newPassword === this.confirmNewPassword) {
          validations["confirmNewPassword"].isValid = true;
        } else {
          validations["confirmNewPassword"].isValid = false;
          validations["confirmNewPassword"]["format"] = {
            with: `^${this.newPassword}$`,
            invalidFeedback: this.constants.PASSWORDS_DO_NOT_MATCH
          };
        }
        const newValidations = this.utils.validateForm(validations);
        this.set("validations", newValidations);
      },
      togglePasswordVisibility() {
        this.set("passwordVisibility", !this.passwordVisibility);
      },
      updatePwd() {
        /* 
          set is loading state
        */
        this.setProperties({
          isUpdatingPwd: true,
          errorStatus: "",
          success: ""
        });
        const tenant_obj = {
          data: {
            id: this.auth.getUserId(),
            type: "admin_user",
            attributes: {
              password: this.password,
              new_password: this.newPassword,
              password_confirmation: this.confirmNewPassword
            }
          }
        };
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
          },
          "X-AB-Trace-ID": this.urls.generate_trace_id()
        });
        var URL = this.urls.URL_RESET_ADMIN_USER_PASSWORD(this.auth.getUserId());
        this.server.request("PUT", URL, tenant_obj, response => {
          if (response.meta && response.meta.errors) {
            this.setProperties({
              errorStatus: response.meta.errors[0] ? response.meta.errors[0].detail : response.meta.errors.detail,
              isUpdatingPwd: false
            });
          } else {
            this.setProperties({
              isUpdatingPwd: false,
              success: this.constants.ADMIN_PASSWORD_RESET_SUCCESSFULLY
            });
            this.auth.setIsAdminUserResttingPwd(false);
            window.location = this.constants.ADMIN_CUSTOMER_LIST;
          }
        }, error => {
          if (error.payload && error.payload.meta && error.payload.meta.errors) {
            this.setProperties({
              isUpdatingPwd: false,
              errorStatus: error.payload.meta.errors[0]["detail"]
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});