define("abcloud-ui/components/system-localbay/localcomponent-form", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    services: Ember.inject.service("import_all_services"),
    init() {
      this._super(...arguments);
      const defaultDisplay = "Kubernetes";
      const {
        type,
        orch
      } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[defaultDisplay];
      this.setProperties({
        title: "",
        description: "",
        passphrase: "",
        instanceDisplayChosen: defaultDisplay,
        instanceTypeChosen: type,
        instanceOrchestrationChosen: orch,
        dataRetentionSizeChosen: "8 GB",
        config_link: "",
        installMode: "easy",
        token: "",
        curlCommand: "",
        wgetCommand: "",
        dropdownInstanceOptions: "",
        showPassphrase: false,
        validations: {
          isFormValid: false,
          title: {
            isValid: null,
            required: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              min: this.constants.MIN_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          description: {
            isValid: true,
            length: {
              max: this.constants.MAX_DESCRIPTION_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Description",
                maxLen: this.constants.MAX_DESCRIPTION_CHARS
              })
            }
          },
          passphrase: {
            isValid: true,
            required: false,
            length: {
              min: this.constants.MIN_PASSPHRASE_CHARS,
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.PASSPHRASE_REQUIREMENTS
            }
          }
        }
      });
    },
    setDropdownOptions() {
      // As of release 20.2.3.0 we only support instance type 'Kubernetes' for stand alone local controller
      let dropdownInstanceOptions = JSON.parse(JSON.stringify(this.constants.DROPDOWN_INSTANCE_OPTIONS));
      if (this.localcomponentType === "eeconsole") {
        dropdownInstanceOptions = dropdownInstanceOptions.filter(option => !["Linux AMI"].includes(option.type));
      }
      this.set("dropdownInstanceOptions", dropdownInstanceOptions);
    },
    loadFormWithData() {
      const {
        config_link,
        token,
        install_mode,
        instance_type,
        instance_orch,
        hashed_passphrase,
        description,
        title
      } = this.localcomponent;
      let curlCmd = "curl -o setup.sh " + config_link;
      let wgetCmd = "wget -O setup.sh " + config_link;
      if (this.localcomponent && token) {
        curlCmd = 'curl -o setup.sh -H "Authorization: Bearer ' + token + '" ' + config_link;
        wgetCmd = 'wget -O setup.sh --header="Authorization: Bearer ' + token + '" ' + config_link;
      }
      this.setProperties({
        config_link,
        token,
        installMode: install_mode,
        instanceDisplayChosen: this.constants.INVERSE_DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[`${instance_type}${instance_orch}`],
        curlCommand: curlCmd,
        wgetCommand: wgetCmd,
        showPassphrase: false,
        passphrase: hashed_passphrase,
        description,
        title
      });
      if (this.localcomponentType === "eeconsole") {
        this.set("dataRetentionSizeChosen", [(0, _lodash.get)(this, "localcomponent.localentities[7].additional_meta.data_retention"), "GB"].join(" "));
      }
    },
    didInsertElement() {
      this.setDropdownOptions();
      if (this.editView) {
        this.loadFormWithData();
      }
    },
    actions: {
      formatTitle() {
        this.set("title", this.services.utils.preFormatTitle(this.title));
      },
      toggleInstallMode(event) {
        const installMode = event.target.value;
        let validations = (0, _lodash.cloneDeep)(this.validations);

        /* 
          Passphrase becomes mandatory when install mode is selected to advanced 
        */
        if (installMode === "advanced") {
          validations.passphrase.isValid = false;
          validations.passphrase.required = true;
        } else {
          validations.passphrase.isValid = true;
          validations.passphrase.required = false;
          this.set("passphrase", "");
        }
        validations = this.services.utils.validateForm(validations);
        this.setProperties({
          installMode,
          validations
        });
      },
      chooseInstance(id, instanceDisplay) {
        const {
          type,
          orch
        } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[instanceDisplay];
        this.setProperties({
          instanceTypeChosen: type,
          instanceOrchestrationChosen: orch,
          instanceDisplayChosen: instanceDisplay
        });
      },
      togglePassphraseView() {
        this.set("showPassphrase", !this.showPassphrase);
      },
      cancel(e) {
        this.cancel(e);
      },
      chooseDataRetentionSize(id, dataRetentionSize) {
        this.set("dataRetentionSizeChosen", dataRetentionSize);
      },
      submitForm() {
        let passphrase = "";
        let uuid = "";
        let hashed_passphrase = "";
        if (this.installMode === "advanced") {
          uuid = this.services.encrypt.generateUUID();
          passphrase = this.passphrase;
          hashed_passphrase = this.services.encrypt.encryptString(passphrase, uuid);
        } else {
          uuid = this.services.encrypt.generateUUID();
          passphrase = "easyinstallmode";
          hashed_passphrase = this.services.encrypt.encryptString(passphrase, uuid);
        }
        const requestObject = {
          component_type: "eeconsole",
          hashed_passphrase: hashed_passphrase,
          install_mode: this.installMode,
          instance_orch: this.instanceOrchestrationChosen,
          instance_type: this.instanceTypeChosen,
          data_retention_size: Number(this.dataRetentionSizeChosen.split(" ")[0]),
          title: this.title,
          description: this.description,
          uuid: uuid
        };
        this.createLocalcomponent(requestObject);
      }
    }
  });
  _exports.default = _default;
});