define("abcloud-ui/routes/system/hosted/microsensor/kong-api-gateway/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-CV-Trace-ID': this.urls.generate_trace_id()
        }
      });
      return this.ajax.request(this.urls.URL_GET_LOCALBAYS_RAILS(), {
        method: 'GET'
      }).then(localcomponents => {
        localcomponents = localcomponents.data;
        return localcomponents;
      });
    },
    setupController(controller, model) {
      try {
        controller.setProperties({
          'localcomponents': model,
          'current_localbay': model[0],
          'current_localbay_ip': model[0].attributes.load_balancer_ip
        });
      } catch (directLandingOnRouteDetected) {
        this.transitionTo('system.hosted.microsensor');
      }
    },
    renderTemplate() {
      this.render("system.hosted.microsensor.kong-api-gateway.new", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController(controller) {
      controller.setProperties({
        'current_localbay': controller.localcomponents[0],
        'current_localbay_ip': controller.localcomponents[0].attributes.load_balancer_ip,
        'progress_percent': 0,
        'savedSuccessfully': false,
        'config_link': '',
        'validTitle': null,
        'dpletError': ''
      });
      this.disconnectOutlet('dplet_form');
    }
  });
  _exports.default = _default;
});