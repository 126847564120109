define("abcloud-ui/controllers/policies/profiles/data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    urls: Ember.inject.service("urls"),
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    localconsoles_list: "",
    localconsoles_present: "",
    current_localconsole: "",
    current_localconsole_id: "",
    current_localconsole_host_ip: "",
    current_localconsole_instance_orch: "",
    errorMessage: "",
    getElementValue(id) {
      return document.getElementById(id).value;
    },
    isValidApiPath(api) {
      if (!api) {
        return false;
      }
      //-- if api path has http/https as scheme, then check if host exists or not
      else if (api.substring(0, 7) === "http://") {
        if (!api.substring(7, api.length)) {
          return false;
        }
      } else if (api.substring(0, 8) === "https://") {
        if (!api.substring(8, api.length)) {
          return false;
        }
      }
      return true;
    },
    actions: {
      confirmPublish() {
        this.send("saveDataExport", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      },
      toggle(otherInputId) {
        Ember.$("#" + otherInputId + "").prop("checked", false);
      },
      saveDataExport(action) {
        let name = this.getElementValue("name");
        let description = this.getElementValue("description");
        let api = this.getElementValue("api");
        let authToken = this.getElementValue("authToken");
        let sourceType = this.getElementValue("sourceType");
        let localconsole_host_ip = this.getElementValue("localconsoleIp");
        let receiverType = {};
        let is_valid_splunk_object = true;
        let is_valid_local_console_object = true;
        let isSendTobAbCloudSelected = document.getElementById("sendToAbCloud").checked;
        let isReceiverTypeSplunkSelected = document.getElementById("receiverTypeSplunk").checked;
        let isExportToLocalconsoleSelected = document.getElementById("sendToLocalconsole").checked;
        var changeLabelForLocalConsole = String(isExportToLocalconsoleSelected);

        //-- if api entered doesn't have http or https, include it and send for validation
        if (api && !(api.substring(0, 7) === "http://" || api.substring(0, 8) === "https://")) {
          api = "http://" + api;
        }
        //-- send for validation, if validation fails, set api to be empty so we can inject error notofication later on
        if (!this.isValidApiPath(api)) {
          api = "";
        }
        if (isSendTobAbCloudSelected) {
          receiverType["abcloud"] = true;
        }
        if (isReceiverTypeSplunkSelected) {
          receiverType["splunk"] = {
            type: this.getElementValue("receiverType"),
            api: api,
            auth_token: authToken,
            source_type: sourceType
          };
          if (!api || !authToken || !sourceType) {
            is_valid_splunk_object = false;
          }
        }
        if (isExportToLocalconsoleSelected) {
          changeLabelForLocalConsole = [String(isExportToLocalconsoleSelected), "(", this.current_localconsole, ")"].join("");
          receiverType["localconsole"] = {
            id: this.current_localconsole_id,
            title: this.current_localconsole,
            ip: localconsole_host_ip,
            hashed_passphrase: this.current_localconsole_hashed_passphrase
          };
          if (!localconsole_host_ip) {
            is_valid_local_console_object = false;
          }
        }
        let request_obj = {
          data: {
            type: "profile",
            attributes: {
              sub_type: "data_export",
              profile_type: "splunk",
              name: name,
              description: description,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "apiLabel.Receiver_Type.API_Path": api,
                "authTokenLabel.Receiver_Type.Splunk_Auth_Token": authToken,
                "sourceTypeLabel.Receiver_Type.Source_Type": sourceType,
                "receiverTypeLabel.Receiver_Type.Splunk_HTTP_Event_Collector": String(isReceiverTypeSplunkSelected),
                "sendToLocalconsoleLabel.Receiver_Type.Export_To_Local_Console": changeLabelForLocalConsole,
                "localconsoleIpLabel.Receiver_Type.Export_To_Local_Console.Host_IP": localconsole_host_ip,
                "sendToAbCloudLabel.Receiver_Type.Sent_to_AB_Cloud": String(isSendTobAbCloudSelected)
              },
              json: {
                data_receiver_information: receiverType
              }
            }
          }
        };
        if (name && description && (isReceiverTypeSplunkSelected || isSendTobAbCloudSelected || isExportToLocalconsoleSelected) && is_valid_splunk_object && is_valid_local_console_object) {
          //-- reset error notifications
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            authTokenFilledValue: authToken,
            apiPathFilledValue: api,
            sourceTypeFilledValue: sourceType,
            localconsoleIpFilledValue: is_valid_local_console_object,
            sendToAbCloudFilledValue: isReceiverTypeSplunkSelected || isSendTobAbCloudSelected || isExportToLocalconsoleSelected
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          let url = this.urls.URL_POLICY_PROFILE();
          this.publish.profile(action, "POST", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          let element = {
            apiPathFilledValue: api
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(element, "Invalid API Path, Should be a proper URL");
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            authTokenFilledValue: authToken,
            apiPathFilledValue: api,
            sourceTypeFilledValue: sourceType,
            localconsoleIpFilledValue: is_valid_local_console_object
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            sendToAbCloudFilledValue: isReceiverTypeSplunkSelected || isSendTobAbCloudSelected || isExportToLocalconsoleSelected
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.AT_LEAST_ONE_OPTION);
        }
      }
    }
  });
  _exports.default = _default;
});