define("abcloud-ui/routes/exceptions/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    redirect: function () {
      this.transitionTo("exceptions.events", "raw", {
        queryParams: {
          "is_api": "false"
        }
      });
    }
  });
  _exports.default = _default;
});