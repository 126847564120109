define("abcloud-ui/controllers/customer/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    createUserAbility: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        createUserAbility: this.auth.getTenantCreateUserAbility(),
        customerFirstLogIn: this.auth.getTenantIsFirstLogin(),
        customerPasswordWasReset: this.auth.getTenantPasswordWasReset(),
        customerTenantLoginType: this.auth.getTenantLoginType()
      });
    }
  });
  _exports.default = _default;
});