define("abcloud-ui/helpers/stringify-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringifyHash = stringifyHash;
  function stringifyHash(_ref) {
    let [hsh, markup] = _ref;
    // -- convert hash into single-line string
    var hashToString = JSON.stringify(hsh);
    if (markup === 0) {
      // -- remove quotes
      hashToString = hashToString.replace(/\"/g, ''); // eslint-disable-line no-useless-escape
      // -- remove curly braces
      hashToString = hashToString.slice(1, hashToString.length - 1);
    }
    // -- add html style tags
    // if(styleTag != undefined) {
    //   tmp = hashToString.split(',');
    //   hashToString = tmp.forEach(addStyleTag);
    // }
    return hashToString;
  }
  var _default = Ember.Helper.helper(stringifyHash);
  _exports.default = _default;
});