define("abcloud-ui/templates/components/system-localbay/localbay-component-cardset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3i4KvcPt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"component_wrapper\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"titleize\",[[23,[\"componentName\"]]],null],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"badge component_count\"],[9],[0,\"\\n      \"],[1,[21,\"componentCount\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"scrollable\"],[9],[0,\"\\n    \"],[1,[27,\"system-localbay/localbay-component-card\",null,[[\"componentTypeName\",\"componentSet\"],[[23,[\"componentName\"]],[23,[\"componentSet\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/localbay-component-cardset.hbs"
    }
  });
  _exports.default = _default;
});