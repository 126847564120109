define("abcloud-ui/helpers/sort-object-desc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sortObjectDesc = sortObjectDesc;
  function sortObjectDesc(_ref) {
    let [object] = _ref;
    /*
    example object = {80: 102, 380: 3026, 8000: 6900, 30364: 10200}
    arrayfy unsorted JSON object first and then sort(IN ASCENDING): 
    output=["80", 102, "380", 3026, "8000", 6900, "30364", 10200]
    key-val pairs are adjacent values in this new array
    */
    var sortedArray = [];
    for (var key in object) {
      sortedArray.push(key, object[key]);
    }
    sortedArray.sort((a, b) => {
      return a[1] - b[1];
    });

    /* Object does not persist order, so frame an array because array persists order */
    var persistOrder = [];
    var j = 0;
    for (var i = sortedArray.length - 1; i > 0; i--) {
      let key = sortedArray[i - 1];
      var value = sortedArray[i];
      persistOrder[j] = {
        [key]: value
      };
      /*
      key-val pairs are adjacent values in this new array, 
      so once we form our object in the array as [0: {key : value}], 
      we skip the value now and go to next key in the sortedArray
      */
      i = i - 1;

      //increment the index of persistOrder to accept the next object
      j++;
    }
    return persistOrder;
  }
  var _default = Ember.Helper.helper(sortObjectDesc);
  _exports.default = _default;
});