define("abcloud-ui/services/toggle-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    with_caret(caret_id, toggle_element_id) {
      var display_status = Ember.$('#' + toggle_element_id).css('display');
      if (display_status == 'none') {
        Ember.$('#' + toggle_element_id).css('display', 'flex');
        Ember.$('#' + caret_id).removeClass('fa fa-caret-right');
        Ember.$('#' + caret_id).addClass('fa fa-caret-down');
      } else {
        Ember.$('#' + toggle_element_id).css('display', 'none');
        Ember.$('#' + caret_id).removeClass('fa fa-caret-down');
        Ember.$('#' + caret_id).addClass('fa fa-caret-right');
      }
    }
  });
  _exports.default = _default;
});