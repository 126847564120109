define("abcloud-ui/services/stacked-bar-chart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    utils: Ember.inject.service('utils'),
    setup() {
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          var ctx = chart.chart.ctx;
          ctx.height = '90%';
        }
      });
      Chart.defaults.global.legend.display = false;
      Chart.defaults.global.maintainAspectRatio = false;
      Chart.defaults.scale.gridLines.display = false;
      Chart.defaults.scale.ticks.beginAtZero = true;

      // -- Future reference --

      // Chart.defaults.scale.ticks.userCallback = function(value, index, values) {
      //   if (Math.floor(value) === value) {
      //     return value;
      //   }
      // };
    },

    // -- data should be combo of dataSetLabels, ex:
    // [{"data":[0, 0, 0, 147, 0, 0, 0, 152, 0, 235, 103, 0, 0, 80, 5, 26, 116], "label":"200"}, {"data":[0, 0, 2, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], "label":"201"}..]
    // -- labels should be date/time - single level array, eg: ['05-24-2019','05-25-2019','05-26-2019']
    // -- colorSets will most likely be null
    getChartData(data, labels, colorSets) {
      var colors = [];
      var defaultColors = ['#15570d', '#0d4c3e', '#3d675e', '#43939d', '#4e8248', '#535652', '#85b0b5', '#86a982', '#959c98', '#bfd3bd', '#cacfc9', '#cce2e5', '#e9f1e8'];
      var dataSets = [];
      // -- add background colors into object
      for (var i = 0; i < data.length; i++) {
        data[i]['backgroundColor'] = defaultColors;
      }
      var chartData = {
        labels: labels,
        // -- dates, single-level array, eg: ['05-24-2019','05-25-2019','05-26-2019'],
        datasets: data
      };
      return chartData;
    },
    // -- removed title from args list as we are no longer using it
    getChartOptions(labelOrientation, dateAggregationLevel, chartHasData) {
      const _this = this;
      var opts = {
        // barPercentage: 0.85,
        // categoryPercentage: 0.9,
        barValueSpacing: 1,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            display: true
          }
        },
        label: {
          display: true
        },
        title: {
          display: false
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const {
                datasetIndex,
                yLabel
              } = tooltipItem;
              const {
                datasets
              } = data;
              let label = datasets[datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += !isNaN(yLabel) ? _this.utils.formatNumber(yLabel) : yLabel;
              return label;
            }
          }
        },
        scales: {
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                if (!isNaN(label)) {
                  if (Math.floor(label) === label) {
                    return _this.utils.formatNumber(label);
                  }
                } else {
                  return label;
                }
              }
            },
            gridLines: {
              color: "rgba(255,255,255,0)",
              display: false
            }
          }],
          xAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                // when the floored value is the same as the value we have a whole number
                if (labelOrientation == undefined) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                } else {
                  if (dateAggregationLevel == 'day' || dateAggregationLevel == 'week') return (0, _moment.default)(label).format('MMM DD');else if (dateAggregationLevel == 'hour') {
                    if (index === 0 || Number((0, _moment.default)(label).diff((0, _moment.default)(label).startOf('day'), 'hour')) === 0) {
                      return (0, _moment.default)(label).format('MMM D, h a');
                    } else {
                      return (0, _moment.default)(label).format('ha');
                    }
                  } else if (dateAggregationLevel == '12 hours') return (0, _moment.default)(label).format('MMM D, h a');else if (dateAggregationLevel == '5 minutes') return (0, _moment.default)(label).format('h:mm a');else return label;
                }
              },
              autoSkip: true
            },
            gridLines: {
              color: "rgba(255,255,255,0)",
              display: false
            }
          }]
        }
      };
      return opts;
    }
  });
  _exports.default = _default;
});