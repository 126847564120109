define("abcloud-ui/components/dashboard/dart-wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['dart_wrapper'],
    actions: {}
  });
  _exports.default = _default;
});