define("abcloud-ui/routes/customer/account/tenant-users/create-new-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    urls: Ember.inject.service("urls"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    beforeModel() {
      if (!this.auth.isLoggedIn("customer")) {
        this.transitionTo("/customer/login");
      }
    },
    model() {
      return this.store.findRecord("tenant_user", this.auth.getTenantUserId());
    },
    setupController(controller, model) {
      const userRoleCreationCapabilities = this.auth.getTenantCreateUserRole().split(",");
      const canCreateMultipleRoles = userRoleCreationCapabilities.length > 1;
      const userRole = userRoleCreationCapabilities[0];
      let url = this.urls.SYSTEM_FEATURES();
      this.server.request("GET", url, null, response => {
        if (response.data && response.data.length) {
          const {
            config_value
          } = response.data.find(feature => feature.config_key === "sso_enabled");
          if (config_value) {
            controller.setProperties({
              ssoEnabled: config_value === "true",
              userAccessType: config_value === "true" ? this.constants.LOGIN_ACCESS_TYPES[1].type : this.constants.LOGIN_ACCESS_TYPES[0].type,
              accessTypes: config_value === "true" ? this.constants.LOGIN_ACCESS_TYPES : this.constants.LOGIN_ACCESS_TYPES.slice(0, 1)
            });
          }
        }
      });
      controller.setProperties({
        createUserRole: userRole,
        canCreateMultipleRoles: canCreateMultipleRoles,
        ssoEnabled: false,
        userAccessType: this.constants.LOGIN_ACCESS_TYPES[0].type,
        accessTypes: this.constants.LOGIN_ACCESS_TYPES.slice(0, 1)
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          isAddUserLoading: false,
          errorStatus: "",
          success: "",
          createUserRole: null,
          canCreateMultipleRoles: false,
          ssoEnabled: false,
          firstName: "",
          lastName: "",
          email: "",
          userRoles: this.constants.USER_ROLES,
          accessTypes: this.constants.LOGIN_ACCESS_TYPES,
          userAccessType: this.constants.LOGIN_ACCESS_TYPES[0].display
        });
      }
    },
    renderTemplate() {
      this.render("customer.account.tenant-users.create-new-user", {
        outlet: "account-edit"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("customer.account.tenant-users.create-new-user");
        controller.set("isAddUserInitLoading", true);
        transition.promise.finally(function () {
          controller.set("isAddUserInitLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});