define("abcloud-ui/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    website: _emberData.default.attr('string'),
    point_of_contact: _emberData.default.attr('string'),
    point_of_contact_email: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    location: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    customer_dbs: _emberData.default.attr(),
    tenant_urls: _emberData.default.attr(),
    tenant_subdomain: _emberData.default.attr('string'),
    hosting: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    hosting_options: _emberData.default.attr(),
    region_options: _emberData.default.attr(),
    is_deleted: _emberData.default.attr('boolean'),
    customer_tenants: _emberData.default.hasMany('customer_tenant'),
    created_at: _emberData.default.attr('number'),
    updated_at: _emberData.default.attr('number')
  });
  _exports.default = _default;
});