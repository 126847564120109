define("abcloud-ui/routes/system/hosted/microsensor/passive-sniffer/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    daemonHelpStyle: 'focused',
    sidecarHelpStyle: '',
    current_localbay_k8_namespace: '',
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      return this.ajax.request(this.urls.URL_GET_LOCALBAYS_RAILS(), {
        method: 'GET'
      }).then(localcomponents => {
        localcomponents = localcomponents.data;
        return localcomponents;
      });
    },
    setupController(controller, model) {
      if (model[0].attributes.hasOwnProperty('localcomponent_properties') && model[0].attributes.localcomponent_properties.length > 0) {
        this.current_localbay_k8_namespace = model[0].attributes.localcomponent_properties[0].k8s_namespace;
      }
      try {
        controller.setProperties({
          'localcomponents': model,
          'current_localbay': model[0],
          'current_localbay_ip': model[0].attributes.load_balancer_ip,
          'current_localbay_k8_namespace': this.current_localbay_k8_namespace
        });
      } catch (directLandingOnRouteDetected) {
        this.transitionTo('system.hosted.microsensor');
      }
    },
    renderTemplate() {
      this.render("system.hosted.microsensor.passive-sniffer.new", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        let lb_namespace = '';
        if (controller.localcomponents[0].attributes.hasOwnProperty('localcomponent_properties') && controller.localcomponents[0].attributes.localcomponent_properties.length > 0) {
          lb_namespace = controller.localcomponents[0].attributes.localcomponent_properties[0].k8s_namespace;
        }
        controller.setProperties({
          'current_localbay': controller.localcomponents[0],
          'current_localbay_ip': controller.localcomponents[0].attributes.load_balancer_ip,
          'current_localbay_k8_namespace': lb_namespace,
          'progress_percent': 0,
          'savedSuccessfully': false,
          'config_link': '',
          'localcomponents': '',
          'current_instance_display': 'Kubernetes',
          'current_instance_type': 'container',
          'current_instance_orch': 'kubernetes',
          'instance_mode': 'daemonset',
          'validTitle': null,
          'dpletError': ''
        });
      }
      document.getElementById('name').value = '';
      document.getElementById('description').value = '';
    }
  });
  _exports.default = _default;
});