define("abcloud-ui/initializers/logger", ["exports", "ember-debug-logger"], function (_exports, _emberDebugLogger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    let debugLog = Ember.Object.extend({
      emberLogger: (0, _emberDebugLogger.default)(),
      log() {
        this.emberLogger(...arguments);
      }
    });
    application.register('DEBUG -- :logger', debugLog);
    application.inject('route', 'debug', 'DEBUG -- :logger');
    application.inject('controller', 'debug', 'DEBUG -- :logger');
    application.inject('component', 'debug', 'DEBUG -- :logger');
    application.inject('adapter', 'debug', 'DEBUG -- :logger');
    application.inject('service', 'debug', 'DEBUG -- :logger');
    application.inject('helper', 'debug', 'DEBUG -- :logger');
  }
  var _default = {
    name: 'logger',
    initialize: initialize
  };
  _exports.default = _default;
});