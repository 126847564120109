define("abcloud-ui/helpers/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    utils: Ember.inject.service('utils'),
    compute(_ref) {
      let [value] = _ref;
      return this.utils.formatNumber(value);
    }
  });
  _exports.default = _default;
});