define("abcloud-ui/helpers/array-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayLength = arrayLength;
  _exports.default = void 0;
  function arrayLength(_ref /*, hash*/) {
    let [array] = _ref;
    try {
      return array.length;
    } catch (arrayUndefined) {
      this.debug.log(arrayUndefined);
    }
  }
  var _default = Ember.Helper.helper(arrayLength);
  _exports.default = _default;
});