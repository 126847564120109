define("abcloud-ui/components/json-style-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      toggleJsonStyle(style) {
        this.set('jsonStyle', style);
      }
    },
    didRender() {
      Ember.$('#raw_json').html(this.raw_json);
    }
  });
  _exports.default = _default;
});