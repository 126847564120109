define("abcloud-ui/templates/system/hosted/microsensor/nginx/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YgZDLG4+",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/dplet-create-form\",null,[[\"createDplet\",\"cancel\",\"progress_percent\",\"savedSuccessfully\",\"config_link\",\"constants\",\"localcomponents\",\"current_localbay\",\"current_localbay_ip\",\"current_instance_type\",\"current_instance_orch\",\"current_instance_display\",\"dplet_type\",\"dpletError\",\"validTitle\"],[[27,\"action\",[[22,0,[]],\"createDplet\"],null],[27,\"action\",[[22,0,[]],\"formCancelTransition\"],null],[23,[\"progress_percent\"]],[23,[\"savedSuccessfully\"]],[23,[\"config_link\"]],[23,[\"constants\"]],[23,[\"localcomponents\"]],[23,[\"current_localbay\"]],[23,[\"current_localbay_ip\"]],[23,[\"current_instance_type\"]],[23,[\"current_instance_orch\"]],[23,[\"current_instance_display\"]],\"nginx\",[23,[\"dpletError\"]],[23,[\"validTitle\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/microsensor/nginx/new.hbs"
    }
  });
  _exports.default = _default;
});