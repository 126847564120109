define("abcloud-ui/controllers/policies/profiles/out-of-spec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    queryParams: ["api_recording_id"],
    api_recording_id: "",
    profile_id: "",
    file_error: true,
    isFileUploaded: false,
    redirected_from_api_recorder_view: false,
    current_spec_info_selection: "csvFileImportDetails",
    errorMessage: "",
    getElementValue(id) {
      return document.getElementById(id).value;
    },
    actions: {
      confirmPublish() {
        this.send("saveOutOfSpec", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      },
      setFileUpload() {
        this.set("isFileUploaded", true);
      },
      saveOutOfSpec(action) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var file = "";
        var fileName = "";
        var valid_spec_info = false;
        var http_method = "";
        var request_obj = {
          data: {
            id: this.profile_id,
            type: "profile",
            attributes: {
              sub_type: "out_of_spec_api",
              name: name,
              description: description,
              is_deleted: false,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "fileLabel.CSV_File_Import.File_Name": "",
                "swaggerLabel.API_Recording": ""
              },
              json: {
                method: ""
              }
            }
          }
        };
        if (this.current_spec_info_selection === this.constants.SPEC_INFO_OPTIONS[0]) {
          http_method = "PUT";
          file = document.getElementById("file-upload").files[0];
          fileName = file ? file.name : "";
          request_obj.data.attributes.json.method = "file_upload";
          request_obj.data.attributes.changeset["fileLabel.CSV_File_Import.File_Name"] = fileName;
          if (!this.file_error) {
            valid_spec_info = true;
          }
        } else if (this.current_spec_info_selection === this.constants.SPEC_INFO_OPTIONS[1]) {
          http_method = this.isFileUploaded ? "PUT" : "POST";
          if (this.current_api_recording != this.constants.NO_API_RECORDINGS_FOUND) {
            valid_spec_info = true;
          }
          request_obj.data.attributes.json.method = "open_api";
          request_obj.data.attributes.changeset["swaggerLabel.swaggerLabel.API_Recording"] = this.current_api_recording;
          request_obj.data.attributes.json["spec_info"] = {
            api_recording: {
              name: this.current_api_recording,
              id: this.current_api_recording_id
            }
          };
        }
        if (name && description && valid_spec_info) {
          let url = this.urls.URL_POLICY_PROFILE(this.profile_id);
          this.publish.profile(action, http_method, url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          // -- if there was a file validation error and yet user tries to save/publish profile,
          //  inject correct notification against file upload instead of the usual Mandatory field error

          if (!valid_spec_info) {
            let elements = {
              fileUploadFilledValue: fileName
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, !fileName ? this.constants.MANDATORY_FIELD : this.constants.FILE_INVALID);
            elements = {
              apiRecordingFilledValue: this.current_api_recording === this.constants.NO_API_RECORDINGS_FOUND ? false : true
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.NO_API_RECORDINGS_FOUND);
            elements = {
              nameFilledValue: name,
              descriptionFilledValue: description
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          } else {
            let elements = {
              nameFilledValue: name,
              descriptionFilledValue: description,
              fileUploadFilledValue: fileName,
              apiRecordingFilledValue: true
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          }
        }
      }
    }
  });
  _exports.default = _default;
});