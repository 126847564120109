define("abcloud-ui/components/policies-tenant/profile-components/edit-api-session-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      try {
        if (this.disabled) {
          for (let location in this.location_of_token) {
            document.getElementById(location + '_checkbox' + this.disabled).checked = true;
            document.getElementById(location + '_input' + this.disabled).value = this.location_of_token[location];
          }
        } else {
          for (let location in this.location_of_token) {
            document.getElementById(location + '_checkbox').checked = true;
            document.getElementById(location + '_input').disabled = false;
            document.getElementById(location + '_input').value = this.location_of_token[location];
            if (location === 'http_request_header') {
              document.getElementById(location + '_input').readOnly = true;
            }
          }
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabled) {
              Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + label_id + '').addClass('draft_label_changed');
            }
          });
        }
      } catch (newProfile) {
        this.debug.log('This is a new Profile', newProfile);
      }
    },
    actions: {
      chooseTokenLocation(element) {
        switch (element.target.id) {
          case 'http_request_header_checkbox':
            document.getElementById('http_request_header_input').disabled = !element.target.checked;
            document.getElementById('http_request_header_input').readOnly = element.target.checked;
            break;
          case 'http_header_checkbox':
            document.getElementById('http_header_input').disabled = !element.target.checked;
            break;
          case 'http_request_cookie_checkbox':
            document.getElementById('http_request_cookie_input').disabled = !element.target.checked;
            break;
          case 'http_request_api_payload_checkbox':
            document.getElementById('http_request_api_payload_input').disabled = !element.target.checked;
            break;
          default:
            this.debug.log('unknown element ID');
        }
      },
      saveProfile(action, whenToPublish) {
        this.saveProfile(action, whenToPublish);
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancel() {
        this.transitionToListView();
      },
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      }
    }
  });
  _exports.default = _default;
});