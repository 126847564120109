define("abcloud-ui/controllers/exceptions/events", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service('import-all-services'),
    utils: Ember.inject.service('utils'),
    queryParams: ['is_api', 'page', 'advancedPage'],
    from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
    to_time: parseInt(_moment.default.utc().valueOf() / 1000),
    advanced_from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
    advanced_to_time: parseInt(_moment.default.utc().valueOf() / 1000),
    page: 1,
    advancedPage: 1,
    is_api: '',
    timeSelectedByUser: "3600",
    advancedTimeSelectedByUser: "3600",
    tagName: 'tr',
    dataTableId: 'events_table',
    isPageRefresh: true,
    isAdvancedSearch: false,
    previousPageChoice: 5,
    lastHourOptionOnly: {
      '3600': 'Last Hour'
    },
    current_selection_from_list: '',
    actions: {
      pageChange(_page) {
        if (this.isAdvancedSearch) {
          this.set('advancedPage', _page);
          this.send('advancedSearch', _page);
        } else {
          this.set('page', _page);
          this.send('refreshCollection');
        }
      },
      advancedSearch(pageChoice) {
        this.setProperties({
          from_time: parseInt(_moment.default.utc().valueOf() / 1000) - this.timeSelectedByUser,
          to_time: parseInt(_moment.default.utc().valueOf() / 1000),
          advanced_from_time: parseInt(_moment.default.utc().valueOf() / 1000) - this.advancedTimeSelectedByUser,
          advanced_to_time: parseInt(_moment.default.utc().valueOf() / 1000)
        });
        this.set('isLoading', true);
        var filter = {};
        let areValidInputs = true;
        this.constants.ADVANCED_SEARCH_OPTIONS.forEach(search_option => {
          if (document.getElementById(search_option.input_id).value) {
            if (document.getElementById(search_option.negation_id).value === 'true') {
              filter[search_option.request_key] = ['!', document.getElementById(search_option.input_id).value].join('');
            } else {
              filter[search_option.request_key] = document.getElementById(search_option.input_id).value;
            }
          }
        });
        if (pageChoice) {
          filter['page'] = pageChoice;
          this.services.query_params.setParam('advancedPage', pageChoice);
        } else {
          filter['page'] = 1;
          this.services.query_params.setParam('advancedPage', 1);
        }
        if (this.is_api === 'true') {
          filter['is_api'] = true;
        }
        filter['collection'] = 'event';
        filter['from_time'] = this.advanced_from_time;
        filter['to_time'] = this.advanced_to_time;
        if (document.getElementById('port_input').value) {
          areValidInputs = areValidInputs && Number(document.getElementById('port_input').value) > 0 && Number(document.getElementById('port_input').value) < 65535;
        }
        if (document.getElementById('localbay_id_input').value) {
          areValidInputs = areValidInputs && Number(document.getElementById('localbay_id_input').value) > 0;
        }
        if (document.getElementById('dplet_id_input').value) {
          areValidInputs = areValidInputs && Number(document.getElementById('dplet_id_input').value) > 0;
        }
        var self = this;
        if (areValidInputs) {
          //-- fetch advanced search results
          this.services.replace_element_with.preloader('.search');
          this.services.server.request('GET', `${this.services.urls.URL_ADVANCED_SEARCH()}?${this.utils.convertToQueryString(filter)}`, null, response => {
            //-- reset/clear all the local event records from the data store
            var events = self.get('store').peekAll('event');
            var isClearingRecordsDone = new Promise(resolve => {
              resolve(events.forEach(event => {
                event.unloadRecord();
              }));
            });
            isClearingRecordsDone.then(() => {
              //-- push the new event records into the data store for easy accessebility in the template
              var isPushingToStoreDone = new Promise(resolve => {
                resolve(response.data.forEach(event => {
                  //-- ember Data store expects object to follow JSON API spec, so nest the object inside `data` key
                  var correctEventObj = {};
                  correctEventObj['data'] = event;
                  self.get('store').push(correctEventObj);
                }));
              });
              isPushingToStoreDone.then(() => {
                //-- fetch all the pushed/serialized records from the data store
                let events = self.get('store').peekAll('event');
                var model = events;

                //-- we need to explicitely set the meta property as meta property does not get set while pushing into the data store
                Ember.set(model, 'meta', response.meta);
                const constants = self.get('constants');
                self.setProperties({
                  'model': model,
                  'tableHeaders': constants.EVENT_TABLE_HEADERS,
                  'advancedPage': pageChoice || 1,
                  'isLoading': false
                });
                // -- auto-select first item per page
                // -- bug here causes transitionTo to fire several times
                // -- only happens for events, maybe filterType param issue

                if (model.content.length > 0) {
                  self.set('firstEvent', model.get('firstObject'));
                } else {
                  self.set('firstEvent', '');
                  self.transitionToRoute('exceptions.events');
                }
              });
            });
          });
        }
      },
      //-- this action is reused in several places based on the filter_type
      selectTimeRange(from_time, to_time, filter_type) {
        if (filter_type === 'advanced') {
          this.set('advancedTimeSelectedByUser', from_time);
        }
        //-- used to reset the advanced search event records in the data store and
        // fetch the original records into data store when the advanced search is closed --
        else if (filter_type === 'resetDataStore') {
          this.set('advancedPage', 1);
          this.services.query_params.setParam('advancedPage', 1);
          var events = this.store.peekAll('event');
          events.forEach(event => {
            event.unloadRecord();
          });
        } else {
          this.setProperties({
            'advancedTimeSelectedByUser': from_time,
            'timeSelectedByUser': from_time
          });
          this.services.query_params.setParam('timeSelectedByUser', from_time);
        }
        if (to_time) {
          to_time = to_time;
        } else {
          to_time = _moment.default.utc().valueOf();
        }
        if (from_time) {
          from_time = _moment.default.utc().valueOf() / 1000 - from_time; // (Date.parse(new Date(now.getTime() + now.getTimezoneOffset() * 60000)) - from_time)
        } else {
          from_time = 1;
        }
        if (filter_type === 'advanced') {
          this.set('advanced_from_time', parseInt(from_time));
          this.set('advanced_to_time', parseInt(to_time / 1000));
        } else {
          this.setProperties({
            'from_time': parseInt(from_time),
            'to_time': parseInt(to_time / 1000),
            'advanced_from_time': parseInt(from_time),
            'advanced_to_time': parseInt(to_time / 1000)
          });
          // -- resetting the page to be first page upon filtering; also used as a flag to update pageArray in the route after the response from backend --
          // this.set(this.queryParams[2], 1);
          this.services.query_params.setParam('from_time', parseInt(from_time));
          this.services.query_params.setParam('to_time', parseInt(to_time / 1000));
          this.services.query_params.setParam('page', 1);
          this.send('refreshModel');
        }
      },
      getEventDetails(id, element_id) {
        this.set('current_selection_from_list', element_id);
        this.transitionToRoute('exceptions.events.event', id);
      },
      refreshCollection() {
        let params = {};
        params['from_time'] = parseInt(_moment.default.utc().valueOf() / 1000) - this.timeSelectedByUser;
        params['to_time'] = parseInt(_moment.default.utc().valueOf() / 1000);
        params['timeSelectedByUser'] = this.timeSelectedByUser;
        params['is_api'] = this.is_api;
        params['page'] = this.page;
        this.setProperties({
          'isLoading': true,
          'from_time': params['from_time'],
          'to_time': params['to_time'],
          'timeSelectedByUser': params['timeSelectedByUser'],
          'is_api': params['is_api'],
          'page': params['page']
        });
        this.services.query_params.setParams(params);
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});