define("abcloud-ui/models/customer-tenant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    customer: _emberData.default.belongsTo('customer'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    customer_id: _emberData.default.attr('number'),
    has_hosted_localbay: _emberData.default.attr('boolean'),
    hosting: _emberData.default.attr('string'),
    is_trial_tenant: _emberData.default.attr('boolean'),
    random_hex: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    tenant_subdomain: _emberData.default.attr('string'),
    tenant_unique_id: _emberData.default.attr('string'),
    is_deleted: _emberData.default.attr('boolean'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    tenant_support_users: _emberData.default.attr(),
    tenant_support_user: _emberData.default.attr(),
    tenant_support_user_email: _emberData.default.attr('string'),
    tenant_support_user_password: _emberData.default.attr('string'),
    adminLoginUrl: _emberData.default.attr('string'),
    has_tenant_with_support_user: _emberData.default.attr(),
    default_admin_account: _emberData.default.attr(),
    updated_at: _emberData.default.attr('string')
  });
  _exports.default = _default;
});