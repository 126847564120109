define("abcloud-ui/routes/insights/dashboard/security/events", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service("ajax"),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    doughnutChart: Ember.inject.service("doughnut-chart"),
    barChart: Ember.inject.service("bar-chart"),
    filterParams: "",
    queryParams: Ember.inject.service("query-params"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    model() {
      this.set("filterParams", this.queryParams.getParams());
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        secTabActive: true,
        observeTabActive: false,
        from_time: this.queryParams.getParam("from_time"),
        to_time: this.queryParams.getParam("to_time"),
        timeSelectedByUser: this.queryParams.getParam("timeSelectedByUser"),
        page: this.queryParams.getParam("page")
      });
      var urls = this.urls;
      var params = this.filterParams;

      // -- events totals
      var eventsTotals = Ember.RSVP.hash({
        eventsTotalsPromise: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_TOTALS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      eventsTotals.then(() => {
        var model = eventsTotals._result.eventsTotalsPromise;
        controller.setProperties({
          model: model,
          showR1C1Preloader: false,
          showR1C2Preloader: false,
          showR1C3Preloader: false
        });
      });
    },
    renderTemplate() {
      this.render("insights.dashboard.security.events", {
        into: "insights.dashboard.security",
        outlet: "dashboard_data"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
          to_time: parseInt(_moment.default.utc().valueOf() / 1000),
          showR1C1Preloader: true,
          showR1C2Preloader: true,
          showR1C3Preloader: true
        });
      }
    }
  });
  _exports.default = _default;
});