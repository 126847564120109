define("abcloud-ui/controllers/system/hosted/microsensor/nginx/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    replace_element_with: Ember.inject.service('replace-element-with'),
    localcomponents: '',
    current_localbay: '',
    current_localbay_ip: '',
    progress_percent: 0,
    savedSuccessfully: false,
    config_link: '',
    validTitle: true,
    dpletError: '',
    success: '',
    actions: {
      editDplet(dplet_id) {
        if (this.validTitle) {
          var name = document.getElementById('name').value;
          var description = document.getElementById('description').value;
          this.replace_element_with.preloader('.save');
          this.store.findRecord('dplet', dplet_id).then(dplet => {
            dplet.setProperties({
              name: name,
              description: description
            });
            dplet.save().then(() => {
              this.setProperties({
                'dpletError': '',
                'success': 'MicroSensor successfully updated.'
              });
            }).catch(error => {
              this.set('dpletError', error.errors[0].detail);
            });
          });
        }
      },
      formCancelTransition() {
        this.transitionToRoute('system.hosted.microsensor');
      }
    },
    resetControllerProperties() {
      this.setProperties({
        'localcomponents': '',
        'current_localbay': '',
        'current_localbay_ip': '',
        'progress_percent': 0,
        'savedSuccessfully': false,
        'config_link': '',
        'dpletError': '',
        'success': ''
      });
    }
  });
  _exports.default = _default;
});