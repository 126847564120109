define("abcloud-ui/routes/system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    beforeModel() {
      if (!this.auth.isLoggedIn("customer")) {
        this.controllerFor("application").set("customerLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("customer.login");
      }
    },
    model() {
      return this.store.query("localcomponent", {
        component_type: "localconsole"
      });
    },
    setupController(controller, model) {
      controller.set("localconsoles", model);
      this.store.find("tenant_user", localStorage.getItem("CUSTOMER_TENANT_USER_ID")).then(response => {
        controller.setProperties({
          isSuperAdmin: !!(response.role_level === this.constants.SUPER_ADMIN)
        });
      });
    }
  });
  _exports.default = _default;
});