define("abcloud-ui/templates/components/exceptions/refresh-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6OisiSuV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"alt\",\"refresh list\"],[11,\"title\",\"Refresh list\"],[11,\"id\",\"theRefresher\"],[12,\"class\",[28,[\"refresh button \",[27,\"if\",[[23,[\"isUpdating\"]],\"updating\",\"fas fa-2x fa-sync-alt\"],null]]]],[9],[0,\" \"],[10],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/exceptions/refresh-button.hbs"
    }
  });
  _exports.default = _default;
});