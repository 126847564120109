define("abcloud-ui/components/im-design-system/im-dropdown/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    label: "",
    setDisplayLabelForCurrentItem() {
      /* 
      Get the display label for current selected dropdown item
      */
      this.set("label", this.options.find(_ref => {
        let {
          type
        } = _ref;
        return type === this.currentValue;
      }).display);
    },
    didReceiveAttrs() {
      this.setDisplayLabelForCurrentItem();
    },
    didUpdateAttrs() {
      this.setDisplayLabelForCurrentItem();
    },
    actions: {
      updateCurrentValue(_ref2) {
        let {
          type
        } = _ref2;
        this.set("currentValue", type);
      }
    }
  });
  _exports.default = _default;
});