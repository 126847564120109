define("abcloud-ui/helpers/arrayfy-on-character", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayfyOnCharacter = arrayfyOnCharacter;
  _exports.default = void 0;
  function arrayfyOnCharacter(_ref) {
    let [stringWithSplCharacter, character] = _ref;
    if (stringWithSplCharacter === undefined || stringWithSplCharacter === null || stringWithSplCharacter === '' || character === undefined || character === null || character === '') {
      return [];
    }
    var stringArray = stringWithSplCharacter.split(character);
    return stringArray;
  }
  var _default = Ember.Helper.helper(arrayfyOnCharacter);
  _exports.default = _default;
});