define("abcloud-ui/templates/policies/policies/api-parameter-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Yclfaqh8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"policy_card_view\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-8\"],[9],[0,\"\\n          API Parameter Pin Policy\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-4\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"policies-tenant/policy-components/edit-api-parameter-pin-policy\",null,[[\"savePolicy\",\"cancel\",\"localbay_list\",\"api_parameter_pin_profiles\",\"api_parameter_pin_linked_profiles\",\"current_localbay\",\"current_localbay_id\",\"selected_profile_ids\",\"severity_options\",\"chosenSeverityLevels\",\"severity_level_responses\",\"current_microsensor\",\"current_microsensor_id\",\"microsensor_list\",\"api_path_severity\",\"api_parameter_severity\",\"errorMessage\",\"apiSessionTokenProfilesList\",\"sessionTerminationProfilesList\",\"disabledStatus\",\"isProfilesEmpty\"],[[27,\"action\",[[22,0,[]],\"savePolicy\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[23,[\"localbay_list\"]],[23,[\"api_parameter_pin_profiles\"]],[23,[\"api_parameter_pin_linked_profiles\"]],[23,[\"current_localbay\"]],[23,[\"current_localbay_id\"]],[23,[\"selected_profile_ids\"]],[23,[\"constants\",\"SEVERITY_OPTIONS\"]],[23,[\"chosenSeverityLevels\"]],[23,[\"severity_level_responses\"]],[23,[\"current_microsensor\"]],[23,[\"current_microsensor_id\"]],[23,[\"microsensor_list\"]],[23,[\"api_path_severity\"]],[23,[\"api_parameter_severity\"]],[23,[\"errorMessage\"]],[23,[\"apiSessionTokenProfilesList\"]],[23,[\"sessionTerminationProfilesList\"]],\"\",[23,[\"isProfilesEmpty\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/policies/api-parameter-pin.hbs"
    }
  });
  _exports.default = _default;
});