define("abcloud-ui/templates/components/dashboard/dart-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/aOsaTR2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"catDetails\",\"status\"]],\"active\"],null]],null,{\"statements\":[[4,\"link-to\",[[23,[\"linkTarget\"]],[23,[\"linkFilter\"]],[27,\"query-params\",null,[[\"type\"],[[23,[\"qpType\"]]]]]],[[\"invokeAction\",\"id\"],[[27,\"action\",[[22,0,[]],\"updateQueryParams\"],null],[23,[\"tabId\"]]]],{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"alt\",[23,[\"catDetails\",\"help_text\"]]],[12,\"title\",[23,[\"catDetails\",\"help_text\"]]],[9],[0,\"\\n      \"],[1,[21,\"catName\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[21,\"tabId\"]],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[21,\"catName\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/dashboard/dart-tab.hbs"
    }
  });
  _exports.default = _default;
});