define("abcloud-ui/routes/customer/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    beforeModel() {
      if (!this.auth.isLoggedIn("customer")) {
        this.transitionTo("/customer/login");
      }
    },
    model() {
      return this.store.findRecord("tenant_user", this.auth.getTenantUserId());
    },
    setupController(controller, model) {
      controller.setProperties({
        tenant: model,
        createUserAbility: this.auth.getTenantCreateUserAbility(),
        isSuperAdmin: model.role_level === this.constants.SUPER_ADMIN,
        isAdmin: model.role_level === "administrator",
        customerFirstLogIn: this.controllerFor("application").get("customerFirstLogIn"),
        customerPasswordWasReset: this.controllerFor("application").get("customerPasswordWasReset")
      });
    },
    renderTemplate() {
      this.render("customer.account");
    },
    redirect() {
      this.transitionTo("customer.account.self-account");
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        // //this.get('manage_requests').abort_ember_request( 'tenant_users');
      }
    }
  });
  _exports.default = _default;
});