define("abcloud-ui/controllers/manage/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        plainTextPassword: ""
      });
    },
    actions: {
      submit: function () {
        var params = {};
        var attrs = ["firstName", "lastName", "email"];
        for (var i = 0; i < attrs.length; i++) {
          var val = [attrs[i]];
          params[[val]] = this.get("" + val + "");
        }
        if (params["firstName"] === undefined || params["firstName"] === "") {
          return false;
        } else if (params["email"] === undefined || params["email"] === "") {
          return false;
        } else {
          var admin_user = this.store.createRecord("admin_user", params);
          admin_user.save().then(response => {
            this.set("plainTextPassword", response.data.plainTextPassword);
            this.notifications.success(this.constants.USER_ADDED_SUCCESSFULLY);
            this.set("error", "");
          }).catch(() => {
            this.set("error", this.constants.SOMETHING_WENT_WRONG);
          });
        }
      },
      navigateBackToUserList() {
        this.transitionToRoute("manage.users.list");
      }
    }
  });
  _exports.default = _default;
});