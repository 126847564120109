define("abcloud-ui/components/policies-tenant/form-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['pure-u-17-24', 'padding_top_20', 'actions_row', 'last'],
    actions: {}
  });
  _exports.default = _default;
});