define("abcloud-ui/templates/components/form-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UDbvJ6s2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row text-right\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-5 d-flex offset-md-7 justify-content-end align-items-center\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"cancelAction\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"im-design-system/im-buttons/im-button\",null,[[\"className\",\"btnText\",\"onclick\"],[\"btn--cancel mr-3\",[23,[\"cancelText\"]],[27,\"action\",[[22,0,[]],[23,[\"cancelAction\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"im-design-system/im-buttons/im-button\",null,[[\"className\",\"btnText\",\"onclick\",\"isLoading\",\"disabled\"],[\"primary right\",[23,[\"submitText\"]],[27,\"action\",[[22,0,[]],[23,[\"submitAction\"]]],null],[23,[\"isLoading\"]],[23,[\"disableSubmit\"]]]]],false],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/form-actions.hbs"
    }
  });
  _exports.default = _default;
});