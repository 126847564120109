define("abcloud-ui/components/system-localbay/localbay-generate-config-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showLocalbayBringupDetails: false,
    showLocalbayConfigFileDetails: false,
    configFileStatusFromLocalComponent: {},
    LBbringupConfigureStatusFromLocalComponent: {},
    INSTANCE_TYPE_NEEDING_ADVANCED_HELP_TEXT: '',
    updateComponentView() {
      Ember.$('#configFileLinkGenProgress').css('width', this.configFileProgressBarPercent + '%');
      Ember.$('#localbayBringUpProgress').css('width', this.localBayProgressBarPercent + '%');
      this.set('INSTANCE_TYPE_NEEDING_ADVANCED_HELP_TEXT', this.constants.INSTANCE_TYPE_NEEDING_ADVANCED_HELP_TEXT);
      var configFileStatusFromController = this.fileConfigureStatus;
      var LBbringupConfigureStatusFromController = this.LBbringupConfigureStatus;
      this.setProperties({
        configFileStatusFromLocalComponent: {},
        LBbringupConfigureStatusFromLocalComponent: {}
      });
      for (var key in configFileStatusFromController) {
        if (configFileStatusFromController.hasOwnProperty(key)) {
          Ember.set(this.configFileStatusFromLocalComponent, key, configFileStatusFromController[key]);
        }
      }
      for (var component in LBbringupConfigureStatusFromController) {
        if (LBbringupConfigureStatusFromController.hasOwnProperty(component)) {
          Ember.set(this.LBbringupConfigureStatusFromLocalComponent, component, LBbringupConfigureStatusFromController[component]);
        }
      }
    },
    didUpdateAttrs() {
      this.updateComponentView();
    },
    didInsertElement() {
      this.updateComponentView();
    },
    actions: {
      copyLinkToClipboard() {
        this.copyLinkToClipboard();
      },
      showLocalbay() {
        this.showLocalbay();
      },
      refreshConfigFileGeneration() {
        this.refreshConfigFileGeneration();
      },
      refreshLocalbayGeneration() {
        this.refreshLocalbayGeneration();
      },
      toggleShowLocalbayBringupDetails() {
        this.set('showLocalbayBringupDetails', !this.showLocalbayBringupDetails);
      },
      toggleShowLocalbayConfigFileDetails() {
        this.set('showLocalbayConfigFileDetails', !this.showLocalbayConfigFileDetails);
      }
    },
    willDestroyElement() {
      this.setProperties({
        configFileStatusFromLocalComponent: {},
        LBbringupConfigureStatusFromLocalComponent: {},
        showLocalbayBringupDetails: false,
        showLocalbayConfigFileDetails: false
      });
      this.destroyComponent();
    }
  });
  _exports.default = _default;
});