define("abcloud-ui/controllers/system/hosted/microsensor/passive-sniffer/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    toggle_element: Ember.inject.service("toggle-element"),
    localcomponents: "",
    current_localbay: "",
    current_localbay_ip: "",
    current_localbay_k8_namespace: "",
    progress_percent: "",
    savedSuccessfully: false,
    validTitle: null,
    config_link: "",
    instance_mode: "daemonset",
    error: "",
    init() {
      this._super(...arguments);
      const instanceDisplay = "Kubernetes";
      const {
        type: instanceType,
        orch: instanceOrchestration
      } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[instanceDisplay];
      this.setProperties({
        current_instance_display: instanceDisplay,
        current_instance_type: instanceType,
        current_instance_orch: instanceOrchestration,
        instance_mode: "daemonset",
        dpletError: ""
      });
    },
    _setCurrentLocalbayNamespace(localbay) {
      if (localbay.attributes.hasOwnProperty("localcomponent_properties") && localbay.attributes.localcomponent_properties.length > 0) {
        return localbay.attributes.localcomponent_properties[0].k8s_namespace;
      } else {
        return "";
      }
    },
    _resetCurrentLocalbayNamespace() {
      this.current_localbay_k8_namespace = "";
    },
    actions: {
      chooseLocalBay(localcomponentId) {
        this.localcomponents.forEach(localbay => {
          if (localbay.id === localcomponentId) {
            this.set("current_localbay_k8_namespace", this._setCurrentLocalbayNamespace(localbay));
            this.setProperties({
              current_localbay: localbay,
              current_localbay_k8_namespace: this.current_localbay_k8_namespace,
              current_localbay_ip: localbay.attributes.load_balancer_ip
            });
          }
        });
      },
      collectLocalbayIp() {
        // -- for user to have manual input option
        this.set("current_localbay_ip", document.getElementById("host_ip").value);
      },
      toggle_other_options() {
        this.toggle_element.with_caret("other_options_caret_icon", "other_options");
      },
      setInstanceMode(e) {
        this.set("instance_mode", e.target.value);
        if (this.instance_mode === "daemonset") {
          this.set("daemonHelpStyle", "focused");
          this.set("sidecarHelpStyle", "");
        } else {
          this.set("daemonHelpStyle", "");
          this.set("sidecarHelpStyle", "focused");
        }
      },
      createDplet() {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;

        // -- if user has entered ip, take it. Otherwise, is the localbay instance_orch k8? if so, localbay_ip = "ablocalbay" else localbay_ip = localbay_loadbalancer_ip
        var localbay_ip = "";
        var namespace = "";
        var cluster_domain = "";
        var dns_ip = "";
        if (this.current_localbay.attributes.instance_type === "hosted") {
          localbay_ip = this.current_localbay_ip;
        } else {
          // -- if localbay is k8, then take the preloaded values or user input in the localbay details.
          // Otherwise, did user eneter input? if so, take it, else localbay_ip = localbay_loadbalancer_ip --
          if (this.current_localbay.attributes.instance_orch === "kubernetes" && this.current_instance_orch === "kubernetes") {
            localbay_ip = document.getElementById("host_ip").value;
            namespace = document.getElementById("namespace").value;
            cluster_domain = document.getElementById("cluster_domain").value;
            dns_ip = document.getElementById("dns_ip") ? document.getElementById("dns_ip").value : "";
          } else if (document.getElementById("host_ip") && document.getElementById("host_ip").value) {
            localbay_ip = document.getElementById("host_ip").value;
          } else {
            // this is specifically for f5-hsl type microsensor
            localbay_ip = this.current_localbay_ip;
          }
        }
        // --  clear the input validation errors before proceeding further
        var elements = {
          nameFilledValue: name,
          localbayIpFilledValue: localbay_ip
        };
        this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, "clear all");
        if (name && localbay_ip && this.validTitle) {
          this.set("progress_percent", 50);
          var passiveSniffer = this.store.createRecord("dplet", {
            sub_type: "pcap",
            name: name,
            install_mode: this.current_localbay.attributes.install_mode,
            uuid: this.current_localbay.attributes.uuid,
            hashed_passphrase: this.current_localbay.attributes.hashed_passphrase,
            localbay_ip: localbay_ip,
            namespace: namespace,
            cluster_domain: cluster_domain,
            dns_ip: dns_ip,
            localcomponent_instance_type: this.current_localbay.attributes.instance_type,
            instance_type: this.current_instance_type,
            dplet_type: "pcap",
            localcomponent_title: this.current_localbay.attributes.title,
            instance_orch: this.current_instance_orch,
            description: description,
            localcomponent_id: this.current_localbay.id,
            instance_mode: this.instance_mode
          });
          this.replace_element_with.preloader(".save");
          passiveSniffer.save().then(response => {
            if (!response.errors.length) {
              this.setProperties({
                progress_percent: 100,
                savedSuccessfully: true,
                config_link: response.data.config_link
              });
            } else {
              passiveSniffer.rollbackAttributes();
              this.debug.log("dplet server error");
              this.set("dpletError", response.errors[0].detail);
            }
          }).catch(error => {
            passiveSniffer.rollbackAttributes();
            this.set("progress_percent", 0);
            this.debug.log("dplet server error");
            if (error.errors[0].detail === this.constants.UNPROCESSABLE_ENTITY) {
              this.set("dpletError", this.constants.DPLET_TITLE_TAKEN);
            } else {
              this.set("dpletError", error.errors[0].detail);
            }
          });
        } else {
          elements = {
            nameFilledValue: name,
            localbayIpFilledValue: localbay_ip
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      copyLinkToClipboard() {
        var copyText = document.getElementById("config_link");
        copyText.select();
        document.execCommand("copy");
      },
      cancel() {
        this.transitionToRoute("system.hosted.microsensor.index");
      }
    },
    resetControllerProperties() {
      this.setProperties({
        localcomponents: "",
        current_localbay: "",
        current_localbay_ip: "",
        progress_percent: 0,
        savedSuccessfully: false,
        config_link: "",
        current_instance_type: "container",
        current_instance_orch: "docker-compose",
        dpletError: "",
        success: ""
      });
    }
  });
  _exports.default = _default;
});