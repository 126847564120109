define("abcloud-ui/routes/system/hosted/localconsole/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('localcomponent', params.id, {
        reload: true
      });
    },
    setupController(controller, model) {
      controller.set('localconsole', model);
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'validTitle': null,
          'config_link': ''
        });
      }
    },
    renderTemplate() {
      this.render("system.hosted.localconsole.edit", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    }
  });
  _exports.default = _default;
});