define("abcloud-ui/routes/policies/policies/data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    ajax: Ember.inject.service('ajax'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      var request = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES('data_export'));
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      var isProfilesEmpty = false;
      if (Ember.$.isEmptyObject(model.data.attributes.localbays)) {
        this.transitionTo('policies.policies');
      } else {
        var tempCurrentProfiles = [];
        var profilesList = model.data.attributes.profiles.available.data;
        var localbaysList = model.data.attributes.localbays.data;
        if (Ember.$.isEmptyObject(model.data.attributes.profiles.available)) {
          tempCurrentProfiles[0] = {};
          tempCurrentProfiles[0]['name'] = '--No Profiles--';
          tempCurrentProfiles[0]['id'] = '--No Profiles ID--';
          isProfilesEmpty = true;
        } else {
          tempCurrentProfiles = profilesList;
        }
        controller.setProperties({
          'profiles_list': profilesList,
          'localbay_list': localbaysList,
          'current_profile': tempCurrentProfiles[0].data.attributes.name,
          'current_profile_id': tempCurrentProfiles[0].data.id,
          'current_localbay': localbaysList[0].title,
          'current_localbay_id': localbaysList[0].id,
          'isProfilesEmpty': isProfilesEmpty
        });
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          'sendToAbCloud': false,
          'isExportProfileChosen': false,
          'current_profile': '',
          'current_profile_id': '',
          'current_profile_object': '',
          'current_localbay': '',
          'current_localbay_id': '',
          'profiles_list': {},
          'errorMessage': ''
        });
      }
    }
  });
  _exports.default = _default;
});