define("abcloud-ui/routes/system/hosted/microsensor/kafka/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('dplet', params.id, {
        reload: true
      });
    },
    setupController(controller, model) {
      controller.set('dplet', model);
    },
    renderTemplate() {
      this.render("system.hosted.microsensor.kafka.edit", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController: function (controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.resetControllerProperties();
      }
    }
  });
  _exports.default = _default;
});