define("abcloud-ui/components/policies-tenant/profile-components/edit-out-of-spec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    notify_unfilled_mandatory_field: Ember.inject.service('inject-notification'),
    isFileDeleted: false,
    didInsertElement() {
      try {
        if (this.redirected_from_api_recorder_view) {
          this.send('toggleSpecInfo', this.constants.SPEC_INFO_OPTIONS[1]);
        } else {
          this.send('toggleSpecInfo', this.constants.SPEC_INFO_OPTIONS[0]);
        }
        if (this.profile.hasOwnProperty('spec_info')) {
          if (this.disabled) {
            this.send('toggleSpecInfo', this.constants.SPEC_INFO_OPTIONS[1] + this.disabled);
          } else {
            this.send('toggleSpecInfo', this.constants.SPEC_INFO_OPTIONS[1]);
          }
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabled) {
              Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + label_id + '').addClass('draft_label_changed');
            }
          });
        }
      } catch (newProfile) {
        this.debug.log('This is a new Profile');
      }
    },
    actions: {
      toggleSpecInfo(element) {
        if (element === this.constants.SPEC_INFO_OPTIONS[1] && this.isFileUploaded && !this.isFileDeleted) {
          //-- notify the user of  that the file needs to be deleted before making another choice
          let elements = {
            'fileUploadFilledValue': false
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.DELETE_NOTIFICATION);
        } else {
          //-- clear the notification if previously injected
          let elements = {
            'fileUploadFilledValue': 'N/A'
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, 'clear the notifications');
          this.constants.SPEC_INFO_OPTIONS.forEach(element_id => {
            if (element === element_id + this.disabled) {
              this.set('current_spec_info_selection', element_id);
              Ember.$('#' + element_id + this.disabled).show();
            } else {
              Ember.$('#' + element_id + this.disabled).hide();
            }
          });
        }
      },
      saveOutOfSpec(action, whenToPublish) {
        this.saveOutOfSpec(action, whenToPublish);
      },
      chooseApiRecording(id, api_recording) {
        this.setProperties({
          'current_api_recording': api_recording,
          'current_api_recording_id': id
        });
      },
      setFileUpload() {
        this.setFileUpload();
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancel() {
        this.transitionToListView();
      },
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      }
    }
  });
  _exports.default = _default;
});