define("abcloud-ui/routes/policies/profiles/api-parameter-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'profile_id': '',
          'file_error': false,
          'file_validated': false,
          'file_id': '',
          'options': {
            'single_api': true,
            'csv_file_import': false
          },
          'pin_static_values': [{
            '': ''
          }],
          'session_first_seen_values': [''],
          'pinned_first_seen_values': [''],
          'errorMessage': ''
        });
      }
    }
  });
  _exports.default = _default;
});