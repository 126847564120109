define("abcloud-ui/templates/components/customer/account-update-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ACT6BFKt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"errorStatus\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-md-10 error_text\"],[9],[1,[21,\"errorStatus\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"success\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-md-10 success_text\"],[9],[1,[21,\"success\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/customer/account-update-status.hbs"
    }
  });
  _exports.default = _default;
});