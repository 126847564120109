define("abcloud-ui/components/policies-tenant/policy-components/invalidate-session-action-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tag: 'span',
    classNameBindings: ['showProfileSelection:negative-margin-10'],
    isChecked: false,
    showProfileSelection: false,
    mainValueCurrentSessionTokenProfile: '',
    mainValueCurrentSessionTerminationProfile: '',
    didUpdateAttrs() {
      if (this.severity_level_response.invalidate_session === 'false') {
        Ember.$('#invalidate-session-checkbox-' + this.index).prop('checked', false);
        this.set('isChecked', false);
      } else {
        this.setProperties({
          'mainValueCurrentSessionTokenProfile': this.severity_level_response.invalidate_session.session_token_profile.name,
          'mainValueCurrentSessionTerminationProfile': this.severity_level_response.invalidate_session.session_termination_profile.name
        });
      }
    },
    didInsertElement() {
      if (this.severity_level_response.invalidate_session != 'false') {
        this.setProperties({
          'mainValueCurrentSessionTokenProfile': this.severity_level_response.invalidate_session.session_token_profile.name,
          'mainValueCurrentSessionTerminationProfile': this.severity_level_response.invalidate_session.session_termination_profile.name,
          'isChecked': true
        });
      } else {
        this.setProperties({
          'mainValueCurrentSessionTokenProfile': this.apiSessionTokenProfilesList.length > 0 ? this.apiSessionTokenProfilesList[0].data.attributes.name : 'N/A',
          'mainValueCurrentSessionTerminationProfile': this.sessionTerminationProfilesList.length > 0 ? this.sessionTerminationProfilesList[0].data.attributes.name : 'N/A'
        });
      }
    },
    actions: {
      autoToggleProfileSelectionBlock(e) {
        this.chooseDefaultProfiles(e.target.checked, this.index);
        this.setProperties({
          'isChecked': e.target.checked,
          'showProfileSelection': e.target.checked
        });
      },
      manualToggleProfileSelectionBlock() {
        this.set('showProfileSelection', !this.showProfileSelection);
      },
      chooseSessionTokenProfile(id, profile_name) {
        this.set('mainValueCurrentSessionTokenProfile', profile_name);
        this.chooseProfileForInvalidation(id, profile_name, 'session_token_profile', this.index);
      },
      chooseSessionTerminationProfile(id, profile_name) {
        this.set('mainValueCurrentSessionTerminationProfile', profile_name);
        this.chooseProfileForInvalidation(id, profile_name, 'session_termination_profile', this.index);
      }
    }
  });
  _exports.default = _default;
});