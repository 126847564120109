define("abcloud-ui/controllers/deployments/stand-alone-controller/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        isCreateControllerLoading: false,
        error: ""
      });
    },
    actions: {
      onCreateEeconsole(requestObject) {
        /* 
          set is loading state
        */
        this.setProperties({
          isCreateControllerLoading: true,
          error: ""
        });
        var localConsole = this.store.createRecord("localcomponent", requestObject);
        localConsole.save().then(response => {
          this.setProperties({
            isCreateControllerLoading: false
          });
          if (!response.errors.length) {
            this.notifications.success(this.constants.CONTROLLER_CREATION_SUCCESS);
            this.transitionToRoute("deployments.stand-alone-controller.edit", response.get("id"));
          } else {
            localConsole.rollbackAttributes();
            this.setProperties({
              error: response.errors[0].detail
            });
            this.debug.log(response.errors[0].detail);
          }
        }).catch(error => {
          this.setProperties({
            isCreateControllerLoading: false,
            error: error.errors[0].detail
          });
          localConsole.rollbackAttributes();
          this.debug.log(error.errors[0].detail);
        });
      },
      cancel(e) {
        this.transitionToRoute("deployments.stand-alone-controller");
      }
    }
  });
  _exports.default = _default;
});