define("abcloud-ui/components/dashboard/dart-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['dart_tab'],
    elementId: '',
    tabId: '',
    qp: Ember.inject.service('query-params'),
    buildHelpText() {
      let htmlString = '';
      htmlString += '<ul>';
      for (var i = 0; i < this.catDetails.help_text.length; i++) {
        htmlString += '<li>' + this.catDetails.help_text[i] + '</li>';
      }
      htmlString += '</ul>';
      return htmlString;
    },
    showCategoryHelp() {
      Ember.$('#category_explanations').html(this.buildHelpText());
      Ember.$('#category_explanations').toggleClass('h');
    },
    hideCategoryHelp() {
      Ember.$('#category_explanations').html('');
      Ember.$('#category_explanations').toggleClass('h');
    },
    mouseEnter() {
      this.showCategoryHelp();
    },
    mouseLeave() {
      this.hideCategoryHelp();
    },
    didInsertElement() {
      this.set('tabId', this.catDetails.tab_id);
    },
    willDestroyElement: function () {
      this.$().off('mouseenter mouseleave');
    },
    actions: {
      updateQueryParams() {
        this.qp.setParams({
          'from_time': this.from_time,
          'to_time': this.to_time,
          'timeSelectedByUser': this.timeSelectedByUser,
          'dart_category': this.catDetails.link_key
        });
      }
    }
  });
  _exports.default = _default;
});