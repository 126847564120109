define("abcloud-ui/templates/policies/profiles/api-parameter-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ljPUU1Sd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"policy_card_view\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-8\"],[9],[0,\"\\n        API Parameter Pin Profile\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-4\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"policies-tenant/profile-components/edit-parameter-pin\",null,[[\"profile\",\"saveProfile\",\"setFileUpload\",\"transitionToListView\",\"confirmPublish\",\"profile_id\",\"file_id\",\"file_validated\",\"isFileUploaded\",\"error\",\"options\",\"pin_static_values\",\"session_first_seen_values\",\"pinned_first_seen_values\",\"AUTOFILL_OPTIONS\",\"disabled\",\"errorMessage\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"saveProfile\"],null],[27,\"action\",[[22,0,[]],\"setFileUpload\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[23,[\"profile_id\"]],[23,[\"file_id\"]],[23,[\"file_validated\"]],[23,[\"isFileUploaded\"]],[23,[\"file_error\"]],[23,[\"options\"]],[23,[\"pin_static_values\"]],[23,[\"session_first_seen_values\"]],[23,[\"pinned_first_seen_values\"]],[23,[\"constants\",\"AUTOFILL_OPTIONS\"]],\"\",[23,[\"errorMessage\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/api-parameter-pin.hbs"
    }
  });
  _exports.default = _default;
});