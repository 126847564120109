define("abcloud-ui/components/transactions/transaction-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    didInsertElement() {},
    click() {
      // let self = this;
      // Ember.$('#'+ self.id+ '').addClass('active').siblings().removeClass('active');
      // -- vv same as above implementation, but more `Emberish`, as we use arrow function for scope instead of setting this to self
      (() => {
        Ember.$('#' + this.id + '').addClass('active').siblings().removeClass('active');
      })();
      this.transition(this.model.id, this.id);
    }
  });
  _exports.default = _default;
});