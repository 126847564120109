define("abcloud-ui/templates/components/back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cXQqx+yY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"queryParam\"]]],null,{\"statements\":[[4,\"link-to\",[[23,[\"routerLink\"]],[23,[\"queryParam\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-start align-items-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"back-icon\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"link-primary\"],[9],[0,\"Back\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[23,[\"routerLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-start align-items-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"back-icon\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"link-primary\"],[9],[0,\"Back\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/back-button.hbs"
    }
  });
  _exports.default = _default;
});