define("abcloud-ui/pods/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    redirect: function () {
      if (window.location.pathname === '/') {
        this.transitionTo('customer.login');
      }
    }
  });
  _exports.default = _default;
});