define("abcloud-ui/templates/components/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hFtzSPSl",
    "block": "{\"symbols\":[\"option\",\"key\"],\"statements\":[[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn-dropdown btn-block dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[12,\"id\",[21,\"customId\"]],[12,\"disabled\",[21,\"disabledStatus\"]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[21,\"mainValue\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"dropdown-menu w-100\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"options\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"class\",[28,[\"dropdown-item \",[21,\"wrapText\"]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"takeAction\"]],[22,1,[\"id\"]],[27,\"val-for-key\",[[22,1,[]],[23,[\"searchKey\"]]],null],[22,2,[]]],null]],[9],[0,\"\\n        \"],[1,[27,\"val-for-key\",[[22,1,[]],[23,[\"searchKey\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/dropdown-menu.hbs"
    }
  });
  _exports.default = _default;
});