define("abcloud-ui/controllers/system/hosted/localbay/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    localbayDetails: null,
    componentSize: null,
    componentDetails: null,
    componentData: null,
    showSubComponent: false,
    actions: {
      copyLinkToClipboard() {
        var copyText = document.getElementById("config_link");
        copyText.select();
        document.execCommand("copy");
      }
    }
  });
  _exports.default = _default;
});