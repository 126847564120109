define("abcloud-ui/controllers/system/hosted/microsensor/f5-hsl-consumer/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    init() {
      this._super(...arguments);
      const defaultInstanceDisplay = "Kubernetes";
      const {
        type,
        orch
      } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[defaultInstanceDisplay];
      this.setProperties({
        localcomponents: "",
        current_localbay: "",
        current_localbay_ip: "",
        progress_percent: "",
        savedSuccessfully: false,
        validTitle: null,
        config_link: "",
        current_instance_display: defaultInstanceDisplay,
        current_instance_type: type,
        current_instance_orch: orch,
        dpletError: ""
      });
    },
    actions: {
      createDplet() {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;

        // -- if user has entered ip, take it. Otherwise, is the localbay instance_orch k8? if so, localbay_ip = "ablocalbay" else localbay_ip = localbay_loadbalancer_ip
        var localbay_ip = "";
        if (this.current_localbay.attributes.instance_type === "hosted") {
          localbay_ip = this.current_localbay_ip;
        } else {
          if (document.getElementById("host_ip").value) {
            localbay_ip = document.getElementById("host_ip").value;
          } else if (this.current_localbay.attributes.instance_orch == "kubernetes") {
            localbay_ip = this.constants.KUBERNETES_LOCALBAY_IP;
          } else {
            localbay_ip = this.current_localbay_ip;
          }
        }

        // --  clear the input validation errors before proceeding further
        var elements = {
          nameFilledValue: name,
          localbayIpFilledValue: localbay_ip
        };
        this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, "clear all");
        if (name && description && localbay_ip && this.validTitle) {
          this.set("progress_percent", 50);
          var f5hslDplet = this.store.createRecord("dplet", {
            sub_type: "f5hsl",
            name: name,
            install_mode: this.current_localbay.attributes.install_mode,
            uuid: this.current_localbay.attributes.uuid,
            hashed_passphrase: this.current_localbay.attributes.hashed_passphrase,
            localbay_ip: localbay_ip,
            localcomponent_instance_type: this.current_localbay.attributes.instance_type,
            instance_type: this.current_instance_type,
            dplet_type: "f5hsl",
            localcomponent_title: this.current_localbay.attributes.title,
            instance_orch: this.current_instance_orch,
            description: description,
            localcomponent_id: this.current_localbay.id
          });
          this.replace_element_with.preloader(".save");
          f5hslDplet.save().then(response => {
            if (!response.errors.length) {
              this.setProperties({
                progress_percent: 100,
                savedSuccessfully: true,
                config_link: response.data.config_link
              });
            } else {
              f5hslDplet.rollbackAttributes();
              this.debug.log("dplet server error");
              this.set("dpletError", response.errors[0].detail);
            }
          }).catch(error => {
            f5hslDplet.rollbackAttributes();
            this.debug.log("dplet server error");
            if (error.errors[0].detail === this.constants.UNPROCESSABLE_ENTITY) {
              this.set("dpletError", this.constants.DPLET_TITLE_TAKEN);
            } else {
              this.set("dpletError", error.errors[0].detail);
            }
          });
        } else {
          elements = {
            nameFilledValue: name,
            localbayIpFilledValue: localbay_ip
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      formCancelTransition() {
        this.transitionToRoute("system.hosted.microsensor.index");
      }
    }
  });
  _exports.default = _default;
});