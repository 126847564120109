define("abcloud-ui/helpers/to-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toJson = toJson;
  function toJson(jsonObj) {
    var jsonStr = JSON.stringify(jsonObj[0]).slice(1, JSON.stringify(jsonObj[0]).length - 2).split('","');
    var output = '\n{\n';
    for (var n = 0; n < jsonStr.length; n++) {
      var comma = n < jsonStr.length - 1 ? ',\n' : '\n';
      output += ['  ', jsonStr[n].replace(/\"/g, '').replace(/:/g, ': '), comma].join(''); // eslint-disable-line no-useless-escape 
    }

    output += '}';
    return output;
  }
  var _default = Ember.Helper.helper(toJson);
  _exports.default = _default;
});