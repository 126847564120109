define("abcloud-ui/templates/deployments/stand-alone-controller/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rRkBy4HH",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/localcomponent-form\",null,[[\"cancel\",\"createLocalcomponent\",\"localcomponentType\",\"isLoading\",\"error\"],[[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"onCreateEeconsole\"],null],\"eeconsole\",[23,[\"isCreateControllerLoading\"]],[23,[\"error\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/deployments/stand-alone-controller/new.hbs"
    }
  });
  _exports.default = _default;
});