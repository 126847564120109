define("abcloud-ui/controllers/system/user-authentication/sso", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    server: Ember.inject.service("ab-ajax-requests/server"),
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    utils: Ember.inject.service("utils"),
    init() {
      this._super(...arguments);
      this.setProperties({
        isSsoSaveLoading: false,
        editSso: false,
        ssoEnabled: false,
        isIssuerUrlValid: false,
        provider_name: "pn_okta",
        provider_type: "pt_oidc",
        client_id: "",
        issuer_url: "",
        defaultRedirectUrl: window.location.origin + "/sso/oidc/auth",
        redirect_url: window.location.origin + "/sso/oidc/auth",
        unauthorized_error: this.constants.DEFAULT_SSO_ERROR,
        features: [],
        originalSsoConfig: {},
        validations: {
          isFormValid: false,
          client_id: {
            isValid: true,
            required: true
          },
          issuer_url: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.ISSUER_URL_REGEX,
              invalidFeedback: this.constants.INVALID_ISSUER_URL
            },
            length: {
              min: this.constants.MIN_ISSUER_URL_CHARS,
              invalidFeedback: this.constants.EMPTY_ISSUER_URL
            }
          },
          redirect_url: {
            isValid: true,
            required: true
          },
          unauthorized_error: {
            isValid: true,
            length: {
              max: this.constants.MAX_DESCRIPTION_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: this.constants.SSO_ERROR_NOTIFICATION_TEXT,
                maxLen: this.constants.MAX_DESCRIPTION_CHARS
              })
            }
          }
        }
      });
    },
    actions: {
      toggleSsoEdit() {
        const isAllowUserToEditForm = !this.editSso;
        if (isAllowUserToEditForm) {
          this.set("editSso", true);
        } else {
          this.send("cancel");
        }
      },
      toggleSso() {
        const isEnableSso = !this.ssoEnabled;
        let validations = (0, _lodash.cloneDeep)(this.validations);
        if (isEnableSso) {
          validations = {
            ...validations,
            client_id: {
              isValid: !!this.client_id,
              required: true
            },
            issuer_url: {
              isValid: !!this.issuer_url,
              required: true,
              format: {
                with: this.constants.ISSUER_URL_REGEX,
                invalidFeedback: this.constants.INVALID_ISSUER_URL
              },
              length: {
                min: this.constants.MIN_ISSUER_URL_CHARS,
                invalidFeedback: this.constants.EMPTY_ISSUER_URL
              }
            },
            redirect_url: {
              isValid: !!this.redirect_url,
              required: true
            },
            unauthorized_error: {
              isValid: true,
              length: {
                max: this.constants.MAX_DESCRIPTION_CHARS,
                invalidFeedback: this.constants.INPUT_TOO_LONG({
                  fControlLabel: this.constants.SSO_ERROR_NOTIFICATION_TEXT,
                  maxLen: this.constants.MAX_DESCRIPTION_CHARS
                })
              }
            }
          };
          this.setProperties({
            client_id: this.client_id,
            issuer_url: this.issuer_url,
            redirect_url: this.redirect_url,
            unauthorized_error: this.unauthorized_error
          });
        } else {
          delete validations["client_id"];
          delete validations["issuer_url"];
          delete validations["redirect_url"];
          delete validations["unauthorized_error"];
        }
        const newValidations = this.utils.validateForm(validations);
        this.setProperties({
          ssoEnabled: isEnableSso,
          validations: newValidations
        });
      },
      cancel() {
        this.set("editSso", false);

        /* 
          Reset sso status with original value from features response data
        */

        const ssoStatus = this.features.find(feature => feature.config_key === "sso_enabled");
        const ssoEnabled = ssoStatus ? !!(ssoStatus.config_value === "true") : false;
        this.setProperties({
          ssoEnabled
        });

        /* 
          Find and set the original status and values for the form if present
        */
        if (Object.keys(this.originalSsoConfig) && Object.keys(this.originalSsoConfig).length > 0) {
          const {
            client_id,
            issuer_url,
            redirect_url,
            unauthorized_error
          } = this.originalSsoConfig.json;
          this.setProperties({
            client_id,
            issuer_url,
            redirect_url,
            unauthorized_error
          });
        } else {
          this.setProperties({
            client_id: "",
            issuer_url: "",
            redirect_url: this.defaultRedirectUrl,
            unauthorized_error: this.constants.DEFAULT_SSO_ERROR
          });
        }
      },
      onSubmit() {
        /* 
          set is loading state
        */
        this.set("isSsoSaveLoading", true);
        let request_object = {
          data: {
            attributes: {
              provider_name: this.provider_name,
              provider_type: this.provider_type,
              json: {
                client_id: this.client_id,
                issuer_url: this.issuer_url,
                redirect_url: this.redirect_url,
                unauthorized_error: this.unauthorized_error
              }
            },
            type: "sso_providers"
          }
        };
        let url = this.urls.URL_GET_SSO_CONFIG();
        this.server.request("POST", url, request_object, response => {
          this.setProperties({
            editSso: false,
            originalSsoConfig: response.data.attributes.json,
            isSsoSaveLoading: false
          });
          this.notifications.success(this.constants.SSO_UPDATED_SUCCESFULLY);
        }, error => {
          this.set("isSsoSaveLoading", false);
          this.notifications.error(this.constants.SOMETHING_WENT_WRONG);
        });
        url = this.urls.SYSTEM_FEATURES();
        request_object = {
          data: [{
            config_key: "sso_enabled",
            config_value: this.ssoEnabled.toString()
          }]
        };
        this.server.request("POST", url, request_object, response => {}, error => {
          this.notifications.error(this.constants.SOMETHING_WENT_WRONG, {
            autoClear: true,
            clearDuration: 2000
          });
        });
      }
    }
  });
  _exports.default = _default;
});