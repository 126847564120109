define("abcloud-ui/services/urls", ["exports", "abcloud-ui/config/environment", "crypto-js/sha256", "ember-uuid", "moment"], function (_exports, _environment, _sha, _emberUuid, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BASE_URL = _environment.default.apiHost + "/" + _environment.default.version;
  var _default = Ember.Service.extend({
    auth: Ember.inject.service("auth"),
    generate_trace_id(is_admin) {
      var trace_id = "";
      if (is_admin) {
        trace_id = (0, _sha.default)((0, _emberUuid.v4)() + (0, _moment.default)().format("x"));
        return trace_id;
      } else {
        trace_id = this.auth.getTenantId() + "-" + (0, _sha.default)((0, _emberUuid.v4)() + (0, _moment.default)().format("x"));
        return trace_id;
      }
    },
    build_url(args, is_admin) {
      let url = [BASE_URL, "tenants", this.auth.getTenantId()].join("/");
      args.forEach(path => {
        if (path) {
          url = [url, path].join("/");
        }
      });
      if (is_admin) {
        url = BASE_URL;
        args.forEach(path => {
          if (path) {
            url = [url, path].join("/");
          }
        });
      }
      return url;
    },
    //AUTH Services
    URL_ADMIN_SIGN_IN() {
      return _environment.default.apiHost + "/auth/admin/sign_in";
    },
    URL_ADMIN_USER_SIGN_IN() {
      return _environment.default.apiHost + "/auth/admin/users/sign_in";
    },
    URL_TENANT_SIGN_IN() {
      return _environment.default.apiHost + "/auth/tenant/sign_in";
    },
    GET_LOGIN_TYPE() {
      return _environment.default.apiHost + "/auth/tenant_users/login_type";
    },
    SSO_VERIFIER() {
      return _environment.default.apiHost + "/auth/tenant_users/sso/oidc/auth";
    },
    URL_LOG_OUT() {
      return _environment.default.apiHost + "/auth/logout";
    },
    //Admin routes
    URL_CREATE_TENANT_SUPPORT_USER(customer_id, tenant_id) {
      return this.build_url(["admin/customers", customer_id, "tenants", tenant_id, "support_user"], "admin");
    },
    URL_UPDATE_SUPPORT_USER_PASSWORD(customer_id, tenant_id) {
      return this.build_url(["admin/customers", customer_id, "tenants", tenant_id, "support_user/generate_password"], "admin");
    },
    //Account update services
    URL_ACCOUNT(id) {
      if (id) {
        return this.build_url(["tenant_users", id]);
      } else {
        return this.build_url(["tenant_users/for/current_user"]);
      }
    },
    URL_ACCOUNT_RESET_PASSWORD(tenantUserId) {
      return this.build_url(["tenant_users", tenantUserId, "password/reset"]);
    },
    URL_RESET_ADMIN_USER_PASSWORD(adminUserId) {
      return this.build_url(["admin/admin_users", adminUserId], true);
    },
    //LocalBay Services
    URL_POST_LOCALBAY() {
      return this.build_url(["system/config/local_bay"]);
    },
    URL_GET_LOCALBAYS() {
      return this.build_url(["system/config/local_bays"]);
    },
    URL_GET_LOCALBAYS_WITH_HEALTH() {
      return this.build_url(["system/config/local_bays/health"]);
    },
    URL_GET_LOCALBAY(id) {
      return this.build_url(["system/config/local_bay", id]);
    },
    URL_GET_LOCALBAYS_RAILS() {
      return this.build_url(["localbays"]);
    },
    URL_GET_LOCALBAY_RAILS(id) {
      return this.build_url(["localbays", id]);
    },
    URL_GET_LOCALBAY_DISPLAY_DETAILS(id) {
      return this.build_url(["localbays", id, "display_details"]);
    },
    URL_POLL_LOCALBAY(id) {
      return this.build_url(["localcomponents", id, "current_status"]);
    },
    //Exceptions and Alerts Services
    URL_GET_ALERTS(id) {
      return this.build_url(["alerts", id]);
    },
    URL_GET_DART(category) {
      return this.build_url(["dart", category]);
    },
    URL_GET_MAP() {
      return this.build_url(["maps/image"]);
    },
    //Dashboard Services
    // -- Totals
    URL_GET_SUMMARY_TOTALS() {
      return this.build_url(["summary/totals"]);
    },
    URL_GET_SUMMARY_ALERT_DATA_TOTAL() {
      return this.build_url(["summary/alerts/total"]);
    },
    URL_GET_SUMMARY_DART_DATA_TOTAL() {
      return this.build_url(["summary/dart/total"]);
    },
    URL_GET_SUMMARY_EVENT_DATA_TOTAL() {
      return this.build_url(["summary/events/total"]);
    },
    URL_GET_SUMMARY_API_DATA_TOTAL() {
      return this.build_url(["summary/apis/total"]);
    },
    URL_GET_SUMMARY_TRANSACTION_DATA_TOTAL() {
      return this.build_url(["summary/transactions/total"]);
    },
    URL_GET_SUMMARY_ALERT_DATA_TOTAL_WITH_DETECTION(detection_type) {
      return this.build_url(["summary/alerts/total/detect", detection_type]);
    },
    //Dashboard Discovery
    URL_VERIFY_INSIGHTS_CONFIGURATION() {
      return this.build_url(["summary/pg/discovery/configured"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS() {
      return this.build_url(["summary/pg/discovery/new_apis"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_BY_TIME() {
      return this.build_url(["summary/pg/discovery/new_apis/by/time"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_BY_VOLUME() {
      return this.build_url(["summary/pg/discovery/new_apis/by/volume"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_PARAMS() {
      return this.build_url(["summary/pg/discovery/new_apis/with/params"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_PARAMS_TIMESERIES() {
      return this.build_url(["summary/pg/discovery/new_apis/with/params/by/time"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_PARAMS_BY_VOLUME_TIMESERIES() {
      return this.build_url(["summary/pg/discovery/new_apis/with/params/by/volume"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_METHODS() {
      return this.build_url(["summary/pg/discovery/new_apis/with/methods"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_METHODS_TIMESERIES() {
      return this.build_url(["summary/pg/discovery/new_apis/with/methods/by/time"]);
    },
    URL_GET_SUMMARY_DISCOVERY_NEW_APIS_WITH_METHODS_BY_VOLUME_TIMESERIES() {
      return this.build_url(["summary/pg/discovery/new_apis/with/methods/by/volume"]);
    },
    URL_GET_THREAT_MAP_LOCATIONS() {
      return this.build_url(["summary/pg/discovery/map_locations"]);
    },
    // -- Transactions
    URL_GET_APP_APIS() {
      return this.build_url(["summary/app_apis"]);
    },
    URL_GET_USERS() {
      return this.build_url(["summary/users"]);
    },
    URL_GET_TRANSACTIONS() {
      return this.build_url(["summary/transactions"]);
    },
    URL_GET_TRANSACTIONS_BY_TYPE() {
      return this.build_url(["summary/transactions/by/type"]);
    },
    // -- Events
    URL_GET_SUMMARY_EVENTS() {
      return this.build_url(["summary/events"]);
    },
    URL_GET_SUMMARY_EVENTS_BY_CLIENT_IP() {
      return this.build_url(["summary/events/by/client"]);
    },
    URL_GET_SUMMARY_EVENTS_BY_DEST_IP() {
      return this.build_url(["summary/events/by/service"]);
    },
    URL_GET_SUMMARY_EVENTS_BY_DEST_PORT() {
      return this.build_url(["summary/events/by/port"]);
    },
    URL_GET_SUMMARY_EVENTS_BY_API() {
      return this.build_url(["summary/events/by/api"]);
    },
    URL_GET_SUMMARY_API_EVENTS_BY_CLIENT_IP() {
      return this.build_url(["summary/events/by/api+client"]);
    },
    URL_GET_SUMMARY_API_EVENTS_BY_DEST_IP() {
      return this.build_url(["summary/events/by/api+service"]);
    },
    URL_GET_SUMMARY_API_EVENTS_BY_DEST_PORT() {
      return this.build_url(["summary/events/by/api+port"]);
    },
    URL_ADVANCED_SEARCH() {
      return this.build_url(["events"]);
    },
    // -- downloads
    URL_DOWNLOAD_RECORD_AS_FILE(klass, record_id, file_format) {
      if (file_format == undefined) {
        file_format = "csv";
      }
      let klassSegment = klass.toLowerCase() + "s";
      return this.build_url([klassSegment, record_id, "download", file_format]);
    },
    // -- Alerts
    URL_GET_SUMMARY_ALERTS() {
      return this.build_url(["summary/alerts"]);
    },
    URL_GET_SUMMARY_ALERTS_BY_POLICY_NAME() {
      return this.build_url(["summary/alerts/policies"]);
    },
    URL_GET_SUMMARY_ALERTS_BY_NAME() {
      return this.build_url(["summary/alerts/by/name"]);
    },
    URL_GET_SUMMARY_ALERTS_BY_USER() {
      return this.build_url(["summary/alerts/users"]);
    },
    URL_GET_SUMMARY_ALERTS_BY_ACTION() {
      return this.build_url(["summary/alerts/actions"]);
    },
    URL_GET_SUMMARY_ALERTS_WITH_DETECTION(detection_type) {
      return this.build_url(["summary/alerts/detect", detection_type]);
    },
    URL_GET_SUMMARY_ALERTS_BY_POLICY_NAME_WITH_DETECTION(detection_type) {
      return this.build_url(["summary/alerts/policies/detect", detection_type]);
    },
    URL_GET_SUMMARY_ALERTS_BY_NAME_WITH_DETECTION(detection_type) {
      return this.build_url(["summary/alerts/by/name/detect", detection_type]);
    },
    URL_GET_SUMMARY_ALERTS_BY_USER_WITH_DETECTION(detection_type) {
      return this.build_url(["summary/alerts/users/detect", detection_type]);
    },
    URL_GET_SUMMARY_ALERTS_BY_ACTION_WITH_DETECTION(detection_type) {
      return this.build_url(["summary/alerts/actions/detect", detection_type]);
    },
    // -- DART
    URL_GET_SUMMARY_DART_DATA() {
      return this.build_url(["summary/dart"]);
    },
    URL_GET_SUMMARY_DART_CATEGORY_DATA() {
      return this.build_url(["summary/dart/categories"]);
    },
    URL_GET_SUMMARY_DART_DETECTION_DATA() {
      return this.build_url(["summary/dart/detections"]);
    },
    URL_GET_SUMMARY_DART_SEVERITY_DATA() {
      return this.build_url(["summary/dart/severity"]);
    },
    URL_GET_SUMMARY_DART_USER_DATA() {
      return this.build_url(["summary/dart/users"]);
    },
    URL_GET_SUMMARY_DART_POLICY_DATA() {
      return this.build_url(["summary/dart/policies"]);
    },
    // -- Discovery APIs
    URL_GET_SUMMARY_DISCOVERY_APIS() {
      return this.build_url(["summary/pg/discovery/apis"]);
    },
    URL_GET_SUMMARY_DISCOVERY_APIS_BY_TIME() {
      return this.build_url(["summary/pg/discovery/apis/by/time"]);
    },
    URL_GET_SUMMARY_DISCOVERY_APIS_WITH_METHODS() {
      return this.build_url(["summary/pg/discovery/apis/with/methods"]);
    },
    URL_GET_SUMMARY_DISCOVERY_APIS_WITH_METHODS_BY_TIME() {
      return this.build_url(["summary/pg/discovery/apis/with/methods/by/time"]);
    },
    URL_GET_SUMMARY_DISCOVERY_APIS_RESPONSES() {
      return this.build_url(["summary/pg/discovery/apis/responses"]);
    },
    URL_GET_SUMMARY_DISCOVERY_APIS_RESPONSES_BY_TIME() {
      return this.build_url(["summary/pg/discovery/apis/responses/by/time"]);
    },
    //Policies
    URL_POLICY_PROFILE(id) {
      return this.build_url(["profiles", id]);
    },
    URL_PROFILE_FILE_DELETE(id) {
      return this.build_url(["profiles/file", id, "delete"]);
    },
    URL_PROFILE_FILE_UPLOAD(id) {
      return this.build_url(["profiles/file/upload", id]);
    },
    URL_POLICY_CONFIGURATION() {
      return this.build_url(["configuration_policies"]);
    },
    URL_POLICY(id) {
      return this.build_url(["policies", id]);
    },
    URL_GET_PROFILES_FOR_POLICIES(policy_sub_type, policy_id) {
      return this.build_url(["policies", policy_id, "new", policy_sub_type]);
    },
    URL_PROFILES_FOR_MICROSENSOR(sub_type, microsensor_id) {
      return this.build_url(["profiles/by/sub_type", sub_type, "for/microsensor", microsensor_id]);
    },
    URL_GET_JWT_AUTH_TOKEN() {
      return this.build_url(["policies/get_auth_token"]);
    },
    URL_PROFILES_FOR_POLICY_MICROSENSOR(policy_id, microsensor_id) {
      return this.build_url(["policies", policy_id, "microsensor", microsensor_id, "profiles"]);
    },
    URL_GET_API_RECORDER() {
      return this.build_url(["policies/of/api_recorder"]);
    },
    URL_CAN_RECORD(localcomponentId, dplet_id) {
      return this.build_url(["policies/can/record", localcomponentId, dplet_id]);
    },
    URL_STOP_RECORDING(id) {
      return this.build_url(["policies", id, "stop"]);
    },
    URL_RE_RECORD(id) {
      return this.build_url(["policies", id, "clone"]);
    },
    URL_RESUME_RECORD(id) {
      return this.build_url(["policies", id, "resume"]);
    },
    URL_COMPLETED_API_RECORDER(id) {
      return this.build_url(["policies/of/api_recorder", id, "assoc"]);
    },
    URL_COMPLETED_API_RECORDER_LABELS(id) {
      return this.build_url(["policies/of/api_recorder", id, "assoc", "get_labels"]);
    },
    URL_DOWNLOAD_OPEN_API_SPEC(id) {
      return this.build_url(["policies", id, "download/open_api"]);
    },
    URL_GET_DATA_FOR_PROFILES_BY_SUB_TYPE(sub_type) {
      return this.build_url(["profiles/new", sub_type]);
    },
    URL_MINIMUM_API_RECORDINGS() {
      return this.build_url(["api_recordings?minimum=true"]);
    },
    URL_POLL_API_RECORDER(id) {
      return this.build_url(["policies", id, "recorded/data"]);
    },
    URL_GET_POLICY_STATE(id) {
      return this.build_url(["policies", id, "state"]);
    },
    URL_CAN_RESUME(id) {
      return this.build_url(["policies", id, "can_resume"]);
    },
    //DPLet Services
    URL_GET_DPLETS() {
      return this.build_url(["dplets"]);
    },
    URL_GET_DPLET(dplet_id) {
      return this.build_url(["dplets", dplet_id]);
    },
    URL_GET_DPLET_DISPLAY_DETAILS(id) {
      return this.build_url(["dplets", id, "display_details"]);
    },
    //Docs
    URL_GET_DOCS_DIRECTORY_LISTS() {
      return this.build_url(["directory_lists"]);
    },
    URL_GET_DOC_FILE_CONTENT() {
      return this.build_url(["files_content"]);
    },
    // SSO
    URL_GET_SSO_CONFIG() {
      return this.build_url(["system_config/sso"]);
    },
    SYSTEM_FEATURES() {
      return this.build_url(["system_config/features"]);
    },
    RESET_ADMIN_USER_PASSWORD(id) {
      return this.build_url(["admin/admin_users", id, "password/reset"], true);
    }
  });
  _exports.default = _default;
});