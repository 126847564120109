define("abcloud-ui/templates/components/ab-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gYJKxh/C",
    "block": "{\"symbols\":[\"display_value\",\"actual_value\"],\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"dropdown ab-dropdown\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"tooltip-wrapper\"],[12,\"data-title\",[21,\"tooltip\"]],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-dropdown dropdown-toggle btn-block\"],[12,\"disabled\",[21,\"isDisabled\"]],[11,\"data-toggle\",\"dropdown\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"false\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"custom_icon\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[12,\"class\",[21,\"custom_icon\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[21,\"current_option\"],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dropdown-menu\"],[11,\"aria-labelledby\",\"dropdownMenuButton\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"options\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"class\",\"dropdown-item\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"filter\",[22,2,[]]],null]],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/ab-filter.hbs"
    }
  });
  _exports.default = _default;
});