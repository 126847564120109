define("abcloud-ui/templates/policies/api-recorder/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4lSJoLMV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"policy_card_view\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-8\"],[9],[0,\"\\n          API Recorder\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-4\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"policies-tenant/policy-components/edit-api-recorder\",null,[[\"savePolicy\",\"cancel\",\"chooseLocalbay\",\"chooseMicroSensor\",\"localbayList\",\"currentLocalbay\",\"currentLocalbayId\",\"currentMicrosensor\",\"currentMicrosensorId\",\"microsensorList\",\"can_publish\",\"recordOnlyApis\",\"disabledStatus\",\"RECORD_EXISTS\"],[[27,\"action\",[[22,0,[]],\"savePolicy\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"chooseLocalbay\"],null],[27,\"action\",[[22,0,[]],\"chooseMicroSensor\"],null],[23,[\"localbayList\"]],[23,[\"currentLocalbay\"]],[23,[\"currentLocalbayId\"]],[23,[\"currentMicrosensor\"]],[23,[\"currentMicrosensorId\"]],[23,[\"microsensorList\"]],[23,[\"can_publish\"]],[23,[\"recordOnlyApis\"]],\"\",[23,[\"constants\",\"RECORD_EXISTS\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/api-recorder/new.hbs"
    }
  });
  _exports.default = _default;
});