define("abcloud-ui/components/policies-tenant/policy-components/edit-api-session-token-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    severityValuesInSet: new Set(["High", "Medium"]),
    didInsertElement() {
      try {
        if (this.changed_labels) {
          this.changed_labels.forEach(labelId => {
            if (this.disabledStatus) {
              Ember.$("#" + labelId + this.disabledStatus + "").addClass("published_label_changed");
            } else {
              Ember.$("#" + labelId + "").addClass("draft_label_changed");
            }
          });
        }
      } catch (newPolicy) {
        this.debug.log("This is a new API Session Token Policy", newPolicy);
      }
    },
    actions: {
      savePolicy(action) {
        this.savePolicy(action);
      },
      cancel() {
        this.cancel();
      },
      chooseLocalbay(localcomponentId, localcomponent) {
        this.localbay_list.forEach(localbayObj => {
          if (localcomponentId === localbayObj.id) {
            this.setProperties({
              microsensor_list: localbayObj.dplets.data,
              current_localbay_id: localcomponentId,
              current_localbay: localcomponent,
              current_microsensor_id: localbayObj.dplets.data[0].id,
              current_microsensor: localbayObj.dplets.data[0].name
            });
            Ember.$.ajaxSetup({
              headers: {
                Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
                "X-AB-Trace-ID": this.urls.generate_trace_id()
              }
            });
            var url = this.urls.URL_PROFILES_FOR_MICROSENSOR("api_session_token", localbayObj.dplets.data[0].id);
            if (this.view === "edit") {
              url = this.urls.URL_PROFILES_FOR_POLICY_MICROSENSOR(this.policy_data.policy_id, localbayObj.dplets.data[0].id);
            }
            this.ajax.request(url, {
              method: "GET"
            }).then(response => {
              var pastSelectedProfileIds = new Set();
              response.data.attributes.profiles.linked_to_policy.data.forEach(apiSessionTokenProfile => {
                pastSelectedProfileIds.add(apiSessionTokenProfile.data.id);
              });
              this.setProperties({
                availableProfilesList: response.data.attributes.profiles.available.data,
                linkedProfilesList: response.data.attributes.profiles.linked.data,
                profiles_linked_to_this_microsensor: response.data.attributes.profiles.linked_to_policy.data,
                selected_profile_ids: new Set(),
                past_selected_profile_ids: pastSelectedProfileIds
              });
              // -- uncheck the profile selections when dplets selection changed
              this.availableProfilesList.forEach((profile, index) => {
                if (document.getElementById("apiSessionProfile" + index)) {
                  document.getElementById("apiSessionProfile" + index).checked = false;
                }
              });
            });
          }
        });
      },
      chooseMicroSensor(id, chosenOption) {
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          }
        });
        var url = this.urls.URL_PROFILES_FOR_MICROSENSOR("api_session_token", id);
        if (this.view === "edit") {
          url = this.urls.URL_PROFILES_FOR_POLICY_MICROSENSOR(this.policy_data.policy_id, id);
        }
        this.ajax.request(url, {
          method: "GET"
        }).then(response => {
          var pastSelectedProfileIds = new Set();
          response.data.attributes.profiles.linked_to_policy.data.forEach(apiSessionTokenProfile => {
            pastSelectedProfileIds.add(apiSessionTokenProfile.data.id);
          });
          this.setProperties({
            availableProfilesList: response.data.attributes.profiles.available.data,
            linkedProfilesList: response.data.attributes.profiles.linked.data,
            profiles_linked_to_this_microsensor: response.data.attributes.profiles.linked_to_policy.data,
            selected_profile_ids: new Set(),
            past_selected_profile_ids: pastSelectedProfileIds
          });
          // -- uncheck the profile selections when dplets selection changed
          this.availableProfilesList.forEach((profile, index) => {
            if (document.getElementById("apiSessionProfile" + index)) {
              document.getElementById("apiSessionProfile" + index).checked = false;
            }
          });
        });
        this.setProperties({
          current_microsensor_id: id,
          current_microsensor: chosenOption
        });
      },
      chooseSessionTokenProfile(id, profile_name) {
        this.chooseSessionTokenProfile(id, profile_name);
      },
      chooseSessionTerminationProfile(id, profile_name) {
        this.chooseSessionTerminationProfile(id, profile_name);
      }
    }
  });
  _exports.default = _default;
});