define("abcloud-ui/routes/admin/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    beforeModel() {
      if (this.auth.getRole() === "admin") {
        if (this.auth.isLoggedIn("admin")) {
          this.transitionTo("/admin/customers");
        }
      } else if (this.auth.getRole() === "adminUser") {
        if (this.auth.isLoggedIn("adminUser")) {
          this.transitionTo("/manage/users");
        }
      }
    }
  });
  _exports.default = _default;
});