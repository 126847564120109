define("abcloud-ui/routes/exceptions/security", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    qp: Ember.inject.service('query-params'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    queryParams: {
      page: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      }
    },
    model(params) {
      let type = params.type;
      let qp = this.qp;
      var url = this.urls.URL_GET_ALERTS();
      this.qp.setParam('type', type);
      params = qp.getParams();
      this.set('timeSelectedByUserOnRoute', params.timeSelectedByUser);
      if (params.type === "dart") {
        url = this.urls.URL_GET_DART(params.dart_category);
      }
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var request = Ember.$.getJSON(url, params);
      this.manage_requests.add_request(this.routeName, request);
      return Ember.RSVP.hash({
        alerts: request
      });
    },
    setupController(controller, model) {
      const constants = this.constants;
      var modelData = model && model.alerts ? model.alerts.data : [];
      var metaProps = {
        'total': 0
      };
      var pageDetails = model.alerts && model.alerts.meta ? model.alerts.meta : metaProps;
      controller.setProperties({
        'alerts': modelData,
        'pageDetails': pageDetails,
        'tableHeaders': constants.ALERT_TABLE_HEADERS,
        'alert_severity': this.qp.getParam('alert_severity'),
        'dart_category': this.qp.getParam('dart_category'),
        'timeSelectedByUser': this.qp.getParam('timeSelectedByUser')
      });
      // -- auto-select first item per page
      if (model.alerts && model.alerts.data.length > 0) {
        controller.set('firstAlert', model.alerts.data[0].id);
      } else {
        controller.set('firstAlert', '');
        this.transitionTo('exceptions.security');
      }
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    },
    renderTemplate() {
      this.render("exceptions.security", {
        into: "exceptions",
        outlet: "alerts"
      });
    },
    resetController(controller, isExiting, transition) {
      controller.set('isLoading', false);
      var type = transition.queryParams.hasOwnProperty("type") ? transition.queryParams.type : "";
      if (isExiting || type != controller.type) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          'from_time': parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
          'to_time': parseInt(_moment.default.utc().valueOf() / 1000),
          'page': 1,
          'alert_severity': '',
          'alertDetails': null,
          'pageDetails': null,
          'alerts': null,
          'alertGeoLocationMap': null,
          'timeSelectedByUser': "3600",
          'pageChoice': 1,
          'previousPageChoice': 5
        });
      }
    }
  });
  _exports.default = _default;
});