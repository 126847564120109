define("abcloud-ui/controllers/admin/customers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        error: "",
        isAddCustomerLoading: false,
        name: "",
        point_of_contact: "",
        point_of_contact_email: "",
        website: "",
        language: "",
        city: "",
        state: "",
        country: "",
        validations: {
          isFormValid: false,
          name: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          point_of_contact: {
            isValid: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Point of contact",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          point_of_contact_email: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          },
          website: {
            isValid: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Website",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          language: {
            isValid: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Language",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          city: {
            isValid: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "City",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          state: {
            isValid: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "State",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          country: {
            isValid: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Country",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          }
        }
      });
    },
    actions: {
      submit: function () {
        /* 
          set is loading state
        */
        this.set("isAddCustomerLoading", true);
        var params = {
          name: this.name,
          point_of_contact: this.point_of_contact,
          point_of_contact_email: this.point_of_contact_email,
          website: this.website,
          language: this.language,
          location: {
            city: this.get("city"),
            state: this.get("state"),
            country: this.get("country")
          }
        };
        var customer = this.store.createRecord("customer", params);
        customer.save().then(() => {
          this.notifications.success(this.constants.CUSTOMER_ADDED_SUCCESSFULLY);
          this.setProperties({
            error: "",
            isAddCustomerLoading: false
          });
          this.transitionToRoute("admin.customers");
        }).catch(() => {
          this.setProperties({
            error: this.constants.SOMETHING_WENT_WRONG,
            isAddCustomerLoading: false
          });
          customer.rollbackAttributes();
        });
      },
      navigateBackToCustomerList() {
        this.transitionToRoute("admin.customers");
      }
    }
  });
  _exports.default = _default;
});