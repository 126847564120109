define("abcloud-ui/components/system-localbay/dplet-create-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    instanceType: false,
    localbay: false,
    localbayIp: false,
    instanceMode: false,
    networkPassiveSnifferInstallInstructions: false,
    restApiDetails: false,
    kafkaTopics: false,
    kafkaServers: false,
    init() {
      this._super(...arguments);
      let instanceTypes = JSON.parse(JSON.stringify(this.constants.DPLET_DROPDOWN_INSTANCE_OPTIONS));
      // -- supporting only Docker Compose: container as of 20.2.4.0 release
      if (this.dplet_type === 'kafka') {
        instanceTypes.forEach(instanceType => {
          if (instanceType.type === 'Generic Container') {
            instanceType['disable'] = false;
          } else {
            instanceType['disable'] = true;
          }
        });
      }
      // -- supporting only Linux Binary Debian & Linux Binary RPM as of 20.3.1.0 release
      else if (this.dplet_type === 'nginx') {
        instanceTypes = instanceTypes.filter(instanceType => instanceType.type === 'Linux Binary Debian' || instanceType.type === 'Linux Binary RPM');
      } else {
        instanceTypes = instanceTypes.filter(instanceType => instanceType.type != "Generic Container");
        instanceTypes.forEach(instanceType => {
          instanceType['disable'] = false;
        });
      }

      // -- instance_type = Linux Binary Generic applies only for Network passive sniffer
      if (this.dplet_type != 'pcap') {
        instanceTypes = instanceTypes.filter(instanceType => instanceType.type != 'Linux Binary Generic');
      }
      this.constants.MICROSENSOR_FORM_INPUTS[this.dplet_type].inputs.forEach(inputType => {
        this.set('' + [inputType] + '', true);
      });
      this.setProperties({
        daemonHelpStyle: 'focused',
        sidecarHelpStyle: '',
        instanceTypes: instanceTypes
      });
    },
    actions: {
      toggle_other_options() {
        this.toggle_other_options();
      },
      generateRestApiAuthToken() {
        this.generateRestApiAuthToken();
      },
      chooseLocalBay(localcomponentId) {
        this.localcomponents.forEach(localcomponent => {
          if (localcomponent.id === localcomponentId) {
            this.setProperties({
              current_localbay: localcomponent,
              current_localbay_ip: localcomponent.attributes.load_balancer_ip
            });
          }
        });
      },
      chooseInstance(id, instanceDisplay) {
        const {
          type,
          orch
        } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[instanceDisplay];
        this.setProperties({
          current_instance_display: instanceDisplay,
          current_instance_type: type,
          current_instance_orch: orch
        });

        // Instance Mode is required only in case of kubernetes.
        this.set("instance_mode", orch === "kubernetes" ? "daemonset" : "");

        // Leave Instance Mode unchanged if instance_type is not docker_generic.
        this.set('instance_mode', type === 'docker_generic' ? 'docker_generic' : this.instance_mode);
      },
      collectLocalbayIp() {
        this.set("current_localbay_ip", document.getElementById("host_ip").value);
      },
      copyLinkToClipboard() {
        var copyText = document.getElementById("config_link");
        copyText.select();
        document.execCommand("copy");
      },
      createDplet() {
        this.createDplet();
      },
      setInstanceMode(e) {
        this.set("instance_mode", e.target.value);
        if (this.instance_mode === "daemonset") {
          this.set("daemonHelpStyle", "focused");
          this.set("sidecarHelpStyle", "");
        } else {
          this.set("daemonHelpStyle", "");
          this.set("sidecarHelpStyle", "focused");
        }
      },
      cancel() {
        this.cancel();
      }
    },
    willDestroyElement() {
      this.setProperties({
        current_localbay: this.localcomponents[0],
        current_localbay_ip: this.localcomponents[0].attributes.load_balancer_ip,
        progress_percent: 0,
        savedSuccessfully: false,
        config_link: ""
      });
      document.getElementById("name").value = "";
      document.getElementById("description").value = "";
    }
  });
  _exports.default = _default;
});