define("abcloud-ui/helpers/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (_ref) {
    let [type, paramName, labels] = _ref;
    if (!labels) {
      return [];
    }
    return labels[type][paramName] ? labels[type][paramName] : [];
  });
  _exports.default = _default;
});