define("abcloud-ui/controllers/insights/dashboard/security/api-bruteforce", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // queryParams: ['from_time', 'to_time', 'page', 'timeSelectedByUser'],

    secTabActive: true,
    observeTabActive: false,
    showPreloader: true,
    showAlertsRequestPreloader: true,
    showAlertsByNameRequestPreloader: true,
    showAlertsByPolicyNameRequestPreloader: true,
    showAlertsByUserRequestPreloader: true,
    showAlertsByActionRequestPreloader: true,
    showSummaryTransactionsRequestPreloader: true,
    showApisRequestPreloader: true,
    showSummaryTransactionsByTypeRequestPreloader: true,
    showSummaryEventsByApiPreloader: true,
    showSummaryEventsByClientIpPreloader: true,
    showSummaryEventsByDestIpPreloader: true,
    showSummaryEventsByDestPortPreloader: true,
    showSummaryRawEventsPreloader: true,
    showSummaryApiEventsByClientIpPreloader: true,
    showSummaryApiEventsByDestPortPreloader: true,
    showSummaryApiEventsByDestIpPreloader: true,
    showDartRequestPreloader: true,
    showDartDetectionsRequestPreloader: true,
    showAlertTotalPreloader: true,
    showDartTotalPreloader: true,
    showEventTotalPreloader: true,
    showTransactionTotalPreloader: true,
    showApiTotalPreloader: true,
    showDartCategoriesRequestPreloader: true,
    showDartUsersRequestPreloader: true,
    showDartPoliciesRequestPreloader: true

    // from_time: parseInt(moment.utc().valueOf() / 1000) - 86400,   // -- DEFAULT = Yesterday's timestamp in epoch
    // to_time: parseInt(moment.utc().valueOf() / 1000),   // -- DEFAULT = Today's timestamp in epoch
    // timeSelectedByUser: "86400",   // -- DEFAULT = 1 day in seconds

    // actions: {
    //   selectTimeRange(from_time, to_time) {
    //     this.setProperties({
    //       'showPreloader': true,
    //       'timeSelectedByUser': from_time
    //     });
    //     if (to_time) {
    //       to_time = to_time;
    //     }
    //     else {
    //       to_time = moment.utc().valueOf() / 1000;
    //     }
    //     if (from_time) {
    //       from_time = (moment.utc().valueOf() / 1000 - from_time);// (Date.parse(new Date(now.getTime() + now.getTimezoneOffset() * 60000)) - from_time)
    //     }
    //     else {
    //       from_time = 1;
    //     }
    //     this.set('from_time', parseInt(from_time));
    //     this.set('to_time', parseInt(to_time));
    //   }
    // }
  });
  _exports.default = _default;
});