define("abcloud-ui/services/inject-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    notify_unfilled_mandatory_field(elements, notification_message) {
      var errored_element_ids = [];
      for (var element_id in elements) {
        Ember.$('#' + element_id + '').removeClass('incomplete_form');
        Ember.$('#' + element_id + '').removeClass('validated_form');
        if (!elements[element_id]) {
          errored_element_ids.push(element_id);
        }
      }
      errored_element_ids.forEach(element_id => {
        Ember.$('#' + element_id + '').addClass('incomplete_form');
        document.getElementById(element_id).title = notification_message;
        document.getElementById(element_id).alt = notification_message;
      });
    },
    notify_ok(elements, notification_message) {
      var correct_element_ids = [];
      for (var element_id in elements) {
        Ember.$('#' + element_id + '').removeClass('incomplete_form');
        if (elements[element_id]) {
          correct_element_ids.push(element_id);
        }
      }
      correct_element_ids.forEach(element_id => {
        Ember.$('#' + element_id + '').addClass('validated_form');
        document.getElementById(element_id).title = notification_message;
        document.getElementById(element_id).alt = notification_message;
      });
    }
  });
  _exports.default = _default;
});