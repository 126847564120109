define("abcloud-ui/controllers/exceptions/transactions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    qp: Ember.inject.service('query-params'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    mc: 0,
    queryParams: ['page'],
    from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
    to_time: parseInt(_moment.default.utc().valueOf() / 1000),
    page: 1,
    timeSelectedByUser: "3600",
    dataTableId: 'transactions_table',
    current_selection_from_list: '',
    isPageRefresh: true,
    previousPageChoice: 5,
    actions: {
      pageChange(_page) {
        this.set('page', _page);
        this.qp.setParam('page', _page);
        this.send('refreshCollection');
      },
      selectTimeRange(from_time, to_time) {
        this.set('timeSelectedByUser', from_time);
        this.qp.setParam('timeSelectedByUser', from_time);

        // -- resetting the page to be first page upon filtering; also used as a flag to update pageArray in the route after the response from backend --
        // this.set(this.queryParams[2], 1);
        this.qp.setParam('page', 1);
        if (to_time) {
          to_time = to_time;
        } else {
          to_time = _moment.default.utc().valueOf() / 1000;
        }
        if (from_time) {
          from_time = _moment.default.utc().valueOf() / 1000 - from_time; // (Date.parse(new Date(now.getTime() + now.getTimezoneOffset() * 60000)) - from_time)
        } else {
          from_time = 1;
        }
        this.set('from_time', parseInt(from_time));
        this.set('to_time', parseInt(to_time));
        this.qp.setParam('from_time', parseInt(from_time));
        this.qp.setParam('to_time', parseInt(to_time));
        this.send('refreshModel');
      },
      goToTransactionDetail(id, element_id) {
        Ember.$('#' + this.dataTableId + '').unbind('click').on('click', 'tbody tr', function () {
          Ember.$(this).addClass('active').siblings().removeClass('active');
        });
        this.set('current_selection_from_list', element_id);
        this.transitionToRoute('exceptions.transactions.transaction', id);
      },
      refreshCollection() {
        let params = {};
        params['from_time'] = parseInt(_moment.default.utc().valueOf() / 1000) - this.timeSelectedByUser;
        params['to_time'] = parseInt(_moment.default.utc().valueOf() / 1000);
        params['timeSelectedByUser'] = this.timeSelectedByUser;
        params['page'] = this.page;
        this.setProperties({
          'isLoading': true,
          'from_time': params['from_time'],
          'to_time': params['to_time'],
          'timeSelectedByUser': params['timeSelectedByUser'],
          'page': params['page']
        });
        this.qp.setParams(params);
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});