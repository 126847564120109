define("abcloud-ui/controllers/exceptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    qp: Ember.inject.service('query-params'),
    actions: {
      resetPage() {
        this.qp.setParam('page', 1);
      }
    }
  });
  _exports.default = _default;
});