define("abcloud-ui/components/ab-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    didInsertElement() {
      Ember.$('.tooltip-wrapper').tooltip({
        position: "bottom"
      });
    },
    actions: {
      filter(time_offset) {
        this.filter(time_offset);
      }
    }
  });
  _exports.default = _default;
});