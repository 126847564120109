define("abcloud-ui/controllers/policies/profiles/session-termination/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    buildProfile: Ember.inject.service("build-object-for-server/profile"),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    errorMessage: "",
    actions: {
      saveProfile(action) {
        function getValFromId(id) {
          return document.getElementById(id).value;
        }
        const name = getValFromId("name");
        const description = getValFromId("description");
        const apiUrl = getValFromId("apiUrl");
        const reqParam = getValFromId("reqParam");
        const authToken = getValFromId("authToken");
        const apiHost = getValFromId("apiHost");
        const json = {
          api_url: apiUrl,
          request_param: reqParam,
          auth_token: authToken,
          api_host: apiHost
        };
        const changeset = {
          "nameLabel.Name": name,
          "descriptionLabel.Description": description,
          "apiUrlLabel.Session_Information.API_URL": apiUrl,
          "reqParamLabel.Session_Information.Request_Body_Param_Name": reqParam,
          "authTokenLabel.Session_Information.Authorization_Token": authToken,
          "apiHostLabel.Session_Information.API_Host": apiHost
        };
        this.buildProfile.setSubType("session_termination");
        this.buildProfile.setCommonTopLevelKeys(name, description, action);
        this.buildProfile.setChangeset(changeset);
        this.buildProfile.setJson(json);
        const requestObj = this.buildProfile.getRequestObject();
        if (name && apiUrl && reqParam && authToken && apiHost) {
          const url = this.urls.URL_POLICY_PROFILE();
          this.publish.profile(action, "POST", url, requestObj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
                this.set("errorMessage", error.payload.meta.errors[0].detail);
              } else {
                this.debug.log("error", error);
              }
            }
          });
        } else {
          const elements = {
            nameFilledValue: name,
            apiUrlFilledValue: apiUrl,
            reqParamFilledValue: reqParam,
            authTokenFilledValue: authToken,
            apiHostFilledValue: apiHost
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      confirmPublish() {
        this.send("saveProfile", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      }
    }
  });
  _exports.default = _default;
});