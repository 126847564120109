define("abcloud-ui/controllers/policies/api-recorder/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    getValueOfElementById(element_id) {
      return document.getElementById(element_id).value;
    },
    actions: {
      transitionToListView() {
        this.transitionToRoute("policies.api-recorder");
      },
      chooseLocalbay(localcomponentId, localcomponent) {
        this.localbayList.forEach(localbayObj => {
          if (localcomponentId === localbayObj.id) {
            this.setProperties({
              microsensorList: localbayObj.dplets.data,
              currentLocalbayId: localcomponentId,
              currentLocalbay: localcomponent,
              currentMicrosensorId: localbayObj.dplets.data[0].id,
              currentMicrosensor: localbayObj.dplets.data[0].name
            });
            var url = this.services.urls.URL_CAN_RECORD(localcomponentId, localbayObj.dplets.data[0].id);
            this.services.ajax.request(url, {
              method: "GET"
            }).then(response => {
              this.set("can_publish", response.can_record);
            });
          }
        });
      },
      chooseMicroSensor(microsensorId, microsensor) {
        this.setProperties({
          currentMicrosensor: microsensor,
          currentMicrosensorId: microsensorId
        });
        var url = this.services.urls.URL_CAN_RECORD(this.currentLocalbayId, microsensorId);
        this.services.ajax.request(url, {
          method: "GET"
        }).then(response => {
          this.set("can_publish", response.can_record);
        });
      },
      savePolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var profiles_ids = [];
        var localbay_details = {
          id: this.currentLocalbayId,
          title: this.currentLocalbay
        };
        var dplet_details = {
          id: this.currentMicrosensorId,
          name: this.currentMicrosensor
        };
        var changeset = {
          "nameLabel.Profile_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.currentLocalbay,
          "dpletLabel.MicroSensor_Information.MicroSensor": this.currentMicrosensor,
          "apiNetworkTrafficLabel.MicroSensor_Information.Src_IP_CIDR": this.getValueOfElementById("src_ip_cidr"),
          "apiNetworkTrafficLabel.MicroSensor_Information.Src_IP_Port": this.getValueOfElementById("src_ip_port"),
          "apiNetworkTrafficLabel.MicroSensor_Information.Dest_IP_CIDR": this.getValueOfElementById("dest_ip_cidr"),
          "apiNetworkTrafficLabel.MicroSensor_Information.Dest_IP_Port": this.getValueOfElementById("dest_ip_port"),
          "autoStopLabel.Schedule_Information.Stop_Automatically": document.getElementById("auto_stop").checked ? this.getValueOfElementById("autoTime") : "",
          "manualStopLabel.Schedule_Information.Stop_Manually": document.getElementById("manual_stop").checked ? "true" : "false"
        };
        var json_object = {
          policy_filters: {
            dplet_id: this.currentMicrosensorId,
            data_type: {
              api: this.get("recordOnlyApis")
            },
            traffic: {
              src_port: this.getValueOfElementById("src_ip_port"),
              src_ip: this.getValueOfElementById("src_ip_cidr"),
              dst_ip: this.getValueOfElementById("dest_ip_cidr"),
              dst_port: this.getValueOfElementById("dest_ip_port")
            }
          },
          schedule_information: {}
        };
        let valid_schedule_info = false;
        if (document.getElementById("auto_stop").checked) {
          json_object["schedule_information"]["auto_stop"] = [this.getValueOfElementById("autoTime"), "hours"].join(" ");
          valid_schedule_info = 0 < this.getValueOfElementById("autoTime") && this.getValueOfElementById("autoTime") < 73 ? true : false;
        } else if (document.getElementById("manual_stop").checked) {
          json_object["schedule_information"]["manual_stop"] = true;
          valid_schedule_info = true;
        }
        this.services.build_policy_object_for_server.set_type("policy", "api_recorder");
        this.services.build_policy_object_for_server.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.services.build_policy_object_for_server.set_changeset(changeset);
        this.services.build_policy_object_for_server.set_json(json_object);
        var request_object = this.services.build_policy_object_for_server.get_request_object();
        if (name && description && valid_schedule_info) {
          var url = this.services.urls.URL_POLICY();
          this.services.publish.policy(action, "POST", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.api-recorder");
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            scheduleInfoFilledValue: valid_schedule_info
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MINIMUM_REQUIREMENTS);
        }
      }
    }
  });
  _exports.default = _default;
});