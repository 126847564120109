define("abcloud-ui/templates/components/transactions/transaction-geo-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jeF6o0R1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"padded_10\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\" col_row\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"data_label col-xs-12\"],[9],[0,\"Client Geo-location\"],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"data_value col-xs-12\"],[9],[0,\"\\n        \"],[1,[21,\"city\"],false],[0,\", \"],[1,[21,\"state\"],false],[0,\", \"],[1,[21,\"country\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"geo_map\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/transactions/transaction-geo-location.hbs"
    }
  });
  _exports.default = _default;
});