define("abcloud-ui/templates/components/title-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Umsym4er",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"class\",[28,[\"title-validator form-control\\n      \",[27,\"if\",[[27,\"eq\",[[23,[\"validTitle\"]],null],null],\"\",[27,\"if\",[[23,[\"validTitle\"]],\"is-valid\",\"is-invalid\"],null]],null]]]],[12,\"id\",[21,\"inputId\"]],[12,\"value\",[21,\"inputValue\"]],[12,\"placeholder\",[21,\"inputPlaceholder\"]],[12,\"maxlength\",[21,\"maxLength\"]],[12,\"onkeyup\",[27,\"action\",[[22,0,[]],\"checkIfTitleValid\"],null]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"invalid-feedback\"],[9],[0,\"\\n    Title should contain only underscore, lowercase or uppercase characters.\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/title-validator.hbs"
    }
  });
  _exports.default = _default;
});