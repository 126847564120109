define("abcloud-ui/components/policies-tenant/policies-list-view-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      transitionToEditView(profile_id, policy_type) {
        this.transitionToEditView(profile_id, policy_type);
      },
      deleteProfile(profile_id) {
        this.deleteProfile(profile_id);
      },
      record(object, action) {
        this.record(object, action);
      },
      openResumeRecordModal(object) {
        this.openResumeRecordModal(object);
      }
    }
  });
  _exports.default = _default;
});