define("abcloud-ui/controllers/policies/policies/out-of-spec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    current_localbay: "",
    current_localbay_id: "",
    localbay_list: "",
    current_microsensor: "",
    current_microsensor_id: "",
    microsensor_list: "",
    out_of_spec_profiles: {},
    selected_profile_ids: new Set(),
    severity_level_responses: [],
    profileOptionAreaClass: "",
    api_path_severity: "High",
    api_parameter_severity: "High",
    api_parameter_data_type_severity: "High",
    errorMessage: "",
    getValueOfElementById(element_id) {
      return document.getElementById(element_id).value;
    },
    actions: {
      transitionToListView() {
        this.transitionToRoute("policies.policies");
      },
      saveOutOfSpecPolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var detected_actions_severity_object = {};
        var coma_seperated_chosen_profile_names = "";
        var out_of_spec_profiles_array = [];
        var buildPolicyObjectForServer = this.services.build_policy_object_for_server;
        var buildProfileObjectForServer = this.services.build_profile_object_for_server;
        this.out_of_spec_profiles.forEach(out_of_spec_profile => {
          if (this.selected_profile_ids.has(out_of_spec_profile.data.id)) {
            var out_of_spec_profile_object = {
              data: {
                id: out_of_spec_profile.data.id,
                type: "profile",
                attributes: {
                  name: out_of_spec_profile.data.attributes.profile_data[0].name,
                  description: out_of_spec_profile.data.attributes.profile_data[0].description,
                  sub_type: out_of_spec_profile.data.attributes.sub_type,
                  json: {
                    method: out_of_spec_profile.data.attributes.profile_data[0].json.method,
                    spec_info: {
                      file: {}
                    }
                  }
                }
              }
            };
            if (out_of_spec_profile.data.attributes.profile_data[0].json.method === "file_upload") {
              out_of_spec_profile_object.data.attributes.json.spec_info.file = {
                id: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_id,
                name: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_name,
                size: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_size,
                type: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_type
              };
            } else if (out_of_spec_profile.data.attributes.profile_data[0].json.method === "open_api") {
              out_of_spec_profile_object.data.attributes.json.spec_info.file = {
                id: out_of_spec_profile.data.attributes.profile_data[0].json.spec_info.api_recording.id,
                name: out_of_spec_profile.data.attributes.profile_data[0].json.spec_info.api_recording.name
              };
            }
            coma_seperated_chosen_profile_names += out_of_spec_profile.data.attributes.name + ", ";
            out_of_spec_profiles_array.push(out_of_spec_profile_object);
          }
        });
        // -- remove the trailing whitespace and coma respectively
        coma_seperated_chosen_profile_names = coma_seperated_chosen_profile_names.slice(0, coma_seperated_chosen_profile_names.length - 2);
        if (document.getElementById("apiPath").checked) {
          detected_actions_severity_object["detect_for_non_matching_api_paths"] = this.api_path_severity.toLowerCase();
        }
        if (document.getElementById("apiParameter").checked) {
          detected_actions_severity_object["detect_for_non_matching_api_params"] = this.api_parameter_severity.toLowerCase();
        }
        if (document.getElementById("apiParameterDataType").checked) {
          detected_actions_severity_object["detect_for_non_matching_api_param_data_type"] = this.api_parameter_data_type_severity.toLowerCase();
        }
        var getSeverityInfoForDetectionType = detectionType => {
          if (detected_actions_severity_object[detectionType]) {
            return detected_actions_severity_object[detectionType];
          } else {
            return "--Unchecked--";
          }
        };
        const {
          sessionTokenProfileIds,
          sessionTerminationProfileIds
        } = buildPolicyObjectForServer.getActionProfileIds(this.severity_level_responses);
        const actions_api_session_token_profiles = buildProfileObjectForServer.whichIncludesSpecificIds(sessionTokenProfileIds, this.apiSessionTokenProfilesList, action);
        const actions_session_termination_profiles = buildProfileObjectForServer.whichIncludesSpecificIds(sessionTerminationProfileIds, this.sessionTerminationProfilesList, action);
        var profiles_ids = [...this.selected_profile_ids, ...sessionTerminationProfileIds, ...sessionTokenProfileIds];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: this.current_microsensor_id,
          name: this.current_microsensor
        };
        var changeset = {
          "nameLabel.Profile_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.current_localbay,
          "dpletLabel.Enforcement_Information.MicroSensor": this.current_microsensor,
          "httpHeaderLabel.Enforcement_Information.HTTP_Header": this.getValueOfElementById("http_header"),
          "enforcementApiParameterLabel.Enforcement_Information.API_Parameter": this.getValueOfElementById("api_parameter"),
          "apiNetworkTrafficLabel.Enforcement_Information.Src_IP_CIDR": this.getValueOfElementById("src_ip_cidr"),
          "apiNetworkTrafficLabel.Enforcement_Information.Dest_IP_CIDR": this.getValueOfElementById("dest_ip_cidr"),
          "apiNetworkTrafficLabel.Enforcement_Information.Dest_IP_Port": this.getValueOfElementById("dest_ip_port"),
          "profileLabel.Profile_Information.Profiles": coma_seperated_chosen_profile_names,
          "apiPathLabel.Detection_Information.Detect_for_API_paths_that_are_not_part_of_existing_API_Specification": getSeverityInfoForDetectionType("detect_for_non_matching_api_paths"),
          "apiParameterLabel.Detection_Information.Detect_for_API_parameters_that_are_not_part_of_existing_API_Specification": getSeverityInfoForDetectionType("detect_for_non_matching_api_params"),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Critical": buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.High": buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Medium": buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Low": buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Audit": buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Critical": buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.High": buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Medium": buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Low": buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Audit": buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "invalidate_session", this.severity_level_responses)
        };
        var json_object = {
          enforcement_information: {
            dplet_id: this.current_microsensor_id,
            http_header: this.getValueOfElementById("http_header"),
            api_parameter: this.getValueOfElementById("api_parameter"),
            traffic: {
              src_ip: this.getValueOfElementById("src_ip_cidr"),
              dst_ip: this.getValueOfElementById("dest_ip_cidr"),
              dst_port: this.getValueOfElementById("dest_ip_port")
            }
          },
          detected_actions_severity: detected_actions_severity_object,
          severity_level_responses: this.severity_level_responses,
          out_of_spec_api_profiles: out_of_spec_profiles_array,
          actions_api_session_token_profiles,
          actions_session_termination_profiles
        };
        buildPolicyObjectForServer.set_type("policy", "out_of_spec_api");
        buildPolicyObjectForServer.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        buildPolicyObjectForServer.set_changeset(changeset);
        buildPolicyObjectForServer.set_json(json_object);
        var request_object = buildPolicyObjectForServer.get_request_object();
        if (name && description && [...this.selected_profile_ids].length > 0) {
          var url = this.services.urls.URL_POLICY();
          this.services.publish.policy(action, "POST", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            profileFilledValue: [...this.selected_profile_ids].length > 0
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, "Please choose atleast one profile");
        }
      }
    }
  });
  _exports.default = _default;
});