define("abcloud-ui/routes/policies/profiles/data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    ajax: Ember.inject.service('ajax'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      var request = Ember.$.getJSON(urls.URL_GET_DATA_FOR_PROFILES_BY_SUB_TYPE('data_export'));
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      controller.setProperties({
        'localconsoles_list': model.data,
        'localconsoles_present': model.data.length,
        'current_localconsole': model.data.length ? model.data[0].attributes.title : this.constants.NO_LOCALCONSOLES_CREATED,
        'current_localconsole_id': model.data.length ? model.data[0].id : 'N/A',
        'current_localconsole_hashed_passphrase': model.data.length ? model.data[0].attributes.hashed_passphrase : '',
        'current_localconsole_instance_orch': model.data.length ? model.data[0].attributes.instance_orch : ''
      });
      if (model.data.length > 0 && model.data[0].attributes.instance_orch === 'kubernetes') {
        controller.set('current_localconsole_host_ip', this.constants.KUBERNETES_LOCALCONSOLE_IP);
      }
    },
    resetController(controller, isExiting) {
      this.manage_requests.abort_request(this.routeName);
    }
  });
  _exports.default = _default;
});