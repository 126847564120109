define("abcloud-ui/helpers/epoch-time-difference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.epochTimeDiffernce = epochTimeDiffernce;
  function epochTimeDiffernce(_ref) {
    let [future_epoch_time, past_epoch_time, exception] = _ref;
    if (!past_epoch_time || !future_epoch_time) {
      return '';
    }
    var duration = parseFloat((future_epoch_time - past_epoch_time).toFixed(6)); // -- In Seconds
    if (duration <= 0) {
      if (exception) {
        return parseInt(duration);
      }
      return '--';
    }
    if (duration < 0.01) {
      if (duration < 0.00001) {
        // -- check if result has a decimal value. If it does, limit the decimal value to 3 places, else return the whole number.
        duration = duration * 1000000 % 1 == 0 ? duration * 1000000 : parseFloat((duration * 1000000).toFixed(3));
        return duration + ' Microseconds'; // -- In Microseconds
      }

      // -- check if result has a decimal value. If it does, limit the decimal value to 3 places, else return the whole number.
      duration = duration * 1000 % 1 == 0 ? duration * 1000 : parseFloat((duration * 1000).toFixed(3));
      return duration + ' Milliseconds'; // -- In Milliseconds
    }

    return parseFloat((future_epoch_time - past_epoch_time).toFixed(3)) + ' Seconds';
  }
  var _default = Ember.Helper.helper(epochTimeDiffernce);
  _exports.default = _default;
});