define("abcloud-ui/templates/components/dashboard/category-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QYjLIQZo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"active\"]],true],null]],null,{\"statements\":[[4,\"link-to\",[[23,[\"linkTargetPath\"]]],[[\"id\",\"class\",\"alt\",\"title\"],[[23,[\"elmId\"]],\"active\",[23,[\"linkText\"]],[23,[\"linkText\"]]]],{\"statements\":[[0,\"    \"],[1,[21,\"linkText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[23,[\"linkTargetPath\"]]],[[\"id\",\"alt\",\"title\"],[[23,[\"elmId\"]],[23,[\"linkText\"]],[23,[\"linkText\"]]]],{\"statements\":[[0,\"    \"],[1,[21,\"linkText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/dashboard/category-tab.hbs"
    }
  });
  _exports.default = _default;
});