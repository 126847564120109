define("abcloud-ui/controllers/policies/policies/api-session-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    current_localbay: "",
    current_localbay_id: "",
    localbay_list: "",
    current_microsensor: "",
    current_microsensor_id: "",
    microsensor_list: "",
    selected_profile_ids: new Set(),
    chosenSeverityLevels: new Set(),
    severity_level_responses: {},
    session_token_violated: "High",
    session_token_not_present: "High",
    errorMessage: "",
    getValueOfElementById(element_id) {
      return document.getElementById(element_id).value;
    },
    actions: {
      transitionToListView() {
        this.transitionToRoute("policies.policies");
      },
      savePolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var detected_actions_severity_object = {};
        var coma_seperated_chosen_profile_names = "";
        var api_session_token_profiles_array = [];
        var isValidDuration = true;
        var buildPolicyObjectForServer = this.services.build_policy_object_for_server;
        var buildProfileObjectForServer = this.services.build_profile_object_for_server;
        this.availableProfilesList.forEach(api_session_token_profile => {
          [...this.selected_profile_ids].forEach((profile_id, index) => {
            if (Number(profile_id) === Number(api_session_token_profile.data.id)) {
              if (document.getElementById("duration" + index).value) {
                var session_validity = document.getElementById("duration" + index).value + " " + document.getElementById("period" + index).innerText;
                var api_parameter_pin_profile_object = {
                  data: {
                    id: api_session_token_profile.data.id,
                    type: "profile",
                    attributes: {
                      name: api_session_token_profile.data.attributes.profile_data[0].name,
                      description: api_session_token_profile.data.attributes.profile_data[0].description,
                      sub_type: api_session_token_profile.data.attributes.sub_type,
                      json: {
                        session_validity: session_validity,
                        location_of_token: api_session_token_profile.data.attributes.profile_data[0].json.location_of_token
                      }
                    }
                  }
                };
                if (api_session_token_profile.data.attributes.profile_data[0].json.method === "single_api") {
                  api_parameter_pin_profile_object.data.attributes.json["api_list_information"] = api_session_token_profile.data.attributes.profile_data[0].json.api_list_information;
                }
                coma_seperated_chosen_profile_names += api_session_token_profile.data.attributes.name + ": " + session_validity + ", ";
                api_session_token_profiles_array.push(api_parameter_pin_profile_object);
              } else {
                isValidDuration = false;
              }
            }
          });
        });
        // -- remove the trailing whitespace and coma respectively
        coma_seperated_chosen_profile_names = coma_seperated_chosen_profile_names.slice(0, coma_seperated_chosen_profile_names.length - 2);
        if (document.getElementById("sessionTokenViolatedInput").checked) {
          detected_actions_severity_object["session_token_validity_violated_in_api_call"] = this.session_token_violated.toLowerCase();
        }
        if (document.getElementById("sessionTokenNotPresentInput").checked) {
          detected_actions_severity_object["session_token_not_present_in_api_call"] = this.session_token_not_present.toLowerCase();
        }
        var getSeverityInfoForDetectionType = detectionType => {
          if (detected_actions_severity_object[detectionType]) {
            return detected_actions_severity_object[detectionType];
          } else {
            return "--Unchecked--";
          }
        };
        const {
          sessionTokenProfileIds,
          sessionTerminationProfileIds
        } = buildPolicyObjectForServer.getActionProfileIds(this.severity_level_responses);
        const actions_api_session_token_profiles = buildProfileObjectForServer.whichIncludesSpecificIds(sessionTokenProfileIds, this.apiSessionTokenProfilesList, action);
        const actions_session_termination_profiles = buildProfileObjectForServer.whichIncludesSpecificIds(sessionTerminationProfileIds, this.sessionTerminationProfilesList, action);
        var profiles_ids = [...this.selected_profile_ids, ...sessionTokenProfileIds, ...sessionTerminationProfileIds];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: this.current_microsensor_id,
          name: this.current_microsensor
        };
        var changeset = {
          "nameLabel.Policy_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.current_localbay,
          "dpletLabel.Enforcement_Information.MicroSensor": this.current_microsensor,
          "profileLabel.Profile_Information.Profiles": coma_seperated_chosen_profile_names,
          "sessionTokenViolatedLabel.Detection_&_Severity_Information.Session_Token_Validity_violated_in_API_call": getSeverityInfoForDetectionType("session_token_validity_violated_in_api_call"),
          "sessionTokenNotPresentLabel.Detection_&_Severity_Information.Session_Token_not_present_in_API_call": getSeverityInfoForDetectionType("session_token_not_present_in_api_call"),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Critical": buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.High": buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Medium": buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Low": buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Audit": buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Critical": buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.High": buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Medium": buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Low": buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Audit": buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "invalidate_session", this.severity_level_responses)
        };
        var json_object = {
          enforcement_information: {
            dplet_id: this.current_microsensor_id
          },
          detected_actions_severity: detected_actions_severity_object,
          severity_level_responses: this.severity_level_responses,
          api_session_token_profiles: api_session_token_profiles_array,
          actions_api_session_token_profiles,
          actions_session_termination_profiles
        };
        buildPolicyObjectForServer.set_type("policy", "api_session_token");
        buildPolicyObjectForServer.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        buildPolicyObjectForServer.set_changeset(changeset);
        buildPolicyObjectForServer.set_json(json_object);
        var request_object = buildPolicyObjectForServer.get_request_object();
        if (name && description && [...this.selected_profile_ids].length > 0 && isValidDuration) {
          var url = this.services.urls.URL_POLICY();
          this.services.publish.policy(action, "POST", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            profileFilledValue: [...this.selected_profile_ids].length > 0 && isValidDuration
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.AT_LEAST_ONE_OPTION);
        }
      }
    }
  });
  _exports.default = _default;
});