define("abcloud-ui/services/build-object-for-server/policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var request_object = {
    data: {
      type: "",
      attributes: {
        localcomponent: {
          id: ""
        },
        profiles_ids: [],
        sub_type: "",
        action: "",
        name: "",
        description: "",
        dplet_id: "",
        dplet_name: "",
        changeset: {},
        json: {
          data: {
            type: "",
            attributes: {
              name: "",
              description: "",
              action: "",
              sub_type: "",
              dplet_id: "",
              dplet_name: "",
              profiles_ids: [],
              localcomponent_ids: [],
              localcomponent_title: "",
              // Note(sanath): localbay_ids and localbay_title are deprecated
              localbay_ids: [],
              localbay_title: ""
            }
          }
        }
      }
    }
  };
  var _default = Ember.Service.extend({
    getActionProfileIds(severity_level_responses) {
      var sessionTokenProfileIds = new Set();
      var sessionTerminationProfileIds = new Set();
      severity_level_responses.forEach(actionInfoChoices => {
        if (actionInfoChoices.invalidate_session != "false") {
          sessionTokenProfileIds.add(actionInfoChoices.invalidate_session.session_token_profile.id);
          sessionTerminationProfileIds.add(actionInfoChoices.invalidate_session.session_termination_profile.id);
        }
      });
      return {
        sessionTokenProfileIds,
        sessionTerminationProfileIds
      };
    },
    getActionsInfoForAlertEvent(severity, actionInfo, severity_level_responses) {
      let alertActionInfo = "--Unchecked--";
      severity_level_responses.forEach(severity_response => {
        if (severity_response.severity === severity) {
          if (actionInfo === "invalidate_session" && severity_response[actionInfo] != "false") {
            alertActionInfo = [severity_response[actionInfo]["session_termination_profile"]["name"], "&", severity_response[actionInfo]["session_token_profile"]["name"]].join(" ");
          } else {
            alertActionInfo = severity_response[actionInfo];
          }
        }
      });
      return alertActionInfo;
    },
    set_type(type, sub_type) {
      request_object.data.type = type;
      request_object.data.attributes.json.data.type = type;
      request_object.data.attributes.sub_type = sub_type;
      request_object.data.attributes.json.data.attributes.sub_type = sub_type;
    },
    set_changeset(changeset) {
      request_object.data.attributes.changeset = changeset;
    },
    set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details) {
      request_object.data.attributes.localcomponent.id = localbay_details.id;
      request_object.data.attributes.json.data.attributes.localcomponent_ids[0] = localbay_details.id;
      request_object.data.attributes.json.data.attributes.localcomponent_title = localbay_details.title;

      //Note(sanath): Deprecated
      request_object.data.attributes.json.data.attributes.localbay_ids[0] = localbay_details.id;
      request_object.data.attributes.json.data.attributes.localbay_title = localbay_details.title;
      request_object.data.attributes.profiles_ids = profiles_ids;
      request_object.data.attributes.json.data.attributes.profiles_ids = profiles_ids;
      request_object.data.attributes.action = action;
      request_object.data.attributes.json.data.attributes.action = action;
      request_object.data.attributes.name = name;
      request_object.data.attributes.json.data.attributes.name = name;
      request_object.data.attributes.description = description;
      request_object.data.attributes.json.data.attributes.description = description;
      if (dplet_details.id) {
        request_object.data.attributes.dplet_id = dplet_details.id;
        request_object.data.attributes.json.data.attributes.dplet_id = dplet_details.id;
        request_object.data.attributes.dplet_name = dplet_details.name;
        request_object.data.attributes.json.data.attributes.dplet_name = dplet_details.name;
      } else {
        delete request_object.data.attributes["dplet_id"];
        delete request_object.data.attributes.json.data.attributes["dplet_id"];
        delete request_object.data.attributes["dplet_name"];
        delete request_object.data.attributes.json.data.attributes["dplet_name"];
      }
    },
    set_json(json_object) {
      for (var key in json_object) {
        request_object.data.attributes.json.data.attributes[key] = "";
        request_object.data.attributes.json.data.attributes[key] = json_object[key];
      }
    },
    set_top_level_values(top_level_object) {
      for (var key in top_level_object) {
        request_object.data.attributes[key] = "";
        request_object.data.attributes[key] = top_level_object[key];
      }
    },
    get_request_object() {
      var temp_request_object = request_object;
      request_object = JSON.parse(JSON.stringify(this.constants.RESET_POLICY_REQUEST_OBJECT));
      return temp_request_object;
    }
  });
  _exports.default = _default;
});