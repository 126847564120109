define("abcloud-ui/helpers/epoch-to-datetime", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.epochToDatetime = epochToDatetime;
  function epochToDatetime(_ref) {
    let [timestamp, type, format = null] = _ref;
    let outputFormat = 'YYYY-MM-DD HH:mm:ss';
    if (format !== null) {
      outputFormat = format;
    }
    if (!timestamp) {
      return '--';
    }
    var currentDate = (0, _moment.default)(new Date()); //-- Local time
    var dateString = '';
    timestamp = parseInt(timestamp);
    if (type === 'diff' && timestamp) {
      if (timestamp.length < 10) {
        dateString = new Date(timestamp); //-- Local time
      } else {
        dateString = new Date(timestamp * 1000); //-- Local time
      }

      var duration = _moment.default.duration(currentDate.diff(dateString));
      var hours = duration.asHours();
      var approxDurationInDays = Math.round(hours / 24);
      if (approxDurationInDays < 1) {
        if (hours < 1) {
          var minutes = Math.round(hours * 60);
          if (minutes === 0) {
            return 'Just now';
          } else {
            return Math.round(hours * 60) + ' minutes ago';
          }
        } else {
          return Math.round(hours) + ' hours ago';
        }
      } else {
        return approxDurationInDays + ' days ago';
      }
    } else {
      if (timestamp.length < 10) {
        return new Date(timestamp); //-- Local time
      } else {
        return (0, _moment.default)(new Date(timestamp * 1000)).format(outputFormat); //-- Local time
      }
    }
  }
  var _default = Ember.Helper.helper(epochToDatetime);
  _exports.default = _default;
});