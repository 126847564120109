define("abcloud-ui/constants/dialogue-texts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DIALOGUE_TEXTS = void 0;
  const DIALOGUE_TEXTS = {
    LOCALCONSOLE_DELETE_CONFIRMATION: "Are you sure you want to delete the Controller?",
    API_RECORDER_START_CONFIRMATION: "Are you sure you want to publish this API Recorder?",
    API_RECORDER_STOP_CONFIRMATION: "Are you sure you want to Stop Recording?",
    API_RECORDER_CLONE_CONFIRMATION: "Are you sure you want to clone with this configuration?",
    API_RECORDER_DELETE_CONFIRMATION: "Are you sure you want to delete this API Recorder?",
    CONFIRM_DELETE: "Confirm delete",
    CONFIRM_RESET_PWD: "Confirm resetting user password",
    CONFIRM_ADMIN_RESET_PWD: "Confirm resetting password",
    DELETE_CUSTOMER: "Are you sure you wish to delete this customer? Deleting this customer will DELETE ALL Tenants for this customer.",
    DELETE_CUSTOMER_TENANT: "Are you sure you want to delete this Customer Tenant?",
    DELETE_USER: "Are you sure you wish to delete this user?",
    RESET_USER_PWD: "Are you sure you want to reset password?",
    RESET_ADMIN_USER_PWD: "Are you sure you want to reset password for this user?",
    RESET_ADMIN_PWD: "Are you sure you want to reset your password? Once you confirm, you will be logged out. Please use your existing password to login and once logged-in, we will prompt you to change your password."
  };
  _exports.DIALOGUE_TEXTS = DIALOGUE_TEXTS;
});