define("abcloud-ui/components/dropdown-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      take_action(option_id, chosen_option, dropdown_index, extraParameterToAction) {
        this.take_action(option_id, chosen_option, dropdown_index, extraParameterToAction);
      }
    }
  });
  _exports.default = _default;
});