define("abcloud-ui/components/copy-to-clipboard", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    uniqueId: "",
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.set("uniqueId", (0, _uuid.v4)());
    },
    actions: {
      copyToClipboard(targetId) {
        const copyText = document.getElementById(targetId);
        copyText.select();
        document.execCommand("copy");
        this.notifications.success(this.constants.COPIED_SUCCESSFFULLY);
      },
      copyFromId(elmId) {
        const textToCopy = document.getElementById(elmId).innerText;
        const myTemporaryInputElement = document.createElement("input");
        myTemporaryInputElement.type = "text";
        myTemporaryInputElement.value = textToCopy;
        document.body.appendChild(myTemporaryInputElement);
        myTemporaryInputElement.select();
        document.execCommand("Copy");
        document.body.removeChild(myTemporaryInputElement);
        this.notifications.success(this.constants.COPIED_SUCCESSFFULLY);
      }
    }
  });
  _exports.default = _default;
});