define("abcloud-ui/routes/system/hosted/microsensor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    model(params) {
      return params.type;
    },
    setupController(controller, model) {
      controller.setProperties({
        'dpletStatus': model
      });
    },
    renderTemplate() {
      this.render("system.hosted.microsensor.template", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    }
  });
  _exports.default = _default;
});