define("abcloud-ui/routes/system/hosted/localbay/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord("localcomponent", params.id, {
        reload: true
      });
    },
    renderTemplate() {
      this.render("system.hosted.localbay.edit", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    }
  });
  _exports.default = _default;
});