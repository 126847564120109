define("abcloud-ui/components/forms/ajax-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {},
    // click() {
    //   self.send('createSupportUser');
    // },

    actions: {
      // click() {
      //   self.send("createSupportUser");
      // },

      // createSupportUser(customer, tenant, elementId, transitionText) {
      //   console.log('????url: ', URL, 'customerId: ', customerId, 'teneandId: ', tenantId, 'elementId: ', elementId);
      //   let customerId  = customer.id;
      //   let tenantId    = tenant.id;
      //   let URL = this.get('urls').URL_CREATE_TENANT_SUPPORT_USER(customerId, tenantId);
      //   console.log('url: ', URL, 'customerId: ', customerId, 'teneandId: ', tenantId);
      // $(document).ajaxStart(() => {
      //   run(() => {
      //     $('td#support_user' + tenantId).css({
      //       'opacity': '0.35',
      //       'background': '#cacfc9'
      //     });
      //     $('td#support_user' + tenantId).html('').text('Creating account ...');
      //   })
      // });

      // this.get('ajax').request(URL, {
      //   method: 'POST',
      //   data: {},
      // })
      // .then(response => {
      //   // if (response.meta.success.status === 200) {
      //   //   this.get('notifications').success('Created successfully!', {
      //   //     autoClear: true,
      //   //     clearDuration: CLEAR_DURATION
      //   //   });
      //   // }
      // })
      // },
    }
  });
  _exports.default = _default;
});