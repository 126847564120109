define("abcloud-ui/routes/customer/account/tenant-users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    beforeModel() {
      if (!this.auth.isLoggedIn("customer")) {
        this.transitionTo("/customer/login");
      }
    },
    model(params) {
      return this.store.find("tenant_user", params.tenant_user_id);
    },
    setupController(controller, model) {
      var userRole = this.auth.getTenantCreateUserRole();
      var canCreateMultipleRoles = this.auth.getTenantCreateUserRole().lastIndexOf(",") != -1;
      if (canCreateMultipleRoles) {
        userRole = userRole.split(",")[0];
      }
      let userAccessType = this.constants.LOGIN_ACCESS_TYPES.find(accessType => accessType.type === model.login_type);
      controller.setProperties({
        tenant: model,
        isSuperAdmin: model.role_level === this.constants.SUPER_ADMIN,
        createUserAbility: this.auth.getTenantCreateUserAbility(),
        createUserRole: userRole,
        currentUserRole: model.role_level,
        canCreateMultipleRoles: canCreateMultipleRoles,
        userAccessType: userAccessType ? userAccessType : {
          type: "",
          display: ""
        }
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          tenant: "",
          errorStatus: "",
          success: "",
          createUserAbility: "",
          createUserRole: null,
          canCreateMultipleRoles: false,
          userRoles: ["administrator", "standard user"],
          isSuperAdmin: "",
          currentUserRole: "",
          validPassword: null,
          confirmValidPassword: null,
          passwordMatching: null
        });
      }
    },
    renderTemplate() {
      this.render("customer.account.tenant-users.edit", {
        into: "customer.account.tenant-users",
        outlet: "account-edit"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("customer.account.tenant-users.create-new-user");
        controller.set("isEditUserInitLoading", true);
        transition.promise.finally(function () {
          controller.set("isEditUserInitLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});