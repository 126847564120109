define("abcloud-ui/controllers/exceptions/security", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    qp: Ember.inject.service('query-params'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    queryParams: ['page', 'type'],
    from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 3600,
    to_time: parseInt(_moment.default.utc().valueOf() / 1000),
    alert_severity: '',
    type: '',
    dart_category: 'compliance',
    timeSelectedByUser: "3600",
    dataTableId: 'alerts_table',
    page: 1,
    alertDetails: null,
    pageDetails: null,
    alerts: null,
    alertGeoLocationMap: null,
    pageChoice: 1,
    previousPageChoice: 5,
    current_selection_from_list: '',
    init() {
      this._super(...arguments);
    },
    actions: {
      forceRefreshModel() {
        this.send('refreshModel');
      },
      filter_category(dart_category) {
        this.setProperties({
          'dart_category': dart_category,
          'page': 1
        });
        this.qp.setParam('dart_category', dart_category);
        this.qp.setParam('page', 1);
        // this.transitionToRoute('exceptions.security', 'dart', {queryParams:{'page':1}});
        // this.set(this.queryParams[0], 1);
        this.send('refreshModel');
      },
      filter(alert_severity) {
        this.setProperties({
          'alert_severity': alert_severity,
          'page': 1
        });
        this.qp.setParam('alert_severity', alert_severity);

        // -- resetting the page to be first page upon filtering; also used as a flag to update pageArray in the route after the response from backend --
        this.qp.setParam('page', 1);
        this.send('refreshModel');
      },
      selectTimeRange(from_time, to_time) {
        this.set('timeSelectedByUser', from_time);
        this.qp.setParam('timeSelectedByUser', from_time);

        // -- resetting the page to be first page upon filtering; also used as a flag to update pageArray in the route after the response from backend --
        this.qp.setParam('page', 1);
        // this.set(this.queryParams[3], 1);

        if (to_time) {
          to_time = to_time;
        } else {
          to_time = _moment.default.utc().valueOf() / 1000;
        }
        if (from_time) {
          from_time = _moment.default.utc().valueOf() / 1000 - from_time; // (Date.parse(new Date(now.getTime() + now.getTimezoneOffset() * 60000)) - from_time)
        } else {
          from_time = 1;
        }
        this.set('from_time', parseInt(from_time));
        this.set('to_time', parseInt(to_time));
        this.qp.setParam('from_time', parseInt(from_time));
        this.qp.setParam('to_time', parseInt(to_time));
        this.send('refreshModel');
      },
      getAlertDetails(id, element_id) {
        this.set('current_selection_from_list', element_id);
        this.transitionToRoute('exceptions.security.alert', id);
      },
      pageChange(_page) {
        this.set('page', _page);
        this.qp.setParam('page', _page);
        this.send('refreshCollection');
      },
      refreshCollection() {
        let params = {};
        params['from_time'] = parseInt(_moment.default.utc().valueOf() / 1000) - this.timeSelectedByUser;
        params['to_time'] = parseInt(_moment.default.utc().valueOf() / 1000);
        params['timeSelectedByUser'] = this.timeSelectedByUser;
        params['alert_severity'] = this.alert_severity;
        params['page'] = this.page;
        params['type'] = this.type;
        this.setProperties({
          'isLoading': true,
          'from_time': params['from_time'],
          'to_time': params['to_time'],
          'timeSelectedByUser': params['timeSelectedByUser'],
          'alert_severity': params['alert_severity'],
          'page': params['page'],
          'type': params['type']
        });
        this.qp.setParams(params);
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});