define("abcloud-ui/routes/manage/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    notifications: Ember.inject.service(),
    beforeModel() {
      if (!this.auth.isLoggedIn("adminUser")) {
        this.controllerFor("application").set("adminUserLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("/manage/users/login");
      }
    },
    async model() {
      await this.store.findAll("customer", {
        include: "customer_tenants",
        reload: true
      }).sortBy("updated_at").reverse();
      return this.store.peekAll("customer");
    },
    setupController(controller, model) {
      const isResettingPassword = this.auth.getIsAdminUserResttingPwd();
      if (isResettingPassword) {
        window.location = this.constants.ADMIN_USER_RESET_PWD;
      }
      this._super(controller, model);
      controller.set("model", model);
    },
    renderTemplate() {
      this.render("manage.customers", {
        into: "application",
        outlet: "admin"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("manage.customers");
        controller.set("isCustomersLoading", true);
        transition.promise.finally(function () {
          controller.set("isCustomersLoading", false);
        });
      },
      destroy: function (customer) {
        customer.destroyRecord().then(() => {
          this.notifications.success(this.constants.CUSTOMER_DELETED_SUCCESSFULLY);
        }).catch(() => {
          this.notifications.error("Something went wrong, could not delete the customer record.");
          customer.rollbackAttributes();
        });
        this.transitionTo("manage.customers");
      }
    }
  });
  _exports.default = _default;
});