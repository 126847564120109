define("abcloud-ui/constants/config-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONFIG_OBJECTS = void 0;
  const CONFIG_OBJECTS = {
    DROPDOWN_INSTANCE_OPTIONS: [{
      type: "Appliance (Docker Compose)"
    }, {
      type: "Kubernetes"
    }, {
      type: "Linux AMI"
    }, {
      type: "AWS Fargate"
    }],
    DROPDOWN_DATA_RETENTION_SIZE: [{
      size: "8 GB"
    }, {
      size: "32 GB"
    }, {
      size: "64 GB"
    }],
    DPLET_DROPDOWN_INSTANCE_OPTIONS: [{
      type: "Generic Container"
    }, {
      type: "Docker Compose"
    }, {
      type: "Kubernetes"
    }, {
      type: "Linux Binary Debian"
    }, {
      type: "Linux Binary RPM"
    }, {
      type: "Linux Binary Generic"
    }, {
      type: "Docker Generic"
    }],
    RESOLVE_DPLET_SUB_TYPE: {
      lambda: "Amazon AWS Lambda",
      f5hsl: "F5 High Speed Log",
      pcap: "Network Packet Sniffer",
      receiver: "API Receiver",
      kong_api_gateway: "Kong API Gateway",
      kafka: "Kafka",
      nginx: "Nginx"
    },
    DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING: {
      "Appliance (Docker Compose)": {
        type: "container",
        orch: "docker-compose"
      },
      Kubernetes: {
        type: "container",
        orch: "kubernetes"
      },
      "Linux AMI": {
        type: "ami",
        orch: ""
      },
      "Linux Binary RPM": {
        type: "rpm",
        orch: ""
      },
      "Linux Binary Debian": {
        type: "deb",
        orch: ""
      },
      Hosted: {
        type: "hosted",
        orch: ""
      },
      "Linux Binary Generic": {
        type: "linux_binary_generic",
        orch: ""
      },
      "Generic Container": {
        type: "container",
        orch: ""
      },
      "Docker Generic": {
        type: "docker_generic",
        orch: ""
      },
      "AWS Fargate": {
        type: "fargate",
        orch: "fargate"
      }
    },
    INVERSE_DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING: {
      "containerdocker-compose": "Docker Compose",
      containerkubernetes: "Kubernetes",
      container: "Generic",
      ami: "Linux AMI",
      rpm: "Linux Binary RPM",
      deb: "Linux Binary Debian",
      hosted: "Hosted",
      linux_binary_generic: "Linux Binary Generic",
      docker_generic: "Docker Generic",
      fargatefargate: "AWS Fargate"
    },
    RESOLVE_LOCALBAY_DETAILS: {
      ovf: "Virtual Machine",
      container: "Docker Container",
      "docker-container": "Docker Container",
      //-- for legacy localcomponents
      ami: "Linux AMI",
      "linux-ami": "Linux AMI",
      "": "N/A",
      compose: "Docker Compose",
      "docker-compose": "Docker Compose",
      //-- for legacy localcomponents
      kubernetes: "Kubernetes",
      "on-prem": "On Prem",
      hosted: "Hosted",
      rpm: "Linux Binary - RPM",
      deb: "Linux Binary - Debian",
      f5hsl: "F5 High Speed Log",
      lambda: "Lambda",
      kong_api_gateway: "Kong API Gateway",
      receiver: "API Receiver",
      pcap: "Network Packet Sniffer"
    },
    RESOLVE_DOWNLOAD_ACTIVTY_SESSION_LOCATION: {
      http_request_header: "HTTP Request Header",
      http_request_cookie: "HTTP Request Cookie"
    },
    DART_SELECTION_MATCHER: {
      all: "All",
      compliance: "Non-compliance",
      parameter_tampering: "Input Tampering",
      dos: "Api-scanning & DOS",
      token: "Auth/Auth Abuse",
      exposure: "Unintended Exposure",
      leakage: "Leakage",
      manipulation: "Manipulation",
      loss: "Control Loss"
    },
    ALERT_SELECTION_MATCHER: {
      "": "All",
      high: "High",
      medium: "Medium",
      low: "Low",
      audit: "Audit",
      critical: "Critical"
    },
    TIME_SELECTION_MATCHER: {
      // -- uncomment following on local_dev if you want to test data for all time. BUT REMEMBER TO COMMENT IT AGAIN WHEN PUSHING TO STAGING/PRODUCTION
      // ""                                            : "All",
      7776000: "Last 90 Days",
      2592000: "Last 30 Days",
      3600: "Last Hour",
      86400: "Last Day",
      604800: "Last Week"
    },
    POLICY_SUB_TYPE_MATCHER: {
      api_parameter_pin: "API Parameter Pin",
      s3_file_download: "Protected AWS S3 File Download",
      out_of_spec_api: "API Out of Specification",
      api_session_token: "API Session Validity",
      data_export: "Data Export",
      api_recorder: "API Recorder"
    },
    PROFILE_SUB_TYPE_MATCHER: {
      api_parameter_pin: "API Parameter Pin",
      out_of_spec_api: "API Specification",
      api_session_token: "API Session Token",
      data_export: "Data Export",
      activity_session: "Activity Session",
      user_group: "User Group",
      user_session: "User Session",
      session_termination: "Session Termination"
    },
    DROPDOWN_API_RECORDER_FILTER_OPTIONS: {
      "Only APIs": "Only APIs",
      "Non APIs": "Non APIs",
      All: "All"
    },
    RESOLVE_API_RECORDER_TABLE_HEADER: {
      "Only APIs": "API",
      API: "API",
      "Non APIs": "Endpoint URL",
      All: "All"
    },
    RESOLVE_API_RECORDER_SUB_HEADING: {
      "Only APIs": "Discovered APIs",
      API: "Discovered APIs",
      "Non APIs": "Discovered Endpoint URLs",
      All: "All"
    },
    SEVERITY_OPTIONS: [{
      severity: "Critical"
    }, {
      severity: "High"
    }, {
      severity: "Medium"
    }, {
      severity: "Low"
    }, {
      severity: "Audit"
    }],
    PERIOD_OPTIONS: [{
      period: "Seconds"
    }, {
      period: "Days"
    }, {
      period: "Months"
    }],
    ADVANCED_SEARCH_OPTIONS: [{
      label: "URL",
      placeholder: "/api/users",
      input_id: "url_input",
      input_type: "text",
      input_class: "col-12",
      negation_id: "url_negation",
      request_key: "api",
      class: "col-12 col-md-3"
    }, {
      label: "Host",
      placeholder: "www.myhostname.com",
      input_id: "host_input",
      input_type: "text",
      input_class: "col-12",
      negation_id: "host_negation",
      request_key: "http_host",
      class: "col-12 col-md-2"
    }, {
      label: "Method",
      placeholder: "POST",
      input_id: "method_input",
      input_type: "text",
      input_class: "col-12",
      negation_id: "method_negation",
      request_key: "http_method",
      class: "col-12 col-md-1"
    }, {
      label: "Client IP",
      placeholder: "10.0.81.18",
      input_id: "client_ip_input",
      input_type: "text",
      input_class: "col-12",
      negation_id: "client_ip_negation",
      request_key: "client_ip",
      class: "col-12 col-md-1"
    }, {
      label: "Dest IP",
      placeholder: "0.0.0.12/32",
      input_id: "dest_ip_input",
      input_type: "text",
      input_class: "col-12",
      negation_id: "dest_ip_negation",
      request_key: "destination_ip",
      class: "col-12 col-md-1"
    }, {
      label: "Port",
      placeholder: "8350",
      input_id: "port_input",
      input_type: "number",
      input_class: "col-12",
      negation_id: "port_negation",
      request_key: "destination_port",
      class: "col-12 col-md-1"
    }, {
      label: "Response",
      placeholder: "404, 500",
      input_id: "response_input",
      input_type: "text",
      input_class: "col-12",
      negation_id: "response_negation",
      request_key: "response_code",
      class: "col-12 col-md-1"
    }, {
      label: "LocalBay ID",
      placeholder: "21",
      input_id: "localbay_id_input",
      input_type: "number",
      input_class: "col-12",
      negation_id: "lb_id_negation",
      request_key: "localcomponentId",
      class: "col-12 col-md-1"
    }, {
      label: "MicroSensor ID",
      placeholder: "43",
      input_id: "dplet_id_input",
      input_type: "number",
      input_class: "col-12",
      negation_id: "dp_id_negation",
      request_key: "dplet_id",
      class: "col-12 col-md-1"
    }],
    ADVANCED_FILTER_OPTIONS: [{
      label: "Source Port",
      placeholder: "3412",
      inputId: "js-src-port",
      inputType: "number",
      requestKey: "src_port"
    }, {
      label: "Source IP",
      placeholder: "0.0.0.12/32",
      inputId: "js-src-ip",
      inputType: "text",
      requestKey: "src_ip"
    }, {
      label: "Destination Port",
      placeholder: "2143",
      inputId: "js-dest-port",
      inputType: "number",
      requestKey: "dest_port"
    }, {
      label: "Destination IP",
      placeholder: "0.0.0.12/32",
      inputId: "js-dest-ip",
      inputType: "text",
      requestKey: "dest_ip"
    }, {
      label: "Client Namespace",
      placeholder: "Kubernetes Client Namespace",
      inputId: "js-client-namespace",
      inputType: "text",
      requestKey: "k8s_client_namespace"
    }, {
      label: "Client IP",
      placeholder: "Kubernetes Client IP",
      inputId: "js-client-ip",
      inputType: "text",
      requestKey: "k8s_client_podname"
    }],
    NUMBER_INPUT_NEGATION_BUTTONS: new Set(["lb_id_negation", "dp_id_negation", "port_negation"]),
    METHOD_LIST: ["GET", "POST", "PUT", "HEAD", "DELETE", "PATCH", "OPTIONS"],
    CSV_HEADERS: ["api", "method", "optionalqp", "requiredqp", "optionalbp", "requiredbp", "contentype", "deprecated"],
    AUTOFILL_OPTIONS: ["http-rsp-Status", "http-req-method", "total_size_bytes", "http-rsp-body-id", "http-rsp-body-type", "http-rsp-body-props", "http-req-body-params", "http-rsp-Status-Code", "http-rsp-body-manual", "http-rsp-body-params", "http-rsp-body-status", "http-rsp-header-Date", "http-rsp-body-message", "http-rsp-body-root_id", "http-rsp-body-user_id", "http-req-body-message", "http-req-body-user_id", "http-rsp-body-edit_at", "http-req-header-Accept", "http-req-header-Cookie", "http-req-header-Origin", "http-req-body-file_ids", "http-rsp-body-hashtags", "http-req-body-create_at", "http-req-body-update_at", "http-rsp-body-create_at", "http-rsp-body-delete_at", "http-rsp-body-is_pinned", "http-rsp-body-parent_id", "http-rsp-body-update_at", "http-req-body-channel_id", "http-rsp-body-channel_id", "http-rsp-body-original_id", "http-req-header-Connection", "http-req-header-User-Agent", "http-req-header-Content-Type", "http-rsp-header-Content-Type", "http-rsp-header-X-Request-Id", "http-rsp-header-X-Version-Id", "http-req-body-pending_post_id", "http-rsp-body-pending_post_id", "http-req-header-Cookie-params", "http-req-header-Content-Length", "http-rsp-body-last_activity_at", "http-rsp-header-Content-Length", "http-req-header-Accept-Encoding", "http-req-header-Accept-Language", "http-req-header-Cookie-MMUSERID", "http-req-header-X-Requested-With", "http-rsp-header-Set-Cookie-params", "http-req-header-Cookie-MMAUTHTOKEN", "http-req-header-Cookie-ajs_user_id", "http-req-header-Cookie-ajs_group_id", "http-req-header-Cookie-ajs_anonymous_id"],
    // -- options IDs in the same order as on the UI
    SPEC_INFO_OPTIONS: ["csvFileImportDetails", "swaggerDetails"],
    EVENT_TABLE_HEADERS: ["Time", "Host", "URL", "Client IP", "Destination IP", "Destination Port", "Protocol", "Protocol Method", "Protocol Response", "Response Size (b)"],
    TRANSACTION_TABLE_HEADERS: ["Time", "Name", "User", "Data", "APIs"],
    ALERT_TABLE_HEADERS: ["Time", "Severity", "Detection Name", "User", "IP", "Policy"],
    API_RECORDER_API_SPEC_TABLE_HEADERS: ["Method", "API"],
    RESET_POLICY_REQUEST_OBJECT: {
      data: {
        type: "",
        attributes: {
          localcomponent: {
            id: ""
          },
          profiles_ids: [],
          sub_type: "",
          action: "",
          name: "",
          description: "",
          dplet_id: "",
          dplet_name: "",
          changeset: {},
          json: {
            data: {
              type: "",
              attributes: {
                name: "",
                description: "",
                action: "",
                sub_type: "",
                dplet_id: "",
                dplet_name: "",
                profiles_ids: [],
                localcomponent_ids: [],
                localcomponent_title: "",
                // Note(sanath): localbay_ids and localbay_title are deprecated
                localbay_ids: [],
                localbay_title: ""
              }
            }
          }
        }
      }
    },
    RESET_PROFILE_REQUEST_OBJECT: {
      data: {
        type: "profile",
        attributes: {
          sub_type: "",
          name: "",
          description: "",
          action: "",
          changeset: {},
          json: {}
        }
      }
    },
    MICROSENSOR_FORM_INPUTS: {
      pcap: {
        inputs: ["instanceType", "localbay", "localbayIp", "instanceMode", "networkPassiveSnifferInstallInstructions"],
        display: true,
        displayName: "Network Passive Sniffer",
        helpText: "This MicroSensor can be deployed to passively sniff network traffic.",
        createRoute: "system.hosted.microsensor.passive-sniffer.new"
      },
      lambda: {
        inputs: ["localbay", "localbayIp"],
        display: true,
        displayName: "Amazon AWS Lambda",
        helpText: "This MicroSensor can be deployed in Amazon Infrastructure as a Lambda or LambdaEdge function.",
        createRoute: "system.hosted.microsensor.lambda.new"
      },
      kong_api_gateway: {
        inputs: ["localbay"],
        display: true,
        displayName: "Kong API Gateway",
        helpText: "This MicroSensor can be deployed to use HTTP REST API and Post information.",
        createRoute: "system.hosted.microsensor.kong-api-gateway.new"
      },
      f5hsl: {
        inputs: ["instanceType", "localbay", "localbayIp"],
        display: true,
        displayName: "F5 High Speed Log",
        helpText: "This MicroSensor can be deployed to consume the F5 High Speed Log.",
        createRoute: "system.hosted.microsensor.f5-hsl-consumer.new"
      },
      receiver: {
        inputs: ["localbay", "restApiDetails"],
        display: false,
        displayName: "API Receiver",
        helpText: "This MicroSensor can be deployed to use HTTP REST API and Post information.",
        createRoute: "system.hosted.microsensor.api-receiver.new"
      },
      kafka: {
        inputs: ["localbay", "localbayIp", "instanceType", "kafkaTopics", "kafkaServers", "kafkaConsumerGroupName"],
        display: true,
        displayName: "Kafka",
        helpText: "This MicroSensor can be deployed to passively consume/monitor data flowing through Kafka.",
        createRoute: "system.hosted.microsensor.kafka.new"
      },
      nginx: {
        inputs: ["localbay", "localbayIp", "instanceType"],
        display: true,
        displayName: "Nginx",
        helpText: "This MicroSensor starts Nginx server to monitor HTTP REST API and POST information.",
        createRoute: "system.hosted.microsensor.nginx.new"
      }
    },
    LOGIN_ACCESS_TYPES: [{
      id: 46,
      type: "lt_local",
      display: "Local",
      description: null
    }, {
      id: 47,
      type: "lt_sso",
      display: "SSO",
      description: null
    }],
    ENVIRONMENTS: {
      LOCAL_DEV: "local_dev",
      STAGING: "staging",
      PRODUCTION: "production"
    },
    PROTOCOLS: {
      HTTP: "http",
      HTTPS: "https"
    },
    WEB_HOSTS: {
      local_dev: "lvh.me",
      staging: "stg-arecabay.net",
      production: "cloudvector.net"
    },
    USER_ROLES: [{
      type: "administrator",
      display: "Administrator"
    }, {
      type: "standard user",
      display: "Standard user"
    }]
  };
  _exports.CONFIG_OBJECTS = CONFIG_OBJECTS;
});