define("abcloud-ui/components/policies-tenant/policies-protected-aws-s3-file-downloads/detection-and-severity-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    disabledStatusUnAuth: true,
    disabledStatusExpiredToken: true,
    disabledStatusFileGroup: true,
    updateUI(active_checkbox, disabled_checkbox, disable_dropdown_input, current_severity_input, current_severity) {
      if (this.disabledStatus) {
        document.getElementById(disabled_checkbox).checked = true;
      } else {
        document.getElementById(active_checkbox).checked = true;
      }
      this.set(disable_dropdown_input, false);
      this.set(current_severity_input, current_severity.replace(/\w/, c => c.toUpperCase()));
    },
    didInsertElement() {
      //-- Resetting/Disable all the checkboxes before doing anything --
      // document.getElementById('fileReqUnAuth').checked = false;
      // document.getElementById('userExpToken').checked = false;
      // document.getElementById('userNotInFileGrp').checked = false;
      if (this.disabledStatus) {
        document.getElementById('fileReqUnAuthDisabled').checked = false;
        document.getElementById('userExpTokenDisabled').checked = false;
        document.getElementById('userNotInFileGrpDisabled').checked = false;
      }
      /* -- for a new policy, the 'this.policy' object doesn't exist and hence the following would fail,
       which would be caught in the try catch block -- */
      try {
        var policy_data_severity = this.policy.json.data.attributes.detected_actions_severity;
        for (var key in policy_data_severity) {
          switch (key) {
            case 'file_download_from_unauthenticated_user':
              this.updateUI('fileReqUnAuth', 'fileReqUnAuthDisabled', 'disabledStatusUnAuth', 'unauth_user_severity', policy_data_severity[key]);
              break;
            case 'file_download_from_user_with_expired_token':
              this.updateUI('userExpToken', 'userExpTokenDisabled', 'disabledStatusExpiredToken', 'expired_token_severity', policy_data_severity[key]);
              break;
            case 'file_download_from_user_not_in_file_group':
              this.updateUI('userNotInFileGrp', 'userNotInFileGrpDisabled', 'disabledStatusFileGroup', 'user_not_in_file_group_severity', policy_data_severity[key]);
              break;
          }
        }
      } catch (thisIsNewProfile) {
        document.getElementById('recommendedSettings').checked = true;
        document.getElementById('fileReqUnAuth').checked = true;
        document.getElementById('userExpToken').checked = true;
        document.getElementById('userNotInFileGrp').checked = true;
        this.set('disabledStatusUnAuth', false);
        this.set('disabledStatusExpiredToken', false);
        this.set('disabledStatusFileGroup', false);
      }
      //-- after all the operations and insertions have been performed, disable all the checkboxes and dropdowns --
      if (this.disabledStatus) {
        this.setProperties({
          'disabledStatusUnAuth': true,
          'disabledStatusExpiredToken': true,
          'disabledStatusFileGroup': true
        });
        document.getElementById('fileReqUnAuthDisabled').disabled = true;
        document.getElementById('userExpTokenDisabled').disabled = true;
        document.getElementById('userNotInFileGrpDisabled').disabled = true;
      }
    },
    /*
    --  Based on which dropdown menu the user selected the option from, we take the chosen option from that
        dropdown div (being passed as 1st argument to the method, instead of current value of the input element because
        the current value in the input element is the choice from previous selection and not the user choice from the dropdown div)
        and add it to the newSet, if no arguments are passed then the newSet generation is triggered by checkboxes in which case
        the current value in the input dropdowns is valid.
    --  Always check if the corresponding checkbox is checked, only then add the value into the set.
    */
    generateNewSet(option, id) {
      var newSet = new Set();
      switch (id) {
        case 'fileReqUnAuth':
          if (document.getElementById('fileReqUnAuth').checked) {
            newSet.add(option);
          }
          if (document.getElementById('userExpToken').checked) {
            newSet.add(document.getElementById('expTokenDropdownInput').textContent);
          }
          if (document.getElementById('userNotInFileGrp').checked) {
            newSet.add(document.getElementById('fileGrpDropdownInput').textContent);
          }
          break;
        case 'userExpToken':
          if (document.getElementById('fileReqUnAuth').checked) {
            newSet.add(document.getElementById('unauthDropdownInput').textContent);
          }
          if (document.getElementById('userExpToken').checked) {
            newSet.add(option);
          }
          if (document.getElementById('userNotInFileGrp').checked) {
            newSet.add(document.getElementById('fileGrpDropdownInput').textContent);
          }
          break;
        case 'userNotInFileGrp':
          if (document.getElementById('fileReqUnAuth').checked) {
            newSet.add(document.getElementById('unauthDropdownInput').textContent);
          }
          if (document.getElementById('userExpToken').checked) {
            newSet.add(document.getElementById('expTokenDropdownInput').textContent);
          }
          if (document.getElementById('userNotInFileGrp').checked) {
            newSet.add(option);
          }
          break;
        default:
          //-- this would be triggered when checking/unchecking checkboxes only--
          if (document.getElementById('fileReqUnAuth').checked) {
            newSet.add(document.getElementById('unauthDropdownInput').textContent);
          }
          if (document.getElementById('userExpToken').checked) {
            newSet.add(document.getElementById('expTokenDropdownInput').textContent);
          }
          if (document.getElementById('userNotInFileGrp').checked) {
            newSet.add(document.getElementById('fileGrpDropdownInput').textContent);
          }
      }
      return newSet;
    },
    actions: {
      //-- disables the corresponding input dropdown based on user selection for checkbox --
      //-- everytime user checks or unchecks a checkbox, we need to generate a new set to be consumed by actions-information --
      toggleInputDisabling(event) {
        switch (event.target.id) {
          case 'fileReqUnAuth':
            if (document.getElementById('fileReqUnAuth').checked) {
              this.set('disabledStatusUnAuth', false);
            } else {
              this.set('disabledStatusUnAuth', true);
            }
            break;
          case 'userExpToken':
            if (document.getElementById('userExpToken').checked) {
              this.set('disabledStatusExpiredToken', false);
            } else {
              this.set('disabledStatusExpiredToken', true);
            }
            break;
          case 'userNotInFileGrp':
            if (document.getElementById('userNotInFileGrp').checked) {
              this.set('disabledStatusFileGroup', false);
            } else {
              this.set('disabledStatusFileGroup', true);
            }
            break;
          default:
            this.debug.log('unknown input id..');
        }
        document.getElementById('recommendedSettings').checked = false;
        this.updateSet(this.generateNewSet());
      },
      //-- a new set needs to be generated for actions-information consumption
      // everytime a user chooses a new option from any one of the dropdowns --
      unAuthUserSeverity(id, chosenOption) {
        document.getElementById('recommendedSettings').checked = false;
        this.unAuthUserSeverity(chosenOption, this.generateNewSet(chosenOption, 'fileReqUnAuth'));
      },
      expiredTokenSeverity(id, chosenOption) {
        document.getElementById('recommendedSettings').checked = false;
        this.expiredTokenSeverity(chosenOption, this.generateNewSet(chosenOption, 'userExpToken'));
      },
      userNotInFileGroupSeverity(id, chosenOption) {
        document.getElementById('recommendedSettings').checked = false;
        this.userNotInFileGroupSeverity(chosenOption, this.generateNewSet(chosenOption, 'userNotInFileGrp'));
      },
      resetToRecommendedSettings() {
        document.getElementById('fileReqUnAuth').checked = true;
        document.getElementById('userExpToken').checked = true;
        document.getElementById('userNotInFileGrp').checked = true;
        this.set('disabledStatusUnAuth', false);
        this.set('disabledStatusExpiredToken', false);
        this.set('disabledStatusFileGroup', false);
        this.resetToRecommendedSettings();
      }
    }
  });
  _exports.default = _default;
});