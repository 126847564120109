define("abcloud-ui/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    api: _emberData.default.attr('string'),
    dplet_id: _emberData.default.attr('number'),
    dplet_type: _emberData.default.attr('string'),
    event_protocol: _emberData.default.attr('string'),
    user: _emberData.default.attr('string'),
    total_size_bytes: _emberData.default.attr('number'),
    total_pkt_count: _emberData.default.attr('number'),
    app_ip: _emberData.default.attr('number'),
    app_id: _emberData.default.attr('number'),
    app_port: _emberData.default.attr('number'),
    event_timestamp: _emberData.default.attr('number'),
    event_json: _emberData.default.attr(),
    links: _emberData.default.attr(),
    csv_file: _emberData.default.attr(),
    http_host: _emberData.default.attr('string'),
    localbay_id: _emberData.default.attr('number'),
    localcomponent_id: _emberData.default.attr('number')

    // dplet: DS.belongs_to('dplet', {embedded: 'always'})
  });
  _exports.default = _default;
});