define("abcloud-ui/helpers/format-string-for-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [str, maxLength, removeFromMiddle] = _ref;
      let formattedString = '';
      if (str && str.length > parseInt(maxLength)) {
        if (removeFromMiddle) {
          let thirdsLength = parseInt(maxLength / 3);
          let firstChunk = str.slice(0, thirdsLength);
          let lastChunk = str.slice(-thirdsLength);
          formattedString = firstChunk + '...' + lastChunk;
        } else {
          formattedString = str.slice(0, parseInt(maxLength)) + '...';
        }
      } else {
        formattedString = str;
      }
      return formattedString;
    }
  });
  _exports.default = _default;
});