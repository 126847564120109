define("abcloud-ui/routes/insights/dashboard/security/dart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service("ajax"),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    doughnutChart: Ember.inject.service("doughnut-chart"),
    barChart: Ember.inject.service("bar-chart"),
    filterParams: "",
    queryParams: Ember.inject.service("query-params"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    beforeModel() {
      this.controllerFor("insights.dashboard.security").set("securitySubMenuHidden", false);
      this.controllerFor("insights.dashboard.security").set("observabilitySubMenuHidden", true);
    },
    model() {
      this.set("filterParams", this.queryParams.getParams());
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        observabilitySubMenuHidden: true,
        securitySubMenuHidden: false,
        secTabActive: true,
        observeTabActive: false,
        from_time: this.queryParams.getParam("from_time"),
        to_time: this.queryParams.getParam("to_time"),
        timeSelectedByUser: this.queryParams.getParam("timeSelectedByUser"),
        page: this.queryParams.getParam("page"),
        showR1C1Preloader: false,
        showR1C2Preloader: true,
        showR1C3Preloader: true,
        showR2C1Preloader: true,
        showR2C2Preloader: true,
        showR2C3Preloader: true
      });
      var urls = this.urls;
      var params = this.filterParams;
      var self = this;
      this.doughnutChart.setup();
      this.barChart.setup();

      // -- TOTALS: dart
      var dart_total_request = Ember.RSVP.hash({
        req: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_DATA_TOTAL(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_total_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dartTotal = dart_total_request._result.req.totals.dart;
        controller.setProperties({
          dartTotal: dartTotal,
          showDartTotalPreloader: false
        });
      });

      // -- by dart severity
      var dart_severity_request = Ember.RSVP.hash({
        dart: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_SEVERITY_DATA(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_severity_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dart_severity = dart_severity_request._result.dart.data[0];
        controller.setProperties({
          dart_severity: dart_severity,
          dartSeverityData: self.get("barChart").getChartData(dart_severity["attributes"]["chart_data"], dart_severity["attributes"]["chart_labels"]),
          dartSeverityOpts: self.get("barChart").getChartOptions({
            labels: []
          }),
          showR1C2Preloader: false,
          dartSeverityDataAvailable: dart_severity["attributes"]["chart_data"].length === 0 ? false : true
        });
      });

      // -- for dart users
      var dart_user_request = Ember.RSVP.hash({
        dart_users: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_USER_DATA(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_user_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dart_users = dart_user_request._result.dart_users.data[0];
        controller.setProperties({
          dart_users: dart_users,
          dartUsersData: self.get("doughnutChart").getChartData(dart_users["attributes"]["chart_data"], dart_users["attributes"]["chart_labels"]),
          dartUsersOpts: self.get("doughnutChart").getChartOptions(dart_users["attributes"]["chart_center_text"]),
          showR2C1Preloader: false
        });
      });

      // -- for dart policy
      var dart_policies_request = Ember.RSVP.hash({
        dart_policies: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_POLICY_DATA(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_policies_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dart_policies = dart_policies_request._result.dart_policies.data[0];
        controller.setProperties({
          dart_policies: dart_policies,
          dartPoliciesData: self.get("doughnutChart").getChartData(dart_policies["attributes"]["chart_data"], dart_policies["attributes"]["chart_labels"]),
          dartPoliciesOpts: self.get("doughnutChart").getChartOptions(dart_policies["attributes"]["chart_center_text"]),
          showR2C3Preloader: false
        });
      });

      // -- for dart detections
      var dart_detection_request = Ember.RSVP.hash({
        dart_detections: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_DETECTION_DATA(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_detection_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dart_detections = dart_detection_request._result.dart_detections.data[0];
        controller.setProperties({
          dart_detections: dart_detections,
          dartDetectionsData: self.get("doughnutChart").getChartData(dart_detections["attributes"]["chart_data"], dart_detections["attributes"]["chart_labels"]),
          dartDetectionsOpts: self.get("doughnutChart").getChartOptions(dart_detections["attributes"]["chart_center_text"]),
          showR2C2Preloader: false
        });
      });

      // -- for dart categories
      var dart_category_request = Ember.RSVP.hash({
        dart_categories: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_CATEGORY_DATA(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_category_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dart_categories = dart_category_request._result.dart_categories.data[0];
        controller.setProperties({
          dart_categories: dart_categories,
          dartCategoriesData: self.get("barChart").getChartData(dart_categories["attributes"]["chart_data"], dart_categories["attributes"]["chart_labels"], ["#0a3405", "#325a2e", "#4e8248", "#5aa352", "#86a982", "#bfd3bd", "#e9f1e8", "#e8f6e7"]),
          dartCategoriesOpts: self.get("barChart").getChartOptions("horizontal"),
          showR1C3Preloader: false,
          dartCategoriesDataAvailable: dart_categories["attributes"]["chart_data"].length === 0 ? false : true
        });
      });
      // -- by dart
      var dart_request = Ember.RSVP.hash({
        dart: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-AB-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DART_DATA(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      dart_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var dart = dart_request._result.dart.data[0];
        controller.setProperties({
          dart: dart,
          dartData: self.get("doughnutChart").getChartData(dart["attributes"]["chart_data"], dart["attributes"]["chart_labels"], "dart"),
          dartOpts: self.get("doughnutChart").getChartOptions(dart["attributes"]["chart_center_text"]),
          showR1C1Preloader: false
        });
      });
    },
    renderTemplate() {
      this.render("insights.dashboard.security.dart", {
        into: "insights.dashboard.security",
        outlet: "dashboard_data"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
          to_time: parseInt(_moment.default.utc().valueOf() / 1000),
          timeSelectedByUser: "86400",
          page: 1,
          showR1C1Preloader: true,
          showR1C2Preloader: true,
          showR1C3Preloader: true,
          showR2C1Preloader: true,
          showR2C2Preloader: true,
          showR2C3Preloader: true
        });
      }
    }
  });
  _exports.default = _default;
});