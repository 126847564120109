define("abcloud-ui/templates/components/dashboard/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aLSOaAVW",
    "block": "{\"symbols\":[],\"statements\":[[2,\" textWidth calculated dynamically on browser resize \"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"pure-u-16-24 single_line\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"category_text\"],[12,\"title\",[28,[[21,\"type\"],\" - \",[27,\"calc-percent\",null,[[\"num\",\"total\"],[[23,[\"count\"]],[23,[\"total\"]]]]],\"%\"]]],[12,\"alt\",[28,[[21,\"type\"],\" - \",[27,\"calc-percent\",null,[[\"num\",\"total\"],[[23,[\"count\"]],[23,[\"total\"]]]]],\"%\"]]],[9],[1,[27,\"format-string-for-length\",[[23,[\"type\"]],[23,[\"maxTextWidth\"]],1],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"pure-u-8-24 single_line text_right\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"category_text dark\"],[9],[1,[27,\"format-number\",[[23,[\"count\"]]],null],false],[0,\" \"],[10],[7,\"span\"],[11,\"class\",\"percent_text\"],[9],[0,\"(\"],[1,[27,\"calc-percent\",null,[[\"num\",\"total\"],[[23,[\"count\"]],[23,[\"total\"]]]]],false],[0,\"%)\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"clearfix pure-u-24-24\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progress_bar\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"filled\"],[12,\"title\",[28,[[21,\"type\"],\" - \",[27,\"calc-percent\",null,[[\"num\",\"total\"],[[23,[\"count\"]],[23,[\"total\"]]]]],\"%\"]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/dashboard/progress-bar.hbs"
    }
  });
  _exports.default = _default;
});