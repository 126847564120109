define("abcloud-ui/components/customer/create-user-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['create_user_button'],
    click() {
      this.createNewUser();
    }
  });
  _exports.default = _default;
});