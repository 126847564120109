define("abcloud-ui/components/system-localbay/localbay-config-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showPassword: false,
    showOrch: false,
    resolveInstanceType: {
      'ovf': 'Virtual Machine',
      'container': 'Docker Container',
      'linux-ami': 'Linux AMI'
    },
    resolveInstanceOrch: {
      '': 'N/A',
      'docker-compose': 'Docker Compose',
      'kubernetes': 'Kubernetes'
    },
    actions: {
      togglePassphraseView() {
        this.set('showPassword', !this.showPassword);
      },
      generateConfigFiles() {
        this.generateConfigFiles();
      },
      goBack() {
        this.goBack();
      }
    }
  });
  _exports.default = _default;
});