define("abcloud-ui/components/system-localbay/localcomponent-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      deleteLocalcomponent(id) {
        this.deleteLocalcomponent(id);
      },
      editLocalcomponent(id) {
        this.editLocalcomponent(id);
      }
    }
  });
  _exports.default = _default;
});