define("abcloud-ui/components/policies-tenant/policies-api-recorder/microsensor-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      toggle_advanced_options() {
        if (Ember.$('.advanced_options').css('display') === 'block') {
          Ember.$('#advanced_options_caret').removeClass('fa-caret-down');
          Ember.$('#advanced_options_caret').addClass('fa-caret-right');
          Ember.$('.advanced_options').hide(200);
        } else {
          Ember.$('#advanced_options_caret').removeClass('fa-caret-right');
          Ember.$('#advanced_options_caret').addClass('fa-caret-down');
          Ember.$('.advanced_options').show(200);
        }
      },
      chooseMicroSensor(id, chosen_option) {
        this.chooseMicroSensor(id, chosen_option);
      }
    }
  });
  _exports.default = _default;
});