define("abcloud-ui/components/policies-tenant/profile-file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    notify_ok: Ember.inject.service("inject-notification"),
    current_valid_action: "select",
    file_id: "",
    didInsertElement() {
      if (this.spec_file) {
        this.setProperties({
          spec_file_id: this.spec_file.file_id,
          file_name: this.spec_file.file_name,
          file_size: this.spec_file.file_size,
          file_type: this.spec_file.file_type
        });
        if (this.spec_file.file_name) {
          this.set("current_valid_action", "delete");
          Ember.$("#validateFile").attr("disabled", false);
        }
        if (this.spec_file.file_id) {
          this.set("file_id", this.spec_file_id);
        }
      }
    },
    actions: {
      selectFile(element) {
        Ember.$("#spec_file_name").val(element.target.value);
        Ember.$("#validateFile").attr("disabled", false);
        this.set("current_valid_action", "delete");
        if (!element.target.value) {
          this.set("current_valid_action", "select");
          var elements = {
            fileUploadFilledValue: ""
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      delete_file() {
        this.setProperties({
          current_valid_action: "select",
          isFileDeleted: "true"
        });
        Ember.$("#validateFile").attr("disabled", true);
        if (this.file_id) {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-CV-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var url = this.urls.URL_PROFILE_FILE_DELETE(this.file_id);
          this.ajax.request(url, {
            method: "DELETE"
          }).then(response => {
            Ember.$("#spec_file_name").val("");
            Ember.$("#file-upload").val("");
            this.set("file_validated", false);
            if (this.sub_type === "api_parameter_pin") {
              this.getProfile();
            }
          });
        } else {
          Ember.$("#spec_file_name").val("");
          Ember.$("#file-upload").val("");
        }
        var elements = {
          fileUploadFilledValue: ""
        };
        if (this.is_mandatory_field === "false") {
          elements = {
            fileUploadFilledValue: "thisIsNotAMantoryField"
          };
        }
        this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
      },
      validateFile() {
        this.setFileUpload();
        var file = document.getElementById("file-upload").files[0];
        const FILE_VALID = this.constants.FILE_VALID;
        const SELECT_FILE = this.constants.SELECT_FILE;
        if (file) {
          var formData = new FormData();
          formData.append("file", file);
          formData.append("profile_id", this.profile_id);
          formData.append("sub_type", this.sub_type);
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
              "X-CV-Trace-ID": this.urls.generate_trace_id()
            }
          });
          var url = this.urls.URL_PROFILE_FILE_UPLOAD();
          this.ajax.request(url, {
            method: "POST",
            data: formData,
            processData: false,
            contentType: false
          }).then(response => {
            if (!response.error) {
              // -- remove any notification that may have been posted to state file upload error
              this.setProperties({
                profile_id: response.data.attributes.profile_data.profile_id,
                file_id: response.data.attributes.profile_data.spec_file.file_id,
                error: false,
                file_validated: true
              });
              let elements = {
                fileUploadFilledValue: response.data.id
              };
              this.notify_ok.notify_ok(elements, FILE_VALID);
            } else {
              this.set("error", true);
              let elements = {
                fileUploadFilledValue: ""
              };
              this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, response.error.payload.meta.validation_errors.structure);
            }
          }).catch(error => {
            this.debug.log("error", error);
            this.set("error", true);
            let elements = {
              fileUploadFilledValue: ""
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, error.payload.meta.validation_errors.structure);
          });
        } else {
          let elements = {
            fileUploadFilledValue: ""
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, SELECT_FILE);
        }
        return false;
      }
    }
  });
  _exports.default = _default;
});