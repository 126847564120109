define("abcloud-ui/templates/components/tracker-spine-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sryiPhSb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"tracker_spine active\"],[12,\"id\",[21,\"customId\"]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/tracker-spine-active.hbs"
    }
  });
  _exports.default = _default;
});