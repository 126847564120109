define("abcloud-ui/routes/customer/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    beforeModel() {
      if (this.auth.isLoggedIn("customer")) {
        this.transitionTo("deployments.stand-alone-controller");
      }
    }
  });
  _exports.default = _default;
});