define("abcloud-ui/pods/application/adapter", ["exports", "ember-data", "ember-inflector", "abcloud-ui/config/environment"], function (_exports, _emberData, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    xhr: [],
    headers: Ember.computed('auth.authToken', function () {
      return {
        "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
        "X-AB-Trace-ID": this.urls.generate_trace_id()
      };
    }),
    host: _environment.default.apiHost,
    namespace: Ember.computed('auth.tenant_id', function () {
      return `${_environment.default.version}/tenants/${this.auth.getTenantId()}`;
    }),
    pathForType: function (type) {
      var underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },
    ajax: function (url, type, hash) {
      var adapter = this;
      // hash.timeout = 10000;

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        hash = adapter.ajaxOptions(url, type, hash);
        hash.success = function (json) {
          if (json && json.errors) {
            Ember.run.join(null, reject, json);
          }
          Ember.run.join(null, resolve, json);
        };
        hash.error = function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.hasOwnProperty('responseJSON') && jqXHR.responseJSON.hasOwnProperty('errors')) {
            Ember.run.join(null, reject, jqXHR.responseJSON);
          } else {
            var errors = {
              "errors": [{
                "detail": jqXHR.statusText ? jqXHR.statusText : this.constants.SOMETHING_WENT_WRONG,
                "source": {
                  "pointer": "application-adapter"
                },
                "jqXHR": jqXHR,
                "status": jqXHR.status ? jqXHR.status : 499
              }]
            };
            Ember.run.join(null, reject, errors);
          }
        };
        var charArray = url.split('/');
        var request_identifier = charArray[charArray.length - 1];
        if (!isNaN(Number(request_identifier))) {
          request_identifier = charArray[charArray.length - 2];
        }
        adapter.get('manage_requests').add_request(request_identifier, Ember.$.ajax(hash));
      }, "DS: RestAdapter#ajax " + type + " to " + url);
      return promise;
    },
    handleResponse: function (status, headers, payload) {
      if (status === 204) {
        payload = {
          "data": []
        };
        return new _emberData.default.Adapter(payload);
      } else if (payload.errors) {
        payload.errors = payload.errors;
      }
      return this._super(...arguments);
    }

    // buildURL() {
    //   let url = this._super(...arguments);
    //   return [url,  't', this.get('urls').generate_trace_id()].join('/');
    // },
  });
  _exports.default = _default;
});