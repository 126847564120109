define("abcloud-ui/templates/system/hosted/microsensor/passive-sniffer/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "v1uQ00Dy",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/dplet-edit-form\",null,[[\"editDplet\",\"cancel\",\"dplet\",\"dpletError\",\"success\",\"showInstanceMode\",\"validTitle\"],[[27,\"action\",[[22,0,[]],\"editDplet\"],null],[27,\"action\",[[22,0,[]],\"formCancelTransition\"],null],[23,[\"dplet\"]],[23,[\"dpletError\"]],[23,[\"success\"]],true,[23,[\"validTitle\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/microsensor/passive-sniffer/edit.hbs"
    }
  });
  _exports.default = _default;
});