define("abcloud-ui/helpers/friendly-format-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.friendlyFormatString = friendlyFormatString;
  function friendlyFormatString(_ref) {
    let [string, capitalize] = _ref;
    if (!string || string.length < 1) {
      return '';
    }
    if (!isNaN(string)) {
      return string;
    }
    if (!capitalize || capitalize.length < 1) {
      capitalize = true;
    }
    // -- replace ugly non-word characters
    var s = string.replace(/[_-]/gi, ' ').toLowerCase();
    // -- split string into words
    var sArr = s.split(' ');
    if (capitalize === true) {
      for (var w = 0; w < sArr.length; w++) {
        var sArrUpper = sArr[w].slice(0, 1).toUpperCase();
        sArr[w] = sArrUpper + sArr[w].slice(1);
      }
    }
    return sArr.join(' ');
  }
  var _default = Ember.Helper.helper(friendlyFormatString);
  _exports.default = _default;
});