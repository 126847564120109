define("abcloud-ui/components/exceptions/formatted-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    hashHTMLData: '',
    counter: 0,
    string_modifiers: Ember.inject.service('string-modifiers'),
    didInsertElement() {
      // -- test hash:
      // var hashObject = {'a':'b', 'c':'d', f: 'nm'};
      var hashObject = this.hashObject;
      //var styleTag      = this.styleTag;
      //var braces        = this.braces;
      var hashHTMLData = this.hashHTMLData;
      var objLength = Object.keys(hashObject).length;
      //var tmp           = null;
      var counter = this.counter;
      for (var pair in hashObject) {
        counter += 1;
        var tmpKey = this.string_modifiers.capitalize(pair.replace(/\"/g, '')); // eslint-disable-line no-useless-escape
        var tmpVal = hashObject[pair].replace(/\"/g, ''); // eslint-disable-line no-useless-escape
        var separator = '';
        var leftBrace = '';
        var rightBrace = '';
        if (counter < objLength) {
          separator = ', ';
        }
        if (this.braces === true) {
          if (counter === 1) {
            leftBrace = '{ ';
          }
          if (counter >= objLength) {
            rightBrace = ' }';
          }
        }
        this.set('hashHTMLData', hashHTMLData += leftBrace + this.formatHtml(tmpKey, 1) + this.formatHtml(tmpVal) + separator + rightBrace);
      }
      return this.hashHTMLData;
    },
    formatHtml(strVal, addTag) {
      var outputHTML = '<li>';
      if (addTag === true) {
        outputHTML += '<' + this.styleTag + '>';
        outputHTML += strVal;
        outputHTML += '</' + this.styleTag + '>';
        outputHTML += ': ';
      } else {
        outputHTML += strVal;
      }
      outputHTML += '</li>';
      return outputHTML;
    }
  });
  _exports.default = _default;
});