define("abcloud-ui/templates/components/transactions/transaction-apis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ir47SaHd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"padded_10\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card flat with_icon api\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"detail_title\"],[9],[0,\"APIs\"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"apis_length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"ul\"],[11,\"class\",\"api_list\"],[9],[0,\"\\n        \\n        \"],[7,\"li\"],[9],[1,[21,\"apis\"],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      n/a\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/transactions/transaction-apis.hbs"
    }
  });
  _exports.default = _default;
});