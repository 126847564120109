define("abcloud-ui/components/system-localbay/dplet-health-status-details", ["exports", "abcloud-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    overallPercent: 0,
    activeCtrlProcess: 0,
    degradedCtrlProcess: 0,
    totalCtrlProcess: 0,
    activeCtrlProcessPercent: 0,
    degradedCtrlProcessPercent: 0,
    activeDataProcess: 0,
    degradedDataProcess: 0,
    totalDataProcess: 0,
    activeDataProcessPercent: 0,
    degradedDataProcessPercent: 0,
    dpletHealthDetailsView: false,
    dpletHealthReported: true,
    didInsertElement() {
      var dplet = this.dplet.data;
      if (_environment.default.environment === "local_dev") {
        //-- If local DB does not have appropriate data to show then data is hard coded --
        /*
        if(dplet.health === null || $.isEmptyObject(dplet.health)){
            dplet={
                "health":{
                    "health_stats":{
                        "ctrlStatus":["Up", "Down", "Up", "Up", "Down", "Down", "Down"],
                        "dataStatus":["Down", "Down", "Down", "Down", "Down"],
                    }
                }
            }
        }
        */
      }
      try {
        var ctrlStatus = dplet.health.health_stats.ctrlStatus;
        var dataStatus = dplet.health.health_stats.dataStatus;
        var ctrlStatusLen = ctrlStatus.length;
        var dataStatusLen = dataStatus.length;
        var totalComponents = ctrlStatusLen + dataStatusLen;
        var activeCtrlProcess = 0,
          activeDataProcess = 0,
          degradedCtrlProcess = 0,
          degradedDataProcess = 0;
        ctrlStatus.forEach(component => {
          if (component === "Up") {
            activeCtrlProcess++;
          } else if (component === "Down") {
            degradedCtrlProcess++;
          }
        });
        dataStatus.forEach(component => {
          if (component === "Up") {
            activeDataProcess++;
          } else if (component === "Down") {
            degradedDataProcess++;
          }
        });
        var overallPercent = Math.round((activeCtrlProcess + activeDataProcess) / totalComponents * 100);
        var activeCtrlProcessPercent = Math.round(activeCtrlProcess / ctrlStatusLen * 100);
        var degradedCtrlProcessPercent = Math.round(degradedCtrlProcess / ctrlStatusLen * 100);
        var activeDataProcessPercent = Math.round(activeDataProcess / dataStatusLen * 100);
        var degradedDataProcessPercent = Math.round(degradedDataProcess / dataStatusLen * 100);
        this.setProperties({
          'activeCtrlProcess': activeCtrlProcess,
          'totalCtrlProcess': ctrlStatus.length,
          'degradedCtrlProcess': degradedCtrlProcess,
          'activeCtrlProcessPercent': activeCtrlProcessPercent,
          'degradedCtrlProcessPercent': degradedCtrlProcessPercent,
          'activeDataProcess': activeDataProcess,
          'degradedDataProcess': degradedDataProcess,
          'totalDataProcess': dataStatus.length,
          'activeDataProcessPercent': activeDataProcessPercent,
          'degradedDataProcessPercent': degradedDataProcessPercent
        });
        Ember.$('.myBar').css('width', overallPercent + '%');
      } catch (healthNull) {
        this.set('dpletHealthReported', false);
      }
    },
    actions: {
      toggleDpletHealthDetails(caret_id) {
        this.set('dpletHealthDetailsView', !this.dpletHealthDetailsView);
        if (this.dpletHealthDetailsView) {
          Ember.$('#dplet_card_' + caret_id + '').addClass('auto_height');
          Ember.$('#dplet_caret_' + caret_id + '').removeClass('fa-caret-right');
          Ember.$('#dplet_caret_' + caret_id + '').addClass('fa-caret-down');
          Ember.$('#' + caret_id + '').addClass('show_dplet_details');
        } else {
          Ember.$('#' + caret_id + '').removeClass('show_dplet_details');
          Ember.$('#dplet_caret_' + caret_id + '').removeClass('fa-caret-down');
          Ember.$('#dplet_caret_' + caret_id + '').addClass('fa-caret-right');
          Ember.$('#dplet_card_' + caret_id + '').removeClass('auto_height');
        }
      }
    }
  });
  _exports.default = _default;
});