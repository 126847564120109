define("abcloud-ui/controllers/system/hosted/localbay/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STEP_1 = 22.5,
    RESET = 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    encrypt: Ember.inject.service("encrypt"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      const defaultDisplay = "Kubernetes";
      const {
        type,
        orch
      } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[defaultDisplay];
      this.setProperties({
        instanceDisplayChosen: defaultDisplay,
        instanceTypeChosen: type,
        instanceOrchestrationChosen: orch,
        configLink: false,
        disableSave: false
      });
    },
    actions: {
      saveLocalbayConfiguration(localbayDetails) {
        this.setProperties({
          progressPercentComplete: STEP_1,
          indicatorTwoActive: true
        });
        var uuid = this.encrypt.generateUUID();
        var hashedPassphrase = this.encrypt.encryptString(localbayDetails.passphrase, uuid);
        this.replace_element_with.preloader(".save");
        var localbay = this.store.createRecord("localcomponent", {
          title: localbayDetails.title,
          description: localbayDetails.description,
          instance_type: localbayDetails.instanceType,
          instance_orch: localbayDetails.instanceOrch,
          hashed_passphrase: hashedPassphrase,
          uuid: uuid,
          install_mode: localbayDetails.installMode,
          component_type: "localbay",
          city: "",
          state: "",
          country: ""
        });
        this.set("disableSave", true);
        localbay.save().then(() => {
          this.set("disableSave", false);
          Ember.$(".action-area").hide();
          this.setProperties({
            configLink: localbay.config_link
          });
        }).catch(error => {
          this.set("disableSave", false);
          this.notifications.error(error.errors[0].detail, {
            autoClear: true,
            clearDuration: 2000
          });
        });
      },
      goBack() {
        this.setProperties({
          saveClicked: false,
          progressPercentComplete: RESET,
          indicatorTwoActive: false
        });
      },
      encryptPassphrase(string) {
        var enc = this.encrypt.encryptString(string);
        return enc;
      },
      formCancelTransition() {
        this.transitionToRoute("system.hosted.localbay");
      }
    }
  });
  _exports.default = _default;
});