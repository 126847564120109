define("abcloud-ui/controllers/system/hosted/localbay/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      toggleConfigPanel() {
        if (Ember.$('.config_panel').hasClass('collapsed')) {
          Ember.$('.config_panel').removeClass('collapsed');
          Ember.$('.config_options').addClass('expanded');
        } else {
          Ember.$('.config_panel').addClass('collapsed');
          Ember.$('.config_options').removeClass('expanded');
        }
      }
    }
  });
  _exports.default = _default;
});