define("abcloud-ui/controllers/insights/dashboard/security/dart", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    qp: Ember.inject.service('query-params'),
    observabilitySubMenuHidden: true,
    securitySubMenuHidden: false,
    secTabActive: true,
    observeTabActive: false,
    showPreloader: true,
    showAlertsRequestPreloader: true,
    showAlertsByNameRequestPreloader: true,
    showAlertsByPolicyNameRequestPreloader: true,
    showAlertsByUserRequestPreloader: true,
    showAlertsByActionRequestPreloader: true,
    showSummaryTransactionsRequestPreloader: true,
    showApisRequestPreloader: true,
    showSummaryTransactionsByTypeRequestPreloader: true,
    showSummaryEventsByApiPreloader: true,
    showSummaryEventsByClientIpPreloader: true,
    showSummaryEventsByDestIpPreloader: true,
    showSummaryEventsByDestPortPreloader: true,
    showSummaryRawEventsPreloader: true,
    showSummaryApiEventsByClientIpPreloader: true,
    showSummaryApiEventsByDestPortPreloader: true,
    showSummaryApiEventsByDestIpPreloader: true,
    showDartRequestPreloader: true,
    showDartDetectionsRequestPreloader: true,
    showAlertTotalPreloader: true,
    showDartTotalPreloader: true,
    showEventTotalPreloader: true,
    showTransactionTotalPreloader: true,
    showApiTotalPreloader: true,
    showDartCategoriesRequestPreloader: true,
    showDartUsersRequestPreloader: true,
    showDartPoliciesRequestPreloader: true,
    query_params: {}
  });
  _exports.default = _default;
});