define("abcloud-ui/templates/deployments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ElXC5ulr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row no-gutters\"],[9],[0,\"\\n\\n  \"],[1,[27,\"side-nav\",null,[[\"view\",\"isSuperAdmin\"],[\"deployments\",[23,[\"isSuperAdmin\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"center_stack\"],[9],[0,\"\\n    \"],[1,[27,\"outlet\",[\"deploymentsOutlet\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/deployments.hbs"
    }
  });
  _exports.default = _default;
});