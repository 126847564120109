define("abcloud-ui/templates/sso/oidc/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZOX3dIsI",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"notification-container\",null,[[\"position\"],[\"top-right\"]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"login_wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-7 d-flex justify-content-center align-items-center\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"auth-box\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row form-group\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"ssoError\"]],[23,[\"jwtVerifyError\"]]],null]],null,{\"statements\":[[0,\"\\n            \"],[1,[27,\"inline-notification-message\",null,[[\"status\",\"class\",\"message\",\"showNotification\"],[\"error\",\"col-12\",[27,\"if\",[[23,[\"ssoError\"]],[23,[\"ssoErrorDescription\"]],[27,\"if\",[[23,[\"jwtVerifyError\"]],[23,[\"jwtVerifyError\"]],\"\"],null]],null],[27,\"or\",[[23,[\"ssoError\"]],[23,[\"jwtVerifyError\"]]],null]]]],false],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"col-12 mt-3\"],[9],[0,\"\\n              \"],[7,\"button\"],[11,\"class\",\"btn-block btn primary\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"navigateToLogin\"],null]],[9],[0,\"\\n                Go to Login\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n              Logging you in...\\n            \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n        \"],[10],[0,\"\\n\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"col-5\"],[9],[0,\"\\n      \"],[1,[21,\"brand-panel\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/sso/oidc/auth.hbs"
    }
  });
  _exports.default = _default;
});