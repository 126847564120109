define("abcloud-ui/components/policies-tenant/policies-api-session-token/profile-validity-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    current_period_for_linked_profile: "Days",
    current_period: "Days",
    didInsertElement() {
      try {
        this.profiles_linked_to_this_microsensor.forEach((profile, index) => {
          this.debug.log(profile.data.attributes);
          // -- if disabledStatus it means it is published view on a view with both current draft version and published version.
          // -- On published view we are poppulating data from policy object
          if (this.disabledStatus) {
            document.getElementById("linked_profile_duration" + index + this.disabledStatus).value = profile.data.attributes.json.session_validity.split(" ")[0];
            document.getElementById("linked_profile_period" + index + this.disabledStatus).value = profile.data.attributes.json.session_validity.split(" ")[1];
          }
          // -- if disabledStatus is false it means it is current draft view. On published view we are poppulating data from policy object
          // -- as linked_to_policy key from profiles object reflects draft version profiles
          else {
            document.getElementById("linked_profile_duration" + index).value = profile.data.attributes.session_validity.split(" ")[0];

            // -- persisting the dropdown caret upon using innerHTML on the input element
            document.getElementById("linked_profile_period" + index).innerHTML = profile.data.attributes.session_validity.split(" ")[1];
          }
        });
      } catch (e) {
        this.debug.log("This is a new policy");
      }
    },
    actions: {
      create_profile_object(profile_id, index, element) {
        if (element.target.checked) {
          this.selected_profile_ids.add(profile_id);

          // -- enable duration selection
          document.getElementById("duration" + index).disabled = false;
          document.getElementById("period" + index).disabled = false;
        } else {
          this.selected_profile_ids.delete(profile_id);

          // -- disable duration selection
          document.getElementById("duration" + index).disabled = true;
          document.getElementById("period" + index).disabled = true;
        }
      },
      create_selected_profile_object(profile_id, index, element) {
        if (element.target.checked) {
          this.past_selected_profile_ids.add(profile_id);

          // -- enable duration selection
          document.getElementById("linked_profile_duration" + index).disabled = false;
          document.getElementById("linked_profile_period" + index).disabled = false;
        } else {
          // -- delete the validity_info_object upon unchecking the profile
          this.past_selected_profile_ids.delete(profile_id);

          // -- disable duration selection
          document.getElementById("linked_profile_duration" + index).disabled = true;
          document.getElementById("linked_profile_period" + index).disabled = true;
        }
      },
      choosePeriod(option_id, chosen_option, option_index, buttonId) {
        document.getElementById(buttonId).innerHTML = chosen_option;
      },
      choosePeriodForLinkedProfiles(option_id, chosen_option, option_index, buttonId) {
        document.getElementById(buttonId).innerHTML = chosen_option;
      }
    }
  });
  _exports.default = _default;
});