define("abcloud-ui/components/security-alerts/alerts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    didInsertElement() {},
    click() {
      (() => {
        Ember.$('#' + this.id + '').addClass('active').siblings().removeClass('active');
      })();
      this.getAlertDetails(this.alert.id, this.id);
    }
  });
  _exports.default = _default;
});