define("abcloud-ui/templates/components/no-records-found-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "j+dyZlxY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"second_filter\"]]],null,{\"statements\":[[0,\"  No \"],[1,[21,\"type\"],false],[0,\" found for \"],[1,[21,\"second_filter\"],false],[0,\" \"],[1,[27,\"if\",[[27,\"eq\",[[23,[\"type\"]],\"DART Detections\"],null],\"category\",\"severity\"],null],false],[0,\" within \"],[1,[27,\"val-for-key\",[[23,[\"constants\",\"TIME_SELECTION_MATCHER\"]],[23,[\"timeSelectedByUser\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    No \"],[1,[21,\"type\"],false],[0,\" found within \"],[1,[27,\"val-for-key\",[[23,[\"constants\",\"TIME_SELECTION_MATCHER\"]],[23,[\"timeSelectedByUser\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/no-records-found-text.hbs"
    }
  });
  _exports.default = _default;
});