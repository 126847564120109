define("abcloud-ui/templates/system/hosted/microsensor/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T63qRiXp",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/dplet-list\",null,[[\"localcomponents\",\"dplets\",\"deleteDplet\",\"editDplet\",\"createMicrosensor\",\"RESOLVE_DPLET_SUB_TYPE\"],[[23,[\"localcomponents\"]],[23,[\"dplets\"]],[27,\"action\",[[22,0,[]],\"deleteDplet\"],null],[27,\"action\",[[22,0,[]],\"editDplet\"],null],[27,\"action\",[[22,0,[]],\"createMicrosensor\"],null],[23,[\"constants\",\"RESOLVE_DPLET_SUB_TYPE\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/microsensor/index.hbs"
    }
  });
  _exports.default = _default;
});