define("abcloud-ui/templates/insights/dashboard/discovery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XZ2f5k+f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-21-24\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"pure-u-1\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"section_wrapper dashboard clearfix\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"control_area right pure-u-3-24\"],[9],[0,\"\\n          \"],[1,[27,\"ab-filter\",null,[[\"custom_icon\",\"options\",\"filter\",\"current_option\"],[\"fas fa-calendar\",[23,[\"constants\",\"TIME_SELECTION_MATCHER\"]],[27,\"action\",[[22,0,[]],\"selectTimeRange\"],null],[27,\"val-for-key\",[[23,[\"constants\",\"TIME_SELECTION_MATCHER\"]],[23,[\"timeSelectedByUser\"]]],null]]]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"pure-u-1-1 dash_cat_wrapper rel\"],[9],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"dashboard_data\"],[9],[0,\"\\n          \"],[1,[27,\"outlet\",[\"dashboard_data\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/insights/dashboard/discovery.hbs"
    }
  });
  _exports.default = _default;
});