define("abcloud-ui/services/ab-ajax-requests/manage-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // -- request_identifier is not unique and any or all the requests matching the request identifier will be aborted
  var _default = Ember.Service.extend({
    ajax_requests: [],
    add_request(request_identifier, request) {
      this.ajax_requests.push({
        [request_identifier]: request
      });
    },
    add_requests(requests) {
      requests.forEach(request => {
        for (var request_identifier in request) {
          this.ajax_requests.push({
            [request_identifier]: request[request_identifier]
          });
        }
      });
    },
    abort_request(request_identifier) {
      this.ajax_requests.forEach((request, index) => {
        if (request.hasOwnProperty(request_identifier)) {
          request[request_identifier].abort();
        }
      });
      this.ajax_requests;
    },
    abort_ember_request(request_identifier) {
      var charArray = request_identifier.split(".");
      var model = charArray[charArray.length - 1];
      this.ajax_requests.forEach((request, index) => {
        if (request.hasOwnProperty(model)) {
          request[model].abort();
        }
      });
    }
  });
  _exports.default = _default;
});