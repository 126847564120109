define("abcloud-ui/templates/components/transactions/timeline-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iylmIw1S",
    "block": "{\"symbols\":[],\"statements\":[[2,\" should be wrapped in a div \"],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"karat_shadow\"],[9],[0,\" \"],[10],[0,\"\\n  \"],[1,[21,\"timelineValue\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/transactions/timeline-marker.hbs"
    }
  });
  _exports.default = _default;
});