define("abcloud-ui/routes/manage/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    beforeModel() {
      if (!this.auth.isLoggedIn("adminUser")) {
        this.controllerFor("application").set("adminUserLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("/manage/users/login");
      }
    },
    async model() {
      await this.store.findAll("admin_user").sortBy("updated_at").reverse();
      return this.store.peekAll("admin_user");
    },
    setupController(controller, model) {
      const isResettingPassword = this.auth.getIsAdminUserResttingPwd();
      if (isResettingPassword) {
        window.location = this.constants.ADMIN_USER_RESET_PWD;
      }
      this._super(controller, model);
      let currentUserEmail = this.auth.getUserEmail();
      controller.set("model", model);
      controller.setProperties({
        currentUserEmail: currentUserEmail
      });
    },
    renderTemplate() {
      this.render("manage.users.list", {
        into: "application",
        outlet: "admin"
      });
    },
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.setProperties({
          currentUserEmail: null
        });
      }
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("manage.users.list");
        controller.set("isAdminUsersLoading", true);
        transition.promise.finally(function () {
          controller.set("isAdminUsersLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});