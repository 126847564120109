define("abcloud-ui/routes/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    beforeModel() {
      if (!this.auth.isLoggedIn('customer')) {
        this.controllerFor('application').set('customerLoggedIn', false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo('/customer/login');
      }
    }
  });
  _exports.default = _default;
});