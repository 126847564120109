define("abcloud-ui/templates/components/forms/delete-and-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8CJaUvAp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-5 col-sm-6 col-md-4 col-lg-3 \"],[9],[0,\"\\n    \"],[1,[27,\"policies-tenant/forms/cancel-button\",null,[[\"text\",\"click\"],[[23,[\"cancelButtonText\"]],[27,\"action\",[[22,0,[]],[23,[\"cancelAction\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"hidden-md-down col-md-3 col-lg-5 \"],[9],[0,\" \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6 col-sm-6 col-md-5 col-lg-4 \"],[9],[0,\"\\n    \"],[1,[27,\"forms/delete-button\",null,[[\"text\",\"click\"],[[23,[\"deleteButtonText\"]],[27,\"action\",[[22,0,[]],[23,[\"deleteAction\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/forms/delete-and-cancel.hbs"
    }
  });
  _exports.default = _default;
});