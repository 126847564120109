define("abcloud-ui/helpers/epoch-decimal-time-appender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.epochDecimalTimeAppender = epochDecimalTimeAppender;
  function epochDecimalTimeAppender(_ref) {
    let [epoch_time, epoch_decimal_time] = _ref;
    if (!epoch_time) {
      if (!epoch_decimal_time) {
        return '';
      } else return Number('0.' + epoch_decimal_time);
    }
    return Number(epoch_time + '.' + epoch_decimal_time);
  }
  var _default = Ember.Helper.helper(epochDecimalTimeAppender);
  _exports.default = _default;
});