define("abcloud-ui/templates/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C3CJzmSc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-3-24 sidenav\"],[9],[0,\"\\n\\n    \"],[7,\"ul\"],[11,\"class\",\"\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"reports.user-access-report\"],null,{\"statements\":[[0,\"User Access\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"reports.user-transaction-report\"],null,{\"statements\":[[0,\"User Transaction\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"        \\n    \"],[10],[0,\"\\n\\n    \"],[2,\"<div class=\\\"divider\\\"></div>\"],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-21-24 center_stack\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/reports.hbs"
    }
  });
  _exports.default = _default;
});