define("abcloud-ui/constants/toast-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TOAST_MESSAGES = void 0;
  const TOAST_MESSAGES = {
    UNPROCESSABLE_ENTITY: "Unprocessable Entity",
    UNAUTHORIZED_ACCESS: "Ajax authorization failed",
    REQUEST_REJECTED: "Request was rejected because user is not permitted to perform this operation.",
    SESSION_TIME_OUT: "Your session has timed out, please log in to continue",
    UNKNOWN_USERNAME_PWD: "Unknown username or password",
    EMAIL_ALREADY_EXISTS: "Email already exists.",
    SOMETHING_WENT_WRONG: "Sorry, something went wrong. Please try again.",
    SOMETHING_WENT_WRONG_WITH_DOWNLOAD: "Something went wrong with the download, please try again later.",
    EMAIL_OR_PASSWORD_MISSING: "Email or password is missing",
    SUBDOMAIN_NOT_AVAILABLE: "Tenant subdomain has already been taken",
    SETTING_UP_TENANT: "Please wait, setting up database, tenant, and accounts. This may take a while."
  };
  _exports.TOAST_MESSAGES = TOAST_MESSAGES;
});