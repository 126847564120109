define("abcloud-ui/templates/system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3g4rAhHv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row no-gutters\"],[9],[0,\"\\n  \"],[1,[27,\"side-nav\",null,[[\"view\",\"isSuperAdmin\"],[\"system\",[23,[\"isSuperAdmin\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"addLocalbayForm center_stack\"],[9],[0,\"\\n    \"],[1,[27,\"outlet\",[\"localbayconfiguration\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[27,\"outlet\",[\"localbayDetails\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system.hbs"
    }
  });
  _exports.default = _default;
});