define("abcloud-ui/routes/exceptions/events/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    geo_map_src: null,
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    keys_to_show_in_raw_json: ['event_json'],
    model(params) {
      let event = this.store.findRecord('event', params.event_id);
      return event;
    },
    setupController(controller, model) {
      let downloadLink = null;
      if (model.csv_file && model.csv_file.file_path) {
        downloadLink = model.data.csv_file.file_path;
      }
      if (Ember.$.isEmptyObject(model.data.event_json)) {
        controller.set('empty_tx_json', true);
      }
      controller.setProperties({
        'isFilterTypeApi': this.controllerFor('exceptions.events').get('is_api'),
        'model': model,
        'model_string': JSON.stringify(model, undefined, 10),
        'structured_data_for_raw_json': model.data.event_json,
        'displayPane': true,
        'downloadLink': downloadLink
      });
    },
    resetController(controller, isExiting, transition) {
      //this.get('manage_requests').abort_ember_request( 'events');
      controller.setProperties({
        'displayPane': false,
        'firstEvent': 0,
        'localcomponent': null,
        'dplet': null
      });
    },
    renderTemplate() {
      this.render('exceptions.events.event', {
        into: 'exceptions.events',
        outlet: 'event'
      });
    }
  });
  _exports.default = _default;
});