define("abcloud-ui/components/system-localbay/confirm-unlinked-localbay-deletion", ["exports", "abcloud-ui/components/base/div-row-component"], function (_exports, _divRowComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _divRowComponent.default.extend({
    layoutName: 'components/base/div-row-component'
  });
  _exports.default = _default;
});