define("abcloud-ui/constants/form-validation-texts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FORM_VALIDATION_TEXTS = void 0;
  const FORM_VALIDATION_TEXTS = {
    INVALID_ISSUER_URL_ERROR: "Enter a valid URL",
    ONLY_HTTPS_ALLOWED_MESSAGE: "Only https URLs are accepted",
    DEFAULT_SSO_ERROR: "Login Error: You are not authorized to access this console. Please contact IT support team for more information.",
    DELETE_NOTIFICATION: "Please delete the file first to choose an alternate option",
    VALIDATION_INCOMPLETE: "Please validate the file first to complete the process",
    DPLET_TITLE_TAKEN: "MicroSensor Name has already been taken",
    FILE_VALID: "File validation successfull",
    FILE_INVALID: "File size too large or too many number of columns",
    MANDATORY_FIELD: "This is a Mandatory Field",
    SELECT_FILE: "Please select a file first",
    AT_LEAST_ONE_OPTION: "Please select at least one option. If selected, please fill in the respective inputs",
    AT_LEAST_ONE_PROFILE: "Please choose atleast one profile",
    VALID_POSITIVE_NUMBER: "Value should be >= 0",
    VALID_PORT_NUMBER: "Port value should be positive and in between 0-65535",
    MINIMUM_REQUIREMENTS: "Entered value must meet the requirements.",
    PASSWORD_NOT_MET_REQUIREMENTS: "Password does not meet the requirements",
    EMAIL_NOT_MET_REQUIREMENTS: "Email does not meet the requirements",
    PASSWORDS_DO_NOT_MATCH: "Passwords do not match",
    PASSPHRASE_REQUIREMENTS: "Passphrase must be between 16 and 32 characters in length",
    INVALID_ISSUER_URL: "Issuer URL must begin with https://",
    EMPTY_ISSUER_URL: "https:// must be followed by a valid URL",
    PASSWORDS_NOT_SAME: "Current and new passwords may not be same",
    INVALID_NAME: "Name can not contain numbers or special characters except '-'",
    INPUT_TOO_LONG: _ref => {
      let {
        fControlLabel,
        maxLen
      } = _ref;
      return `${fControlLabel} can not exceed ${maxLen} characters`;
    }
  };
  _exports.FORM_VALIDATION_TEXTS = FORM_VALIDATION_TEXTS;
});