define("abcloud-ui/templates/system/hosted/localbay/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qUjCZv8i",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"divContainer\"],[11,\"class\",\"pure-u-24-24\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"wrapper_container\"],[9],[0,\"\\n      \"],[1,[27,\"system-localbay/localbay-create-form\",null,[[\"instanceDisplayChosen\",\"instanceTypeChosen\",\"instanceOrchestrationChosen\",\"saveLocalbayConfiguration\",\"cancel\",\"localbayDetails\",\"disableSave\"],[[23,[\"instanceDisplayChosen\"]],[23,[\"instanceTypeChosen\"]],[23,[\"instanceOrchestrationChosen\"]],[27,\"action\",[[22,0,[]],\"saveLocalbayConfiguration\"],null],[27,\"action\",[[22,0,[]],\"formCancelTransition\"],null],[23,[\"preRequestLocalbayDetails\"]],[23,[\"disableSave\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"configLink\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"pure-g wrapper_container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"wrapper_container\"],[9],[0,\"\\n          \"],[1,[27,\"copy-to-clipboard\",null,[[\"inputValue\",\"class\"],[[23,[\"configLink\"]],\"pure-u-19-24 mt-3\"]]],false],[0,\"\\n\\n          \"],[7,\"div\"],[11,\"class\",\"pure-u-19-24 mt-3\"],[9],[0,\"Next Steps:\"],[10],[0,\"\\n          \"],[7,\"pre\"],[11,\"class\",\"pure-u-18-24 install-instructions p-4\"],[9],[0,\"            \"],[1,[21,\"system-localbay/localbay/installation-instructions\"],false],[0,\"          \"],[10],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/localbay/add.hbs"
    }
  });
  _exports.default = _default;
});