define("abcloud-ui/templates/components/brand-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "03Pn8kJj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"brand-panel\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"panel-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"brand-panel__logo\"],[9],[0,\" \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"text--h2 my-3\"],[9],[0,\"Observability and Security for Your APIs\"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"text--h2 text--label\"],[9],[0,\"\\n      Deep API Inspection for real-time observability and security of your APIs\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/brand-panel.hbs"
    }
  });
  _exports.default = _default;
});