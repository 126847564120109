define("abcloud-ui/routes/policies/api-recorder/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service('ajax'),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          "Access-Control-Allow-Origin": "*",
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      var request = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES('api_recorder'));
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      try {
        if (Ember.$.isEmptyObject(model.data.attributes.localbays)) {
          this.transitionTo('policies.api-recorder');
        } else {
          var localbaysList = model.data.attributes.localbays.data;
          var can_publish = model.data.attributes['can_publish?'];
          controller.setProperties({
            'localbayList': localbaysList,
            'currentLocalbay': localbaysList[0].title,
            'currentLocalbayId': localbaysList[0].id,
            'currentMicrosensorId': localbaysList[0].dplets.data[0].id,
            'currentMicrosensor': localbaysList[0].dplets.data[0].name,
            'microsensorList': localbaysList[0].dplets.data,
            'can_publish': can_publish,
            'recordOnlyApis': true
          });
        }
      } catch (dataInitiationGoneWrong) {
        this.debug.log('Something went wrong with data initiation', dataInitiationGoneWrong);
        this.transitionTo('policies.api-recorder');
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          'currentLocalbay': '',
          'currentLocalbayId': '',
          'localbayList': '',
          'currentMicrosensor': '',
          'currentMicrosensorId': '',
          'microsensorList': '',
          'recordOnlyApis': true
        });
      }
    }
  });
  _exports.default = _default;
});