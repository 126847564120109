define("abcloud-ui/templates/components/transactions/duration-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dN7YT6od",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"duration\"],[9],[0,\"Duration: \"],[1,[21,\"duration\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/transactions/duration-display.hbs"
    }
  });
  _exports.default = _default;
});