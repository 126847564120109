define("abcloud-ui/helpers/sum-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sumValues = sumValues;
  function sumValues(_ref) {
    let [num1, num2] = _ref;
    // -- check if each argument exists and if it is a number after applying parseFloat
    //  -- example: "null", parseFloat("null") = NaN
    if (num1 && !isNaN(parseFloat(num1))) {
      if (num2 && !isNaN(parseFloat(num2))) {
        return parseFloat(parseFloat(num1) + parseFloat(num2));
      }
      return parseFloat(num1);
    } else {
      if (num2 && !isNaN(parseFloat(num2))) {
        return parseFloat(num2);
      } else {
        return '';
      }
    }
  }
  var _default = Ember.Helper.helper(sumValues);
  _exports.default = _default;
});