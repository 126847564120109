define("abcloud-ui/templates/components/nav-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BsFc3o4v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"logo\"],[9],[0,\"\\n\\t\"],[7,\"a\"],[11,\"href\",\"/\"],[9],[0,\"\\n\\t  \"],[7,\"img\"],[11,\"src\",\"/assets/images/imperva-logo.svg\"],[9],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/nav-logo.hbs"
    }
  });
  _exports.default = _default;
});