define("abcloud-ui/components/exceptions/refresh-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    refreshButton: null,
    refreshButtonId: 'theRefresher',
    autoMode: false,
    isUpdating: false,
    // click() {
    //   if(this.submit()) {
    //     this.submit();
    //   }
    // },
    didReceiveAttrs() {
      this._super(...arguments);
    },
    didInsertElement() {
      let self = this;
      this.set('refreshButton', Ember.$('#' + this.refreshButtonId + ''));
      this.refreshButton.on('click', function () {
        self.toggleCss(true);
        self.submit();
      });
    },
    didUpdate() {
      this.toggleCss(false);
    },
    // didRender(){this.toggleCss(false);},
    willDestroyElement() {
      this.set('autoMode', false);
      this.set('refreshButton', null);
      this.set('isUpdating', false);
    },
    toggleCss(bool) {
      this.set('isUpdating', bool);
    },
    actions: {
      refreshCollection(cModel) {
        this.sendAction('refreshCollection', cModel);
      }
    }
  });
  _exports.default = _default;
});