define("abcloud-ui/templates/components/buttons/radio-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RnZSCWAN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[11,\"class\",\"bx--radio-button\"],[12,\"name\",[21,\"uniqueName\"]],[12,\"id\",[21,\"uniqueName\"]],[12,\"value\",[21,\"value\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"onClick\"]]],null]],[12,\"checked\",[21,\"checked\"]],[12,\"disabled\",[21,\"disabled\"]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\"],[7,\"label\"],[12,\"for\",[21,\"uniqueName\"]],[11,\"class\",\"bx--radio-button__label\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"bx--radio-button__appearance\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/buttons/radio-btn.hbs"
    }
  });
  _exports.default = _default;
});