define("abcloud-ui/templates/components/policies-tenant/policies-helper-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sZuJe6vF",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[7,\"div\"],[11,\"class\",\"policies_helper_text\"],[9],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[1,[21,\"helper_info_1\"],false],[10],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"cursor_pointer\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleJsonView\"],null]],[9],[7,\"i\"],[11,\"id\",\"expandable_div_caret\"],[11,\"class\",\"fa fa-caret-right\"],[9],[10],[0,\"REST API Information\"],[10],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"policies_expandable_json_div\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"tx_json_raw policies_json\"],[9],[0,\"\\n                \"],[1,[21,\"helper_info_2\"],false],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"details_json\"]]],null]],null,{\"statements\":[[0,\"                    \"],[7,\"span\"],[11,\"class\",\"json-key\"],[9],[1,[22,2,[]],false],[10],[0,\":\"],[7,\"span\"],[11,\"class\",\"json-string\"],[9],[1,[22,1,[]],false],[10],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"            \"],[10],[0,\"\\n            \"],[1,[27,\"style-raw-json\",null,[[\"component_id\",\"raw_json\",\"customClass\"],[\"2\",[23,[\"json_format\"]],\"policies_json auto_json\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/policies-tenant/policies-helper-text.hbs"
    }
  });
  _exports.default = _default;
});