define("abcloud-ui/components/policies-tenant/policy-components/edit-api-parameter-pin-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    severityValuesInSet: new Set(['High', 'Medium']),
    didInsertElement() {
      try {
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabledStatus) {
              Ember.$('#' + label_id + this.disabledStatus + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + label_id + '').addClass('draft_label_changed');
            }
          });
        }
      } catch (newPolicy) {
        this.debug.log('This is a new API Parameter Pinning Policy', newPolicy);
      }
    },
    actions: {
      savePolicy(action) {
        this.savePolicy(action);
      },
      cancel() {
        this.cancel();
      },
      chooseLocalbay(localcomponentId, localcomponent) {
        this.localbay_list.forEach(localbayObj => {
          if (localcomponentId === localbayObj.id) {
            this.setProperties({
              'microsensor_list': localbayObj.dplets.data,
              'current_localbay_id': localcomponentId,
              'current_localbay': localcomponent,
              'current_microsensor_id': localbayObj.dplets.data[0].id,
              'current_microsensor': localbayObj.dplets.data[0].name
            });
            Ember.$.ajaxSetup({
              headers: {
                "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
                'X-CV-Trace-ID': this.urls.generate_trace_id()
              }
            });
            var url = this.urls.URL_PROFILES_FOR_MICROSENSOR('api_parameter_pin', localbayObj.dplets.data[0].id);
            if (this.view === "edit") {
              url = this.urls.URL_PROFILES_FOR_POLICY_MICROSENSOR(this.policy_data.policy_id, localbayObj.dplets.data[0].id);
            }
            this.ajax.request(url, {
              method: 'GET'
            }).then(response => {
              var past_selected_profile_ids = new Set();
              response.data.attributes.profiles.linked_to_policy.data.forEach(out_of_spec_profile => {
                past_selected_profile_ids.add(out_of_spec_profile.data.id);
              });
              this.setProperties({
                'api_parameter_pin_profiles': response.data.attributes.profiles.available.data,
                'linked_profiles': response.data.attributes.profiles.linked.data,
                'profiles_linked_to_this_microsensor': response.data.attributes.profiles.linked_to_policy.data,
                'selected_profile_ids': new Set(),
                'past_selected_profile_ids': past_selected_profile_ids
              });
            });
          }
        });
      },
      chooseMicroSensor(id, chosenOption) {
        Ember.$.ajaxSetup({
          headers: {
            "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
            'X-CV-Trace-ID': this.urls.generate_trace_id()
          }
        });
        var url = this.urls.URL_PROFILES_FOR_MICROSENSOR('api_parameter_pin', id);
        if (this.view === "edit") {
          url = this.urls.URL_PROFILES_FOR_POLICY_MICROSENSOR(this.policy_data.policy_id, id);
        }
        this.ajax.request(url, {
          method: 'GET'
        }).then(response => {
          var past_selected_profile_ids = new Set();
          response.data.attributes.profiles.linked_to_policy.data.forEach(outOfSpecProfile => {
            past_selected_profile_ids.add(outOfSpecProfile.data.id);
          });
          this.setProperties({
            'api_parameter_pin_profiles': response.data.attributes.profiles.available.data,
            'linked_profiles': response.data.attributes.profiles.linked.data,
            'profiles_linked_to_this_microsensor': response.data.attributes.profiles.linked_to_policy.data,
            'selected_profile_ids': new Set(),
            'past_selected_profile_ids': past_selected_profile_ids
          });
        });
        this.setProperties({
          'current_microsensor_id': id,
          'current_microsensor': chosenOption
        });
      },
      chooseSessionTokenProfile(id, profile_name) {
        this.chooseSessionTokenProfile(id, profile_name);
      },
      chooseSessionTerminationProfile(id, profile_name) {
        this.chooseSessionTerminationProfile(id, profile_name);
      }
    }
  });
  _exports.default = _default;
});