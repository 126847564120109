define("abcloud-ui/templates/policies/profiles/out-of-spec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OlwCliS6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"policy_card_view\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-8\"],[9],[0,\"\\n        API Specifications Profile\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-4\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"policies-tenant/profile-components/edit-out-of-spec\",null,[[\"profile\",\"api_recordings_list\",\"current_api_recording\",\"current_api_recording_id\",\"current_spec_info_selection\",\"isFileUploaded\",\"saveOutOfSpec\",\"transitionToListView\",\"setFileUpload\",\"confirmPublish\",\"disabled\",\"profile_id\",\"error\",\"errorMessage\",\"redirected_from_api_recorder_view\"],[[23,[\"model\"]],[23,[\"api_recordings_list\"]],[23,[\"current_api_recording\"]],[23,[\"current_api_recording_id\"]],[23,[\"current_spec_info_selection\"]],[23,[\"isFileUploaded\"]],[27,\"action\",[[22,0,[]],\"saveOutOfSpec\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"setFileUpload\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],\"\",[23,[\"profile_id\"]],[23,[\"file_error\"]],[23,[\"errorMessage\"]],[23,[\"redirected_from_api_recorder_view\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/out-of-spec.hbs"
    }
  });
  _exports.default = _default;
});