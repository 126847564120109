define("abcloud-ui/components/exceptions/events/microsensor-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    dplet_id: null,
    fetchDetails(id) {
      // -- DPLET DETAILS
      var dpletRequest = Ember.RSVP.hash({
        req: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-CV-Trace-ID": this.urls.generate_trace_id()
          });
          var dpletRequest = Ember.$.getJSON(this.urls.URL_GET_DPLET_DISPLAY_DETAILS(id));
          this.manage_requests.add_request(this.routeName, dpletRequest);
          resolve(dpletRequest);
        })
      });
      dpletRequest.then(() => {
        // -- ._result.dart => reference to Promise above
        var dplet = dpletRequest._result.req;
        if (dplet && dplet.dplet) {
          this.set('dplet', dplet.dplet);
        }
      });
    },
    didReceiveAttrs() {
      this.fetchDetails(this.get('dplet_id'));
    },
    didUpdateAttrs() {
      this.fetchDetails(this.get('dplet_id'));
    },
    willDestroyElement() {
      this.set('dplet_id', null);
    },
    actions: {}
  });
  _exports.default = _default;
});