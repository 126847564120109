define("abcloud-ui/pods/application/controller", ["exports", "change-case"], function (_exports, _changeCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    notifications: Ember.inject.service(),
    qp: Ember.inject.service("query-params"),
    urls: Ember.inject.service("urls"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    customerLoggedIn: null,
    adminLoggedIn: null,
    adminUserLoggedIn: null,
    customerEmail: "",
    createUserAbility: "",
    customerFirstLogIn: null,
    customerPasswordWasReset: null,
    userId: "",
    userRole: "",
    init() {
      this._super(...arguments);
      this.setProperties({
        adminLoggedIn: this.auth.isLoggedIn("admin"),
        adminUserLoggedIn: this.auth.isLoggedIn("adminUser"),
        createUserAbility: this.auth.getTenantCreateUserAbility(),
        customerLoggedIn: this.auth.isLoggedIn("customer"),
        customerEmail: this.auth.getUserEmail(),
        customerFirstLogIn: this.auth.getTenantIsFirstLogin(),
        customerPasswordWasReset: this.auth.getTenantPasswordWasReset(),
        customerTenantLoginType: this.auth.getTenantLoginType(),
        userId: this.auth.getUserId(),
        userRole: this.auth.getUserRole(),
        isSuperAdmin: this.auth.getIsSuperAdmin(),
        isResettingPassword: this.auth.getIsAdminUserResttingPwd()
      });
    },
    actions: {
      checkSession(role, action) {
        if (action === "logout") {
          const logoutUrl = this.urls.URL_LOG_OUT();
          const data = {
            role: (0, _changeCase.snakeCase)(role),
            id: this.auth.getUserId()
          };
          this.server.request("DELETE", logoutUrl, data, response => {
            if (response.meta && response.meta.errors) {
              this.notifications.error(this.constants.SOMETHING_WENT_WRONG);
            } else {
              this.auth.logout(role);
            }
          }, error => {
            this.notifications.error(this.constants.SOMETHING_WENT_WRONG);
          });
        } else {
          if (!this.auth.isLoggedIn(role)) {
            this.auth.didUserSessionTimeOut(true);
            if (role === "customer") {
              this.set("customerLoggedIn", false);
              this.transitionToRoute("/customer/login");
            } else if (role === "adminUser") {
              this.set("adminUserLoggedIn", false);
              this.transitionToRoute("/manage/users/login");
            } else {
              this.set("adminLoggedIn", false);
              this.transitionToRoute("/admin/login");
            }
          }
        }
      },
      transitionTo() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        args.pop();
        this.transitionToRoute.apply(this, args);
      },
      resetPassword() {
        const url = this.urls.RESET_ADMIN_USER_PASSWORD(this.auth.getUserId());
        this.server.request("POST", url, null, response => {
          this.notifications.success(this.constants.ADMIN_PASSWORD_RESET_SUCCESSFULLY);
          this.auth.logout(this.constants.adminUser);
        }, error => {
          this.notifications.error(this.constants.SOMETHING_WENT_WRONG);
        });
      }
    }
  });
  _exports.default = _default;
});