define("abcloud-ui/controllers/policies/policies/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    errorMessage: "",
    init() {
      this._super(...arguments);
      this.setProperties({
        policy_id: "",
        current_profile: "",
        current_profile_id: "",
        current_localbay: "",
        current_localbay_id: "",
        localbay_list: "",
        sendToAbCloud: false,
        isExportProfileChosen: false,
        current_microsensor: "",
        current_microsensor_id: "",
        microsensor_list: "",
        apiSessionTokenProfilesList: "",
        sessionTerminationProfilesList: "",
        out_of_spec_profiles: {},
        selected_profile_ids: new Set(),
        chosenSeverityLevels: new Set(),
        publishedChosenSeverityLevels: new Set(),
        past_selected_profile_ids: new Set(),
        severity_level_responses: {},
        api_path_severity: "High",
        api_parameter_severity: "High",
        session_token_violated: "High",
        session_token_not_present: "High",
        buildPolicyObjectForServer: this.services.build_policy_object_for_server,
        buildProfileObjectForServer: this.services.build_profile_object_for_server
      });
    },
    getValueOfElementById(element_id) {
      return document.getElementById(element_id).value;
    },
    actions: {
      toggleDiffViewVisibility(viewRequest) {
        if (viewRequest === "hide") {
          var widthToHide = "-" + Ember.$(".diff_stack").css("width");
          Ember.$(".nav_stack").css("margin-left", widthToHide);
          Ember.$(".diff_stack_toggle_caret").css("visibility", "visible");
        } else if (viewRequest === "show") {
          Ember.$(".nav_stack").css("margin-left", "0");
          Ember.$(".diff_stack").css("display", "inline-block");
          Ember.$(".diff_stack_toggle_caret").css("visibility", "hidden");
        }
      },
      savedataExportPolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var data_options = {};
        var data_export_profile = {};
        if (document.getElementById("exportOriginal").checked) {
          data_options["export_original_data_events"] = "true";
        } else if (document.getElementById("exportObfuscated").checked) {
          data_options["export_obfuscated_data_events"] = "true";
        } else if (document.getElementById("exportHeadersOnly").checked) {
          data_options["export_data_events_headers_only"] = "true";
        }
        this.profiles_list.forEach(profile => {
          if (Number(profile.data.id) === Number(this.current_profile_id)) {
            profile = profile.data.attributes;
            if (Ember.$.isEmptyObject(profile.profile_data)) {
              profile.profile_data = [];
              profile.profile_data[0] = {};
              profile.profile_data[0]["json"] = {};
              profile.profile_data[0]["json"]["api"] = "";
              profile.profile_data[0]["json"]["auth_token"] = "";
              profile.profile_data[0]["json"]["receiver_type"] = "";
              profile.profile_data[0]["json"]["source_type"] = "";
            }
            data_export_profile = {
              data: {
                id: profile.profile_data[0].profile_id,
                type: "profile",
                attributes: {
                  sub_type: profile.sub_type,
                  profile_type: profile.profile_type,
                  name: profile.name,
                  description: profile.description,
                  json: {
                    data_receiver_information: profile.profile_data[0].json.data_receiver_information
                  }
                }
              }
            };
          }
        });
        var profiles_ids = [Number(this.current_profile_id)];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: "",
          name: ""
        };
        var changeset = {
          "nameLabel.Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Installed_on": this.current_localbay,
          "dataOptionsLabel.Data_Options.Export_Original_Data_Events": document.getElementById("exportOriginal").checked ? "true" : "--Unchecked--",
          "dataOptionsLabel.Data_Options.Export_Obfuscated_Data_Events": document.getElementById("exportObfuscated").checked ? "true" : "--Unchecked--",
          "dataOptionsLabel.Data_Options.Export_Data_Events_Headers_Only": document.getElementById("exportHeadersOnly").checked ? "true" : "--Unchecked--",
          "exportOptionsLabel.Export_To_Profile": data_export_profile.data.attributes.name
        };
        var json_object = {
          data_options: data_options,
          data_export_profile: data_export_profile
        };
        this.services.build_policy_object_for_server.set_type("policy", "data_export");
        this.services.build_policy_object_for_server.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.services.build_policy_object_for_server.set_changeset(changeset);
        this.services.build_policy_object_for_server.set_json(json_object);
        var request_object = this.services.build_policy_object_for_server.get_request_object();
        if (name && description) {
          var url = this.services.urls.URL_POLICY(this.policy_id);
          this.services.publish.policy(action, "PUT", url, request_object, response => {
            if (response.errors) {
              this.set("errorMessage", response.errors[0].detail);
            } else {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      saveOutOfSpecPolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var detected_actions_severity_object = {};
        var coma_seperated_chosen_profile_names = "";
        var out_of_spec_profiles_array = [];
        this.profiles_linked_to_this_microsensor.forEach(out_of_spec_profile => {
          if (this.past_selected_profile_ids.has(out_of_spec_profile.data.id)) {
            var out_of_spec_profile_object = {
              data: {
                id: out_of_spec_profile.data.id,
                type: "profile",
                attributes: {
                  name: out_of_spec_profile.data.attributes.profile_data[0].name,
                  description: out_of_spec_profile.data.attributes.profile_data[0].description,
                  sub_type: out_of_spec_profile.data.attributes.sub_type,
                  json: {
                    method: out_of_spec_profile.data.attributes.profile_data[0].json.method,
                    spec_info: {
                      file: {}
                    }
                  }
                }
              }
            };
            if (out_of_spec_profile.data.attributes.profile_data[0].json.method === "file_upload") {
              out_of_spec_profile_object.data.attributes.json.spec_info.file = {
                id: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_id,
                name: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_name,
                size: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_size,
                type: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_type
              };
            } else if (out_of_spec_profile.data.attributes.profile_data[0].json.method === "open_api") {
              out_of_spec_profile_object.data.attributes.json.spec_info.file = {
                id: out_of_spec_profile.data.attributes.profile_data[0].json.spec_info.api_recording.id,
                name: out_of_spec_profile.data.attributes.profile_data[0].json.spec_info.api_recording.name
              };
            }
            coma_seperated_chosen_profile_names += out_of_spec_profile.data.attributes.name + ", ";
            out_of_spec_profiles_array.push(out_of_spec_profile_object);
          }
        });
        this.profiles_list.forEach(out_of_spec_profile => {
          if (this.selected_profile_ids.has(out_of_spec_profile.data.id)) {
            var out_of_spec_profile_object = {
              data: {
                id: out_of_spec_profile.data.id,
                type: "profile",
                attributes: {
                  name: out_of_spec_profile.data.attributes.profile_data[0].name,
                  description: out_of_spec_profile.data.attributes.profile_data[0].description,
                  sub_type: out_of_spec_profile.data.attributes.sub_type,
                  json: {
                    method: out_of_spec_profile.data.attributes.profile_data[0].json.method,
                    spec_info: {
                      file: {}
                    }
                  }
                }
              }
            };
            if (out_of_spec_profile.data.attributes.profile_data[0].json.method === "file_upload") {
              out_of_spec_profile_object.data.attributes.json.spec_info.file = {
                id: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_id,
                name: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_name,
                size: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_size,
                type: out_of_spec_profile.data.attributes.profile_data[0].spec_file.file_type
              };
            } else if (out_of_spec_profile.data.attributes.profile_data[0].json.method === "open_api") {
              out_of_spec_profile_object.data.attributes.json.spec_info.file = {
                id: out_of_spec_profile.data.attributes.profile_data[0].json.spec_info.api_recording.id,
                name: out_of_spec_profile.data.attributes.profile_data[0].json.spec_info.api_recording.name
              };
            }
            out_of_spec_profiles_array.push(out_of_spec_profile_object);
            coma_seperated_chosen_profile_names += out_of_spec_profile.data.attributes.name + ", ";
          }
        });
        coma_seperated_chosen_profile_names = coma_seperated_chosen_profile_names.slice(0, coma_seperated_chosen_profile_names.length - 2);
        if (document.getElementById("apiPath").checked) {
          detected_actions_severity_object["detect_for_non_matching_api_paths"] = this.api_path_severity.toLowerCase();
        }
        if (document.getElementById("apiParameter").checked) {
          detected_actions_severity_object["detect_for_non_matching_api_params"] = this.api_parameter_severity.toLowerCase();
        }
        if (document.getElementById("apiParameterDataType").checked) {
          detected_actions_severity_object["detect_for_non_matching_api_param_data_type"] = this.api_parameter_data_type_severity.toLowerCase();
        }
        var getSeverityInfoForDetectionType = detectionType => {
          if (detected_actions_severity_object[detectionType]) {
            return detected_actions_severity_object[detectionType];
          } else {
            return "--Unchecked--";
          }
        };
        const {
          sessionTokenProfileIds,
          sessionTerminationProfileIds
        } = this.buildPolicyObjectForServer.getActionProfileIds(this.severity_level_responses);
        const actions_api_session_token_profiles = this.buildProfileObjectForServer.whichIncludesSpecificIds(sessionTokenProfileIds, this.apiSessionTokenProfilesList, action);
        const actions_session_termination_profiles = this.buildProfileObjectForServer.whichIncludesSpecificIds(sessionTerminationProfileIds, this.sessionTerminationProfilesList, action);
        var profiles_ids = [...new Set([...this.selected_profile_ids, ...this.past_selected_profile_ids]), ...sessionTerminationProfileIds, ...sessionTokenProfileIds];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: this.current_microsensor_id,
          name: this.current_microsensor
        };
        var changeset = {
          "nameLabel.Profile_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.current_localbay,
          "dpletLabel.Enforcement_Information.MicroSensor": this.current_microsensor,
          "httpHeaderLabel.Enforcement_Information.HTTP_Header": this.getValueOfElementById("http_header"),
          "enforcementApiParameterLabel.Enforcement_Information.API_Parameter": this.getValueOfElementById("api_parameter"),
          "apiNetworkTrafficLabel.Enforcement_Information.Src_IP_CIDR": this.getValueOfElementById("src_ip_cidr"),
          "apiNetworkTrafficLabel.Enforcement_Information.Dest_IP_CIDR": this.getValueOfElementById("dest_ip_cidr"),
          "apiNetworkTrafficLabel.Enforcement_Information.Dest_IP_Port": this.getValueOfElementById("dest_ip_port"),
          "profileLabel.Profile_Information.Profiles": coma_seperated_chosen_profile_names,
          "apiPathLabel.Detection_Information.Detect_for_API_paths_that_are_not_part_of_existing_API_Specification": getSeverityInfoForDetectionType("detect_for_non_matching_api_paths"),
          "apiParameterLabel.Detection_Information.Detect_for_API_parameters_that_are_not_part_of_existing_API_Specification": getSeverityInfoForDetectionType("detect_for_non_matching_api_params"),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Critical": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.High": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Medium": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Low": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Audit": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Critical": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.High": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Medium": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Low": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Audit": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "invalidate_session", this.severity_level_responses)
        };
        var json_object = {
          enforcement_information: {
            dplet_id: this.current_microsensor_id,
            http_header: this.getValueOfElementById("http_header"),
            api_parameter: this.getValueOfElementById("api_parameter"),
            traffic: {
              src_ip: this.getValueOfElementById("src_ip_cidr"),
              dst_ip: this.getValueOfElementById("dest_ip_cidr"),
              dst_port: this.getValueOfElementById("dest_ip_port")
            }
          },
          detected_actions_severity: detected_actions_severity_object,
          severity_level_responses: this.severity_level_responses,
          out_of_spec_api_profiles: out_of_spec_profiles_array,
          actions_api_session_token_profiles,
          actions_session_termination_profiles
        };
        this.buildPolicyObjectForServer.set_type("policy", "out_of_spec_api");
        this.buildPolicyObjectForServer.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.buildPolicyObjectForServer.set_changeset(changeset);
        this.buildPolicyObjectForServer.set_json(json_object);
        var request_object = this.buildPolicyObjectForServer.get_request_object();
        if (name && description && [...this.selected_profile_ids, ...this.past_selected_profile_ids].length > 0) {
          var url = this.services.urls.URL_POLICY(this.policy_id);
          this.services.publish.policy(action, "PUT", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            profileFilledValue: [...this.selected_profile_ids, ...this.past_selected_profile_ids].length > 0
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, "Please choose atleast one profile");
        }
      },
      saveApiParameterPinningPolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var detected_actions_severity_object = {};
        var coma_seperated_chosen_profile_names = "";
        var api_parameter_pin_profiles_array = [];
        this.profiles_linked_to_this_microsensor.forEach(api_parameter_pin_profile => {
          if (this.past_selected_profile_ids.has(api_parameter_pin_profile.data.id)) {
            var api_parameter_pin_profile_object = {
              data: {
                id: api_parameter_pin_profile.data.id,
                type: "profile",
                attributes: {
                  name: api_parameter_pin_profile.data.attributes.profile_data[0].name,
                  description: api_parameter_pin_profile.data.attributes.profile_data[0].description,
                  sub_type: api_parameter_pin_profile.data.attributes.sub_type,
                  json: {
                    method: api_parameter_pin_profile.data.attributes.profile_data[0].json.method,
                    parameter_pin_information: api_parameter_pin_profile.data.attributes.profile_data[0].json.parameter_pin_information
                  }
                }
              }
            };
            if (api_parameter_pin_profile.data.attributes.profile_data[0].json.method === "single_api") {
              api_parameter_pin_profile_object.data.attributes.json["api_list_information"] = api_parameter_pin_profile.data.attributes.profile_data[0].json.api_list_information;
            } else {
              api_parameter_pin_profile_object.data.attributes.json["spec_info"] = {};
              api_parameter_pin_profile_object.data.attributes.json["spec_info"] = {
                file: {
                  id: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_id,
                  name: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_name,
                  size: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_size,
                  type: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_type
                }
              };
            }
            coma_seperated_chosen_profile_names += api_parameter_pin_profile.data.attributes.name + ", ";
            api_parameter_pin_profiles_array.push(api_parameter_pin_profile_object);
          }
        });
        this.profiles_list.forEach(api_parameter_pin_profile => {
          if (this.selected_profile_ids.has(api_parameter_pin_profile.data.id)) {
            var api_parameter_pin_profile_object = {
              data: {
                id: api_parameter_pin_profile.data.id,
                type: "profile",
                attributes: {
                  name: api_parameter_pin_profile.data.attributes.profile_data[0].name,
                  description: api_parameter_pin_profile.data.attributes.profile_data[0].description,
                  sub_type: api_parameter_pin_profile.data.attributes.sub_type,
                  json: {
                    method: api_parameter_pin_profile.data.attributes.profile_data[0].json.method,
                    parameter_pin_information: api_parameter_pin_profile.data.attributes.profile_data[0].json.parameter_pin_information,
                    file: {
                      id: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_id,
                      name: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_name,
                      size: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_size,
                      type: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_type
                    }
                  }
                }
              }
            };
            if (api_parameter_pin_profile.data.attributes.profile_data[0].json.method === "single_api") {
              api_parameter_pin_profile_object.data.attributes.json["api_list_information"] = api_parameter_pin_profile.data.attributes.profile_data[0].json.api_list_information;
            } else {
              api_parameter_pin_profile_object.data.attributes.json["spec_info"] = {};
              api_parameter_pin_profile_object.data.attributes.json["spec_info"] = {
                file: {
                  id: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_id,
                  name: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_name,
                  size: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_size,
                  type: api_parameter_pin_profile.data.attributes.profile_data[0].spec_file.file_type
                }
              };
            }
            api_parameter_pin_profiles_array.push(api_parameter_pin_profile_object);
            coma_seperated_chosen_profile_names += api_parameter_pin_profile.data.attributes.name + ", ";
          }
        });
        coma_seperated_chosen_profile_names = coma_seperated_chosen_profile_names.slice(0, coma_seperated_chosen_profile_names.length - 2);
        if (document.getElementById("apiParameter").checked) {
          detected_actions_severity_object["detect_for_violation_of_api_parameter_pinning_profile_conditions"] = this.api_parameter_severity.toLowerCase();
        }
        var getSeverityInfoForDetectionType = detectionType => {
          if (detected_actions_severity_object[detectionType]) {
            return detected_actions_severity_object[detectionType];
          } else {
            return "--Unchecked--";
          }
        };
        const {
          sessionTokenProfileIds,
          sessionTerminationProfileIds
        } = this.buildPolicyObjectForServer.getActionProfileIds(this.severity_level_responses);
        const actions_api_session_token_profiles = this.buildProfileObjectForServer.whichIncludesSpecificIds(sessionTokenProfileIds, this.apiSessionTokenProfilesList, action);
        const actions_session_termination_profiles = this.buildProfileObjectForServer.whichIncludesSpecificIds(sessionTerminationProfileIds, this.sessionTerminationProfilesList, action);
        var profiles_ids = [...new Set([...this.selected_profile_ids, ...this.past_selected_profile_ids]), ...sessionTokenProfileIds, ...sessionTerminationProfileIds];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: this.current_microsensor_id,
          name: this.current_microsensor
        };
        var changeset = {
          "nameLabel.Profile_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.current_localbay,
          "dpletLabel.Enforcement_Information.MicroSensor": this.current_microsensor,
          "httpHeaderLabel.Enforcement_Information.HTTP_Header": this.getValueOfElementById("http_header"),
          "enforcementApiParameterLabel.Enforcement_Information.API_Parameter": this.getValueOfElementById("api_parameter"),
          "apiNetworkTrafficLabel.Enforcement_Information.Src_IP_CIDR": this.getValueOfElementById("src_ip_cidr"),
          "apiNetworkTrafficLabel.Enforcement_Information.Dest_IP_CIDR": this.getValueOfElementById("dest_ip_cidr"),
          "apiNetworkTrafficLabel.Enforcement_Information.Dest_IP_Port": this.getValueOfElementById("dest_ip_port"),
          "profileLabel.Profile_Information.Profiles": coma_seperated_chosen_profile_names,
          "apiParameterLabel.Detection_Information.Detect_for_violation_of_API_Parameter_Pinning_Profile_conditions": getSeverityInfoForDetectionType("detect_for_violation_of_api_parameter_pinning_profile_conditions"),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Critical": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.High": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Medium": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Low": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Audit": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Critical": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.High": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Medium": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Low": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Audit": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "invalidate_session", this.severity_level_responses)
        };
        var json_object = {
          enforcement_information: {
            dplet_id: this.current_microsensor_id,
            http_header: this.getValueOfElementById("http_header"),
            api_parameter: this.getValueOfElementById("api_parameter"),
            traffic: {
              src_ip: this.getValueOfElementById("src_ip_cidr"),
              dst_ip: this.getValueOfElementById("dest_ip_cidr"),
              dst_port: this.getValueOfElementById("dest_ip_port")
            }
          },
          detected_actions_severity: detected_actions_severity_object,
          severity_level_responses: this.severity_level_responses,
          api_parameter_pin_profiles: api_parameter_pin_profiles_array,
          actions_api_session_token_profiles,
          actions_session_termination_profiles
        };
        this.buildPolicyObjectForServer.set_type("policy", "api_parameter_pin");
        this.buildPolicyObjectForServer.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.buildPolicyObjectForServer.set_changeset(changeset);
        this.buildPolicyObjectForServer.set_json(json_object);
        var request_object = this.buildPolicyObjectForServer.get_request_object();
        if (name && description && [...this.selected_profile_ids, ...this.past_selected_profile_ids].length > 0) {
          var url = this.services.urls.URL_POLICY(this.policy_id);
          this.services.publish.policy(action, "PUT", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            profileFilledValue: [...this.selected_profile_ids, ...this.past_selected_profile_ids].length > 0
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, "Please choose atleast one profile");
        }
      },
      saveApiSessionTokenPolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var detected_actions_severity_object = {};
        var coma_seperated_chosen_profile_names = "";
        var api_session_token_profiles_array = [];
        var isValidDuration = true;
        this.profiles_linked_to_this_microsensor.forEach(api_session_token_profile => {
          [...this.past_selected_profile_ids].forEach((profile_id, index) => {
            if (Number(profile_id) === Number(api_session_token_profile.data.id)) {
              if (document.getElementById("linked_profile_duration" + index).value) {
                var session_validity = document.getElementById("linked_profile_duration" + index).value + " " + document.getElementById("linked_profile_period" + index).innerText;
                var api_parameter_pin_profile_object = {
                  data: {
                    id: api_session_token_profile.data.id,
                    type: "profile",
                    attributes: {
                      name: api_session_token_profile.data.attributes.profile_data[0].name,
                      description: api_session_token_profile.data.attributes.profile_data[0].description,
                      sub_type: api_session_token_profile.data.attributes.sub_type,
                      json: {
                        session_validity: session_validity,
                        location_of_token: api_session_token_profile.data.attributes.profile_data[0].json.location_of_token
                      }
                    }
                  }
                };
                if (api_session_token_profile.data.attributes.profile_data[0].json.method === "single_api") {
                  api_parameter_pin_profile_object.data.attributes.json["api_list_information"] = api_session_token_profile.data.attributes.profile_data[0].json.api_list_information;
                }
                coma_seperated_chosen_profile_names += api_session_token_profile.data.attributes.name + ": " + session_validity + ", ";
                api_session_token_profiles_array.push(api_parameter_pin_profile_object);
              } else {
                isValidDuration = false;
              }
            }
          });
        });
        this.profiles_list.forEach(api_session_token_profile => {
          [...this.selected_profile_ids].forEach((profile_id, index) => {
            if (Number(profile_id) === Number(api_session_token_profile.data.id)) {
              if (document.getElementById("duration" + index).value) {
                var session_validity = document.getElementById("duration" + index).value + " " + document.getElementById("period" + index).innerText;
                var api_parameter_pin_profile_object = {
                  data: {
                    id: api_session_token_profile.data.id,
                    type: "profile",
                    attributes: {
                      name: api_session_token_profile.data.attributes.profile_data[0].name,
                      description: api_session_token_profile.data.attributes.profile_data[0].description,
                      sub_type: api_session_token_profile.data.attributes.sub_type,
                      json: {
                        session_validity: session_validity,
                        location_of_token: api_session_token_profile.data.attributes.profile_data[0].json.location_of_token
                      }
                    }
                  }
                };
                if (api_session_token_profile.data.attributes.profile_data[0].json.method === "single_api") {
                  api_parameter_pin_profile_object.data.attributes.json["api_list_information"] = api_session_token_profile.data.attributes.profile_data[0].json.api_list_information;
                }
                coma_seperated_chosen_profile_names += api_session_token_profile.data.attributes.name + ": " + session_validity + ", ";
                api_session_token_profiles_array.push(api_parameter_pin_profile_object);
              } else {
                isValidDuration = false;
              }
            }
          });
        });
        // -- remove the trailing whitespace and coma respectively
        coma_seperated_chosen_profile_names = coma_seperated_chosen_profile_names.slice(0, coma_seperated_chosen_profile_names.length - 2);
        if (document.getElementById("sessionTokenViolatedInput").checked) {
          detected_actions_severity_object["session_token_validity_violated_in_api_call"] = this.session_token_violated.toLowerCase();
        }
        if (document.getElementById("sessionTokenNotPresentInput").checked) {
          detected_actions_severity_object["session_token_not_present_in_api_call"] = this.session_token_not_present.toLowerCase();
        }
        var getSeverityInfoForDetectionType = detectionType => {
          if (detected_actions_severity_object[detectionType]) {
            return detected_actions_severity_object[detectionType];
          } else {
            return "--Unchecked--";
          }
        };
        const {
          sessionTokenProfileIds,
          sessionTerminationProfileIds
        } = this.buildPolicyObjectForServer.getActionProfileIds(this.severity_level_responses);
        const actions_api_session_token_profiles = this.buildProfileObjectForServer.whichIncludesSpecificIds(sessionTokenProfileIds, this.apiSessionTokenProfilesList, action);
        const actions_session_termination_profiles = this.buildProfileObjectForServer.whichIncludesSpecificIds(sessionTerminationProfileIds, this.sessionTerminationProfilesList, action);
        var profiles_ids = [...new Set([...this.selected_profile_ids, ...this.past_selected_profile_ids]), ...sessionTokenProfileIds, ...sessionTerminationProfileIds];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: this.current_microsensor_id,
          name: this.current_microsensor
        };
        var changeset = {
          "nameLabel.Policy_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.current_localbay,
          "dpletLabel.Enforcement_Information.MicroSensor": this.current_microsensor,
          "profileLabel.Profile_Information.Profiles": coma_seperated_chosen_profile_names,
          "sessionTokenViolatedLabel.Detection_&_Severity_Information.Session_Token_Validity_violated_in_API_call": getSeverityInfoForDetectionType("session_token_validity_violated_in_api_call"),
          "sessionTokenNotPresentLabel.Detection_&_Severity_Information.Session_Token_not_present_in_API_call": getSeverityInfoForDetectionType("session_token_not_present_in_api_call"),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Critical": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.High": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Medium": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Low": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Audit": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "generate_alert_event", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Critical": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("critical", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.High": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("high", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Medium": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("medium", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Low": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("low", "invalidate_session", this.severity_level_responses),
          "actionsInfoLabel.Actions_Information.Invalidate_session_using_out-of-band_API_call.Audit": this.buildPolicyObjectForServer.getActionsInfoForAlertEvent("audit", "invalidate_session", this.severity_level_responses)
        };
        var json_object = {
          enforcement_information: {
            dplet_id: this.current_microsensor_id
          },
          detected_actions_severity: detected_actions_severity_object,
          severity_level_responses: this.severity_level_responses,
          api_session_token_profiles: api_session_token_profiles_array,
          actions_api_session_token_profiles,
          actions_session_termination_profiles
        };
        this.services.build_policy_object_for_server.set_type("policy", "api_session_token");
        this.services.build_policy_object_for_server.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.services.build_policy_object_for_server.set_changeset(changeset);
        this.services.build_policy_object_for_server.set_json(json_object);
        var request_object = this.services.build_policy_object_for_server.get_request_object();
        if (name && description && [...this.selected_profile_ids, ...this.past_selected_profile_ids].length > 0 && isValidDuration) {
          var url = this.services.urls.URL_POLICY(this.policy_id);
          this.services.publish.policy(action, "PUT", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.policies");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            profileFilledValue: [...this.selected_profile_ids, ...this.past_selected_profile_ids].length > 0 && isValidDuration
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.AT_LEAST_ONE_OPTION);
        }
      },
      transitionToListView() {
        this.transitionToRoute("policies.policies");
      }
    }
  });
  _exports.default = _default;
});