define("abcloud-ui/components/policies-tenant/api-recorder-list-view-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: "tr",
    poll_data: false,
    download_error: false,
    urls: Ember.inject.service("urls"),
    download_file: Ember.inject.service("download-file"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    actions: {
      record(object, action) {
        this.record(object, action);
      },
      openResumeRecordModal(object) {
        this.openResumeRecordModal(object);
      },
      download_api_recording(file_type, policy) {
        if (file_type === "csv") {
          this.download_file.in_csv(this.poll_data, policy.attributes.name);
        } else if (file_type === "json") {
          this.download_file.in_json(this.poll_data, policy.attributes.name);
        } else if (file_type === "open_api") {
          var url = this.urls.URL_DOWNLOAD_OPEN_API_SPEC(policy.id);
          this.server.request("GET", url, "", open_apis => {
            if (!open_apis.errors) {
              this.set("download_error", false);
              this.download_file.in_json(open_apis, policy.attributes.name);
            } else {
              this.set("download_error", true);
            }
          }, error => {
            this.set("download_error", true);
            this.debug.log(error);
          });
        }
      },
      request_poll_data(policy_id) {
        var poll_data = Ember.RSVP.hash({
          poll_data: new Promise(resolve => {
            var request = Ember.$.getJSON(this.urls.URL_POLL_API_RECORDER(policy_id));
            resolve(request);
          })
        });
        poll_data.then(response => {
          if (response.poll_data.data.length && response.poll_data.data[0].attributes.api_spec.length) {
            this.set("poll_data", response.poll_data.data[0].attributes.api_spec);
          } else {
            this.set("poll_data", false);
          }
        });
      }
    }
  });
  _exports.default = _default;
});