define("abcloud-ui/routes/exceptions/security/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    map: Ember.inject.service('map'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    keys_to_show_in_raw_json: ['alert_timestamp', 'alert_severity', 'alert_class', 'alert_type', 'alert_name', 'alert_iab_data', 'alert_data', 'alert_policy_data'],
    model(params) {
      var url = this.urls.URL_GET_ALERTS(params.id);
      var request = Ember.$.getJSON(url);
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      var structured_data_for_raw_json = {
        'type': model.data.type
      };
      this.keys_to_show_in_raw_json.forEach(key => {
        if (model.data.attributes.hasOwnProperty(key)) {
          structured_data_for_raw_json[key] = model.data.attributes[key];
        }
      });
      controller.set('model', null);
      if (Ember.$.isEmptyObject(model.data.attributes)) {
        controller.set('empty_alert_json', true);
      }
      controller.setProperties({
        'model': model.data,
        'apis_length': model.data.attributes.alert_transaction_apis ? (model.data.attributes.alert_transaction_apis.length - 1).toString() : '0',
        'structured_data_for_raw_json': structured_data_for_raw_json,
        'displayPane': true
      });
      let url = this.urls.URL_GET_MAP();
      let geoData;
      if (model.data.attributes.alert_iab_data && model.data.attributes.alert_iab_data !== undefined) {
        geoData = model.data.attributes.alert_iab_data.iab_src_geo_location;
      }
      if (geoData && geoData !== undefined) {
        // -- set background image for map cell
        this.map.getMap(geoData, url, 'geo_map');
      }
    },
    resetController(controller, isExiting, transition) {
      this.manage_requests.abort_request(this.routeName);
      controller.setProperties({
        'displayPane': false,
        'firstEvent': 0
        // 'showTransactionJSON': false,
      });
    },

    renderTemplate() {
      this.render('exceptions.security.alert', {
        into: 'exceptions.security',
        outlet: 'alert'
      });
    }
  });
  _exports.default = _default;
});