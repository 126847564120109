define("abcloud-ui/templates/components/transactions/transaction-policy-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "paApmXDs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"padded_10\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card flat with_icon policy\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"detail_title policy\"],[9],[0,\"Policy\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"transaction_policy\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"pure-u-12-24\"],[9],[0,\"\\n                    \"],[7,\"p\"],[9],[0,\"Name\"],[10],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"policy_value\"],[9],[1,[23,[\"policy_data\",\"name\"]],false],[10],[0,\"\\n                \"],[10],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"pure-u-12-24\"],[9],[0,\"\\n                    \"],[7,\"p\"],[9],[0,\"Last Updated\"],[10],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"policy_value\"],[9],[1,[23,[\"policy_data\",\"updated_at\"]],false],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"pure-u-12-24\"],[9],[0,\"\\n                    \"],[7,\"p\"],[9],[0,\"Version\"],[10],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"policy_value\"],[9],[1,[23,[\"policy_data\",\"version\"]],false],[10],[0,\"\\n                \"],[10],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"pure-u-12-24\"],[9],[0,\"\\n                    \"],[7,\"p\"],[9],[0,\"Updated By\"],[10],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"policy_value\"],[9],[1,[23,[\"policy_data\",\"last_edited_by\",\"name\"]],false],[10],[0,\"\\n                    \"],[7,\"p\"],[11,\"class\",\"policy_value email\"],[9],[1,[23,[\"policy_data\",\"last_edited_by\",\"email\"]],false],[10],[0,\"\\n                \"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/transactions/transaction-policy-details.hbs"
    }
  });
  _exports.default = _default;
});