define("abcloud-ui/components/system-localbay/localbay-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service("auth"),
    notifications: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    linked_dplets: "",
    linked_recorders: "",
    linked_policies: "",
    current_lb_id: "",
    actions: {
      populateLbDetails(localcomponent) {
        this.setProperties({
          linked_dplets: localcomponent.dplets,
          linked_recorders: localcomponent.local_recorders,
          linked_policies: localcomponent.policies,
          current_lb_id: localcomponent.id
        });
        Ember.$("#open-modal").show();
      },
      deleteLocalbay(localcomponentId) {
        this.deleteLocalbay(localcomponentId);
      },
      getLocalbayDetails(localcomponent) {
        // Note: Show only simple view based on
        // https://cloudvector.atlassian.net/browse/ENG-1434
        /**
        if (localcomponent.config_status === this.constants.LB_CONFIG_STATUS_DONE) {
          this.transitionToLocalbayDetails(localcomponent.id);
        } else {
          this.transitionToConfigureLocalbay(localcomponent.id);
        }
        */
        this.transitionToConfigureLocalbay(localcomponent.id);
      },
      cancel() {
        this.cancel();
      }
    }
  });
  _exports.default = _default;
});