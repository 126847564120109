define("abcloud-ui/routes/admin/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    async model() {
      await this.store.findAll("admin_user").sortBy("updated_at").reverse();
      return this.store.peekAll("admin_user");
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set("model", model);
    },
    renderTemplate() {
      this.render("admin.users.list", {
        into: "application",
        outlet: "admin"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("admin.users.list");
        controller.set("isUsersListLoading", true);
        transition.promise.finally(function () {
          controller.set("isUsersListLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});