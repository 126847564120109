define("abcloud-ui/components/policies-tenant/policies-api-session-token/detection-and-severity-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    disabledStatusSessionTokenViolated: true,
    disabledStatusSessionTokenNotPresent: true,
    didInsertElement() {
      try {
        var detected_actions_severity = this.policy_data.json.data.attributes.detected_actions_severity;
        var disabledStatus = this.disabledStatus ? this.disabledStatus : "";
        if (detected_actions_severity["session_token_validity_violated_in_api_call"]) {
          document.getElementById("sessionTokenViolatedInput" + disabledStatus).checked = true;
          if (disabledStatus) {
            document.getElementById("sessionTokenViolatedInput" + disabledStatus).disabled = true;
          }
          var dropdownOption = detected_actions_severity["session_token_validity_violated_in_api_call"];
          dropdownOption = dropdownOption.charAt(0).toUpperCase() + dropdownOption.slice(1);
          this.set("session_token_violated", dropdownOption);
          if (!disabledStatus) {
            this.set("disabledStatusSessionTokenViolated", false);
          }
        }
        if (detected_actions_severity["session_token_not_present_in_api_call"]) {
          document.getElementById("sessionTokenNotPresentInput" + disabledStatus).checked = true;
          if (disabledStatus) {
            document.getElementById("sessionTokenNotPresentInput" + disabledStatus).disabled = true;
          }
          dropdownOption = detected_actions_severity["session_token_not_present_in_api_call"];
          dropdownOption = dropdownOption.charAt(0).toUpperCase() + dropdownOption.slice(1);
          this.set("session_token_not_present", dropdownOption);
          if (!disabledStatus) {
            this.set("disabledStatusSessionTokenNotPresent", false);
          }
        }
      } catch (thisIsANewPolicy) {
        this.debug.log("This is a New Policy");
      }
    },
    generateNewSet() {
      var chosenSeverityLevels = new Set();
      var severity_level_responses = [];
      if (!this.disabledStatusSessionTokenViolated) {
        chosenSeverityLevels.add(this.session_token_violated);
      }
      if (!this.disabledStatusSessionTokenNotPresent) {
        chosenSeverityLevels.add(this.session_token_not_present);
      }
      chosenSeverityLevels.forEach(severity => {
        severity_level_responses.push({
          severity: severity,
          generate_alert_event: "true",
          invalidate_session: "false"
        });
      });
      this.setProperties({
        severity_level_responses: severity_level_responses
      });
    },
    actions: {
      sessionTokenViolated(id, severity_level) {
        document.getElementById("recommendedSettings").checked = false;
        this.set("session_token_violated", severity_level);
        this.generateNewSet();
      },
      sessionTokenNotPresent(id, severity_level) {
        document.getElementById("recommendedSettings").checked = false;
        this.set("session_token_not_present", severity_level);
        this.generateNewSet();
      },
      toggleInputDisabling(event) {
        switch (event.target.id) {
          case "sessionTokenViolatedInput":
            if (document.getElementById("sessionTokenViolatedInput").checked) {
              this.set("disabledStatusSessionTokenViolated", false);
            } else {
              this.set("disabledStatusSessionTokenViolated", true);
            }
            break;
          case "sessionTokenNotPresentInput":
            if (document.getElementById("sessionTokenNotPresentInput").checked) {
              this.set("disabledStatusSessionTokenNotPresent", false);
            } else {
              this.set("disabledStatusSessionTokenNotPresent", true);
            }
            break;
          default:
            this.debug.log("unknown input id..");
        }
        document.getElementById("recommendedSettings").checked = false;
        this.generateNewSet();
      },
      resetToRecommendedSettings() {
        document.getElementById("sessionTokenViolatedInput").checked = true;
        document.getElementById("sessionTokenNotPresentInput").checked = true;
        this.setProperties({
          disabledStatusSessionTokenViolated: false,
          disabledStatusSessionTokenNotPresent: false,
          session_token_violated: "High",
          session_token_not_present: "High",
          chosenSeverityLevels: ["High"],
          severity_level_responses: [{
            severity: "high",
            generate_alert_event: "true",
            invalidate_session: "false"
          }]
        });
      }
    }
  });
  _exports.default = _default;
});