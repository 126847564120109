define("abcloud-ui/controllers/system/hosted/microsensor/api-receiver/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    encrypt: Ember.inject.service("encrypt"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    init() {
      this._super(...arguments);
      this.setProperties({
        localcomponents: "",
        current_localbay: "",
        current_localbay_ip: "",
        progress_percent: "",
        restApiAuthTokenForGroupInfo: "",
        validTitle: null,
        dpletError: ""
      });
    },
    actions: {
      generateRestApiAuthToken(event) {
        if (event.target.checked) {
          var uuid = this.encrypt.generateUUID();
          this.set("restApiAuthTokenForGroupInfo", uuid);
        } else {
          this.set("restApiAuthTokenForGroupInfo", "");
        }
      },
      createDplet() {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var rest_api_auth_token = this.restApiAuthTokenForGroupInfo;

        // -- if user has entered ip, take it. Otherwise, is the localbay instance_orch k8? if so, localbay_ip = "ablocalbay" else localbay_ip = localbay_loadbalancer_ip
        var localbay_ip = "";
        if (this.current_localbay.attributes.instance_type === "hosted") {
          localbay_ip = this.current_localbay_ip;
        } else {
          if (this.current_localbay.attributes.instance_orch == "kubernetes") {
            localbay_ip = this.constants.KUBERNETES_LOCALBAY_IP;
          } else {
            localbay_ip = this.current_localbay_ip;
          }
        }

        // --  clear the input validation errors before proceeding further
        var elements = {
          nameFilledValue: name,
          authTokenFilledValue: rest_api_auth_token
        };
        this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, "clear all");
        if (name && description && rest_api_auth_token && this.validTitle) {
          this.set("progress_percent", 50);
          var apiReceiverDplet = this.store.createRecord("dplet", {
            name: name,
            description: description,
            install_mode: this.current_localbay.attributes.install_mode,
            localcomponent_instance_type: this.current_localbay.attributes.instance_type,
            localcomponent_title: this.current_localbay.attributes.title,
            localcomponent_id: this.current_localbay.id,
            rest_api_host_ip: localbay_ip,
            rest_api_auth_token: rest_api_auth_token,
            dplet_type: "receiver",
            sub_type: "receiver"
          });
          this.replace_element_with.preloader(".save");
          apiReceiverDplet.save().then(response => {
            if (!response.errors.length) {
              this.setProperties({
                progress_percent: 100
              });
            } else {
              apiReceiverDplet.rollbackAttributes();
              this.debug.log("dplet server error");
              this.set("dpletError", response.errors[0].detail);
            }
          }).catch(error => {
            apiReceiverDplet.rollbackAttributes();
            this.debug.log("dplet server error");
            if (error.errors[0].detail === this.constants.UNPROCESSABLE_ENTITY) {
              this.set("dpletError", this.constants.DPLET_TITLE_TAKEN);
            } else {
              this.set("dpletError", error.errors[0].detail);
            }
          });
        } else {
          elements = {
            nameFilledValue: name,
            authTokenFilledValue: rest_api_auth_token
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      cancel() {
        this.transitionToRoute("system.hosted.microsensor.index");
      }
    }
  });
  _exports.default = _default;
});