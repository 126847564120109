define("abcloud-ui/components/tooltip-plugin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ["d-inline-block"]
  });
  _exports.default = _default;
});