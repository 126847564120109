define("abcloud-ui/routes/policies/policies/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    policy_id: "",
    ajax: Ember.inject.service("ajax"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    model(params) {
      this.set("policy_id", params.policy_id);
      var urls = this.urls;
      Ember.$.ajaxSetup({
        headers: {
          Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
          "X-CV-Trace-ID": this.urls.generate_trace_id()
        }
      });
      var request1 = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES(params.policy_type, params.policy_id));
      var request2 = Ember.$.getJSON(urls.URL_POLICY(params.policy_id));
      var routeName = this.routeName;
      this.manage_requests.add_requests([{
        [routeName]: request1
      }, {
        [routeName]: request2
      }]);
      return Ember.RSVP.hash({
        dropdownOptions: request1,
        policy: request2
      });
    },
    setupController(controller, model) {
      let profileOptionAreaClass = "col-xs-12 col-sm-6";
      var policy = model.policy.data.attributes;
      var policy_data = policy.policy_data;
      var profiles_list = "";
      const profiles = model.dropdownOptions.data.attributes.profiles;
      if (policy.sub_type === "data_export") {
        profiles_list = model.dropdownOptions.data.attributes.profiles.available;
        controller.set("profiles_list", profiles_list.data);
      } else {
        profiles_list = model.dropdownOptions.data.attributes.profiles.available;
        if (model.dropdownOptions.data.attributes.profiles.linked_to_policy.data.length < 1) {
          profileOptionAreaClass = "col-xs-12 col-sm-10";
        }
        controller.setProperties({
          profiles_list: profiles_list.data,
          linked_profiles: model.dropdownOptions.data.attributes.profiles.linked.data,
          profiles_linked_to_this_microsensor: model.dropdownOptions.data.attributes.profiles.linked_to_policy.data,
          profileOptionAreaClass: profileOptionAreaClass,
          apiSessionTokenProfilesList: profiles.api_session_token_profiles.data,
          sessionTerminationProfilesList: profiles.session_termination_profiles.data
        });
      }
      var localbay_list = model.dropdownOptions.data.attributes.localbays;
      var isProfilesEmpty = false,
        isLocalBayReadOnly = false;
      if (Ember.$.isEmptyObject(profiles_list)) {
        isProfilesEmpty = true;
      } else {
        profiles_list = profiles_list.data;
      }
      if (Ember.$.isEmptyObject(localbay_list)) {
        isLocalBayReadOnly = true;
      } else {
        localbay_list = localbay_list.data;
      }

      // Note(sanath): localbay_title, localbay_ids are deprecated
      const {
        localcomponent_title: publishedCurrentLocalComponentTitle,
        localcomponent_ids: publishedCurrentLocalComponentIds,
        localbay_title: publishedCurrentLocalbayTitle,
        localbay_ids: publishedCurrentLocalbayIds,
        data_export_profile: publishedCurrentDataExportProfile,
        severity_level_responses: publishedCurrentSeverityLevelResponses,
        dplet_id: publishedCurrentDpletId,
        dplet_name: publishedCurrentDpletName
      } = policy_data[0].json.data.attributes;
      if (policy_data.length > 1) {
        // Note(sanath): localbay_title, localbay_ids are deprecated
        const {
          localcomponent_title: currentLocalComponentTitle,
          localcomponent_ids: currentLocalComponentIds,
          localbay_title: currentLocalbayTitle,
          localbay_ids: currentLocalbayIds,
          data_export_profile: currentDataExportProfile,
          severity_level_responses: currentSeverityLevelResponses,
          dplet_id: currentDpletId
        } = policy_data[1].json.data.attributes;
        try {
          controller.setProperties({
            published_current_localbay: publishedCurrentLocalComponentTitle || publishedCurrentLocalbayTitle,
            published_current_localbay_id: publishedCurrentLocalComponentIds ? publishedCurrentLocalComponentIds[0] : publishedCurrentLocalbayIds[0],
            current_localbay: currentLocalComponentTitle || currentLocalbayTitle,
            current_localbay_id: currentLocalComponentIds ? currentLocalComponentIds[0] : currentLocalbayIds[0]
          });
          if (policy.sub_type === "data_export") {
            controller.setProperties({
              current_profile: currentDataExportProfile ? currentDataExportProfile.data.attributes.name : "--No Option choosen--",
              current_profile_id: currentDataExportProfile ? currentDataExportProfile.data.id : "--No Option choosen--",
              published_current_profile: publishedCurrentDataExportProfile ? publishedCurrentDataExportProfile.data.attributes.name : "--No Option choosen--",
              published_current_profile_id: publishedCurrentDataExportProfile ? publishedCurrentDataExportProfile.data.id : "--No Option choosen--"
            });
          } else if (["out_of_spec_api", "api_parameter_pin", "api_session_token"].includes(policy.sub_type)) {
            let microsensor_list = "";
            let keyOffAttributeName = "";
            if (policy.sub_type === "out_of_spec_api") {
              keyOffAttributeName = "out_of_spec_api_profiles";
            } else if (policy.sub_type === "api_parameter_pin") {
              keyOffAttributeName = "api_parameter_pin_profiles";
            } else if (policy.sub_type === "api_session_token") {
              keyOffAttributeName = "api_session_token_profiles";
            }
            model.dropdownOptions.data.attributes.profiles.linked_to_policy.data.forEach(profile => {
              controller.past_selected_profile_ids.add(profile.data.id);
            });
            localbay_list.forEach(localbay => {
              if (localbay.id === (currentLocalComponentIds && currentLocalComponentIds[0]) || localbay.id === (currentLocalbayIds && currentLocalbayIds[0])) {
                microsensor_list = localbay.dplets.data;
              }
            });
            controller.setProperties({
              severity_level_responses: this.convertLegacyObjToLatestObj(currentSeverityLevelResponses),
              current_microsensor_id: currentDpletId,
              current_microsensor: policy_data[1].json.data.attributes.dplet_name,
              microsensor_list: microsensor_list,
              published_profiles_linked_to_this_microsensor: policy_data[0].json.data.attributes[keyOffAttributeName],
              published_severity_level_responses: this.convertLegacyObjToLatestObj(publishedCurrentSeverityLevelResponses),
              published_current_microsensor: publishedCurrentDpletName
            });
          }
        } catch (somethingWrongWithPolicyData) {
          this.debug.log("policy_data length > 1 for diff view is currupt", somethingWrongWithPolicyData);
        }
      } else {
        try {
          controller.setProperties({
            current_localbay: publishedCurrentLocalComponentTitle || publishedCurrentLocalbayTitle,
            current_localbay_id: publishedCurrentLocalComponentIds ? publishedCurrentLocalComponentIds[0] : publishedCurrentLocalbayIds[0]
          });
          if (policy.sub_type === "data_export") {
            controller.setProperties({
              current_profile: publishedCurrentDataExportProfile ? publishedCurrentDataExportProfile.data.attributes.name : "--No Option choosen--",
              current_profile_id: publishedCurrentDataExportProfile ? publishedCurrentDataExportProfile.data.id : "--No Option choosen--"
            });
          } else if (["out_of_spec_api", "api_parameter_pin", "api_session_token"].includes(policy.sub_type)) {
            let microsensor_list = "";
            let keyOffAttributeName = "";
            if (policy.sub_type === "out_of_spec_api") {
              keyOffAttributeName = "out_of_spec_api_profiles";
            } else if (policy.sub_type === "api_parameter_pin") {
              keyOffAttributeName = "api_parameter_pin_profiles";
            } else if (policy.sub_type === "api_session_token") {
              keyOffAttributeName = "api_session_token_profiles";
            }
            policy_data[0].json.data.attributes[keyOffAttributeName].forEach(profile => {
              controller.past_selected_profile_ids.add(profile.data.id);
            });
            localbay_list.forEach(localbay => {
              if (localbay.id === (publishedCurrentLocalComponentIds && publishedCurrentLocalComponentIds[0]) || localbay.id === (publishedCurrentLocalbayIds && publishedCurrentLocalbayIds[0])) {
                microsensor_list = localbay.dplets.data;
              }
            });
            controller.setProperties({
              severity_level_responses: this.convertLegacyObjToLatestObj(publishedCurrentSeverityLevelResponses),
              current_microsensor_id: publishedCurrentDpletId,
              current_microsensor: publishedCurrentDpletName,
              microsensor_list: microsensor_list
            });
          }
        } catch (somethingWrongWithPolicyData) {
          this.debug.log("policy_data length = 1 for diff view is currupt", somethingWrongWithPolicyData);
        }
      }
      controller.setProperties({
        sub_type: policy.sub_type,
        policy: policy,
        diff: policy.diff,
        policy_id: this.policy_id,
        changed_labels: policy.changed_labels,
        diffLength: this.setDiffLength(policy.diff),
        isProfilesEmpty: isProfilesEmpty,
        isLocalBayReadOnly: isLocalBayReadOnly,
        localbay_list: localbay_list,
        hasDiffView: this.hasDiffView(policy.policy_data.length)
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          policy_id: "",
          current_profile: "",
          current_profile_id: "",
          current_localbay: "",
          current_localbay_id: "",
          localbay_list: "",
          sendToAbCloud: false,
          isExportProfileChosen: false,
          exportOriginalDataEvents: false,
          exportObfuscatedDataEvents: false,
          exportDataEventsHeadersOnly: false,
          published_exportOriginalDataEvents: "",
          published_exportObfuscatedDataEvents: "",
          published_exportDataEventsHeadersOnly: "",
          current_microsensor: "",
          current_microsensor_id: "",
          microsensor_list: "",
          out_of_spec_profiles: {},
          selected_profile_ids: new Set(),
          publishedChosenSeverityLevels: new Set(),
          past_selected_profile_ids: new Set(),
          severity_level_responses: [],
          profileOptionAreaClass: "col-sm-6",
          api_path_severity: "High",
          api_parameter_severity: "High",
          errorMessage: ""
        });
      }
    },
    setDiffLength(diff) {
      if (diff !== null) {
        return Object.keys(diff).length;
      } else {
        return 0;
      }
    },
    retreiveProfileInfo(export_options, property) {
      if (export_options.hasOwnProperty(property)) {
        return export_options[property];
      } else {
        return "--No Option choosen--";
      }
    },
    hasDiffView(policy_length) {
      if (policy_length > 1) {
        return true;
      } else {
        return false;
      }
    },
    convertLegacyObjToLatestObj(severity_level_responses) {
      const available_severity_levels = ["critical", "high", "medium", "low", "audit"];
      let isObjectLegacy = false;
      let latestObj = [];
      available_severity_levels.forEach(severity => {
        if (severity_level_responses.hasOwnProperty(severity)) {
          isObjectLegacy = true;
        }
      });
      if (isObjectLegacy) {
        for (let severity in severity_level_responses) {
          let actionsRow = {
            severity: severity,
            generate_alert_event: severity_level_responses[severity]["generate_alert_event"],
            invalidate_session: "false"
          };
          latestObj.push(actionsRow);
        }
      } else {
        latestObj = severity_level_responses;
      }
      return latestObj;
    }
  });
  _exports.default = _default;
});