define("abcloud-ui/controllers/system/hosted/microsensor/kong-api-gateway/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    init() {
      this._super(...arguments);
      this.setProperties({
        localcomponents: "",
        current_localbay: "",
        current_localbay_ip: "",
        progress_percent: "",
        savedSuccessfully: false,
        config_link: "",
        dpletError: "",
        validTitle: null
      });
    },
    actions: {
      createDplet() {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var localbay_ip = "";
        localbay_ip = this.current_localbay_ip;
        // --  clear the input validation errors before proceeding further
        var elements = {
          nameFilledValue: name
        };
        this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, "clear all");
        if (name && description && this.validTitle) {
          this.set("progress_percent", 50);
          var kongDplet = this.store.createRecord("dplet", {
            name: name,
            description: description,
            install_mode: this.current_localbay.attributes.install_mode,
            localcomponent_title: this.current_localbay.attributes.title,
            localcomponent_instance_type: this.current_localbay.attributes.instance_type,
            localbay_ip: localbay_ip,
            localcomponent_id: this.current_localbay.id,
            dplet_type: "kong_api_gateway",
            sub_type: "kong_api_gateway"
          });
          this.replace_element_with.preloader(".save");
          kongDplet.save().then(response => {
            if (!response.errors.length) {
              this.setProperties({
                progress_percent: 100,
                savedSuccessfully: true,
                config_link: response.data.config_link
              });
            } else {
              kongDplet.rollbackAttributes();
              this.debug.log("dplet server error");
              this.set("dpletError", response.errors[0].detail);
            }
          }).catch(error => {
            kongDplet.rollbackAttributes();
            this.debug.log("dplet server error");
            if (error.errors[0].detail === this.constants.UNPROCESSABLE_ENTITY) {
              this.set("dpletError", this.constants.DPLET_TITLE_TAKEN);
            } else {
              this.set("dpletError", error.errors[0].detail);
            }
          });
        } else {
          elements = {
            nameFilledValue: name
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      cancel() {
        // console.log('hitting localbay-create-form component action cancel()');
        this.transitionToRoute("system.hosted.microsensor.index");
      }
    }
  });
  _exports.default = _default;
});