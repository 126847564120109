define("abcloud-ui/templates/policies/profiles/data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "laqiWVlU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 policy_card_view\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"pure-u-17-24\"],[9],[0,\"\\n          Data Export Profile\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"pure-u-7-24\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"help_tip\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"caret_shadow\"],[9],[10],[0,\"\\n            Data Export profile defines the information to be used by CloudVector to export event data generated from LocalBay\\n            to your application such as Splunk, Alienvault, etc. The information is posted using HTTP REST API call.\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"policies-tenant/profile-components/edit-data-export\",null,[[\"saveDataExport\",\"disabled\",\"transitionToListView\",\"confirmPublish\",\"current_localconsole_id\",\"current_localconsole\",\"current_localconsole_hashed_passphrase\",\"current_localconsole_instance_orch\",\"current_localconsole_host_ip\",\"localconsoles_list\",\"localconsoles_present\",\"errorMessage\"],[[27,\"action\",[[22,0,[]],\"saveDataExport\"],null],\"\",[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[23,[\"current_localconsole_id\"]],[23,[\"current_localconsole\"]],[23,[\"current_localconsole_hashed_passphrase\"]],[23,[\"current_localconsole_instance_orch\"]],[23,[\"current_localconsole_host_ip\"]],[23,[\"localconsoles_list\"]],[23,[\"localconsoles_present\"]],[23,[\"errorMessage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/data-export.hbs"
    }
  });
  _exports.default = _default;
});