define("abcloud-ui/services/encrypt", ["exports", "ember-uuid", "crypto-js/sha256"], function (_exports, _emberUuid, _sha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    generateUUID() {
      return (0, _emberUuid.v4)();
    },
    buildStringToEncrypt(string, uuid) {
      if (uuid === undefined || uuid === '') {
        uuid = this.generateUUID();
      }
      return (string + '_' + uuid).toString();
    },
    encryptString(string, uuid) {
      return (0, _sha.default)(this.buildStringToEncrypt(string, uuid)).toString();
    }
  });
  _exports.default = _default;
});