define("abcloud-ui/templates/components/system-localbay/localbay-component-card-health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "riK0czWd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[9],[7,\"label\"],[9],[0,\"Last seen: \"],[10],[7,\"p\"],[9],[1,[27,\"epoch-to-datetime\",[[23,[\"componentHealth\",\"last_seen\"]],\"diff\"],null],false],[10],[10],[0,\"\\n\"],[7,\"li\"],[9],[7,\"label\"],[9],[0,\"Message: \"],[10],[7,\"p\"],[9],[1,[23,[\"componentHealth\",\"health_message\"]],false],[10],[10],[0,\"\\n\"],[7,\"li\"],[9],[7,\"label\"],[9],[0,\"Status: \"],[10],[7,\"p\"],[12,\"class\",[27,\"lowercase\",[[23,[\"componentHealth\",\"health_status\"]]],null]],[9],[1,[27,\"titleize\",[[23,[\"componentHealth\",\"health_status\"]]],null],false],[10],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/localbay-component-card-health.hbs"
    }
  });
  _exports.default = _default;
});