define("abcloud-ui/controllers/insights/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      toggleChildSidenav(child_list_id, element) {
        let current_element = '#' + element.target.id;
        if (!Ember.$(current_element).hasClass('active')) {
          // -- unhide the sidenav that is clicked
          Ember.$(current_element).next('.child_sidenav_list').toggleClass('display_none');
          Ember.$(current_element).parent().siblings().removeClass('active');
          Ember.$(current_element).parent().addClass('active');

          // -- hide all the other sidenavs
          Ember.$.each(Ember.$(current_element).parent().siblings(), function (idx, elm) {
            Ember.$(elm).children('.child_sidenav_list').addClass('display_none');
          });

          // -- remove active classes for all previosly selected sidenav li
          Ember.$(current_element).parent().siblings().children('a').removeClass('active');
          Ember.$(current_element).parent().siblings().children('ul').children('li').removeClass('active');
          Ember.$(current_element).siblings('ul').children('li').removeClass('active');

          // -- add active class to the current li element
          Ember.$(current_element).siblings('ul').children('li:first').addClass('active');

          // -- navigate to the default first list in the sidenav
          this.transitionToRoute(Ember.$('ul#' + child_list_id + ' li:first a').attr('href'));
        } else {
          // -- navigate to the default first list in the sidenav
          this.transitionToRoute(Ember.$('ul#' + child_list_id + ' li:first a').attr('href'));
        }
      }
    }
  });
  _exports.default = _default;
});