define("abcloud-ui/components/transactions/transaction-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      Ember.$('#autoFocusDiv').focus();
    }
  });
  _exports.default = _default;
});