define("abcloud-ui/routes/customer/account/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    beforeModel() {
      if (!this.auth.isLoggedIn('customer')) {
        this.transitionTo('/customer/login');
      }
    },
    setupController(controller, model) {
      controller.setProperties({
        'createUserAbility': this.auth.getTenantCreateUserAbility()
      });
    },
    renderTemplate() {
      this.render("customer.account.index", {
        into: "customer.account",
        outlet: "account"
      });
    }
  });
  _exports.default = _default;
});