define("abcloud-ui/pods/docs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      toggleNodeExpansion(path) {
        const model = this.get('model');

        //Note: Have to do this to get out of ember watch.
        let nodes = JSON.parse(JSON.stringify(model));
        nodes = this.toggleTreeNode(nodes, path);
        this.set('model', nodes);
      },
      selectNode(path) {
        const model = this.get('model');

        //Note: Have to do this to get out of ember watch.
        let nodes = JSON.parse(JSON.stringify(model));
        nodes = this.selectTreeNode(nodes, path);
        this.set('model', nodes);
      }
    },
    toggleTreeNode(nodes, path) {
      nodes.forEach(node => {
        if (node.type === 'folder' && node.path === path) {
          node.isExpanded = !node.isExpanded;
        }
        if (node.children && node.children.length) {
          this.toggleTreeNode(node.children, path);
        }
      });
      return nodes;
    },
    selectTreeNode(nodes, path) {
      nodes.forEach(node => {
        if (node.type === 'file') {
          node.isSelected = node.path === path;
        }
        if (node.children && node.children.length) {
          this.selectTreeNode(node.children, path);
        }
      });
      return nodes;
    }
  });
  _exports.default = _default;
});