define("abcloud-ui/components/policies-tenant/policies-out-of-spec/localbay-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      chooseLocalbay(localcomponentId, localcomponent) {
        this.chooseLocalbay(localcomponentId, localcomponent);
      }
    }
  });
  _exports.default = _default;
});