define("abcloud-ui/components/policies-tenant/profile-components/edit-data-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    api: '',
    auth_token: '',
    source_type: 'CloudVector:app:events',
    localconsole_dropdown_disabled: true,
    didInsertElement() {
      this._super(...arguments);
      try {
        if (this.disabled) {
          if (this.profile.data_receiver_information.hasOwnProperty('abcloud')) {
            document.getElementById('sendToAbCloud' + this.disabled).checked = true;
          } else {
            document.getElementById('sendToAbCloud' + this.disabled).checked = false;
          }
          if (this.profile.data_receiver_information.hasOwnProperty('splunk')) {
            document.getElementById('receiverTypeSplunk' + this.disabled).checked = true;
            Ember.$('#additional_details_splunk_receiver_type' + this.disabled).show(200);
            this.setProperties({
              'api': this.profile.data_receiver_information.splunk.api,
              'auth_token': this.profile.data_receiver_information.splunk.auth_token,
              'source_type': this.profile.data_receiver_information.splunk.source_type
            });
          } else {
            document.getElementById('receiverTypeSplunk' + this.disabled).checked = false;
          }
          if (this.profile.data_receiver_information.hasOwnProperty('localconsole')) {
            document.getElementById('sendToLocalconsole' + this.disabled).checked = true;
            Ember.$('#localconsole_details' + this.disabled).show(200);
            this.setProperties({
              'current_localconsole_hashed_passphrase': this.profile.data_receiver_information.localconsole.hashed_passphrase,
              'current_localconsole_host_ip': this.profile.data_receiver_information.localconsole.ip
            });
          } else {
            document.getElementById('sendToLocalconsole' + this.disabled).checked = false;
            if (this.localconsoles_present) {
              this.setProperties({
                'current_localconsole': this.localconsoles_list[0].attributes.title
              });
            } else {
              this.set('current_localconsole', this.constants.NO_LOCALCONSOLES_CREATED);
            }
          }
        } else {
          if (this.profile.data_receiver_information.hasOwnProperty('abcloud')) {
            document.getElementById('sendToAbCloud').checked = true;
          } else {
            document.getElementById('sendToAbCloud').checked = false;
          }
          if (this.profile.data_receiver_information.hasOwnProperty('splunk')) {
            document.getElementById('receiverTypeSplunk').checked = true;
            Ember.$('#additional_details_splunk_receiver_type').show(200);
            this.setProperties({
              'api': this.profile.data_receiver_information.splunk.api,
              'auth_token': this.profile.data_receiver_information.splunk.auth_token,
              'source_type': this.profile.data_receiver_information.splunk.source_type
            });
          } else {
            document.getElementById('receiverTypeSplunk').checked = false;
          }
          if (this.profile.data_receiver_information.hasOwnProperty('localconsole')) {
            document.getElementById('sendToLocalconsole').checked = true;
            this.set('localconsole_dropdown_disabled', false);
            Ember.$('#localconsole_details').show(200);
            this.setProperties({
              'current_localconsole_hashed_passphrase': this.profile.data_receiver_information.localconsole.hashed_passphrase,
              'current_localconsole_host_ip': this.profile.data_receiver_information.localconsole.ip
            });
          } else {
            document.getElementById('sendToLocalconsole').checked = false;
            if (this.localconsoles_present) {
              this.setProperties({
                'current_localconsole': this.localconsoles_list[0].attributes.title,
                'current_localconsole_id': this.localconsoles_list[0].id,
                'current_localconsole_hashed_passphrase': this.localconsoles_list[0].attributes.hashed_passphrase,
                'current_localconsole_host_ip': this.localconsoles_list[0].attributes.instance_orch === 'kubernetes' ? this.constants.KUBERNETES_LOCALCONSOLE_IP : ''
              });
            } else {
              this.set('current_localconsole', this.constants.NO_LOCALCONSOLES_CREATED);
            }
          }
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabled) {
              Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + label_id + '').addClass('draft_label_changed');
            }
          });
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabled) {
              Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + label_id + '').addClass('draft_label_changed');
            }
          });
        }
      } catch (newProfile) {
        this.debug.log('This is a new Profile', newProfile);
      }
    },
    actions: {
      toggle(otherInputId) {
        Ember.$('#' + otherInputId + '').prop('checked', false);
      },
      toggleAdditionalDetails(element) {
        if (element.target.checked) {
          Ember.$('#additional_details_splunk_receiver_type').show(200);
        } else {
          Ember.$('#additional_details_splunk_receiver_type').hide(200);
        }
      },
      toggleLocalconsoleDropdown(element) {
        this.set('localconsole_dropdown_disabled', !this.localconsole_dropdown_disabled);
        if (element.target.checked) {
          Ember.$('#localconsole_details').show(200);
        } else {
          Ember.$('#localconsole_details').hide(200);
        }
      },
      chooseLocalconsole(id, localconsole) {
        this.setProperties({
          'current_localconsole_id': id,
          'current_localconsole': localconsole
        });
        var self = this;
        this.localconsoles_list.forEach(localconsole => {
          if (localconsole.id === id) {
            self.set('current_localconsole_hashed_passphrase', localconsole.attributes.hashed_passphrase);
            self.set('current_localconsole_instance_orch', localconsole.attributes.instance_orch);
            if (localconsole.attributes.instance_orch === 'kubernetes') {
              self.set('current_localconsole_host_ip', self.constants.KUBERNETES_LOCALCONSOLE_IP);
            } else {
              self.set('current_localconsole_host_ip', '');
            }
          }
        });
      },
      saveDataExport(action, whenToPublish) {
        this.saveDataExport(action, whenToPublish);
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancel() {
        this.transitionToListView();
      },
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      }
    }
  });
  _exports.default = _default;
});