define("abcloud-ui/templates/components/im-design-system/im-buttons/im-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lN1uP+w8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"btn \",[21,\"className\"]]]],[11,\"disabled\",\"\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"im-design-system/im-spinner/spinner\",null,[[\"isLoading\"],[[23,[\"isLoading\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"btn \",[21,\"className\"]]]],[12,\"disabled\",[27,\"if\",[[23,[\"disabled\"]],\"disabled\",null],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"onclick\"]]],null]],[9],[0,\"\\n    \"],[1,[21,\"btnText\"],false],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/im-design-system/im-buttons/im-button.hbs"
    }
  });
  _exports.default = _default;
});