define("abcloud-ui/routes/insights/dashboard/discovery/apis", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service("ajax"),
    urls: Ember.inject.service("urls"),
    utils: Ember.inject.service('utils'),
    doughnutChart: Ember.inject.service("doughnut-chart"),
    stackedBarChart: Ember.inject.service("stacked-bar-chart"),
    lineChart: Ember.inject.service("line-chart"),
    queryParams: Ember.inject.service("query-params"),
    map: Ember.inject.service("map"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    filterParams: "",
    apis_by_time_request: "",
    apis_with_methods_request: "",
    apis_with_Methods_by_time_request: "",
    apis_responses_request: "",
    apis_responses_by_time_request: "",
    model() {
      this.set("filterParams", this.queryParams.getParams());
    },
    setupController(controller, model) {
      this._super(controller, model);
      var urls = this.urls;
      var params = this.filterParams;
      var self = this;
      this.doughnutChart.setup();
      this.lineChart.setup();
      this.stackedBarChart.setup();
      controller.setProperties({
        from_time: this.queryParams.getParam("from_time"),
        to_time: this.queryParams.getParam("to_time"),
        timeSelectedByUser: this.queryParams.getParam("timeSelectedByUser"),
        page: this.queryParams.getParam("page"),
        showR1C1Preloader: true,
        showR1C2Preloader: true,
        showR2C1Preloader: true,
        showR3C1Preloader: true,
        showR2C2Preloader: true,
        showR3C2Preloader: true
      });

      // -- TOTALS: alert
      var total_request = Ember.RSVP.hash({
        req: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_APIS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      total_request.then(() => {
        // -- ._result.dart => reference to Promise above
        var total = total_request._result.req.data[0].attributes.counts_by_type.total;
        controller.setProperties({
          apiTotal: total,
          apiTotalDisplay: this.utils.formatNumber(total),
          showR1C1Preloader: false
        });
      });

      // -- by time
      var apis_by_time_request = Ember.RSVP.hash({
        time_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_APIS_BY_TIME(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_by_time_request.then(() => {
        var apis = apis_by_time_request._result.time_data.data[0];
        controller.setProperties({
          apisByTime: apis,
          apisByTimeData: self.get("lineChart").getChartData(apis["attributes"]["chart_data"], apis["attributes"]["chart_labels"]),
          apisByTimeOpts: self.get("lineChart").getChartOptions(apis["attributes"]["chart_center_text"], apis["attributes"]["chart_date_label_aggregation_level"], apis["attributes"]["chart_data"] !== 0),
          showR1C2Preloader: false,
          apisByTimeDataAvailable: apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by volume
      var apis_with_methods_request = Ember.RSVP.hash({
        volume_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_APIS_WITH_METHODS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_methods_request.then(() => {
        var apis_with_method = apis_with_methods_request._result.volume_data.data[0];
        controller.setProperties({
          apisWithMethod: apis_with_method,
          apisWithMethodData: self.get("doughnutChart").getChartData(apis_with_method["attributes"]["chart_data"], apis_with_method["attributes"]["chart_labels"]),
          apisWithMethodOpts: self.get("doughnutChart").getChartOptions(apis_with_method["attributes"]["chart_center_text"], apis_with_method["attributes"]["chart_date_label_aggregation_level"], apis_with_method["attributes"]["chart_data"] !== 0),
          showR2C1Preloader: false,
          apisWithMethodDataAvailable: apis_with_method["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by params
      var apis_with_Methods_by_time_request = Ember.RSVP.hash({
        params_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_APIS_WITH_METHODS_BY_TIME(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_with_Methods_by_time_request.then(() => {
        var apis = apis_with_Methods_by_time_request._result.params_data.data[0];
        controller.setProperties({
          apisWithMethodByTime: apis,
          apisWithMethodByTimeData: self.get("lineChart").getChartData(apis["attributes"]["chart_data"], apis["attributes"]["chart_labels"]),
          apisWithMethodByTimeOpts: self.get("lineChart").getChartOptions(apis["attributes"]["chart_center_text"], apis["attributes"]["chart_date_label_aggregation_level"], apis["attributes"]["chart_data"] !== 0),
          showR3C1Preloader: false,
          apisWithMethodByTimeDataAvailable: apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // // -- by params timeseries
      var apis_responses_request = Ember.RSVP.hash({
        timeseries_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_APIS_RESPONSES(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_responses_request.then(() => {
        var apis = apis_responses_request._result.timeseries_data.data[0];
        controller.setProperties({
          apisResponses: apis,
          apisResponsesData: self.get("doughnutChart").getChartData(apis["attributes"]["chart_data"], apis["attributes"]["chart_labels"]),
          apisResponsesOpts: self.get("doughnutChart").getChartOptions(apis["attributes"]["chart_center_text"], apis["attributes"]["chart_date_label_aggregation_level"], apis["attributes"]["chart_data"] !== 0),
          showR2C2Preloader: false,
          apisResponsesDataAvailable: apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });

      // -- by params/volume timeseries
      var apis_responses_by_time_request = Ember.RSVP.hash({
        vol_timeseries_data: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-AB-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_SUMMARY_DISCOVERY_APIS_RESPONSES_BY_TIME(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      apis_responses_by_time_request.then(() => {
        var apis = apis_responses_by_time_request._result.vol_timeseries_data.data[0];
        controller.setProperties({
          apisResponsesByTime: apis,
          apisResponsesByTimeData: self.get("stackedBarChart").getChartData(apis["attributes"]["chart_data"], apis["attributes"]["chart_labels"]),
          apisResponsesByTimeOpts: self.get("stackedBarChart").getChartOptions(apis["attributes"]["chart_center_text"], apis["attributes"]["chart_date_label_aggregation_level"], apis["attributes"]["chart_data"] !== 0),
          showR3C2Preloader: false,
          apisResponsesByTimeDataAvailable: apis["attributes"]["chart_data"] === 0 ? false : true
        });
      });
      var threatMapLocationsRequest = Ember.RSVP.hash({
        threatMapLocationsData: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken('customer')}`
            },
            "X-CV-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(urls.URL_GET_THREAT_MAP_LOCATIONS(), params);
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      threatMapLocationsRequest.then(() => {
        var locations = threatMapLocationsRequest._result.threatMapLocationsData.data;
        controller.setProperties({
          threatLocations: locations,
          mapStyles: this.map.getMapStyles('dark'),
          markerTooltipOpen: false,
          mapIcon: {
            url: '/assets/images/maps/map_info_icon_22x22.png',
            // This marker is 20 pixels wide by 20 pixels high.
            size: new google.maps.Size(22, 22),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the left corner base
            anchor: new google.maps.Point(0, 0)
          },
          mapOptions: {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
          }
        });
      });
    },
    renderTemplate() {
      this.render("insights.dashboard.discovery.apis", {
        into: "insights.dashboard.discovery",
        outlet: "dashboard_data"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
          to_time: parseInt(_moment.default.utc().valueOf() / 1000),
          timeSelectedByUser: "86400",
          showR1C1Preloader: true,
          showR1C2Preloader: true,
          showR2C1Preloader: true,
          showR3C1Preloader: true,
          showR2C2Preloader: true,
          showR3C2Preloader: true,
          apisByTimeDataAvailable: true,
          apisWithMethodDataAvailable: true,
          apisWithMethodByTimeDataAvailable: true,
          apisResponsesDataAvailable: true,
          apisResponsesByTimeDataAvailable: true,
          apiTotal: 0,
          apiTotalDisplay: '',
          markerTooltipOpen: false,
          markers: [],
          mapIcon: null
        });
      }
    }
  });
  _exports.default = _default;
});