define("abcloud-ui/routes/deployments/stand-alone-controller/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    model() {
      try {
        return this.store.query("localcomponent", {
          component_type: "eeconsole"
        });
      } catch (noEeconsole) {
        return {};
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        eeconsoles: model,
        showPreloader: false
      });
    },
    renderTemplate() {
      this.render("deployments.stand-alone-controller.list", {
        into: "deployments",
        outlet: "deploymentsOutlet"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
      }
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("deployments.stand-alone-controller.list");
        controller.set("isControllersListLoading", true);
        transition.promise.finally(function () {
          controller.set("isControllersListLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});