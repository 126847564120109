define("abcloud-ui/controllers/customer/account/tenant-users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    replaceElementWith: Ember.inject.service("replace-element-with"),
    tenant: "",
    success: "",
    createUserAbility: "",
    isSuperAdmin: "",
    actions: {
      createNewUser() {
        this.transitionToRoute("customer.account.tenant-users.create-new-user");
      },
      resetPassword(tenantUser) {
        this.replaceElementWith.preloader(".reset-password");
        const url = this.urls.URL_ACCOUNT_RESET_PASSWORD(tenantUser.id);
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
          },
          "X-CV-Trace-ID": this.urls.generate_trace_id()
        });
        this.ajax.request(url, {
          method: "POST"
        }).then(response => {
          if (response && response.errors) {
            this.notifications.error(response.errors[0].detail);
          } else {
            this.notifications.success("Password has been reset and an email was sent to the user.");
          }
        }).catch(() => {
          this.notifications.error("Something wrong with the server please try again or contact us.");
        });
      },
      deleteTenantUser(tenant_user) {
        this.store.findRecord("tenant-user", tenant_user.id, {
          backgroundReload: false
        }).then(tenant_user => {
          tenant_user.destroyRecord().then(() => {
            this.notifications.success("Deleted successfully!");
            // -- grabbing all records except current user's
            //this.set('model', this.get('store').query('tenant_user', {}));
          }).catch(() => {
            this.notifications.error("Something went wrong, could not delete the user record.");
            tenant_user.rollbackAttributes();
          });
        }).catch(() => {
          this.notifications.error("Something went wrong, could not delete User.");
        });
      },
      editTenantUser(tenantId) {
        this.transitionToRoute("customer.account.tenant-users.edit", tenantId);
      }
    }
  });
  _exports.default = _default;
});