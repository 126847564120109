define("abcloud-ui/helpers/stringify-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringifyArray = stringifyArray;
  function stringifyArray(params) {
    var output = '';
    var [arrayVal, multiLine, displayClass] = params;
    if (arrayVal == undefined || arrayVal == null) {
      return '';
    }
    // prints array as a multi-line string
    if (multiLine === 1) {
      let arrayOfVals;
      if (Array.isArray(arrayVal) == true && arrayVal.length > 1) {
        arrayOfVals = arrayVal.split(',');
      } else {
        arrayOfVals = [arrayVal];
      }
      var ul = document.createElement('ul');
      ul.className = displayClass;
      for (var i = 0; i < arrayOfVals.length; i++) {
        let li = document.createElement('li');
        li.innerText = arrayOfVals[i];
        ul.append(li);
      }
      output = ul;
    } else {
      output = arrayVal.toString();
    }
    return output;
  }
  var _default = Ember.Helper.helper(stringifyArray);
  _exports.default = _default;
});