define("abcloud-ui/controllers/system/hosted/localconsole/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    urls: Ember.inject.service("urls"),
    services: Ember.inject.service("import_all_services"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      const defaultDisplay = "Kubernetes";
      const {
        type,
        orch
      } = this.constants.DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[defaultDisplay];
      this.setProperties({
        validTitle: null,
        install_mode: "easy",
        instanceDisplayChosen: defaultDisplay,
        instanceTypeChosen: type,
        instanceOrchestrationChosen: orch,
        showOrchestration: false,
        config_link: ""
      });
    },
    actions: {
      toggleConfigPanel() {
        Ember.$(".config_panel").toggleClass("collapsed");
        Ember.$(".config_options").toggleClass("expanded");
      },
      toggleInstallMode(event) {
        if (event.target.value === "easy") {
          this.set("install_mode", event.target.value);
        } else {
          this.set("install_mode", event.target.value);
        }
      },
      togglePassphraseView() {
        var passphraseType = Ember.$("#passphrase").attr("type");
        if (passphraseType === "password") {
          Ember.$("#passphrase").attr("type", "text");
        } else {
          Ember.$("#passphrase").attr("type", "password");
        }
      },
      onCreateLocalconsole() {
        var passphrase = "";
        var uuid = "";
        var hashed_passphrase = "";
        var title = document.getElementById("title").value;
        var description = document.getElementById("description").value;
        var isPassphraseValid = false;
        if (this.install_mode === "advanced") {
          uuid = this.services.encrypt.generateUUID();
          passphrase = document.getElementById("passphrase").value;
          if (passphrase) {
            isPassphraseValid = true;
            hashed_passphrase = this.services.encrypt.encryptString(passphrase, uuid);
          }
        } else {
          isPassphraseValid = true;
          uuid = this.services.encrypt.generateUUID();
          passphrase = "easyinstallmode";
          hashed_passphrase = this.services.encrypt.encryptString(passphrase, uuid);
        }
        if (title && this.validTitle && isPassphraseValid) {
          // -- Remove any notifications that might have shown before.
          let elements = {
            titleFilledValue: title,
            passphraseFilledValue: isPassphraseValid
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          var localConsole = this.store.createRecord("localcomponent", {
            component_type: "eeconsole",
            hashed_passphrase: hashed_passphrase,
            install_mode: this.install_mode,
            instance_orch: this.instanceOrchestrationChosen,
            instance_type: this.instanceTypeChosen,
            title: title,
            description: description,
            uuid: uuid
          });
          this.services.replace_element_with.preloader(".save");
          localConsole.save().then(response => {
            if (!response.errors.length) {
              this.setProperties({
                config_link: response.config_link
              });
            } else {
              localConsole.rollbackAttributes();
              this.notifications.error(response.errors[0].detail, {
                autoClear: true,
                clearDuration: 2000
              });
              this.debug.log("local console server error", response.errors[0].detail);
            }
          }).catch(error => {
            localConsole.rollbackAttributes();
            this.notifications.error(error.errors[0].detail, {
              autoClear: true,
              clearDuration: 2000
            });
            this.debug.log("local console server error", error.errors[0].detail);
          });
        } else {
          let elements = {
            titleFilledValue: title,
            passphraseFilledValue: isPassphraseValid
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      cancel(e) {
        this.transitionToRoute("system.hosted.localconsole");
      }
    }
  });
  _exports.default = _default;
});