define("abcloud-ui/controllers/policies/policies/protected-aws-s3-file-downloads", ["exports", "abcloud-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    errors: [],
    didUserSelectRecommendedSettings: true,
    userGroupProfileUpdated: false,
    newPolicy: "",
    policy_to_compare_current_changes_against: {},
    //-- these variables keep track of current value in the input elements --
    current_localbay_id: "",
    current_localbay: "",
    current_user_session: "",
    current_user_group: "",
    current_activity_session: "",
    localcomponents: "",
    restApiHostIp: "",
    restApiAuthToken: "",
    restApiHostIpFromGroupInfo: "",
    restApiAuthTokenForGroupInfo: "",
    current_dplet_id: "",
    current_dplet: "",
    current_dplet_options: [],
    //-- these values keep track of current of user selection of severities for those detections --
    unauth_user_severity: "High",
    expired_token_severity: "Medium",
    user_not_in_file_group_severity: "Medium",
    //-- updates the the corresponding objects based on user selection from dropdown menus --
    //-- These objects will be used to form the request_object in the end --
    current_user_session_object: "",
    current_user_group_object: "",
    current_activity_session_object: "",
    //-- contains unique severity options based on user choice (consumed by actions-information),
    // which gets updated with the value of newSet from the component. --
    severityValuesInSet: [{
      severity: "high",
      generate_alert_event: "true"
    }, {
      severity: "medium",
      generate_alert_event: "true"
    }],
    errorMessage: "",
    actions: {
      toggleDiffViewVisibility(viewRequest) {
        if (viewRequest === "hide") {
          var widthToHide = "-" + Ember.$(".diff_stack").css("width");
          Ember.$(".nav_stack").css("margin-left", widthToHide);
          Ember.$(".diff_stack_toggle_caret").css("visibility", "visible");
        } else if (viewRequest === "show") {
          Ember.$(".nav_stack").css("margin-left", "0");
          Ember.$(".diff_stack").css("display", "inline-block");
          Ember.$(".diff_stack_toggle_caret").css("visibility", "hidden");
        }
      },
      chooseLocalBay(localcomponentId, chosen_option, index) {
        this.setProperties({
          current_localbay: chosen_option,
          current_localbay_id: localcomponentId,
          restApiHostIp: this.localcomponents[index].load_balancer_ip,
          current_dplet_id: this.localcomponents[index].dplets.data[0].id,
          current_dplet: this.localcomponents[index].dplets.data[0].name,
          current_dplet_options: this.localcomponents[index].dplets.data
        });
      },
      chooseDplet(dplet_id, chosen_option) {
        this.setProperties({
          current_dplet: chosen_option,
          current_dplet_id: dplet_id
        });
      },
      chooseUserSessionProfile(user_session_id, chosen_option) {
        //-- for forming the request_object --
        this.user_session_profiles.forEach(user_session => {
          if (user_session.id === user_session_id) {
            this.set("current_user_session_object", user_session);
          }
        });
        //-- for display purpose in the input element --
        this.setProperties({
          current_user_session: chosen_option
        });
      },
      chooseUserGroupProfile(user_group_id, chosen_option) {
        //-- for forming the request_object --
        this.user_group_profiles.forEach(user_group => {
          if (user_group.id === user_group_id) {
            this.set("current_user_group_object", user_group);
          }
        });
        //-- for display purpose in the input element --
        this.setProperties({
          current_user_group: chosen_option
        });
      },
      chooseActivitySessionProfile(activity_session_id, chosen_option) {
        //-- for forming the request_object --
        this.activity_session_profiles.forEach(activity_session => {
          if (activity_session.id === activity_session_id) {
            this.set("current_activity_session_object", activity_session);
          }
        });
        //-- for display purpose in the input element --
        this.setProperties({
          current_activity_session: chosen_option
        });
      },
      //-- update different controller properties based on which component is requesting UUID --
      generateRestApiAuthToken(component) {
        var uuid = this.services.encrypt.generateUUID();
        if (component === "group_information") {
          this.setProperties({
            restApiAuthTokenForGroupInfo: uuid
          });
        } else {
          var url = this.services.urls.URL_GET_JWT_AUTH_TOKEN();
          this.services.ajax.request(url, {
            method: "GET"
          }).then(auth_token => {
            if (_environment.default.emnvironment === "local_dev") {
              this.set("restApiAuthToken", "this_is_for_dev");
            }
            this.set("restApiAuthToken", auth_token.data.jwt);
          }).catch(error => {
            this.set("restApiAuthToken", "this_is_for_dev");
          });
        }
      },
      /*
      this method collects 3 inputs on the UI(onchange), if he decides to type his own instead of auto generating it:
      rest API auth token from user-information and group-infromation,
      rest API host IP from group-information.
          */
      collectInput(controller) {
        if (controller.id === "restApiAuthTokenFromGroupInfo") {
          this.setProperties({
            restApiAuthTokenForGroupInfo: controller.value
          });
        } else if (controller.id === "restApiHostIpFromGroupInfo") {
          this.setProperties({
            restApiHostIpFromGroupInfo: controller.value
          });
        } else {
          this.set("restApiAuthToken", controller.value);
        }
      },
      //-- updating severityValuesInSet when user chooses a different option from the dropdown --
      unAuthUserSeverity(chosen_option, newSet) {
        const severityValuesInSet = this.accomodateNewSetIntoSeverityLevelResponses(newSet);
        this.setProperties({
          unauth_user_severity: chosen_option,
          didUserSelectRecommendedSettings: false,
          severityValuesInSet: severityValuesInSet
        });
      },
      expiredTokenSeverity(chosen_option, newSet) {
        const severityValuesInSet = this.accomodateNewSetIntoSeverityLevelResponses(newSet);
        this.setProperties({
          expired_token_severity: chosen_option,
          severityValuesInSet: severityValuesInSet,
          didUserSelectRecommendedSettings: false
        });
      },
      userNotInFileGroupSeverity(chosen_option, newSet) {
        const severityValuesInSet = this.accomodateNewSetIntoSeverityLevelResponses(newSet);
        this.setProperties({
          user_not_in_file_group_severity: chosen_option,
          severityValuesInSet: severityValuesInSet,
          didUserSelectRecommendedSettings: false
        });
      },
      //-- updating severityValuesInSet when user checks or unchecks an event detection --
      updateSet(newSet) {
        const severityValuesInSet = this.accomodateNewSetIntoSeverityLevelResponses(newSet);
        this.setProperties({
          severityValuesInSet: severityValuesInSet,
          didUserSelectRecommendedSettings: false
        });
      },
      resetToRecommendedSettings() {
        var newSet = new Set(["High", "Medium"]);
        const severityValuesInSet = this.accomodateNewSetIntoSeverityLevelResponses(newSet);
        this.setProperties({
          unauth_user_severity: "High",
          expired_token_severity: "Medium",
          user_not_in_file_group_severity: "Medium",
          severityValuesInSet: severityValuesInSet,
          didUserSelectRecommendedSettings: true
        });
      },
      //-- this method keeps track of user choice for opting alerts for events,
      //based on checkbox selection, the values for those checked keys will be consumed. --
      optForAlertEvent(severity, chosen_option) {
        chosen_option = chosen_option.toString();
        let severityValuesInSet = JSON.parse(JSON.stringify(this.severityValuesInSet));
        severityValuesInSet.forEach(actionsRow => {
          if (actionsRow.severity === severity) {
            actionsRow.generate_alert_event = chosen_option;
          }
        });
        this.set("severityValuesInSet", severityValuesInSet);
      },
      saveS3FileDownloads(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var current_user_session_object = this.current_user_session_object;
        var current_user_group_object = this.current_user_group_object;
        var current_activity_session_object = this.current_activity_session_object;
        var detected_actions_severity = {};
        if (document.getElementById("fileReqUnAuth").checked) {
          detected_actions_severity["file_download_from_unauthenticated_user"] = this.unauth_user_severity;
        }
        if (document.getElementById("userExpToken").checked) {
          detected_actions_severity["file_download_from_user_with_expired_token"] = this.expired_token_severity;
        }
        if (document.getElementById("userNotInFileGrp").checked) {
          detected_actions_severity["file_download_from_user_not_in_file_group"] = this.user_not_in_file_group_severity;
        }
        var getSeverityInfoForDetectionType = detectionType => {
          if (detected_actions_severity[detectionType]) {
            return detected_actions_severity[detectionType];
          } else {
            return "--Unchecked--";
          }
        };
        if (!this.newPolicy) {
          var attr = this.policy_to_compare_current_changes_against.json.data.attributes;
          var previous_user_group_profile = attr.user_group_profile.data.attributes.name;
          var previous_user_group_rest_api_host_ip = attr.user_group_rest_api_host_ip;
          var previous_user_group_rest_api_auth_token = attr.user_group_rest_api_auth_token;
          var previous_dplet = attr.dplet_name;
          if (previous_user_group_profile !== this.current_user_group || previous_user_group_rest_api_host_ip !== this.restApiHostIpFromGroupInfo || previous_user_group_rest_api_auth_token !== this.restApiAuthTokenForGroupInfo || previous_dplet !== this.current_dplet) {
            this.set("userGroupProfileUpdated", true);
          }
        }
        var profiles_ids = [current_user_session_object.id, current_user_group_object.id, current_activity_session_object.id];
        var localbay_details = {
          id: this.current_localbay_id,
          title: this.current_localbay
        };
        var dplet_details = {
          id: this.current_dplet_id,
          name: this.current_dplet
        };
        var changeset = {
          "nameLabel.Profile_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_policy": this.current_localbay,
          "userSessionProfileLabel.User_Information.User_Session": current_user_session_object.name,
          "userSessionRestApiAuthTokenLabel.User_Information.REST_API_Auth_Token": this.restApiAuthToken,
          "userGroupProfileLabel.Group_Information.User_group": current_user_group_object.name,
          "restApiHostIpLabel.Group_Information.REST_API_Host_IP": this.restApiHostIpFromGroupInfo,
          "userGroupRestApiAuthTokenLabel.Group_Information.REST_API_Auth_Token": this.restApiAuthTokenForGroupInfo,
          "dpletLabel.Group_Information.MicroSensor_that_calls_the_User_Group_Profile": this.current_dplet,
          "activitySessionProfileLabel.Download_Activity_Session_Information.Activity_Session_Profile": current_activity_session_object.name,
          "downloadActivityDpletLabel.Download_Activity_Session_Information.MicroSensor_that_calls_the_User_Group_Profile": this.current_dplet,
          "fileReqUnAuthLabel.Detection_and_Severity_Information.File_Download_Request_from_an_Unauthenticated_user": getSeverityInfoForDetectionType("file_download_from_unauthenticated_user"),
          "userExpTokenLabel.Detection_and_Severity_Information.File_Download_Request_from_a_user_with_expired_token": getSeverityInfoForDetectionType("file_download_from_user_with_expired_token"),
          "userNotInFileGrpLabel.Detection_and_Severity_Information.File_Download_Request_from_a_user_not_in_the_file_group": getSeverityInfoForDetectionType("file_download_from_user_not_in_file_group"),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Critical": this.services.build_policy_object_for_server.getActionsInfoForAlertEvent("critical", "generate_alert_event", this.severityValuesInSet),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.High": this.services.build_policy_object_for_server.getActionsInfoForAlertEvent("high", "generate_alert_event", this.severityValuesInSet),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Medium": this.services.build_policy_object_for_server.getActionsInfoForAlertEvent("medium", "generate_alert_event", this.severityValuesInSet),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Low": this.services.build_policy_object_for_server.getActionsInfoForAlertEvent("low", "generate_alert_event", this.severityValuesInSet),
          "actionsInfoLabel.Actions_Information.Generate_Alert_Event.Audit": this.services.build_policy_object_for_server.getActionsInfoForAlertEvent("audit", "generate_alert_event", this.severityValuesInSet)
        };
        var json_object = {
          user_session_rest_api_host_ip: this.restApiHostIp,
          user_session_rest_api_auth_token: this.restApiAuthToken,
          user_group_rest_api_host_ip: this.restApiHostIpFromGroupInfo,
          user_group_rest_api_auth_token: this.restApiAuthTokenForGroupInfo,
          user_session_profile: {
            data: {
              type: "profile",
              id: current_user_session_object.id,
              attributes: {
                sub_type: current_user_session_object.sub_type,
                name: current_user_session_object.name,
                description: current_user_session_object.description,
                version: current_user_session_object.profile_data.version,
                json: {
                  location: current_user_session_object.profile_data.json.location
                }
              }
            }
          },
          user_group_profile: {
            data: {
              type: "profile",
              id: current_user_group_object.id,
              attributes: {
                sub_type: current_user_group_object.sub_type,
                name: current_user_group_object.name,
                description: current_user_group_object.description,
                version: current_user_group_object.profile_data.version,
                json: {
                  location: current_user_group_object.profile_data.json.location,
                  api: current_user_group_object.profile_data.json.api,
                  file_path_conversion: current_user_group_object.profile_data.json.file_path_conversion
                }
              }
            }
          },
          activity_session_profile: {
            data: {
              type: "profile",
              id: current_activity_session_object.id,
              attributes: {
                sub_type: current_activity_session_object.sub_type,
                name: current_activity_session_object.name,
                description: current_activity_session_object.description,
                version: current_activity_session_object.profile_data.version,
                json: current_activity_session_object.profile_data.json
              }
            }
          },
          detected_actions_severity,
          severity_level_responses: this.severityValuesInSet
        };
        var top_level = {
          user_group_profile_updated: this.userGroupProfileUpdated
        };
        this.services.build_policy_object_for_server.set_type("policy", "s3_file_download");
        this.services.build_policy_object_for_server.set_common_top_level_values(localbay_details, profiles_ids, action, name, description, dplet_details);
        this.services.build_policy_object_for_server.set_changeset(changeset);
        this.services.build_policy_object_for_server.set_json(json_object);
        this.services.build_policy_object_for_server.set_top_level_values(top_level);
        var request_object = this.services.build_policy_object_for_server.get_request_object();
        if (name && description && this.restApiHostIpFromGroupInfo && this.restApiAuthToken) {
          var url = "";
          var method = "";
          if (this.newPolicy) {
            method = "POST";
            url = this.services.urls.URL_POLICY();
          } else {
            method = "PUT";
            url = this.services.urls.URL_POLICY(Number(this.policy.id));
          }
          let self = this;
          this.services.publish.policy(action, method, url, request_object, function (response) {
            if (response) {
              if (action === "save") {
                self.transitionToRoute("policies.policies");
              } else {
                if (self.newPolicy || self.userGroupProfileUpdated) {
                  self.transitionToRoute("system.hosted.microsensor.lambda.edit", response.data.attributes.dplet_id);
                } else {
                  self.transitionToRoute("policies.policies");
                }
              }
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            restApiAuthTokenFilledValue: this.restApiAuthToken,
            groupRestApiHostIpFilledValue: this.restApiHostIpFromGroupInfo,
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      },
      transitionToListView() {
        this.transitionToRoute("policies.policies");
      }
    },
    resetControllerProperties() {
      this.setProperties({
        current_localbay_id: "",
        current_localbay: "",
        current_user_session: "",
        current_user_group: "",
        current_activity_session: "",
        restApiHostIp: "",
        restApiAuthToken: "",
        restApiHostIpFromGroupInfo: "",
        restApiAuthTokenForGroupInfo: "",
        unauth_user_severity: "High",
        expired_token_severity: "Medium",
        user_not_in_file_group_severity: "Medium",
        current_user_session_object: "",
        current_user_group_object: "",
        current_activity_session_object: "",
        severityValuesInSet: [{
          severity: "high",
          generate_alert_event: "true"
        }, {
          severity: "medium",
          generate_alert_event: "true"
        }],
        errors: [],
        didUserSelectRecommendedSettings: true,
        userGroupProfileUpdated: false,
        errorMessage: ""
      });
    },
    accomodateNewSetIntoSeverityLevelResponses(newSet) {
      let severityValuesInSet = [];
      newSet.forEach(severity => {
        severity = severity.toLowerCase();
        let actionsRow = {
          severity,
          generate_alert_event: "true"
        };
        severityValuesInSet.push(actionsRow);
      });
      return severityValuesInSet;
    }
  });
  _exports.default = _default;
});