define("abcloud-ui/templates/system/hosted/localconsole/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t+BKneNi",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/localcomponent-form\",null,[[\"toggleConfigPanel\",\"toggleInstallMode\",\"togglePassphraseView\",\"cancel\",\"createLocalcomponent\",\"validTitle\",\"install_mode\",\"instanceDisplayChosen\",\"instanceTypeChosen\",\"instanceOrchestrationChosen\",\"showOrchestration\",\"config_link\",\"class\",\"localcomponentType\"],[[27,\"action\",[[22,0,[]],\"toggleConfigPanel\"],null],[27,\"action\",[[22,0,[]],\"toggleInstallMode\"],null],[27,\"action\",[[22,0,[]],\"togglePassphraseView\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"onCreateLocalconsole\"],null],[23,[\"validTitle\"]],[23,[\"install_mode\"]],[23,[\"instanceDisplayChosen\"]],[23,[\"instanceTypeChosen\"]],[23,[\"instanceOrchestrationChosen\"]],[23,[\"showOrchestration\"]],[23,[\"config_link\"]],\"wrapper_container\",\"localconsole\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/system/hosted/localconsole/new.hbs"
    }
  });
  _exports.default = _default;
});