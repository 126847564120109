define("abcloud-ui/templates/policies/profiles/api-session-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MvA4/lEp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"policy_card_view\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-8\"],[9],[0,\"\\n        API Session Token Profile\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"policies-tenant/profile-components/edit-api-session-token\",null,[[\"confirmPublish\",\"transitionToListView\",\"saveProfile\",\"disabled\",\"errorMessage\"],[[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"saveProfile\"],null],\"\",[23,[\"errorMessage\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/api-session-token.hbs"
    }
  });
  _exports.default = _default;
});