define("abcloud-ui/helpers/format-name-for-subdomain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNameForSubdomain = formatNameForSubdomain;
  function formatNameForSubdomain(name) {
    return name.replace(/\W+/gi, '').toLowerCase();
  }
  var _default = Ember.Helper.helper(formatNameForSubdomain);
  _exports.default = _default;
});