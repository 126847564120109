define("abcloud-ui/templates/components/dashboard-card-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ktirx8VZ",
    "block": "{\"symbols\":[\"count\",\"type\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-1\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card_summary_data\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"summary_total\"],[9],[0,\"\\n        \"],[1,[23,[\"model\",\"attributes\",\"total\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"p\"],[11,\"class\",\"summary_subheadings\"],[9],[0,\"\\n        \"],[1,[21,\"name\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-1\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"panel gray\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"model\",\"attributes\",\"counts_by_type\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[1,[27,\"dashboard-progress-bar\",null,[[\"count\",\"type\",\"total\"],[[22,1,[]],[22,2,[]],[23,[\"model\",\"attributes\",\"percent_total\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"      \"],[10],[2,\" ./panel \"],[0,\"\\n\\n  \"],[10],[2,\" ./pure-u-1 \"],[0,\"\\n\"],[10],[2,\" ./pure-g \"],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/dashboard-card-content.hbs"
    }
  });
  _exports.default = _default;
});