define("abcloud-ui/pods/application/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function (rawKey) {
      return Ember.String.underscore(rawKey);
    },
    normalizeQueryResponse(store, clazz, payload) {
      const result = this._super(...arguments);
      result.meta = result.meta || {};
      if (payload.links) {
        result.meta.pagination = this.createPageMeta(payload.links);
        if (result.meta.pagination.last) {
          result.meta.totals = this.getPaginationTotals(result.meta.pagination.last);
        }
      }
      return result;
    },
    createPageMeta(data) {
      let meta = {};
      Object.keys(data).forEach(type => {
        const link = data[type];
        meta[type] = {};
        let a = document.createElement('a');
        a.href = link;
        a.search.slice(1).split('&').forEach(pairs => {
          const [param, value] = pairs.split('=');
          if (param === 'page%5Bnumber%5D') {
            meta[type].number = parseInt(value);
          }
          if (param === 'page%5Bsize%5D') {
            meta[type].size = parseInt(value);
          }
        });
        a = null;
      });
      return meta;
    },
    getPaginationTotals(data) {
      // let span = document.createElement('span');
      let totals = {};
      totals.pageCount = data.number;
      totals.recordCount = data.number * data.size;
      return totals;
      // meta['pageCount'] = pageCount;
      // meta['recordCount'] = recordCount;
    }
  });
  _exports.default = _default;
});