define("abcloud-ui/controllers/policies/policies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    replace_element_with: Ember.inject.service("replace-element-with"),
    policyHeadings: ["Status", "Type", "Name", "Description", "Published by", "Published on", "Actions"],
    errors: [],
    model: "",
    actions: {
      transitionToEditView(profile_id, policy_type) {
        if (policy_type === "s3_file_download") {
          this.transitionToRoute("policies.policies.protected-aws-s3-file-downloads", profile_id);
        } else {
          this.transitionToRoute("policies.policies.edit", policy_type, profile_id);
        }
      },
      createProfile() {
        this.transitionToRoute("policies.profiles");
      },
      createNewPolicy(policy_type) {
        let new_policy = "";
        let missing_profile_requirement = "Profiles";
        let missing_localbay_requirement = "";
        let missing_dplet_requirement = "";
        let errors = [];
        let success_redirection_route = "";
        let failure_redirection_link_to_create_local_bay = "";
        let failure_redirection_link_to_create_profile = "";
        let failure_redirection_link_to_create_dplet = "";
        let additional_failure_link = "";

        // -- frame the text and links required to show on the template for variuos policy types
        switch (policy_type) {
          case "s3_file_download":
            missing_profile_requirement = "Profiles";
            missing_localbay_requirement = "Amazon AWS Lambda DPLet";
            new_policy = this.model.meta_data.data.attributes.s3_file_download;
            success_redirection_route = "policies.policies.protected-aws-s3-file-downloads";
            failure_redirection_link_to_create_local_bay = "system.hosted.localbay.add";
            additional_failure_link = "system.hosted.microsensor.lambda.new";
            failure_redirection_link_to_create_profile = "policies.profiles";
            break;
          case "data_export":
            missing_profile_requirement = "Data Export Profile";
            missing_localbay_requirement = "LocalBay";
            missing_dplet_requirement = "Amazon AWS Lambda DPLet";
            new_policy = this.model.meta_data.data.attributes.data_export;
            success_redirection_route = "policies.policies.data-export";
            failure_redirection_link_to_create_local_bay = "system.hosted.localbay.add";
            failure_redirection_link_to_create_profile = "policies.profiles.data-export";
            failure_redirection_link_to_create_dplet = "system.hosted.microsensor.lambda.new";
            break;
          case "out_of_spec_api":
            missing_profile_requirement = "API Specifications Profile";
            missing_localbay_requirement = "LocalBay";
            missing_dplet_requirement = "Amazon AWS Lambda DPLet";
            new_policy = this.model.meta_data.data.attributes.out_of_spec_api;
            success_redirection_route = "policies.policies.out-of-spec";
            failure_redirection_link_to_create_local_bay = "system.hosted.localbay.add";
            failure_redirection_link_to_create_profile = "policies.profiles.out-of-spec";
            failure_redirection_link_to_create_dplet = "system.hosted.microsensor.lambda.new";
            break;
          case "api_parameter_pin":
            missing_profile_requirement = "API Parameter Pinning Profile";
            missing_localbay_requirement = "LocalBay";
            missing_dplet_requirement = "Amazon AWS Lambda DPLet";
            new_policy = this.model.meta_data.data.attributes.api_parameter_pin;
            success_redirection_route = "policies.policies.api-parameter-pin";
            failure_redirection_link_to_create_local_bay = "system.hosted.localbay.add";
            failure_redirection_link_to_create_profile = "policies.profiles.api-parameter-pin";
            failure_redirection_link_to_create_dplet = "system.hosted.microsensor.lambda.new";
            break;
          case "api_session_token":
            missing_profile_requirement = "API Session Token Profile";
            missing_localbay_requirement = "LocalBay";
            missing_dplet_requirement = "Amazon AWS Lambda DPLet";
            new_policy = this.model.meta_data.data.attributes.api_session_token;
            success_redirection_route = "policies.policies.api-session-token";
            failure_redirection_link_to_create_local_bay = "system.hosted.localbay.add";
            failure_redirection_link_to_create_profile = "policies.profiles.api-session-token";
            failure_redirection_link_to_create_dplet = "system.hosted.microsensor.lambda.new";
            break;
          default:
            this.debug.log("unknown policy type", policy_type);
        }
        let minimum_requirements_met = true;
        for (let minimum_requirement in new_policy) {
          if (!new_policy[minimum_requirement]) {
            minimum_requirements_met = false;
            var error_details = {};
            switch (String(minimum_requirement)) {
              case "has_localbays":
                error_details["text"] = missing_localbay_requirement;
                error_details["link"] = failure_redirection_link_to_create_local_bay;
                error_details["type"] = policy_type;

                /*
                if the policy creation does not meet the minimum requirements, then generate the errors object based on the links and text populated above,
                note that if the policy type is s3_file_download we need additional link and param to redirect user to create a Lambda type Microsensor.
                */

                if (policy_type === "s3_file_download") {
                  error_details["additional_link"] = additional_failure_link;
                }
                errors.push(error_details);
                break;
              case "has_dplets":
                error_details["text"] = missing_dplet_requirement;
                error_details["link"] = failure_redirection_link_to_create_dplet;
                error_details["type"] = policy_type;
                errors.push(error_details);
                break;
              case "has_profiles":
                error_details["text"] = missing_profile_requirement;
                error_details["link"] = failure_redirection_link_to_create_profile;
                error_details["type"] = policy_type;
                errors.push(error_details);
                break;
              default:
                this.debug.log("unknown minimum requirement");
            }
          }
        }
        // -- if the policy has localcomponents, MicrSensors and profiles then take user to the create form
        if (minimum_requirements_met) {
          this.replace_element_with.left_justified_preloader(".dropdown-toggle");
          if (policy_type === "s3_file_download") {
            this.transitionToRoute(success_redirection_route, "new");
          } else {
            this.transitionToRoute(success_redirection_route);
          }
        } else {
          //-- set error object here which will trigger the Template to iterate errors object and show errors
          this.set("errors", errors);
        }
      },
      deletePolicy(policy_id) {
        var CLEAR_DURATION = this.constants.CLEAR_DURATION;
        var url = this.urls.URL_POLICY(policy_id);
        if (confirm("Are you sure you want to delete this Policy?")) {
          Ember.$(document).ajaxStart(() => {
            Ember.run(() => {
              Ember.$("tr#" + policy_id).css({
                opacity: "0.35",
                background: "#cacfc9"
              });
              Ember.$("tr#" + policy_id + " td:last-child").html("").text("deleting ...");
            });
          });
          this.ajax.request(url, {
            method: "DELETE"
          }).then(data => {
            if (data && data.errors && data.errors[0].detail) {
              this.notifications.error(data.errors[0].detail, {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            } else {
              this.notifications.success("Deleted successfully!", {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            }
            url = this.urls.URL_POLICY();
            this.ajax.request(url, {
              method: "GET"
            }).then(policies => {
              url = this.urls.URL_GET_PROFILES_FOR_POLICIES();
              this.ajax.request(url, {
                method: "GET"
              }).then(meta_data => {
                var structuredModel = {
                  policies: policies,
                  meta_data: meta_data
                };
                this.set("model", structuredModel);
              });
            });
          }).catch(() => {
            this.notifications.error("Failed to delete!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
          });
        }
      }
    }
  });
  _exports.default = _default;
});