define("abcloud-ui/routes/policies/api-recorder/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service('ajax'),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    queryParams: Ember.inject.service('query-params'),
    policyId: '',
    recording_status: '',
    model(params) {
      this.setProperties({
        policyId: params.id,
        recording_status: this.queryParams.getParam('recording_status')
      });
      const urls = this.urls;
      Ember.$.ajaxSetup({
        headers: {
          Authorization: `Bearer ${this.auth.getAuthToken('customer')}`,
          'Access-Control-Allow-Origin': '*',
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      const request1 = Ember.$.getJSON(urls.URL_POLICY(params.id));
      const routeName = this.routeName;
      let request2 = '';
      let request3 = '';
      let requests = [{
        [routeName]: request1
      }];
      let objectToHash = {
        policy: request1
      };
      if (this.recording_status === this.constants.NOT_PUBLISHED) {
        request2 = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES('api_recorder'));
        requests.push({
          [routeName]: request2
        });
        objectToHash['dropdownOptions'] = request2;
      }
      if (this.recording_status === this.constants.RECORDING) {
        request3 = Ember.$.getJSON(urls.URL_POLL_API_RECORDER(params.id));
        requests.push({
          [routeName]: request3
        });
        objectToHash['pollData'] = request3;
      } else if (this.recording_status === this.constants.DONE_RECORDING) {
        request3 = Ember.$.getJSON(urls.URL_COMPLETED_API_RECORDER(params.id), {
          is_api: true
        });
        requests.push({
          [routeName]: request3
        });
        objectToHash['pollData'] = request3;
      }
      this.manage_requests.add_requests(requests);
      return Ember.RSVP.hash(objectToHash);
    },
    setupController(controller, model) {
      try {
        const dplet = model.policy.data.attributes.dplet;
        const localbay = model.policy.data.attributes.localbay;
        const policy = model.policy.data.attributes;
        let microsensorList = '';
        if (this.recording_status === this.constants.NOT_PUBLISHED) {
          if (Ember.$.isEmptyObject(model.dropdownOptions.data.attributes.localbays)) {
            this.transitionTo('policies.api-recorder');
          } else {
            const localbaysList = model.dropdownOptions.data.attributes.localbays.data;
            const {
              localcomponent_ids,
              localbay_ids,
              policy_filters: {
                data_type: {
                  api: recordOnlyApi
                } = {}
              } = {}
            } = policy.policy_data[0].json.data.attributes;
            localbaysList.forEach(_localbay => {
              // Note: localbay_ids is deprecated.
              if (_localbay.id === (localbay_ids && localbay_ids[0]) || _localbay.id === (localcomponent_ids && localcomponent_ids[0])) {
                microsensorList = _localbay.dplets.data;
              }
            });
            controller.setProperties({
              localbaysList: localbaysList,
              microsensorList: microsensorList,
              recordOnlyApis: !!recordOnlyApi
            });
          }
        }
        if (this.recording_status === this.constants.DONE_RECORDING) {
          const policyFilters = policy.policy_data[0].json.data.attributes.policy_filters;
          const recordOnlyApi = policyFilters && policyFilters.data_type ? policyFilters.data_type.api : true;
          const poll_data_id = this.policyId;
          const pollData = model.pollData.data && !Array.isArray(model.pollData.data) ? model.pollData.data.attributes.data : [];
          controller.setProperties({
            exportToCloud: this.checkIfExportToCloud(model.pollData),
            exportToLocalconsole: this.checkIfExportToLocalconsole(model.pollData),
            localconsoleExportData: this.getExportToLocalconsoleData(model.pollData),
            recordOnlyApis: !!recordOnlyApi,
            pollData: pollData && pollData.length ? pollData : policy.current_state === 'finished' ? this.constants.NO_APIS_FOUND : this.constants.PREPARING_DATA,
            poll_data_id: poll_data_id,
            apiCount: pollData && pollData.length ? pollData.length : '--'
          });
        }
        if (this.recording_status === this.constants.RECORDING) {
          controller.setProperties({
            exportToCloud: this.checkIfExportToCloud(model.pollData),
            exportToLocalconsole: this.checkIfExportToLocalconsole(model.pollData),
            localconsoleExportData: this.getExportToLocalconsoleData(model.pollData),
            pollData: model.pollData.data.length ? model.pollData.data.attributes.data : [],
            poll_data_id: 0
          });
        }
        const {
          localcomponent_title,
          localcomponent_ids,
          localbay_title,
          localbay_ids,
          dplet_id,
          dplet_name
        } = policy.policy_data[0].json.data.attributes;
        const scheduleInfo = policy.policy_data[0].json.data.attributes.schedule_information;
        if (scheduleInfo.manual_stop) {
          controller.set('schedule_info', this.constants.MANUAL_STOP_RECORDING);
        } else if (scheduleInfo.auto_stop) {
          controller.set('schedule_info', this.constants.AUTO_STOP(scheduleInfo.auto_stop));
        }
        controller.setProperties({
          dplet: dplet,
          localbay: localbay,
          policy: policy,
          policyId: this.policyId,
          // Note(sanath): localbay_ids , localbay_title are deprecated
          currentLocalbay: localcomponent_title || localbay_title,
          currentLocalbayId: localcomponent_ids ? localcomponent_ids[0] : localbay_ids ? localbay_ids[0] : undefined,
          currentMicrosensorId: dplet_id,
          currentMicrosensor: dplet_name,
          microsensorList: microsensorList,
          can_publish: policy['can_record?'],
          recording_status: this.recording_status,
          isRecording: !!(this.recording_status === this.constants.RECORDING)
        });
        if (this.recording_status === this.constants.RECORDING) {
          controller.set('pollData', this.constants.RECORDING_IN_PROGRESS);
          if (controller.get('exportToLocalconsole') && !controller.get('exportToCloud')) {
            // dont poll when export to local console and not to cloud
          } else {
            controller.send('pollApiDetails');
          }
        }
      } catch (dataInitiationGoneWrong) {
        this.debug.log('Something went wrong with data initiation', dataInitiationGoneWrong);
        this.transitionTo('policies.api-recorder');
      }
    },
    checkIfExportToLocalconsole(polldata) {
      const {
        data_target
      } = polldata;
      if (data_target && Array.isArray(data_target)) {
        const localconsoleData = data_target.find(target => target.localconsole);
        return !!localconsoleData;
      }
      return false;
    },
    getExportToLocalconsoleData(polldata) {
      const {
        data_target
      } = polldata;
      if (data_target && Array.isArray(data_target)) {
        const localconsoleData = data_target.find(target => target.localconsole);
        return localconsoleData ? localconsoleData.localconsole : undefined;
      }
      return undefined;
    },
    checkIfExportToCloud(polldata) {
      const {
        data_target
      } = polldata;
      if (data_target && Array.isArray(data_target)) {
        const isCloud = data_target.find(target => target.abcloud);
        return !!isCloud;
      }
      return false;
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          currentLocalbay: '',
          dplet: '',
          localbay: '',
          currentLocalbayId: '',
          localbayList: '',
          currentMicrosensor: '',
          currentMicrosensorId: '',
          microsensorList: '',
          policyId: '',
          recording_complete: '',
          headings: ['Method', 'API'],
          apiDetails: '',
          displayPane: false,
          labels: undefined,
          isFetchingSelectedRowData: false,
          detailCellId: 'detail_pane',
          tableClass: 'data-list-table',
          current_selected_row: '',
          pollData: '',
          download_error: false,
          currentFilterOption: 'Only APIs',
          currentHeading: 'API',
          apiCount: '--',
          recordOnlyApis: true,
          exportToLocalconsole: false,
          exportToCloud: true,
          localconsoleExportData: undefined,
          canPolicyResume: true,
          errorMessage: ''
        });
      }
    }
  });
  _exports.default = _default;
});