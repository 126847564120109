define("abcloud-ui/pods/paginator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didReceiveAttrs() {
      this._super(...arguments);
      const itemCount = this.totalNoOfItems || 0;
      const pageSize = 25;
      this.setProperties({
        currentPage: this.page,
        totalPages: Math.ceil(itemCount / pageSize)
      });
      this.fillPageSet();
    },
    tagName: '',
    fillPageSet() {
      const _page = this.get('currentPage');
      const _totalPages = this.get('totalPages');
      let _pageSet = [];
      if (_totalPages <= 5) {
        for (let index = 1; index <= _totalPages; index++) {
          _pageSet.push(index);
        }
      } else {
        if (_page + 2 >= _totalPages) {
          for (let index = _totalPages; index >= _totalPages - 5; index--) {
            _pageSet.unshift(index);
          }
        } else if (_page - 2 <= 1) {
          for (let index = 1; index <= 5; index++) {
            _pageSet.push(index);
          }
        } else {
          for (let index = _page - 2; index <= _page + 2; index++) {
            _pageSet.push(index);
          }
        }
      }
      this.set('pageSet', _pageSet);
    },
    actions: {
      gotoPage(_page) {
        const _currentPage = this.get('currentPage');
        const _pageCount = this.get('totalPages');
        switch (_page) {
          case 'last':
            this.set('currentPage', _pageCount);
            break;
          case 'first':
            this.set('currentPage', 1);
            break;
          case 'next':
            this.set('currentPage', _currentPage + 1);
            break;
          case 'prev':
            this.set('currentPage', _currentPage - 1);
            break;
          default:
            this.set('currentPage', _page);
            break;
        }
        this.onPageChange(this.get('currentPage'));
      }
    }
  });
  _exports.default = _default;
});