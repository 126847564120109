define("abcloud-ui/helpers/format-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  // takes an epoch timestamp as input, and possibly  a format string
  // and return a human-readable date/time 
  function formatDate(dateString) {
    if (Array.isArray(dateString)) {
      dateString = dateString[0];
    }
    if (String(dateString).length < 13) {
      dateString = dateString * 1000;
    }
    let d = new Date(dateString);
    let dateSeparator = '/';
    let timeSeparator = ':';
    let formattedDate = '';
    let zone = d.toLocaleTimeString('en-us', {
      timeZoneName: 'short'
    }).split(' ')[2];
    // if((formatStyle == 'undefined') || (formatStyle == null)) {
    formattedDate += [d.getMonth() + 1, d.getDate(), d.getFullYear()].join(dateSeparator);
    formattedDate += ' ';
    formattedDate += [('0' + d.getHours()).slice(-2), ('0' + d.getMinutes()).slice(-2), ('0' + d.getSeconds()).slice(-2)].join(timeSeparator);
    // }
    return formattedDate + ' ' + zone;
  }
  var _default = Ember.Helper.helper(formatDate);
  _exports.default = _default;
});