define("abcloud-ui/templates/components/inline-notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jlnPXT1R",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showNotification\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"notification-message-container --\",[21,\"status\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row align-items-center\"],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"col-1 fa--\",[21,\"status\"]]]],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-9 notification-message\"],[9],[0,\"\\n        \"],[1,[21,\"message\"],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"col-1 close-icon\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"closeNotification\"],null]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/inline-notification-message.hbs"
    }
  });
  _exports.default = _default;
});