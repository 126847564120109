define("abcloud-ui/components/dashboard/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['pure-g'],
    maxTextWidth: 10,
    filledWidth: 0,
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('filledWidth', 0);
    },
    didInsertElement() {
      let self = this;
      self.set('filledWidth', (self.count / self.total * 100).toFixed(0));
      this.$('.filled').css('width', this.filledWidth + '%');

      // -- calculate width based on width of element associated with component
      this.send('calcWidth');
      Ember.$(window).on('resize', function () {
        self.send('calcWidth');
      });
    },
    willDestroyElement() {
      Ember.$(window).off('resize');
    },
    actions: {
      calcWidth() {
        this.set('maxTextWidth', parseInt(this.element.offsetWidth / 6)); //=~ 6-8px per roman character
      }
    }
  });
  _exports.default = _default;
});