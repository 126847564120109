define("abcloud-ui/helpers/calc-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcPercent = calcPercent;
  _exports.default = void 0;
  function calcPercent(num, total) {
    let width = total['num'] / total['total'] * 100;
    if (width < 1) {
      return width.toFixed(2);
    } else {
      return width.toFixed(0);
    }
  }
  var _default = Ember.Helper.helper(calcPercent);
  _exports.default = _default;
});