define("abcloud-ui/services/ab-ajax-requests/server", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notifications: Ember.inject.service(),
    request(method, url, request_object, results, error) {
      Ember.$.ajaxSetup({
        headers: {
          Authorization: `Bearer ${this.auth.getAuthToken(this.auth.getRole())}`,
          "X-AB-Trace-ID": this.urls.generate_trace_id()
        }
      });
      this.ajax.request(url, {
        method: method,
        contentType: "application/json",
        dataType: "json",
        data: request_object
      }).then(response => {
        return results(response);
      }).catch(unexpectedError => {
        if (unexpectedError.status === 500) {
          this.notifications.error(this.constants.SOMETHING_WENT_WRONG, {
            autoClear: true,
            clearDuration: 5000
          });
        }
        return error(unexpectedError);
      });
    }
  });
  _exports.default = _default;
});