define("abcloud-ui/models/summary/app-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    end_time: _emberData.default.attr('number'),
    ip_range: _emberData.default.attr('string'),
    req_byte_count: _emberData.default.attr('number'),
    rsp_byte_count: _emberData.default.attr('number'),
    req_pkt_count: _emberData.default.attr('number'),
    rsp_pkt_count: _emberData.default.attr('number'),
    destination_port: _emberData.default.attr('string')
  });
  _exports.default = _default;
});