define("abcloud-ui/services/utils", ["exports", "abcloud-ui/config/environment", "lodash", "numbro"], function (_exports, _environment, _lodash, numbro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    formatNumber(value) {
      if (isNaN(value)) {
        return;
      }
      if (value > 999) {
        return numbro(value).format({
          average: true,
          mantissa: 1,
          optionalMantissa: true
        });
      } else {
        return value;
      }
    },
    convertToQueryString(obj, prefix) {
      var str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push(v !== null && typeof v === "object" ? this.convertToQueryString(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&");
    },
    getEnvironmentWebProtocol() {
      if (_environment.default.environment == null || _environment.default.environment == undefined) {
        return "http";
      }
      if (_environment.default.environment === this.constants.ENVIRONMENTS.PRODUCTION || _environment.default.environment === this.constants.ENVIRONMENTS.STAGING) {
        return this.constants.PROTOCOLS.HTTPS;
      } else {
        return this.constants.PROTOCOLS.HTTP;
      }
    },
    getEnvironmentWebHost() {
      return this.constants.WEB_HOSTS[_environment.default.environment];
    },
    getEnvironmentPort() {
      let port = null;
      if (_environment.default.environment == null || _environment.default.environment == undefined) {
        return port;
      }
      if (_environment.default.environment == this.constants.ENVIRONMENTS.LOCAL_DEV) {
        port = "4333";
      }
      return port;
    },
    getEnvironmentUrl() {
      let protocol = this.getEnvironmentWebProtocol();
      let port = this.getEnvironmentPort();
      let host = this.getEnvironmentWebHost();
      let environmentUrl = [protocol, host].join("://");
      if (port) {
        environmentUrl = [environmentUrl, port].join(":");
      }
      return environmentUrl;
    },
    getEnvironmentDomainName() {
      let host = this.getEnvironmentWebHost();
      let port = this.getEnvironmentPort();
      if (port) {
        host = [host, port].join(":");
      }
      return host;
    },
    // -- return the protocol, host for a specific resource, e.g.: admin, adminUser
    getLoginPathForResource(resourceType) {
      let domain = this.getEnvironmentDomainName();
      let loginPath = "";
      if (resourceType === "admin") {
        loginPath = "admin/login";
      } else if (resourceType === "customer") {
        loginPath = "customer/login";
      } else {
        loginPath = "manage/users/login";
      }
      return [domain, loginPath].join("/");
    },
    validateForm(newValidations) {
      const validations = (0, _lodash.cloneDeep)(newValidations);
      let isFormValid = true;

      /* 
      Check if every element's validation in the form isValid, except for isFormValid key 
      (isFormValid key carries the validity of the entire form, 
      but not any single form element) 
      */
      Object.keys(validations).filter(fControl => fControl !== "isFormValid").forEach(fControl => {
        if (!validations[fControl].isValid) {
          isFormValid = false;
        }
      });
      validations.isFormValid = isFormValid;
      return validations;
    },
    /* 
      validations object
       validations = {
        isFormValid: boolean (sets the form to valid/invalid)
         <formControlName>: {
          isValid: boolean,
          
          required: boolean,
          
          length: {
            min: Number (optional),
            max: Number (optional),
            invalidFeedback: String
          },
           format: {
            after: (title) => {
              // Do anything with title, update the title, 
              // after which any other validations will run
              
              self.set("title", this.services.utils.preFormatTitle(title))
            },
             with: <Regex String> (the title will be matched with this regex)
          },
           // force show/hide any custom invalid message for the input
          invalidFeedback: {
            show: boolean,
            message: String
          },
        }
      }
    */
    validateCurrentElement(_ref) {
      let {
        fControlValue = "",
        formValidations = {},
        fControlName = ""
      } = _ref;
      let isInvalid = [];
      let invalidFeedback = [];
      let isValid = true;
      const validations = (0, _lodash.cloneDeep)(formValidations);
      const fControlValidations = validations.hasOwnProperty(fControlName) ? validations[fControlName] : {};
      if (!(0, _lodash.isEmpty)(fControlValidations)) {
        Object.keys(fControlValidations).forEach(validation => {
          switch (validation) {
            case "length":
              {
                /* 
                  if min or max values are not provided in the validations object, the size of
                  the value is taken as default, in which case, this criteria will always be met
                */
                const minLength = fControlValidations["length"].min || (0, _lodash.size)(fControlValue);
                const maxLength = fControlValidations["length"].max || (0, _lodash.size)(fControlValue);
                const isCriteriaMet = (0, _lodash.size)(fControlValue) >= minLength && (0, _lodash.size)(fControlValue) <= maxLength;
                if (!isCriteriaMet) {
                  isInvalid.push(true);
                  invalidFeedback.push(validations[fControlName][validation].invalidFeedback);
                }
                break;
              }
            case "format":
              {
                if (fControlValidations["format"].hasOwnProperty("after")) {
                  fControlValidations["format"].after(fControlValue);
                }
                if (fControlValidations["format"].hasOwnProperty("with")) {
                  const isAMatch = new RegExp(fControlValidations["format"].with).test(fControlValue);
                  if (!isAMatch) {
                    isInvalid.push(true);
                    invalidFeedback.push(validations[fControlName][validation].invalidFeedback);
                  }
                }
                break;
              }
            case "required":
              {
                if (fControlValidations["required"]) {
                  const isValueEmpty = (0, _lodash.isEmpty)(fControlValue);
                  if (isValueEmpty) {
                    isInvalid.push(true);
                    invalidFeedback.push(this.constants.MANDATORY_FIELD);
                  }
                }
                break;
              }
            case "invalidFeedback":
              {
                if (fControlValidations["invalidFeedback"].show) {
                  isInvalid.push(true);
                  invalidFeedback.push(fControlValidations["invalidFeedback"].message);
                }
              }
          }
        });

        /* 
          if at least one of the criteria is not met, the element is set to invalid
          Except,
          when entered value is empty and the formControl is not "required", in which case,
          we don't want to show user any error
        */

        const isValueEmptyButNotRequired = (0, _lodash.isEmpty)(fControlValue) && !fControlValidations.hasOwnProperty("required");
        if (isValueEmptyButNotRequired) {
          isValid = null;
        } else {
          isValid = (0, _lodash.isEmpty)(isInvalid);
        }

        /* 
          If one of the errors is for mandatory field, then remove all other errors,
          and only show mandatory field error for that input to the user
        */
        if (invalidFeedback.includes(this.constants.MANDATORY_FIELD)) {
          invalidFeedback = invalidFeedback.filter(feedback => feedback === this.constants.MANDATORY_FIELD);
        }

        /* 
          validations object needs absolute boolean value for isValid (either true/false).
          Null value for it is not supported, because we deduce overall form's validity 
          based off of it.
          
          When value is empty but not required, fControl is set to true automatically,
          else, we check for if it's proper validity
        */
        fControlValidations["isValid"] = isValueEmptyButNotRequired ? true : (0, _lodash.isEmpty)(isInvalid);
      }
      return {
        validations,
        invalidFeedback,
        isValid
      };
    },
    preFormatTitle(title) {
      return title.replace(this.constants.TITLE_REGEX, "_");
    },
    preFormatSubDomain(subDomain) {
      return subDomain.replace(this.constants.SUB_DOMAIN_REGEX, "-");
    }
  });
  _exports.default = _default;
});