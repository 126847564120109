define("abcloud-ui/router", ["exports", "abcloud-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("not-found", {
      path: "/*path"
    });
    this.route("admin", function () {
      this.route("customers", function () {
        this.route("delete");
        this.route("edit", {
          path: "/edit/:customer_id"
        });
        this.route("new");
        this.route("add-tenant", {
          path: "/:customer_id/new_tenant"
        });
        this.route("customer-tenants", {
          path: "/:customer_id"
        }, function () {});
      });
      this.route("users", function () {
        this.route("list", {
          path: "/"
        });
        this.route("new");
        this.route("delete");
        this.route("edit", {
          path: "/edit/:admin_user_id"
        });
      });
      this.route("login");
    });
    this.route("manage", function () {
      this.route("customers", function () {
        this.route("delete");
        this.route("edit", {
          path: "/edit/:customer_id"
        });
        this.route("new");
        this.route("add-tenant", {
          path: "/:customer_id/new_tenant"
        });
        this.route("customer-tenants", {
          path: "/:customer_id"
        }, function () {});
      });
      this.route("users", function () {
        this.route("login");
        this.route("list");
        this.route("new");
        this.route("delete");
        this.route("edit", {
          path: "/edit/:admin_user_id"
        });
      });
      this.route('reset-password');
    });
    this.route("customer", function () {
      this.route("login");
      this.route("account", function () {
        this.route("self-account");
        this.route("tenant-users", {
          path: "for/current_user"
        }, function () {
          this.route("create-new-user");
          this.route("edit", {
            path: "/:tenant_user_id"
          });
        });
      });
    });
    this.route("insights", function () {
      this.route("dashboard", function () {
        this.route("discovery", function () {
          this.route("new-apis");
          this.route("apis");
        });
        this.route("security", function () {
          this.route("dart");
          this.route("data-leakage");
          this.route("policy-exceptions");
          this.route("shadow-apis");
          this.route("events");
        });
      });
    });
    this.route("docs", {
      path: "help"
    }, function () {
      this.route("doc", {
        path: ":path_name"
      });
    });
    this.route("exceptions", function () {
      this.route("security", {
        path: "security/:filter_type"
      }, function () {
        this.route("alert", {
          path: "alert/:id"
        });
      });
      this.route("transactions", function () {
        this.route("transaction", {
          path: ":transaction_id"
        });
      });
      this.route("events", {
        path: "events/:event_filter_type"
      }, function () {
        this.route("event", {
          path: ":event_id"
        });
      });
    });
    this.route("policies", function () {
      this.route("policies", function () {
        this.route("protected-aws-s3-file-downloads", {
          path: "/:policy_id"
        });
        this.route("data-export");
        this.route("edit", {
          path: ":policy_type/:policy_id"
        });
        this.route("out-of-spec");
        this.route("api-parameter-pin");
        this.route("api-session-token");
      });
      this.route("profiles", function () {
        this.route("activity-sessions");
        this.route("data-export");
        this.route("edit", {
          path: ":profile_id/edit"
        });
        this.route("user-groups");
        this.route("user-sessions");
        this.route("out-of-spec");
        this.route("api-parameter-pin");
        this.route("api-session-token");
        this.route("session-termination", function () {
          this.route("new", {
            path: "/"
          });
          this.route("edit", {
            path: ":id"
          });
        });
      });
      this.route("api-recorder", function () {
        this.route("new");
        this.route("edit", {
          path: "edit/:id"
        });
      });
    });
    this.route("reports", function () {
      this.route("user-access-report");
      this.route("user-transaction-report");
    });
    this.route("system", function () {
      this.route("health");
      this.route("hosted", function () {
        this.route("microsensor", function () {
          this.route("api-receiver", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
          this.route("f5-hsl-consumer", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
          this.route("lambda", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
          this.route("passive-sniffer", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
          this.route("kong-api-gateway", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
          this.route("kafka", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
          this.route("nginx", function () {
            this.route("edit", {
              path: ":id"
            });
            this.route("new", {
              path: "/"
            });
          });
        });
        this.route("localbay", function () {
          this.route("add");
          this.route("edit", {
            path: "/:id"
          });
          this.route("detail", {
            path: "detail/:id"
          });
        });
        this.route("localconsole", function () {
          this.route("new");
          this.route("edit", {
            path: ":id"
          });
        });
      });
      this.route("events", function () {
        // this.route('pages', {path: 'pages/:page_id'});
        this.route("event", {
          path: ":event_id"
        });
      });
      this.route("user-authentication", function () {
        this.route("sso");
      });
    });
    this.route("deployments", function () {
      this.route("stand-alone-controller", function () {
        this.route("index");
        this.route("new");
        this.route("list");
        this.route("edit", {
          path: "/:id"
        });
      });
      this.route("index");
    });
    this.route("not-found", {
      path: "/*path"
    });
    this.route("sso", function () {
      this.route("oidc", function () {
        this.route("auth");
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});