define("abcloud-ui/components/policies-tenant/policy-components/edit-api-recorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      try {
        var scheduleInformation = this.policy_data.json.data.attributes.schedule_information;
        if (scheduleInformation.hasOwnProperty('auto_stop')) {
          Ember.$('#autoTime').val(scheduleInformation.auto_stop.slice(' ')[0]);
          document.getElementById('auto_stop').checked = true;
          document.getElementById('manual_stop').checked = false;
          Ember.$('#manualTime').addClass('light_text');
          Ember.$('#autoTime').removeAttr('disabled');
        } else {
          document.getElementById('manual_stop').checked = true;
          document.getElementById('auto_stop').checked = false;
          Ember.$('#autoTime').attr('disabled', 'true');
          Ember.$('#manualTime').removeClass('light_text');
        }
      } catch (newAPIRecorder) {
        this.debug.log('This is a new API Recorder', newAPIRecorder);
      }
    },
    actions: {
      savePolicy(action) {
        this.savePolicy(action);
      },
      cancel() {
        this.cancel();
      },
      chooseLocalbay(localcomponentId, localcomponent) {
        this.chooseLocalbay(localcomponentId, localcomponent);
      },
      chooseMicroSensor(microsensorId, microsensor) {
        this.chooseMicroSensor(microsensorId, microsensor);
      },
      onChangeRecordType(event) {
        this.set("recordOnlyApis", event.target.value.toLowerCase() === 'true');
      }
    }
  });
  _exports.default = _default;
});