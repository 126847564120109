define("abcloud-ui/routes/admin/customers/customer-tenants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    model(params) {
      return this.store.findRecord("customer", params.customer_id, {
        include: "customer_tenants",
        filter: {
          is_deleted: false
        }
      });
    },
    setupController(controller, model) {
      let supportUser = [];
      controller.set("model", model);
    },
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
    },
    renderTemplate() {
      this.render("admin.customers.customer-tenants", {
        into: "application",
        outlet: "admin"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("admin.customers.customer-tenants");
        controller.set("isCustomerTenantsLoading", true);
        transition.promise.finally(function () {
          controller.set("isCustomerTenantsLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});