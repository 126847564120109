define("abcloud-ui/templates/customer/account/tenant-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YPxx+hVu",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"outlet\",[\"account-edit\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/customer/account/tenant-users.hbs"
    }
  });
  _exports.default = _default;
});