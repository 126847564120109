define("abcloud-ui/routes/policies/profiles/out-of-spec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    ajax: Ember.inject.service('ajax'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    api_recording_id: '',
    queryParams: {
      api_recording_id: {
        refreshModel: true
      }
    },
    model(params) {
      this.set('api_recording_id', params.api_recording_id);
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-CV-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      var request = Ember.$.getJSON(urls.URL_MINIMUM_API_RECORDINGS());
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      if (this.api_recording_id) {
        model.data.forEach(api_recording => {
          if (api_recording.attributes.policy_id === Number(this.api_recording_id)) {
            controller.setProperties({
              'api_recordings_list': model.data,
              'current_api_recording': api_recording.attributes.name,
              'current_api_recording_id': api_recording.id,
              'redirected_from_api_recorder_view': true
            });
          }
        });
      } else {
        controller.setProperties({
          'api_recordings_list': model.data,
          'current_api_recording': model.data.length > 0 ? model.data[0].attributes.name : this.constants.NO_API_RECORDINGS_FOUND,
          'current_api_recording_id': model.data.length > 0 ? model.data[0].id : '',
          'redirected_from_api_recorder_view': false
        });
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          'api_recording_id': '',
          'profile_id': '',
          'file_error': false,
          'redirected_from_api_recorder_view': false,
          'current_spec_info_selection': 'csvFileImportDetails',
          'errorMessage': ''
        });
      }
    }
  });
  _exports.default = _default;
});