define("abcloud-ui/controllers/admin/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    sortProperties: ["updated_at:desc"],
    sortedModel: Ember.computed.sort("model", "sortProperties"),
    urls: Ember.inject.service("urls"),
    auth: Ember.inject.service("auth"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    notifications: Ember.inject.service(),
    actions: {
      deleteAdminUser(adminUser) {
        this.send("destroy", adminUser);
      },
      navigateToAddCustomer() {
        this.transitionToRoute("admin.users.new");
      },
      editCustomer(userId) {
        this.transitionToRoute("admin.users.edit", userId);
      },
      resetAdminUserPassword(adminUserId) {
        const url = this.urls.RESET_ADMIN_USER_PASSWORD(adminUserId);
        this.server.request("POST", url, null, response => {
          this.notifications.success(this.constants.ADMIN_PASSWORD_RESET_SUCCESSFULLY);
        }, error => {
          this.notifications.error(this.constants.SOMETHING_WENT_WRONG);
        });
      }
    }
  });
  _exports.default = _default;
});