define("abcloud-ui/routes/system/hosted/localbay/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model(params) {
      return this.store.findRecord('localcomponent', params.id, {
        reload: true
      });
    },
    setupController(controller, model) {
      try {
        var componentSize = {};
        var componentData = {};
        model.localentities.forEach(entity => {
          componentSize[entity.entity_type] = 1;
          componentData[entity.entity_type] = [this.selectKeysForDisplay(entity)];
        });
        controller.setProperties({
          'localbayDetails': model,
          'componentSize': componentSize,
          'componentData': componentData,
          'localbayHealthTime': model.localcomponent_health['timestamp']
        });

        //-- Location details to be shown in the future
        // var url = this.urls.URL_GET_MAP();
        // this.ajax.request(url,{
        //   method: 'GET',
        //   data:{
        //     city: model.city,
        //     state: model.state,
        //     country: model.country
        //   }
        // })
        // .then(map =>{
        //   if(!map.errors){
        //     // -- set background property as map image
        //     var mapImg = "url('data:image/png;base64, "+ map.data.attributes.file_contents.trim().replace(/(\r\n|\n|\r)/gm, '') + "')";
        //     document.getElementById('map').style.backgroundImage = mapImg;
        //   }
        //   else{
        //     $('#map').attr('title', 'Map not Available');
        //     $('#map').attr('alt', 'Map not Available');
        //     this.debug.log('Something went wrong while loading Map', map);
        //   }

        // });
      } catch (exception) {
        this.debug.log('something went wrong while processing localbay record', exception);
      }
    },
    renderTemplate() {
      this.render("system.hosted.localbay-details", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    // -- select keys we wish to display on our component cards
    selectKeysForDisplay(component) {
      let filteredComponent = {};
      filteredComponent['id'] = component['id'];
      filteredComponent['title'] = component['title'];
      filteredComponent['hostname'] = component['host'];
      filteredComponent['port'] = component['port'];
      filteredComponent['health'] = this.getHealthAttributesForComponent(component['health']);
      return filteredComponent;
    },
    getHealthAttributesForComponent(obj) {
      let healthObj = {
        'health_status': '',
        'health_message': '',
        'last_seen': 0
      };
      if (typeof obj == 'undefined') {
        return healthObj;
      } else {
        return {
          'health_status': obj['status'].toLowerCase(),
          'health_message': obj['message'],
          'last_seen': obj['last_seen']
        };
      }
    },
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          'localbayDetails': null,
          'componentSize': null,
          'componentDetails': null,
          'componentData': null,
          'showSubComponent': false
        });
      }
    }
  });
  _exports.default = _default;
});