define("abcloud-ui/helpers/underscore-to-whitespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.underscoreToWhitespace = underscoreToWhitespace;
  function underscoreToWhitespace(_ref) {
    let [underscored_string, inverse] = _ref;
    if (underscored_string != null) {
      underscored_string = underscored_string.toString();
      if (inverse) {
        underscored_string = underscored_string.replace(/ /gi, '_');
      } else {
        underscored_string = underscored_string.replace(/_/gi, ' ');
      }
      return underscored_string;
    }
    return '';
  }
  var _default = Ember.Helper.helper(underscoreToWhitespace);
  _exports.default = _default;
});