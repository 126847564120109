define("abcloud-ui/routes/insights/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render("insights.dashboard", {
        into: "insights",
        outlet: "dashboard"
      });
    }
  });
  _exports.default = _default;
});