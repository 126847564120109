define("abcloud-ui/templates/components/policies-tenant/policies-list-view-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YSZBEmP/",
    "block": "{\"symbols\":[\"record\",\"thText\"],\"statements\":[[7,\"table\"],[11,\"class\",\"pure-table pure-table-horizontal data-list-table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"headings\"]]],null,{\"statements\":[[0,\"        \"],[7,\"th\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"data\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"is_api_recorder\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"policies-tenant/api-recorder-list-view-table-row\",null,[[\"object\",\"editAction\",\"deleteAction\",\"record\",\"openResumeRecordModal\",\"id\",\"searchKey\",\"searchKey2\",\"SUB_TYPE_MATCHER\"],[[22,1,[]],[23,[\"transitionToEditView\"]],[23,[\"deleteProfile\"]],[27,\"action\",[[22,0,[]],\"record\"],null],[27,\"action\",[[22,0,[]],\"openResumeRecordModal\"],null],[22,1,[\"id\"]],[23,[\"searchKey\"]],[23,[\"searchKey2\"]],[23,[\"SUB_TYPE_MATCHER\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"policies-tenant/policies-list-view-table-row\",null,[[\"object\",\"editAction\",\"deleteAction\",\"id\",\"searchKey\",\"searchKey2\",\"SUB_TYPE_MATCHER\"],[[22,1,[]],[23,[\"transitionToEditView\"]],[23,[\"deleteProfile\"]],[22,1,[\"id\"]],[23,[\"searchKey\"]],[23,[\"searchKey2\"]],[23,[\"SUB_TYPE_MATCHER\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/policies-tenant/policies-list-view-table.hbs"
    }
  });
  _exports.default = _default;
});