define("abcloud-ui/services/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    getMap(obj, url, elementId) {
      var mapImg = '';
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-CV-Trace-ID': this.urls.generate_trace_id()
        }
      });
      this.ajax.request(url, {
        method: 'GET',
        data: {
          city: obj.city,
          state: obj.state,
          country: obj.country
        }
      }).then(map => {
        // -- set background property as map image
        mapImg = "url('data:image/png;base64, " + map.data.attributes.file_contents.trim().replace(/(\r\n|\n|\r)/gm, '') + "')";
        if (document.getElementById('' + elementId + '') != null) {
          document.getElementById('' + elementId + '').style.backgroundImage = mapImg;
        }
        // return mapImg;
      });
    },

    getMapStyles(theme) {
      let themeStyles;
      if (theme && theme == 'dark') {
        themeStyles = [{
          elementType: 'geometry',
          stylers: [{
            color: '#0e3054'
          }]
        },
        //6e7b8f
        {
          elementType: 'labels.text.fill',
          stylers: [{
            visibility: "off"
          }]
        },
        //c2cfe3
        {
          elementType: 'labels.text.stroke',
          stylers: [{
            visibility: 'off'
          }]
        },
        //475163
        {
          elementType: 'geometry',
          stylers: [{
            color: '#0e3054'
          }]
        },
        //6e7b8f
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{
            color: '#8da5b6'
          }]
        }, {
          featureType: 'water',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#8bc6dc'
          }]
        }];
      } else {
        themeStyles = [];
      }
      return themeStyles;
    }
  });
  _exports.default = _default;
});