define("abcloud-ui/initializers/constants", ["exports", "abcloud-ui/constants/config-objects", "abcloud-ui/constants/config-variables", "abcloud-ui/constants/dialogue-texts", "abcloud-ui/constants/form-validation-texts", "abcloud-ui/constants/notification-texts", "abcloud-ui/constants/routes", "abcloud-ui/constants/texts", "abcloud-ui/constants/toast-messages"], function (_exports, _configObjects, _configVariables, _dialogueTexts, _formValidationTexts, _notificationTexts, _routes, _texts, _toastMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const constants = Ember.Object.extend({
    ..._configVariables.CONFIG_VARIABLES,
    ..._configObjects.CONFIG_OBJECTS,
    ..._routes.ROUTES,
    ..._texts.TEXTS,
    ..._toastMessages.TOAST_MESSAGES,
    ..._formValidationTexts.FORM_VALIDATION_TEXTS,
    ..._notificationTexts.NOTIFICATIONS,
    ..._dialogueTexts.DIALOGUE_TEXTS
  });
  function initialize(application) {
    application.register("constants:variables", constants, {
      singleton: true
    });
    application.inject("controller", "constants", "constants:variables");
    application.inject("route", "constants", "constants:variables");
    application.inject("component", "constants", "constants:variables");
    application.inject("service", "constants", "constants:variables");
    application.inject("adapter", "constants", "constants:variables");
  }
  var _default = {
    name: "constants",
    initialize
  };
  _exports.default = _default;
});