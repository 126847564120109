define("abcloud-ui/routes/policies/policies/api-session-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service('ajax'),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          "Access-Control-Allow-Origin": "*",
          'X-CV-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      var request = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES('api_session_token'));
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    setupController(controller, model) {
      try {
        if (Ember.$.isEmptyObject(model.data.attributes.localbays) || Ember.$.isEmptyObject(model.data.attributes.profiles.available.data)) {
          this.transitionTo('policies.policies');
        } else {
          var profiles = model.data.attributes.profiles;
          var availableProfilesList = model.data.attributes.profiles.available.data;
          var linkedProfilesList = model.data.attributes.profiles.linked.data;
          var localbaysList = model.data.attributes.localbays.data;
          controller.setProperties({
            'availableProfilesList': availableProfilesList,
            'linkedProfilesList': linkedProfilesList,
            'localbay_list': localbaysList,
            'current_localbay': localbaysList[0].title,
            'current_localbay_id': localbaysList[0].id,
            'current_microsensor_id': localbaysList[0].dplets.data[0].id,
            'current_microsensor': localbaysList[0].dplets.data[0].name,
            'microsensor_list': localbaysList[0].dplets.data,
            'apiSessionTokenProfilesList': profiles.api_session_token_profiles.data,
            'sessionTerminationProfilesList': profiles.session_termination_profiles.data
          });
        }
      } catch (dataInitiationGoneWrong) {
        this.debug.log('Something went wrong data initiation', dataInitiationGoneWrong);
        this.transitionTo('policies.policies');
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.setProperties({
          'current_localbay': '',
          'current_localbay_id': '',
          'localbay_list': '',
          'current_microsensor': '',
          'current_microsensor_id': '',
          'microsensor_list': '',
          'api_parameter_pin_profiles': {},
          'selected_profile_ids': new Set(),
          'severity_level_responses': {},
          'session_token_violated': 'High',
          'session_token_not_present': 'High',
          'errorMessage': ''
        });
      }
    }
  });
  _exports.default = _default;
});