define("abcloud-ui/templates/components/dashboard-card-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hXmRf1cj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n  \"],[7,\"h5\"],[9],[1,[21,\"type\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/dashboard-card-header.hbs"
    }
  });
  _exports.default = _default;
});