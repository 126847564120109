define("abcloud-ui/components/policies-tenant/policy-components/edit-data-export-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      try {
        var dataOptions = this.policy_data.json.data.attributes.data_options;
        if (this.disabled) {
          document.getElementById('exportOriginal' + this.disabled).checked = dataOptions.hasOwnProperty('export_original_data_events') ? true : false;
          document.getElementById('exportHeadersOnly' + this.disabled).checked = dataOptions.hasOwnProperty('export_data_events_headers_only') ? true : false;
        } else {
          document.getElementById('exportOriginal').checked = dataOptions.export_original_data_events === 'true';
          document.getElementById('exportHeadersOnly').checked = dataOptions.export_data_events_headers_only === 'true';
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(labelId => {
            if (this.disabled) {
              Ember.$('#' + labelId + this.disabled + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + labelId + '').addClass('draft_label_changed');
            }
          });
        }
      } catch (newPolicy) {
        this.debug.log('This is a new Data Export Policy', newPolicy);
      }
    },
    actions: {
      savedataExportPolicy(action) {
        this.savedataExportPolicy(action);
      },
      cancel() {
        this.cancel();
      },
      chooseLocalbay(localcomponentId, localcomponent) {
        this.setProperties({
          'current_localbay_id': localcomponentId,
          'current_localbay': localcomponent
        });
      },
      chooseDataExportProfile(profileId, profileChosen) {
        this.setProperties({
          'current_profile': profileChosen,
          'current_profile_id': profileId
        });
      }
    }
  });
  _exports.default = _default;
});