define("abcloud-ui/services/build-object-for-server/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var requestObj = {
    data: {
      type: "profile",
      attributes: {
        sub_type: "",
        name: "",
        description: "",
        action: "",
        json: {}
      }
    }
  };
  var _default = Ember.Service.extend({
    setId(id) {
      requestObj.data["id"] = id;
    },
    setSubType(sub_type) {
      requestObj.data.attributes.sub_type = sub_type;
    },
    setCommonTopLevelKeys(name, description, action) {
      requestObj.data.attributes.name = name;
      requestObj.data.attributes.description = description;
      requestObj.data.attributes.action = action;
    },
    setChangeset(changeset) {
      requestObj.data.attributes["changeset"] = changeset;
    },
    setJson(jsonObject) {
      for (var key in jsonObject) {
        requestObj.data.attributes.json[key] = "";
        requestObj.data.attributes.json[key] = jsonObject[key];
      }
    },
    getRequestObject() {
      var tempRequestObject = requestObj;
      requestObj = JSON.parse(JSON.stringify(this.constants.RESET_PROFILE_REQUEST_OBJECT));
      return tempRequestObject;
    },
    whichIncludesSpecificIds(ids, profiles, action) {
      let profilesArray = [];
      ids.forEach(id => {
        profiles.forEach(profile => {
          const attributes = profile.data.attributes;
          if (profile.data.id === id) {
            this.setId(id);
            this.setSubType(attributes.sub_type);
            this.setCommonTopLevelKeys(attributes.name, attributes.description, action);
            this.setJson(attributes.profile_data[0].json);
            profilesArray.push(this.getRequestObject());
          }
        });
      });
      return profilesArray;
    }
  });
  _exports.default = _default;
});