define("abcloud-ui/constants/routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ROUTES = void 0;
  const ROUTES = {
    DOCS_DEFAULT_PATH: "documentation__quick-start-guide",
    SYSTEM_ON_PREM_LIST_VIEW: "/deployments/stand-alone-controller/list",
    LANDING_VIEW_AFTER_FIRST_LOGIN: "/insights/dashboard/discovery/apis",
    ADMIN_USER_RESET_PWD: "/manage/reset-password",
    ADMIN_CUSTOMER_LIST: "/manage/customers"
  };
  _exports.ROUTES = ROUTES;
});