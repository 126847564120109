define("abcloud-ui/routes/manage/users/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    beforeModel() {
      if (this.auth.isLoggedIn('adminUser')) {
        this.transitionTo('/manage/users/list');
      }
    }
  });
  _exports.default = _default;
});