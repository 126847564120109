define("abcloud-ui/templates/components/policies-tenant/policies-api-session-token/enforcement-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cGRZ80Pt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row sub_title_policies\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-2\"],[9],[0,\"Enforcement Information\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-7\"],[9],[0,\" \"],[7,\"span\"],[11,\"class\",\"divider in_line_divider\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"form\"],[11,\"class\",\"form-details\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"wrapper_container\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-12 col-md-3 level_label\"],[11,\"for\",\"name\"],[12,\"id\",[28,[\"dpletLabel\",[21,\"disabledStatus\"]]]],[9],[0,\"\\n          \"],[7,\"i\"],[9],[10],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"asterik\"],[9],[0,\"*\"],[10],[0,\"\\n          MicroSensor\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"dropdown-input\",null,[[\"main_value\",\"class\",\"options\",\"search_key\",\"disabledStatus\",\"take_action\"],[[23,[\"current_microsensor\"]],\"col-12 col-md-4 no_padding\",[23,[\"microsensor_list\"]],\"name\",[23,[\"disabledStatus\"]],[27,\"action\",[[22,0,[]],\"chooseMicroSensor\"],null]]]],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-3\"],[9],[0,\"\\n\"],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/policies-tenant/policies-api-session-token/enforcement-information.hbs"
    }
  });
  _exports.default = _default;
});