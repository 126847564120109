define("abcloud-ui/models/transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    transaction_timestamp: _emberData.default.attr('number'),
    transaction_name: _emberData.default.attr('string'),
    transaction_type: _emberData.default.attr('string'),
    transaction_spec_version: _emberData.default.attr('string'),
    transaction_data: _emberData.default.attr(),
    transaction_iab_data: _emberData.default.attr(),
    transaction_apis: _emberData.default.attr('string'),
    user: _emberData.default.attr('string'),
    events_json: _emberData.default.attr(),
    transaction_json: _emberData.default.attr(),
    dplet_id: _emberData.default.attr('number'),
    hour_range: _emberData.default.attr(),
    localbay_id: _emberData.default.attr('number'),
    localcomponent_id: _emberData.default.attr('number'),
    duration: _emberData.default.attr('string')
  });
  _exports.default = _default;
});