define("abcloud-ui/components/policies-tenant/policies-out-of-spec/profile-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      create_profile_object(profile_id, element) {
        if (element.target.checked) {
          this.selected_profile_ids.add(profile_id);
        } else {
          this.selected_profile_ids.delete(profile_id);
        }
      },
      create_selected_profile_object(profile_id, element) {
        if (element.target.checked) {
          this.past_selected_profile_ids.add(profile_id);
        } else {
          this.past_selected_profile_ids.delete(profile_id);
        }
      }
    }
  });
  _exports.default = _default;
});