define("abcloud-ui/routes/policies/policies/protected-aws-s3-file-downloads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    newPolicy: false,
    ajax: Ember.inject.service("ajax"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    model(params) {
      Ember.$.ajaxSetup({
        headers: {
          Authorization: `Bearer ${this.auth.getAuthToken("customer")}`,
          "X-AB-Trace-ID": this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      if (params.policy_id === "new") {
        this.setProperties({
          newPolicy: true
        });
        var request = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES("s3_file_download"));
        this.manage_requests.add_request(this.routeName, request);
        return request;
      } else {
        var request1 = Ember.$.getJSON(urls.URL_POLICY(params.policy_id));
        var request2 = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES("s3_file_download"));
        var routeName = this.routeName;
        this.manage_requests.add_requests([{
          [routeName]: request1
        }, {
          [routeName]: request2
        }]);
        return Ember.RSVP.hash({
          policy: request1,
          model: request2
        });
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      try {
        //-- if user wanted to update the existing policy
        if (!this.newPolicy) {
          var policyForTemplate = model.policy.data;
          var policy = model.policy.data.attributes.policy_data; //[0].json.data.attributes;  //-- policy data
          var top_level_policy_object = model.policy.data.attributes;
          model = model.model.data.attributes; //-- dropdown data
          if (Ember.$.isEmptyObject(model.profiles)) {
            this.transitionTo("policies.policies");
          }
          var tempPolicyToStoreCurrentPolicy = {};
          if (policy.length > 1) {
            tempPolicyToStoreCurrentPolicy = policy[1];
          } else {
            tempPolicyToStoreCurrentPolicy = policy[0];
          }
          model.profiles.activity_session.data.forEach(activity_session => {
            if (activity_session.id === Number(tempPolicyToStoreCurrentPolicy.json.data.attributes.activity_session_profile.data.id)) {
              controller.set("current_activity_session_object", activity_session);
            }
          });
          model.profiles.user_group.data.forEach(user_group => {
            if (user_group.id === Number(tempPolicyToStoreCurrentPolicy.json.data.attributes.user_group_profile.data.id)) {
              controller.set("current_user_group_object", user_group);
            }
          });
          model.profiles.user_session.data.forEach(user_session => {
            if (user_session.id === Number(tempPolicyToStoreCurrentPolicy.json.data.attributes.user_session_profile.data.id)) {
              controller.set("current_user_session_object", user_session);
            }
          });

          /* if the policy length is greater than 1 it means that the policy has both published and draft versions,
          else, it only has a saved version or just the published version. --*/

          var current_dplet = {
            name: top_level_policy_object.dplet_name,
            id: top_level_policy_object.dplet_id
          };
          var notEnoughDplets = false;
          var dpletOptions = {};
          try {
            model.localbays.data[0].dplets.data.unshift(current_dplet);
            dpletOptions = model.localbays.data[0].dplets.data;
          } catch (notEnoughLambdaDplets) {
            notEnoughDplets = true;
          }

          /*
          -- policy_to_compare_current_changes_against will be 0th object in policy_data irrespective of if the policy has a diff view or not.
          If the policy has diff view(policy.length > 1), it means we need to compare the draft version's changes against published version's changes.
          If the policy has no diff view(policy.length < 1), it means we need to compare the current user's changes against current published version's changes.
          */

          if (policy.length > 1) {
            let current_dplet_draft = "",
              current_dplet_published = "",
              current_dplet_id = "";
            if (policy[1].json.data.attributes.hasOwnProperty("dplet_name")) {
              current_dplet_draft = policy[1].json.data.attributes.dplet_name;
              current_dplet_id = policy[1].json.data.attributes.dplet_id;
            } else {
              current_dplet_draft = top_level_policy_object.dplet_name;
              current_dplet_id = top_level_policy_object.dplet_id;
            }
            if (policy[0].json.data.attributes.hasOwnProperty("dplet_name")) {
              current_dplet_published = policy[0].json.data.attributes.dplet_name;
            } else {
              current_dplet_published = top_level_policy_object.dplet_name;
            }
            controller.setProperties({
              policy_to_compare_current_changes_against: policy[0],
              localcomponents: model.localbays.data,
              user_session_profiles: model.profiles.user_session.data,
              user_group_profiles: model.profiles.user_group.data,
              activity_session_profiles: model.profiles.activity_session.data,
              current_localbay_id: policy[1].json.data.attributes.localcomponent_ids ? policy[1].json.data.attributes.localcomponent_ids[0] : policy[1].json.data.attributes.localbay_ids[0],
              current_localbay: policy[1].json.data.attributes.localcomponent_title || policy[1].json.data.attributes.localbay_title,
              current_localbay_id_published: policy[0].json.data.attributes.localcomponent_ids ? policy[0].json.data.attributes.localcomponent_ids[0] : policy[0].json.data.attributes.localbay_ids[0],
              restApiHostIp: policy[1].json.data.attributes.user_session_rest_api_host_ip,
              restApiAuthToken: policy[1].json.data.attributes.user_session_rest_api_auth_token,
              restApiHostIpFromGroupInfo: policy[1].json.data.attributes.user_group_rest_api_host_ip,
              restApiAuthTokenForGroupInfo: policy[1].json.data.attributes.user_group_rest_api_auth_token,
              current_user_session: policy[1].json.data.attributes.user_session_profile.data.attributes.name,
              current_user_group: policy[1].json.data.attributes.user_group_profile.data.attributes.name,
              current_activity_session: policy[1].json.data.attributes.activity_session_profile.data.attributes.name,
              restApiHostIp_published: policy[0].json.data.attributes.user_session_rest_api_host_ip,
              restApiAuthToken_published: policy[0].json.data.attributes.user_session_rest_api_auth_token,
              restApiHostIpFromGroupInfo_published: policy[0].json.data.attributes.user_group_rest_api_host_ip,
              restApiAuthTokenForGroupInfo_published: policy[0].json.data.attributes.user_group_rest_api_auth_token,
              current_user_session_published: policy[0].json.data.attributes.user_session_profile.data.attributes.name,
              current_user_group_published: policy[0].json.data.attributes.user_group_profile.data.attributes.name,
              current_activity_session_published: policy[0].json.data.attributes.activity_session_profile.data.attributes.name,
              severityValuesInSet: this.convertLegacyObjToLatestObj(policy[1].json.data.attributes.severity_level_responses),
              severityValuesInSetPublished: this.convertLegacyObjToLatestObj(policy[0].json.data.attributes.severity_level_responses),
              disable: "Disabled",
              newPolicy: this.newPolicy,
              policy: policyForTemplate,
              current_dplet_published: current_dplet_published,
              current_dplet: current_dplet_draft,
              current_dplet_id: current_dplet_id,
              current_dplet_options: dpletOptions,
              didUserSelectRecommendedSettings: false,
              diff: top_level_policy_object.diff,
              changed_labels: top_level_policy_object.changed_labels,
              diffLength: Object.keys(top_level_policy_object.diff).length,
              notEnoughDplets: notEnoughDplets
            });
          } else {
            let current_dplet_draft = "",
              current_dplet_id = "";
            if (policy[0].json.data.attributes.hasOwnProperty("dplet_name")) {
              current_dplet_draft = policy[0].json.data.attributes.dplet_name;
              current_dplet_id = policy[0].json.data.attributes.dplet_id;
            } else {
              current_dplet_draft = top_level_policy_object.dplet_name;
              current_dplet_id = top_level_policy_object.dplet_id;
            }
            controller.setProperties({
              policy_to_compare_current_changes_against: policy[0],
              localcomponents: model.localbays.data,
              user_session_profiles: model.profiles.user_session.data,
              user_group_profiles: model.profiles.user_group.data,
              activity_session_profiles: model.profiles.activity_session.data,
              current_localbay_id: policy[0].json.data.attributes.localcomponent_ids ? policy[0].json.data.attributes.localcomponent_ids[0] : policy[0].json.data.attributes.localbay_ids[0],
              current_localbay: policy[0].json.data.attributes.localcomponent_title || policy[0].json.data.attributes.localbay_title,
              restApiHostIp: policy[0].json.data.attributes.user_session_rest_api_host_ip,
              restApiAuthToken: policy[0].json.data.attributes.user_session_rest_api_auth_token,
              restApiHostIpFromGroupInfo: policy[0].json.data.attributes.user_group_rest_api_host_ip,
              restApiAuthTokenForGroupInfo: policy[0].json.data.attributes.user_group_rest_api_auth_token,
              current_user_session: policy[0].json.data.attributes.user_session_profile.data.attributes.name,
              current_user_group: policy[0].json.data.attributes.user_group_profile.data.attributes.name,
              current_activity_session: policy[0].json.data.attributes.activity_session_profile.data.attributes.name,
              severityValuesInSet: this.convertLegacyObjToLatestObj(policy[0].json.data.attributes.severity_level_responses),
              disable: "Disabled",
              newPolicy: this.newPolicy,
              policy: policyForTemplate,
              current_dplet_id: current_dplet_id,
              current_dplet: current_dplet_draft,
              current_dplet_options: dpletOptions,
              didUserSelectRecommendedSettings: false,
              notEnoughDplets: notEnoughDplets
            });
          }
        }
        //-- if user opted to create a new policy
        else {
          model = model.data.attributes;
          if (Ember.$.isEmptyObject(model.profiles) || Ember.$.isEmptyObject(model.profiles.activity_session.data) || Ember.$.isEmptyObject(model.profiles.user_group.data) || Ember.$.isEmptyObject(model.profiles.user_session.data) || Ember.$.isEmptyObject(model.localbays)) {
            this.transitionTo("policies.policies");
          } else {
            controller.setProperties({
              localcomponents: model.localbays.data,
              user_session_profiles: model.profiles.user_session.data,
              user_group_profiles: model.profiles.user_group.data,
              activity_session_profiles: model.profiles.activity_session.data,
              current_localbay_id: model.localbays.data[0].id,
              restApiHostIp: model.localbays.data[0].load_balancer_ip,
              current_localbay: model.localbays.data[0].title,
              current_user_session: model.profiles.user_session.data[0].name,
              current_user_group: model.profiles.user_group.data[0].name,
              current_activity_session: model.profiles.activity_session.data[0].name,
              current_user_session_object: model.profiles.user_session.data[0],
              current_user_group_object: model.profiles.user_group.data[0],
              current_activity_session_object: model.profiles.activity_session.data[0],
              disable: "Disabled",
              newPolicy: this.newPolicy,
              current_dplet_id: model.localbays.data[0].dplets.data[0].id,
              current_dplet: model.localbays.data[0].dplets.data[0].name,
              current_dplet_options: model.localbays.data[0].dplets.data
            });
            this.set("newPolicy", false);
          }
        }
      } catch (noRecordsForDropdowns) {
        controller.set("errors", "Please create all the profiles first to create a policy");
        this.debug.log("uncaught error=>", noRecordsForDropdowns.toString().slice(0, 100));
        this.transitionTo("policies.policies");
      }
    },
    resetController: function (controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.resetControllerProperties();
      }
    },
    convertLegacyObjToLatestObj(severity_level_responses) {
      const available_severity_levels = ["critical", "high", "medium", "low", "audit"];
      let isObjectLegacy = false;
      let latestObj = [];
      available_severity_levels.forEach(severity => {
        if (severity_level_responses.hasOwnProperty(severity)) {
          isObjectLegacy = true;
        }
      });
      if (isObjectLegacy) {
        for (let severity in severity_level_responses) {
          let actionsRow = {
            severity: severity,
            generate_alert_event: severity_level_responses[severity]["generate_alert_event"]
          };
          latestObj.push(actionsRow);
        }
      } else {
        latestObj = severity_level_responses;
      }
      return latestObj;
    }
  });
  _exports.default = _default;
});