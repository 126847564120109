define("abcloud-ui/constants/texts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TEXTS = void 0;
  const TEXTS = {
    INSTANCE_TYPE_NEEDING_ADVANCED_HELP_TEXT: "Virtual Machine",
    NOT_PUBLISHED: "Not Published",
    RECORDING: "Recording in progress",
    DONE_RECORDING: "Completed Recording",
    DONE_RECORDING_LOCAL_CONSOLE: "Recording completed, results are available on the localconsole - ",
    ALSO_AVAILABLE_ON_LOCAL_CONSOLE: "Results are also available on the localconsole",
    RECORD_EXISTS: "This recording cannot be published as there is another recording already in progress.",
    MANUAL_STOP_RECORDING: "Manual stop with auto-override at system default (4 hours).",
    NO_LOCALCONSOLES_CREATED: "No Local Consoles created",
    NO_API_RECORDINGS_FOUND: "No API Recordings were found",
    RECORDING_IN_PROGRESS: "Recording in progress ...",
    RECORDING_IN_PROGRESS_LOCAL_CONSOLE: "Recording in progress, results will be available on the localconsole - ",
    PREPARING_DATA: "Recording complete, data will be ready shortly.",
    STATUS_PENDING: "Status pending.",
    NO_APIS_FOUND: "No APIs found.",
    CONFIRM_LB_DETAILS: "Please review LocalBay details before proceeding.",
    RECORDING_CAN_NOT_RESUME: "This API Recorder can not be resumed",
    AUTO_STOP(time) {
      return "Auto stop in " + time;
    },
    SUPER_ADMIN: "super admin",
    PASSWORD_RESET_BY_ADMIN: "Your password has been changed by an admin for you. For security reasons, you should now reset your password.",
    FIRST_LOGIN_PWD_UPDATE: "Get started by changing your password.",
    EMAIL_PLACEHOLDER: "john.doe@imperva.com",
    FIRST_NAME_PLACEHOLDER: "John",
    LAST_NAME_PLACEHOLDER: "Doe",
    COMPANY_NAME_PLACEHOLDER: "Imperva",
    FULL_NAME_PLACEHOLDER: "John Doe",
    CITY_PLACEHOLDER: "San Mateo",
    STATE_PLACEHOLDER: "California",
    COUNTRY_PLACEHOLDER: "United States of America",
    LANGUAGE_PLACEHOLDER: "English",
    WEBSITE_PLACEHOLDER: "www.imperva.com",
    SUB_DOMAIN_PLACEHOLDER: "subdomain",
    MY_LOCAL_CONSOLE: "My Local Console",
    CLIENT_ID_PLACEHOLDER: "Client ID",
    ISSUER_URL_PLACEHOLDER: "https://issuer-url.com",
    REDIRECT_URL_PLACEHOLDER: "https://www.imperva.com",
    SSO_ERROR_NOTIFICATION_TEXT: "Error notification text"
  };
  _exports.TEXTS = TEXTS;
});