define("abcloud-ui/templates/deployments/stand-alone-controller/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RjICiawe",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"system-localbay/localcomponent-form\",null,[[\"cancel\",\"createLocalcomponent\",\"localcomponent\",\"editView\",\"localcomponentType\",\"isLoading\"],[[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"onUpdateEeconsole\"],null],[23,[\"eeconsole\"]],true,\"eeconsole\",[23,[\"isCreateControllerLoading\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/deployments/stand-alone-controller/edit.hbs"
    }
  });
  _exports.default = _default;
});