define("abcloud-ui/controllers/insights/dashboard/discovery", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    qp: Ember.inject.service('query-params'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    observabilitySubMenuHidden: true,
    securitySubMenuHidden: false,
    secTabActive: true,
    observeTabActive: false,
    from_time: parseInt(_moment.default.utc().valueOf() / 1000) - 86400,
    // -- DEFAULT = Yesterday's timestamp in epoch
    to_time: parseInt(_moment.default.utc().valueOf() / 1000),
    // -- DEFAULT = Today's timestamp in epoch
    timeSelectedByUser: "86400",
    // -- DEFAULT = 1 day in seconds

    actions: {
      selectTimeRange(from_time, to_time) {
        this.setProperties({
          'showPreloader': true,
          'timeSelectedByUser': from_time
        });
        if (to_time) {
          this.qp.setParam('to_time', to_time);
        } else {
          this.qp.setParam('to_time', parseInt(_moment.default.utc().valueOf() / 1000));
        }
        if (from_time) {
          this.qp.setParam('from_time', parseInt(_moment.default.utc().valueOf() / 1000) - from_time);
        } else {
          this.qp.setParam('from_time', 1);
        }
        this.set('from_time', parseInt(this.qp.getParam('from_time')));
        this.set('to_time', parseInt(this.qp.getParam('to_time')));
        this.qp.setParam('timeSelectedByUser', this.timeSelectedByUser);

        // -- abort all existing requests running on the current children routes
        this.manage_requests.abort_request('insights.dashboard.discovery.apis');
        this.manage_requests.abort_request('insights.dashboard.discovery.new-apis');
        this.set('page', parseInt(this.qp.getParam('page')));
        this.send('refreshModel');
      }
    }
  });
  _exports.default = _default;
});