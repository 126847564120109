define("abcloud-ui/components/forms/delete-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['delete', 'button', 'full_width'],
    click() {
      if (this.submit()) {
        this.submit();
      }
    }
  });
  _exports.default = _default;
});