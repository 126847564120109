define("abcloud-ui/components/policies-tenant/policies-api-recorder/recorded-api-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isShowDetails: false,
    isShowRecorderDetails: false,
    isShowAdvancedFilters: false,
    didInsertElement() {
      Ember.$('#autoFocusDiv').focus();
    },
    actions: {
      toggleAdvancedFilters() {
        this.set('isShowAdvancedFilters', !this.isShowAdvancedFilters);
        if (!this.isShowAdvancedFilters) {
          this.filterRecordings(!this.isShowAdvancedFilters);
        }
      },
      toggle_policy_config() {
        this.set('isShowRecorderDetails', !this.isShowRecorderDetails);
      },
      showApiDetails(apiDetails, currentSelectedRow) {
        this.showApiDetails(apiDetails, currentSelectedRow);
      },
      clearAdvancedFilters() {
        this.constants.ADVANCED_FILTER_OPTIONS.forEach(options => {
          Ember.$(`#${options.inputId}`).val('');
        });
        const resetFilters = true;
        this.filterRecordings(resetFilters);
      },
      toggleDisplayPane(status) {
        this.toggleDisplayPane(status);
      },
      download_api_spec(file_type) {
        this.download_api_spec(file_type, this.pollData, this.currentFilterOption);
      },
      chooseApiFilterType(choice) {
        this.chooseApiFilterType(choice);
      },
      filterRecordings() {
        this.filterRecordings();
      },
      editApi(index) {}
    },
    willDestroyElement() {
      this.destroyComponent();
    }
  });
  _exports.default = _default;
});