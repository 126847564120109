define("abcloud-ui/templates/policies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wwKslD+K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-3-24 sidenav\"],[9],[0,\"\\n\\n    \"],[7,\"ul\"],[11,\"class\",\"\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"policies.policies\"],null,{\"statements\":[[0,\"Policies\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"policies.profiles\"],null,{\"statements\":[[0,\"Profiles\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n       \"],[7,\"li\"],[9],[0,\"\\n        \"],[4,\"link-to\",[\"policies.api-recorder\"],null,{\"statements\":[[0,\"API Recorders\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[2,\"<div class=\\\"divider\\\"></div>\"],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-21-24 center_stack\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies.hbs"
    }
  });
  _exports.default = _default;
});