define("abcloud-ui/components/dashboard/side-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ["stacked-tabs"],
    setupSidenav(list) {
      Ember.$(list).removeClass("display_none");
      Ember.$(list).parent().siblings().removeClass("active");
      Ember.$(list).parent().addClass("active");
      Ember.$(list).parent().siblings().children("a").removeClass("active");
      Ember.$(list).siblings("a").addClass("active");
      Ember.$(list).parent().siblings().children("ul").addClass("display_none");
    },
    didInsertElement() {
      var charArray = window.location.href.split("/");
      var currentRoute = charArray[charArray.length - 1];
      switch (currentRoute) {
        case "apis":
          this.setupSidenav("#discovery_child_list");
          break;
        case "new-apis":
          this.setupSidenav("#discovery_child_list");
          break;
        case "dart":
          this.setupSidenav("#monitoring_child_list");
          break;
        case "policy-exceptions":
          this.setupSidenav("#monitoring_child_list");
          break;
        case "shadow-apis":
          this.setupSidenav("#monitoring_child_list");
          break;
        case "data-leakage":
          this.setupSidenav("#monitoring_child_list");
          break;
        default:
          this.debug.log("unkown side navigation route");
      }
    },
    actions: {
      toggleChildSidenav(child_list_id, element) {
        this.toggleChildSidenav(child_list_id, element);
      }
    }
  });
  _exports.default = _default;
});