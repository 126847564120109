define("abcloud-ui/services/download-file", ["exports", "json2csv", "file-saver"], function (_exports, _json2csv, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    in_csv(file_data, file_name) {
      let csvData = "";
      let csvHeaders = [];
      let exportFileDefaultName = file_name + '.csv';
      for (var key in file_data[0]) {
        csvHeaders.push(key);
      }
      const parser = new _json2csv.Parser(csvHeaders);
      csvData = parser.parse(file_data);
      let csvFile = new Blob([csvData], {
        type: "text/csv;charset=utf-8;"
      });
      _fileSaver.default.saveAs(csvFile, exportFileDefaultName);
    },
    in_json(file_data, file_name) {
      let exportFileDefaultName = file_name + '.json';
      let jsonData = new Blob([JSON.stringify(file_data)], {
        type: 'application/json;charset=utf-8'
      });
      _fileSaver.default.saveAs(jsonData, exportFileDefaultName);
    }
  });
  _exports.default = _default;
});