define("abcloud-ui/models/localcomponent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    component_type: _emberData.default.attr('string'),
    hashed_passphrase: _emberData.default.attr('string'),
    uuid: _emberData.default.attr('string'),
    install_mode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    instance_orch: _emberData.default.attr('string'),
    instance_type: _emberData.default.attr('string'),
    config_status: _emberData.default.attr('string'),
    config_error: _emberData.default.attr('string'),
    config_link: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    batch_link: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    location_id: _emberData.default.attr('string'),
    components: _emberData.default.attr(),
    dplets: _emberData.default.attr(),
    localentities: _emberData.default.attr(),
    localcomponent_health: _emberData.default.attr(),
    data_retention_size: _emberData.default.attr('number')
  });
  _exports.default = _default;
});