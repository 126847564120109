define("abcloud-ui/components/forms/cancel-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // tagName: 'button',
    // classNames: ['cancel', 'button'],
    // click() {
    //   console.log('hitting forms cancel button component');
    //   if(this.submit()) {
    //     this.submit();
    //   }
    // },
    // actions:{
    //   click() {
    //     console.log('hitting forms cancel button component');
    //     if(this.submit()) {
    //       this.submit();
    //     }
    //   },
    // }
  });
  _exports.default = _default;
});