define("abcloud-ui/controllers/admin/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        error: "",
        isEdituserLoading: false,
        firstName: "",
        lastName: "",
        email: "",
        validations: {
          isFormValid: true,
          firstName: {
            isValid: true,
            required: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "First name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          lastName: {
            isValid: true,
            required: true,
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Last name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          email: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          }
        }
      });
    },
    actions: {
      editUser(admin_user) {
        admin_user.setProperties({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        });
        admin_user.save().then(() => {
          this.notifications.success(this.constants.USER_UPDATED_SUCCESSFULLY);
          this.set("error", "");
          this.transitionToRoute("admin.users.list");
        }).catch(() => {
          this.set("error", this.constants.SOMETHING_WENT_WRONG);
        });
      },
      navigateBackToUserList() {
        this.transitionToRoute("admin.users.list");
      }
    }
  });
  _exports.default = _default;
});