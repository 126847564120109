define("abcloud-ui/controllers/manage/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    sortProperties: ['updated_at:desc'],
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    actions: {
      deleteCustomer(customer) {
        this.send("destroy", customer);
      },
      navigateToAddCustomer() {
        this.transitionToRoute("manage.customers.new");
      },
      navigateToCustomerDetails(customerId) {
        this.transitionToRoute("manage.customers.customer-tenants", customerId);
      },
      editCustomer(customerId) {
        this.transitionToRoute("manage.customers.edit", customerId);
      }
    }
  });
  _exports.default = _default;
});