define("abcloud-ui/pods/docs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "j/jk1E5y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"docs\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row no-gutters\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sidenav\"],[9],[0,\"\\n      \"],[5,\"doc-tree-node\",[],[[\"@nodes\",\"@level\",\"@onToggleNodeExpansion\",\"@onSelectNode\"],[[22,0,[\"model\"]],0,[27,\"action\",[[22,0,[]],\"toggleNodeExpansion\"],null],[27,\"action\",[[22,0,[]],\"selectNode\"],null]]]],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"center_stack\"],[9],[0,\"\\n      \"],[1,[21,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/pods/docs/template.hbs"
    }
  });
  _exports.default = _default;
});