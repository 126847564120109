define("abcloud-ui/templates/components/policies-tenant/diff-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YmE7m+NU",
    "block": "{\"symbols\":[\"array\",\"label\",\"underscored_label\",\"index\",\"diff_value\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 diff_heading\"],[9],[0,\"\\n    Diff\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-caret-down diff_caret\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleDiffViewVisibility\",\"hide\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 diff_content\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"diff\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"ul\"],[11,\"class\",\"parent_list\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[27,\"arrayfy-on-character\",[[22,2,[]],\".\"],null]],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"parent_key\"],[12,\"alt\",[27,\"underscore-to-whitespace\",[[22,3,[]]],null]],[12,\"title\",[27,\"underscore-to-whitespace\",[[22,3,[]]],null]],[9],[0,\"\\n            \"],[1,[27,\"format-string-for-length\",[[27,\"underscore-to-whitespace\",[[22,3,[]]],null],\"25\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"is-last-index-in-array\",[[22,4,[]],[27,\"arrayfy-on-character\",[[22,2,[]],\".\"],null]],null]],null,{\"statements\":[[0,\"            \"],[7,\"ul\"],[12,\"class\",[28,[\"child_list_\",[22,4,[]]]]],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"                \"],[7,\"li\"],[12,\"alt\",[22,5,[]]],[12,\"title\",[27,\"capitalize\",[[22,5,[]]],null]],[9],[0,\"\\n                  \"],[1,[27,\"capitalize\",[[27,\"format-string-for-length\",[[22,5,[]],\"18\"],null]],null],false],[0,\"\\n                \"],[10],[0,\"\\n\"]],\"parameters\":[5]},null],[0,\"            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3,4]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/policies-tenant/diff-drawer.hbs"
    }
  });
  _exports.default = _default;
});