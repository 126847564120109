define("abcloud-ui/helpers/reference-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.referenceData = referenceData;
  function referenceData(_ref) {
    let [value, obj, displayString, keyString] = _ref;
    if (!value || !obj) return "";

    // We should not encounter this case. Its there just for safety.
    if (typeof obj === "string") {
      return obj;
    }
    if (Array.isArray(obj)) {
      const resObj = obj.find(item => item[keyString || "key"] === value) || {};
      return resObj[displayString || "label"];
    }
    return obj[displayString || "label"];
  }
  var _default = Ember.Helper.helper(referenceData);
  _exports.default = _default;
});