define("abcloud-ui/routes/docs", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    beforeModel() {
      if (!this.auth.isLoggedIn('customer')) {
        this.controllerFor('application').set('customerLoggedIn', false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo('customer.login');
      }
    },
    model(params, transition) {
      const {
        targetName,
        routeInfos
      } = transition;
      let docPath;
      const url = this.urls.URL_GET_DOCS_DIRECTORY_LISTS();
      switch (targetName) {
        case 'docs.doc':
          docPath = (0, _lodash.last)(routeInfos).params.path_name;
          break;
        case 'docs.index':
          docPath = this.constants.DOCS_DEFAULT_PATH;
          break;
      }
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-CV-Trace-ID': this.urls.generate_trace_id()
        }
      });
      return Ember.$.getJSON(url).then(resp => {
        return this.transformData(resp.directory, docPath);
      });
    },
    transformData(nodes, docPath) {
      nodes.forEach(node => {
        if (node.type === "folder") {
          node.id = node.path.replace(/\//g, "__");
          node.isExpanded = docPath && !!docPath.includes(node.id);
          if (node.children.length) {
            this.transformData(node.children, docPath);
          }
        } else {
          node.id = node.path.replace(/\//g, "__");
          node.id = node.id.replace(/.yml/g, "");
          node.isSelected = docPath === node.id;
        }
      });
      return nodes;
    }
  });
  _exports.default = _default;
});