define("abcloud-ui/components/policies-tenant/profile-components/edit-parameter-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    didInsertElement() {
      try {
        var options = {
          single_api: false,
          csv_file_import: false
        };
        // for generating API List Information
        if (this.profile.api_list_information.hasOwnProperty("single_api")) {
          options.single_api = true;
          this.set("options", options);
        } else if (this.profile.api_list_information.hasOwnProperty("csv_file_import")) {
          options.csv_file_import = true;
          this.set("options", options);
        }

        // for generating Parameter Pin Information
        if (this.disabled) {
          if (this.pin_static_values_when_creating) {
            document.getElementById("static_values" + this.disabled).checked = true;
            document.getElementById("staticParamCombinationDefinitions" + this.disabled).style.display = "block";
            // set these properties to controller so we have access to these in controller without polluting the parent route
            this.set("pin_static_values", this.pin_static_values_when_creating);
          } else {
            document.getElementById("static_values" + this.disabled).checked = false;
            document.getElementById("staticParamCombinationDefinitions" + this.disabled).style.display = "none";
          }
          if (this.session_first_seen_values_when_creating || this.pinned_first_seen_values_when_creating) {
            document.getElementById("first_seen_values" + this.disabled).checked = true;
            document.getElementById("paramCombinationDefinitions" + this.disabled).style.display = "block";
            // set these properties to controller so we have access to these in controller without polluting the parent route
            if (this.session_first_seen_values_when_creating) {
              this.set("session_first_seen_values", this.session_first_seen_values_when_creating);
            }
            if (this.pinned_first_seen_values_when_creating) {
              this.set("pinned_first_seen_values", this.pinned_first_seen_values_when_creating);
            }
          }
        } else {
          if (this.pin_static_values_when_creating) {
            document.getElementById("static_values").checked = true;
            document.getElementById("staticParamCombinationDefinitions").style.display = "block";
            // set these properties to controller so we have access to these in controller without polluting the parent route
            this.set("pin_static_values", this.pin_static_values_when_creating);
          } else {
            document.getElementById("static_values").checked = false;
            document.getElementById("staticParamCombinationDefinitions").style.display = "none";
          }
          if (this.session_first_seen_values_when_creating || this.pinned_first_seen_values_when_creating) {
            document.getElementById("first_seen_values").checked = true;
            document.getElementById("paramCombinationDefinitions").style.display = "block";
            // set these properties to controller so we have access to these in controller without polluting the parent route
            if (this.session_first_seen_values_when_creating) {
              this.set("session_first_seen_values", this.session_first_seen_values_when_creating);
            }
            if (this.pinned_first_seen_values_when_creating) {
              this.set("pinned_first_seen_values", this.pinned_first_seen_values_when_creating);
            }
          }
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabled) {
              Ember.$("#" + label_id + this.disabled + "").addClass("published_label_changed");
            } else {
              Ember.$("#" + label_id + "").addClass("draft_label_changed");
            }
          });
        }
      } catch (newProfile) {
        this.debug.log("This is a new Profile");
      }
    },
    actions: {
      setFileUpload() {
        this.setFileUpload();
      },
      toggleApiListOptions(option) {
        var previous_selection = this.options[option];
        var options = {};
        var invertOptions = () => {
          for (var profile_option in this.options) {
            if (profile_option === option) {
              options[profile_option] = !previous_selection;
            } else {
              options[profile_option] = false;
            }
          }
          this.set("options", options);
        };
        if (option === "single_api") {
          if (this.file_id) {
            let elements = {
              fileUploadFilledValue: ""
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.DELETE_NOTIFICATION);
          } else {
            invertOptions();
          }
        } else {
          invertOptions();
        }
      },
      toggleParamPinInfo(element) {
        if (element.target.id === "first_seen_values") {
          if (element.target.checked) {
            Ember.$("#paramCombinationDefinitions").show(200);
          } else {
            Ember.$("#paramCombinationDefinitions").hide(200);
          }
        } else if (element.target.id === "static_values") {
          if (element.target.checked) {
            Ember.$("#staticParamCombinationDefinitions").show(200);
          } else {
            Ember.$("#staticParamCombinationDefinitions").hide(200);
          }
        }
      },
      removeElement(index, object_type) {
        var tempArray = [];
        switch (object_type) {
          case "static_values":
            this.pin_static_values.forEach((element, objIndex) => {
              if (objIndex != index) {
                tempArray.push(element);
              }
            });
            if (tempArray.length === 0) {
              tempArray.pushObject({
                "": ""
              });
            }
            this.set("pin_static_values", tempArray);
            break;
          case "session_first_seen_values":
            this.session_first_seen_values.forEach((element, objIndex) => {
              if (objIndex != index) {
                tempArray.push(element);
              }
            });
            if (tempArray.length === 0) {
              tempArray.pushObject("");
            }
            this.set("session_first_seen_values", tempArray);
            break;
          case "pinned_first_seen_values":
            this.pinned_first_seen_values.forEach((element, objIndex) => {
              if (objIndex != index) {
                tempArray.push(element);
              }
            });
            if (tempArray.length === 0) {
              tempArray.pushObject("");
            }
            this.set("pinned_first_seen_values", tempArray);
            break;
          default:
            this.debug.log("unknown object_type", object_type);
        }
      },
      addElement(object_type) {
        var tempArray = [];
        switch (object_type) {
          case "static_values":
            this.pin_static_values.forEach(element => {
              tempArray.push(element);
            });
            tempArray.push({
              "": ""
            });
            this.set("pin_static_values", tempArray);
            break;
          case "session_first_seen_values":
            this.session_first_seen_values.forEach(element => {
              tempArray.push(element);
            });
            tempArray.push("");
            this.set("session_first_seen_values", tempArray);
            break;
          case "pinned_first_seen_values":
            this.pinned_first_seen_values.forEach(element => {
              tempArray.push(element);
            });
            tempArray.push("");
            this.set("pinned_first_seen_values", tempArray);
            break;
          default:
            this.debug.log("unknown object_type", object_type);
        }
      },
      updateParameter(index, what_to_update, object_type, element) {
        var update_value = element.target.value;
        switch (object_type) {
          case "static_values":
            if (what_to_update === "update_name") {
              for (let key in this.pin_static_values[index]) {
                if (update_value != key) {
                  this.pin_static_values[index][update_value] = this.pin_static_values[index][key];
                  delete this.pin_static_values[index][key];
                }
              }
            } else if (what_to_update === "update_value") {
              for (let key in this.pin_static_values[index]) {
                this.pin_static_values[index][key] = update_value;
              }
            }
            this.debug.log("final array", this.pin_static_values);
            break;
          case "session_first_seen_values":
            this.session_first_seen_values[index] = update_value;
            break;
          case "pinned_first_seen_values":
            this.pinned_first_seen_values[index] = update_value;
            break;
          default:
            this.debug.log("unknown object_type", object_type);
        }
      },
      getProfile() {
        this.getProfile();
      },
      saveProfile(action, whenToPublish) {
        this.saveProfile(action, whenToPublish);
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancel() {
        this.transitionToListView();
      },
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      }
    },
    willDestroyElement() {
      this.setProperties({
        session_first_seen_values: [""],
        pinned_first_seen_values: [""],
        pin_static_values: [{
          "": ""
        }]
      });
    }
  });
  _exports.default = _default;
});