define("abcloud-ui/controllers/policies/profiles/api-parameter-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    profile_id: "",
    file_id: "",
    file_error: false,
    file_validated: false,
    isFileUploaded: false,
    options: {
      single_api: true,
      csv_file_import: false
    },
    pin_static_values: [{
      "": ""
    }],
    session_first_seen_values: [""],
    pinned_first_seen_values: [""],
    errorMessage: "",
    getElementValue(id) {
      return document.getElementById(id).value;
    },
    actions: {
      confirmPublish() {
        this.send("saveProfile", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      },
      setFileUpload() {
        this.set("isFileUploaded", true);
      },
      saveProfile(action) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var file = "";
        var fileName = "";
        var single_api = "";
        var method = "";
        var did_user_choose_atleast_one_api_list_info = false;
        var api_list_information = {};
        let attachmentId = 0;
        if (this.options.csv_file_import) {
          file = document.getElementById("file-upload").files[0];
          fileName = file ? file.name : "";
          method = "file_upload";
          api_list_information["csv_file_import"] = fileName;
          if (fileName && this.file_validated) {
            did_user_choose_atleast_one_api_list_info = true;
            attachmentId = this.file_id;
          }
        } else if (this.options.single_api) {
          single_api = this.getElementValue("single_api");
          method = "single_api";
          api_list_information["single_api"] = single_api;
          if (single_api) {
            did_user_choose_atleast_one_api_list_info = true;
          }
        }

        // check if all the key-val in parameter pin information are filled and are not empty

        var check_if_object_key_and_vals_are_valid = (object, array_type) => {
          var flag = true;
          if (array_type === "pure_array") {
            object.forEach(obj => {
              if (!obj) {
                flag = false;
              }
            });
            return flag;
          } else if (array_type === "array_of_objects") {
            object.forEach(obj => {
              for (var key in obj) {
                if (!key || !obj[key]) {
                  flag = false;
                }
              }
            });
            return flag;
          }
        };
        var request_obj = {
          data: {
            id: this.profile_id,
            type: "profile",
            attributes: {
              sub_type: "api_parameter_pin",
              name: name,
              description: description,
              is_deleted: false,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "fileLabel.CSV_File_Import.File_Name": fileName,
                "singleApiLabel.An_API.Single_API": single_api
              },
              json: {
                method: method,
                api_list_information: api_list_information,
                attachment_id: attachmentId
              }
            }
          }
        };
        var parameter_pin_information = {};
        var valid_parameter_pin_information = "";
        if (document.getElementById("static_values").checked) {
          parameter_pin_information["static_values"] = this.pin_static_values;
          valid_parameter_pin_information = check_if_object_key_and_vals_are_valid(this.pin_static_values, "array_of_objects");
          request_obj.data.attributes.changeset["staticValuesLabel.Pin_Static_Values"] = {
            key: this.pin_static_values
          };
        } else {
          request_obj.data.attributes.changeset["staticValuesLabel.Pin_Static_Values"] = {
            key: [""]
          };
        }
        if (document.getElementById("first_seen_values").checked) {
          parameter_pin_information["pin_set_to_first_seen_values"] = {
            session_first_seen_values: this.session_first_seen_values,
            pinned_first_seen_values: this.pinned_first_seen_values
          };
          valid_parameter_pin_information = check_if_object_key_and_vals_are_valid(this.session_first_seen_values, "pure_array") || check_if_object_key_and_vals_are_valid(this.pinned_first_seen_values, "pure_array");
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Session_Parameters"] = {
            key: this.session_first_seen_values
          };
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Pinned_Parameters"] = {
            key: this.pinned_first_seen_values
          };
        } else {
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Session_Parameters"] = {
            key: [""]
          };
          request_obj.data.attributes.changeset["firstSeenValuesLabel.Pin_Set_to_First_Seen_Values.Pinned_Parameters"] = {
            key: [""]
          };
        }
        request_obj.data.attributes.json["parameter_pin_information"] = parameter_pin_information;
        if (name && description && !this.file_error && did_user_choose_atleast_one_api_list_info && valid_parameter_pin_information) {
          // if user chose a file then method if PUT as we already have the profile_id from file upload
          var url = "";
          var req_method = "";
          if (this.options.csv_file_import) {
            url = this.urls.URL_POLICY_PROFILE(this.profile_id);
            req_method = "PUT";
          } else if (this.options.single_api) {
            url = this.urls.URL_POLICY_PROFILE();
            req_method = "POST";
          }
          this.publish.profile(action, req_method, url, JSON.stringify(request_obj), response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          // -- if there was a file validation error and yet user tries to save/publish profile,
          //inject correct notification against file upload instead of the usual Mandatory field error
          let elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            staticValuesFilledValue: valid_parameter_pin_information ? "ok" : ""
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          if (this.options.single_api) {
            let elements = {
              singleApiFilledValue: single_api
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          } else if (this.options.csv_file_import) {
            var notifMessage = this.constants.FILE_INVALID;
            var fileUploadFilledValue = "";
            if (!fileName) {
              notifMessage = this.constants.MANDATORY_FIELD;
            } else if (!this.file_validated) {
              notifMessage = this.constants.VALIDATION_INCOMPLETE;
            } else {
              fileUploadFilledValue = fileName;
            }
            let elements = {
              fileUploadFilledValue: fileUploadFilledValue
            };
            this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, notifMessage);
          }
        }
      }
    }
  });
  _exports.default = _default;
});