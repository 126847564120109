define("abcloud-ui/routes/deployments/stand-alone-controller/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord("localcomponent", params.id, {
        reload: true
      });
    },
    setupController(controller, model) {
      controller.set("eeconsole", model);
    },
    renderTemplate() {
      this.render("deployments.stand-alone-controller.edit", {
        into: "deployments",
        outlet: "deploymentsOutlet"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          isEditControllerLoading: false
        });
      }
    }
  });
  _exports.default = _default;
});