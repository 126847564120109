define("abcloud-ui/routes/admin/customers/add-tenant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    beforeModel() {
      if (!this.auth.isLoggedIn("admin")) {
        this.controllerFor("application").set("adminLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("/admin/login");
      }
    },
    model(params) {
      return this.store.findRecord("customer", params.customer_id);
    },
    setupController(controller, model) {
      controller.set("model", model);
    },
    renderTemplate() {
      this.render("admin.customers.add-tenant", {
        into: "application",
        outlet: "admin"
      });
    },
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.setProperties({
          hosting: "ab",
          region: "us",
          has_hosted_localbay: true,
          tenant_support_user: true,
          is_trial_tenant: false,
          is_customer_created: false,
          tenantFullName: "",
          tenantEmail: "",
          tenantPassword: "",
          tenantSubdomain: "",
          error: "",
          passwordVisibility: false,
          is_processing: false,
          tenant_support_user_email: "",
          tenant_support_user_password: "",
          tenant_subdomain: "",
          first_name: "",
          last_name: "",
          email: "",
          password: ""
        });
      }
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("admin.customers.add-tenant");
        controller.set("isCustomerTenantLoading", true);
        transition.promise.finally(function () {
          controller.set("isCustomerTenantLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});