define("abcloud-ui/controllers/customer/account/self-account", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    utils: Ember.inject.service("utils"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    init() {
      this._super(...arguments);
      this.setProperties({
        isAccountUpdateLoading: false,
        tenant: "",
        errorStatus: "",
        success: "",
        createUserAbility: "",
        isSuperAdmin: "",
        userWantsToEditPassword: false,
        validPassword: null,
        confirmValidPassword: null,
        passwordMatching: null,
        resetPwd: false,
        passwordResetError: this.constants.SOMETHING_WENT_WRONG,
        customerTenantLoginType: this.auth.getTenantLoginType(),
        customerFirstLogIn: !!(this.auth.getTenantIsFirstLogin() === "true"),
        customerPasswordWasReset: !!(this.auth.getTenantPasswordWasReset() === "true"),
        password: "",
        newPassword: "",
        confirmNewPassword: "",
        firstName: "",
        lastName: "",
        email: "",
        roleLevel: "",
        validations: {
          isFormValid: false,
          firstName: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "First Name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          lastName: {
            isValid: true,
            required: true,
            format: {
              with: this.constants.NAME_REGEX,
              invalidFeedback: this.constants.INVALID_NAME
            },
            length: {
              max: this.constants.MAX_INPUT_CHARS,
              invalidFeedback: this.constants.INPUT_TOO_LONG({
                fControlLabel: "Last Name",
                maxLen: this.constants.MAX_INPUT_CHARS
              })
            }
          },
          password: {
            isValid: null,
            required: true
          }
        }
      });

      /* 
        for first-time login, we want to display password field 
        so user can readily see what action they should take
      */
      if (this.customerFirstLogIn || this.customerPasswordWasReset) {
        this.set("userWantsToEditPassword", true);
      }
      let validations = (0, _lodash.cloneDeep)(this.validations);
      if ((this.customerFirstLogIn || this.customerPasswordWasReset) && this.customerTenantLoginType === this.constants.LOGIN_TYPE_LOCAL) {
        validations = {
          ...validations,
          newPassword: {
            isValid: false,
            required: true,
            invalidFeedback: {
              show: false,
              message: this.constants.PASSWORDS_NOT_SAME
            },
            format: {
              with: this.constants.PASSWORD_REGEX,
              invalidFeedback: this.constants.PASSWORD_NOT_MET_REQUIREMENTS
            }
          },
          confirmNewPassword: {
            isValid: false,
            required: true
          }
        };
        this.setProperties({
          resetPwd: true,
          userWantsToEditPassword: true
        });
      } else {
        this.setProperties({
          resetPwd: false,
          userWantsToEditPassword: false
        });
        delete validations["newPassword"];
        delete validations["confirmNewPassword"];
      }
      const newValidations = this.utils.validateForm(validations);
      this.setProperties({
        validations: newValidations
      });
    },
    actions: {
      validatePasswords() {
        /* 
          Validate pwds only when user wants to edit pwd, else there is no need for validation on current pwd 
        */
        if (this.userWantsToEditPassword) {
          let validations = (0, _lodash.cloneDeep)(this.validations);

          /* 
            if current user's password for the account is 
            equal to new password user wants to set, then set newPassword as invalid,
            as current pwd and new pwd can't be same 
          */
          if (this.newPassword === this.password) {
            validations["newPassword"].isValid = false;
            validations["newPassword"].invalidFeedback.show = true;
          } else {
            /* 
              Hide the forced invalidFeedback message first and then run invalidation 
            */
            validations["newPassword"].invalidFeedback.show = false;

            /* 
              Validate the current value of the pwd 
            */
            const {
              isValid
            } = this.utils.validateCurrentElement({
              fControlValue: this.newPassword,
              formValidations: validations,
              fControlName: "newPassword"
            });
            validations["newPassword"].isValid = isValid;
          }

          /* 
            Confirm new password must match new password in order for it to be valid 
             for every change in new password on key up event, we need to update the
            string that confirmPassword validates itself against to this new password
          */
          if (this.newPassword === this.confirmNewPassword) {
            validations["confirmNewPassword"].isValid = true;
          } else {
            validations["confirmNewPassword"].isValid = false;
            validations["confirmNewPassword"]["format"] = {
              with: `^${this.newPassword}$`,
              invalidFeedback: this.constants.PASSWORDS_DO_NOT_MATCH
            };
          }
          const newValidations = this.utils.validateForm(validations);
          this.set("validations", newValidations);
        }
      },
      togglePasswordChange() {
        this.set("userWantsToEditPassword", !this.userWantsToEditPassword);
        let validations = (0, _lodash.cloneDeep)(this.validations);

        /* 
          If user wants to edit pwd, add validations for newPwd and confirmPwd 
          else, delete those validations
        */
        if (this.userWantsToEditPassword) {
          validations = {
            ...validations,
            newPassword: {
              isValid: false,
              required: true,
              invalidFeedback: {
                show: false,
                message: this.constants.PASSWORDS_NOT_SAME
              },
              format: {
                with: this.constants.PASSWORD_REGEX,
                invalidFeedback: this.constants.PASSWORD_NOT_MET_REQUIREMENTS
              }
            },
            confirmNewPassword: {
              isValid: false,
              required: true
            }
          };
        } else {
          this.setProperties({
            newPassword: "",
            confirmNewPassword: ""
          });
          delete validations["newPassword"];
          delete validations["confirmNewPassword"];
        }
        const newValidations = this.utils.validateForm(validations);
        this.set("validations", newValidations);
      },
      togglePasswordVisibility() {
        this.set("passwordVisibility", !this.passwordVisibility);
      },
      updateAccount() {
        /* 
          set is loading state
        */
        this.setProperties({
          isAccountUpdateLoading: true,
          errorStatus: "",
          success: ""
        });
        let tenant_obj;
        if (this.customerFirstLogIn || this.customerPasswordWasReset) {
          tenant_obj = {
            data: {
              attributes: {
                email: this.email,
                password: this.password,
                password_confirmation: this.confirmNewPassword,
                new_password: this.newPassword,
                role_level: this.roleLevel
              }
            }
          };
        } else {
          tenant_obj = {
            data: {
              attributes: {
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                password: this.password,
                password_confirmation: this.confirmNewPassword,
                new_password: this.newPassword,
                role_level: this.roleLevel
              }
            }
          };
        } // -- end else

        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
          },
          "X-AB-Trace-ID": this.urls.generate_trace_id()
        });
        var URL = this.urls.URL_ACCOUNT(this.tenant.id);
        this.server.request("PUT", URL, tenant_obj, response => {
          if (response.meta && response.meta.errors) {
            this.setProperties({
              errorStatus: response.meta.errors[0] ? response.meta.errors[0].detail : response.meta.errors.detail,
              isAccountUpdateLoading: false
            });
          } else {
            this.setProperties({
              isAccountUpdateLoading: false,
              success: "Your account was successfully updated."
            });
            if (this.customerFirstLogIn) {
              this.auth.setTenantIsFirstLogin("false");
            } else if (this.customerPasswordWasReset) {
              this.auth.setTenantPasswordWasReset("false");
            }
            if (this.customerFirstLogIn || this.customerPasswordWasReset) {
              let SYSTEM_ON_PREM_LIST_VIEW = this.constants.SYSTEM_ON_PREM_LIST_VIEW;
              Ember.run.later(function () {
                window.location = SYSTEM_ON_PREM_LIST_VIEW;
              }, 450);
            }
          }
        }, error => {
          if (error.payload && error.payload.meta && error.payload.meta.errors) {
            this.passwordResetError = error.payload.meta.errors[0]["detail"];
          }
          this.setProperties({
            isAccountUpdateLoading: false,
            errorStatus: this.passwordResetError
          });
        });
      },
      formCancelTransition() {
        this.transitionToRoute("deployments.stand-alone-controller");
      }
    }
  });
  _exports.default = _default;
});