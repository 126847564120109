define("abcloud-ui/controllers/customer/account/tenant-users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    replace_element_with: Ember.inject.service("replace-element-with"),
    notifications: Ember.inject.service(),
    init() {
      this._super(...arguments);
      this.setProperties({
        isEditUserLoading: false,
        tenant: "",
        errorStatus: "",
        success: "",
        createUserAbility: "",
        createUserRole: null,
        canCreateMultipleRoles: false,
        userRoles: ["administrator", "standard user"],
        isSuperAdmin: "",
        accessTypes: this.constants.LOGIN_ACCESS_TYPES,
        currentUserRole: "",
        userAccessType: {}
      });
    },
    actions: {
      selectUserRole(role) {
        this.set("currentUserRole", role);
      },
      selectAccessType(accessType) {
        this.set("userAccessType", accessType);
      },
      updateAccount() {
        /* 
          set is loading state
        */

        this.setProperties({
          isEditUserLoading: true,
          errorStatus: "",
          success: ""
        });
        var first_name = document.getElementById("firstName").value;
        var last_name = document.getElementById("lastName").value;
        var email = document.getElementById("email").value;
        var errorCount = 0;

        // -- if they are changing any data and omit values for any of the fields
        if (!email || !first_name || !last_name) {
          errorCount++;
          this.set("errorStatus", "You must provide values for first name, last name and email when updating your account.");
        }
        if (errorCount === 0) {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-CV-Trace-ID": this.urls.generate_trace_id()
          });
          var URL = this.urls.URL_ACCOUNT(this.tenant.id);
          var tenant_obj = {
            data: {
              attributes: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                role_level: this.currentUserRole,
                login_type: this.userAccessType.type
              }
            }
          };
          this.ajax.request(URL, {
            method: "PUT",
            data: tenant_obj
          }).then(response => {
            this.set("isEditUserLoading", true);
            if (response.meta && response.meta.errors) {
              this.set("errorStatus", response.meta.errors[0].detail);
            } else {
              this.notifications.success(this.constants.ACCOUNT_UPDATED_SUCCESSFULLY);
              this.transitionToRoute("customer.account.tenant-users.index");
            }
          }).catch(() => {
            this.setProperties({
              errorStatus: this.constants.SOMETHING_WENT_WRONG,
              isEditUserLoading: true
            });
          });
        } else {
          return false;
        }
        errorCount = 0;
      },
      cancelEdit() {
        this.transitionToRoute("customer.account.tenant-users.index");
      }
    }
  });
  _exports.default = _default;
});