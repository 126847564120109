define("abcloud-ui/templates/components/download-file-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gQx93S8g",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n{{#if (eq downloadLink null)}}\\n  <div onClick={{action \\\"downloadFile\\\"}}  alt=\\\"Export this data to csv\\\" title=\\\"Export this data to csv\\\">\\n    {{#if (eq isRenderingFile true)}}\\n      <i title=\\\"Generating file...\\\" class=\\\"far fa-clock\\\"></i>\\n    {{else}}\\n      <i title=\\\"Export this data to csv\\\"  class=\\\"fas fa-file-export\\\"></i>\\n    {{/if}}\\n  </div>\\n{{else}}\\n  <a href={{downloadLink}} target=\\\"_blank\\\" title=\\\"Download your csv\\\" alt=\\\"Download your csv\\\">\\n    <i class=\\\"fas fa-cloud-download-alt\\\"></i>\\n  </a>\\n{{/if}}\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/download-file-button.hbs"
    }
  });
  _exports.default = _default;
});