define("abcloud-ui/pods/docs/doc/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    urls: Ember.inject.service('urls'),
    beforeModel() {
      if (!this.auth.isLoggedIn('customer')) {
        this.controllerFor('application').set('customerLoggedIn', false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo('customer.login');
      }
    },
    model(routeParams) {
      const url = this.urls.URL_GET_DOC_FILE_CONTENT();
      const path = `${routeParams.path_name.replace(/__/g, "/")}.yml`;
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      return Ember.$.getJSON(url, {
        path
      }).then(resp => {
        return resp.content;
      });
    }
  });
  _exports.default = _default;
});