define("abcloud-ui/templates/manage/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FAshZFrN",
    "block": "{\"symbols\":[\"adminUser\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-between align-items-center\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Users List\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"tr\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"col-6\"],[9],[0,\"Full Name\"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"col-6\"],[9],[0,\"Email\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"sortedModel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"col-6\"],[9],[1,[22,1,[\"firstName\"]],false],[0,\" \"],[1,[22,1,[\"lastName\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"col-6\"],[9],[0,\"\\n          \"],[1,[22,1,[\"email\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[22,1,[\"plain_text_password\"]]],null,{\"statements\":[[0,\"            \"],[7,\"b\"],[9],[0,\"Password: \"],[10],[0,\"\\n            \"],[1,[22,1,[\"plain_text_password\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"model\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"table/empty-data-table\",null,[[\"message\"],[\"There are no users available.\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/manage/users/list.hbs"
    }
  });
  _exports.default = _default;
});