define("abcloud-ui/components/policies-tenant/policies-out-of-spec/detection-and-severity-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    disabledStatusApiPath: true,
    disabledStatusApiParameter: true,
    didInsertElement() {
      try {
        var detected_actions_severity = this.policy_data.json.data.attributes.detected_actions_severity;
        var disabledStatus = this.disabledStatus ? this.disabledStatus : "";
        if (detected_actions_severity["detect_for_non_matching_api_paths"]) {
          document.getElementById("apiParameter" + disabledStatus).checked = true;
          if (disabledStatus) {
            document.getElementById("apiParameter" + disabledStatus).disabled = true;
          }
          var dropdownOption = detected_actions_severity["detect_for_non_matching_api_paths"];
          dropdownOption = dropdownOption.charAt(0).toUpperCase() + dropdownOption.slice(1);
          this.set("api_path_severity", dropdownOption);
          if (!disabledStatus) {
            this.set("disabledStatusApiPath", false);
          }
        }
        if (detected_actions_severity["detect_for_non_matching_api_params"]) {
          document.getElementById("apiPath" + disabledStatus).checked = true;
          if (disabledStatus) {
            document.getElementById("apiPath" + disabledStatus).disabled = true;
          }
          dropdownOption = detected_actions_severity["detect_for_non_matching_api_params"];
          dropdownOption = dropdownOption.charAt(0).toUpperCase() + dropdownOption.slice(1);
          this.set("api_parameter_severity", dropdownOption);
          if (!disabledStatus) {
            this.set("disabledStatusApiParameter", false);
          }
        }
        if (detected_actions_severity["detect_for_non_matching_api_param_data_type"]) {
          document.getElementById("apiParameterDataType" + disabledStatus).checked = true;
          if (disabledStatus) {
            document.getElementById("apiParameterDataType" + disabledStatus).disabled = true;
          }
          dropdownOption = detected_actions_severity["detect_for_non_matching_api_param_data_type"];
          dropdownOption = dropdownOption.charAt(0).toUpperCase() + dropdownOption.slice(1);
          this.set("api_parameter_data_type_severity", dropdownOption);
          if (!disabledStatus) {
            this.set("disabledStatusApiParameterDataType", false);
          }
        }
      } catch (thisIsANewPolicy) {
        this.debug.log("This is a New Policy");
      }
    },
    generateNewSet() {
      var severities = new Set();
      var severity_level_responses = [];
      if (!this.disabledStatusApiPath) {
        severities.add(this.api_path_severity.toLowerCase());
      }
      if (!this.disabledStatusApiParameter) {
        severities.add(this.api_parameter_severity.toLowerCase());
      }
      if (!this.disabledStatusApiParameterDataType) {
        severities.add(this.api_parameter_data_type_severity.toLowerCase());
      }
      severities.forEach(severity => {
        severity_level_responses.push({
          severity: severity,
          generate_alert_event: "true",
          invalidate_session: "false"
        });
      });
      this.setProperties({
        severity_level_responses: severity_level_responses
      });
    },
    actions: {
      apiPathSeverity(id, severity_level) {
        document.getElementById("recommendedSettings").checked = false;
        this.set("api_path_severity", severity_level);
        this.generateNewSet();
      },
      apiParameterSeverity(id, severity_level) {
        document.getElementById("recommendedSettings").checked = false;
        this.set("api_parameter_severity", severity_level);
        this.generateNewSet();
      },
      apiParameterDataTypeSeverity(id, severity_level) {
        document.getElementById("recommendedSettings").checked = false;
        this.set("api_parameter_data_type_severity", severity_level);
        this.generateNewSet();
      },
      toggleInputDisabling(event) {
        switch (event.target.id) {
          case "apiPath":
            if (document.getElementById("apiPath").checked) {
              this.set("disabledStatusApiPath", false);
            } else {
              this.set("disabledStatusApiPath", true);
            }
            break;
          case "apiParameter":
            if (document.getElementById("apiParameter").checked) {
              this.set("disabledStatusApiParameter", false);
            } else {
              this.set("disabledStatusApiParameter", true);
            }
            break;
          case "apiParameterDataType":
            if (document.getElementById("apiParameterDataType").checked) {
              this.set("disabledStatusApiParameterDataType", false);
            } else {
              this.set("disabledStatusApiParameterDataType", true);
            }
            break;
          default:
            this.debug.log("unknown input id..");
        }
        document.getElementById("recommendedSettings").checked = false;
        this.generateNewSet();
      },
      resetToRecommendedSettings() {
        document.getElementById("apiPath").checked = true;
        document.getElementById("apiParameter").checked = true;
        document.getElementById("apiParameterDataType").checked = true;
        this.setProperties({
          disabledStatusApiPath: false,
          disabledStatusApiParameter: false,
          api_path_severity: "High",
          api_parameter_severity: "High",
          api_parameter_data_type_severity: "High",
          severity_level_responses: [{
            severity: "high",
            generate_alert_event: "true",
            invalidate_session: "false"
          }]
        });
      }
    }
  });
  _exports.default = _default;
});