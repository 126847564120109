define("abcloud-ui/routes/customer/account/tenant-users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service("urls"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    beforeModel() {
      if (!this.auth.isLoggedIn("customer")) {
        this.controllerFor("application").set("customerLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("/customer/login");
      }
    },
    model() {
      // -- grabbing all records except current user's
      return this.store.query("tenant_user", {});
    },
    setupController(controller, model) {
      controller.set("model", model);
      var userRole = this.auth.getTenantCreateUserRole();
      var canCreateMultipleRoles = this.auth.getTenantCreateUserRole().lastIndexOf(",") != -1;
      if (canCreateMultipleRoles) {
        userRole = userRole.split(",")[0];
      }
      controller.setProperties({
        isSuperAdmin: model ? model.role_level === this.constants.SUPER_ADMIN : false,
        createUserAbility: this.auth.getTenantCreateUserAbility(),
        createUserRole: userRole,
        canCreateMultipleRoles: canCreateMultipleRoles
      });
    },
    resetController: function (controller, isExiting) {
      this._super.apply(this, arguments);
    },
    renderTemplate() {
      this.render("customer.account.tenant-users.index", {
        into: "customer.account.tenant-users",
        outlet: "account-edit"
      });
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("customer.account.tenant-users.index");
        controller.set("isUsersListLoading", true);
        transition.promise.finally(function () {
          controller.set("isUsersListLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});