define("abcloud-ui/routes/policies/policies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          "Access-Control-Allow-Origin": "*",
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      var urls = this.urls;
      var request1 = Ember.$.getJSON(urls.URL_POLICY());
      var request2 = Ember.$.getJSON(urls.URL_GET_PROFILES_FOR_POLICIES());
      var routeName = this.routeName;
      this.manage_requests.add_requests([{
        [routeName]: request1
      }, {
        [routeName]: request2
      }]);
      return Ember.RSVP.hash({
        policies: request1,
        meta_data: request2
      });
    },
    setupController(controller, model) {
      controller.set('model', model);
    },
    resetController: function (controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
        controller.set('errors', []);
      }
    }
  });
  _exports.default = _default;
});