define("abcloud-ui/components/policies-tenant/profile-components/edit-activity-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      try {
        try {
          if (this.profile.location.http_request_header.value) {
            if (this.disabled) {
              Ember.$('#httpHeader_disabled').attr('checked', 'true');
              Ember.$('#headerName_disabled').removeAttr('disabled');
            } else {
              Ember.$('#httpHeader').attr('checked', 'true');
              Ember.$('#headerName_').removeAttr('disabled');
            }
          }
        } catch (noHeader) {
          this.debug.log('No HTTP Request Header Detected');
        }
        try {
          if (this.profile.location.http_request_cookie.value) {
            if (this.disabled) {
              Ember.$('#httpCookie_disabled').attr('checked', 'true');
              Ember.$('#cookieName_disabled').removeAttr('disabled');
            } else {
              Ember.$('#httpCookie').attr('checked', 'true');
              Ember.$('#cookieName_').removeAttr('disabled');
            }
          }
        } catch (noCookie) {
          this.debug.log('No HTTP Request Cookie Detected');
        }
      } catch (newProfile) {
        this.debug.log('This is a new Profile');
      }
      if (this.changed_labels) {
        this.changed_labels.forEach(label_id => {
          if (this.disabled) {
            Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
          } else {
            Ember.$('#' + label_id + '').addClass('draft_label_changed');
          }
        });
      }
    },
    actions: {
      toggle(input_id, event) {
        if (event.target.checked) {
          Ember.$('#' + input_id + '').prop('disabled', false);
        } else {
          Ember.$('#' + input_id + '').prop('disabled', true);
        }
      },
      chooseActivityName(activityName) {
        this.set('activityName', activityName);
      },
      saveActivitySession(action, whenToPublish) {
        this.saveActivitySession(action, whenToPublish);
      },
      cancel() {
        this.transitionToListView();
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      }
    },
    willDestroyElement() {
      var changed_labels = ['nameLabel', 'descriptionLabel'];
      changed_labels.forEach(label_id => {
        if (this.disabled) {
          Ember.$('#' + label_id + this.disabled + '').removeClass('published_label_changed');
        } else {
          Ember.$('#' + label_id + '').removeClass('draft_label_changed');
        }
      });
    }
  });
  _exports.default = _default;
});