define("abcloud-ui/templates/components/table/empty-data-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iW5JTHFP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"empty-table-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-3x fa-search\"],[11,\"data-toggle\",\"dropdown\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text--h3\"],[9],[0,\"No data available\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-secondary\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/table/empty-data-table.hbs"
    }
  });
  _exports.default = _default;
});