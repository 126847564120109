define("abcloud-ui/templates/components/system-localbay/localbay-health-status-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MgzP962P",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"or\",[[27,\"eq\",[[23,[\"dplet_type\"]],\"lambda\"],null],[27,\"eq\",[[23,[\"dplet_type\"]],\"kong_api_gateway\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"HEALTH NOT SUPPORTED\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],[23,[\"LB_OVERALL_HEALTH_UNAVAILABLE\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Unavailable\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],[23,[\"LB_OVERALL_HEALTH_ERROR\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Pending\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],[23,[\"DPL_HIGH_LEVEL_HEALTH_ERROR\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Unavailable\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],[23,[\"DPL_HIGH_LEVEL_HEALTH_WARNING\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Warning\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"status\"]],[23,[\"DPL_HIGH_LEVEL_HEALTH_SUCCESS\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Available\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Available\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/localbay-health-status-display.hbs"
    }
  });
  _exports.default = _default;
});