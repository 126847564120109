define("abcloud-ui/templates/components/json-style-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5hNNZQ9L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dropdown ab-dropdown\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\" btn btn-dropdown dropdown-toggle\"],[11,\"data-toggle\",\"dropdown\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[21,\"jsonStyle\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"dropdown-item\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleJsonStyle\",\"Tree\"],null]],[9],[0,\"Tree\"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"dropdown-item\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggleJsonStyle\",\"Code\"],null]],[9],[0,\"Code\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/json-style-toggle.hbs"
    }
  });
  _exports.default = _default;
});