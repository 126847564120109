define("abcloud-ui/helpers/lowercase-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lowercaseString = lowercaseString;
  function lowercaseString(_ref) {
    let [string] = _ref;
    if (string) {
      return string.toLowerCase();
    }
  }
  var _default = Ember.Helper.helper(lowercaseString);
  _exports.default = _default;
});