define("abcloud-ui/helpers/concat-strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatStrings = concatStrings;
  _exports.default = void 0;
  function concatStrings(_ref) {
    let [string1, string2] = _ref;
    if (string1 && string2) {
      return String(string1) + String(string2);
    } else {
      if (string1) {
        return String(string1);
      } else {
        return String(string2);
      }
    }
  }
  var _default = Ember.Helper.helper(concatStrings);
  _exports.default = _default;
});