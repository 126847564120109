define("abcloud-ui/routes/admin/customers/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    subdomainVal: null,
    beforeModel() {
      if (!this.auth.isLoggedIn("admin")) {
        this.controllerFor("application").set("adminLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("/admin/login");
      }
    },
    renderTemplate() {
      this.render("admin.customers.new", {
        into: "application",
        outlet: "admin"
      });
    },
    resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.setProperties({
          error: "",
          isAddCustomerLoading: false,
          name: "",
          point_of_contact: "",
          point_of_contact_email: "",
          website: "",
          language: "",
          city: "",
          state: "",
          country: ""
        });
      }
    }
  });
  _exports.default = _default;
});