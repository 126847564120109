define("abcloud-ui/controllers/system/hosted/localconsole/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    notifications: Ember.inject.service(),
    replace_element_with: Ember.inject.service("replace-element-with"),
    localcomponents: null,
    showPreloader: true,
    actions: {
      deleteLocalconsole(id) {
        if (confirm(this.constants.LOCALCONSOLE_DELETE_CONFIRMATION)) {
          this.replace_element_with.mask("#localcomponent_" + id);
          this.store.findRecord("localcomponent", id, {
            backgroundReload: false
          }).then(local_console => {
            local_console.destroyRecord().then(() => {
              this.notifications.success("Deleted successfully!", {
                autoClear: true,
                clearDuration: 2000
              });
            }).catch(error => {
              this.replace_element_with.unmask("#localcomponent_" + id);
              let errorMsg = this.constants.DELETE_FAILED;
              if (error && error.hasOwnProperty("errors")) {
                errorMsg = error.errors[0].detail;
              }
              this.notifications.error(errorMsg, {
                autoClear: true,
                clearDuration: 2000
              });
            });
          });
        }
      },
      editLocalconsole(id) {
        this.replace_element_with.mask("#localcomponent_" + id);
        this.transitionToRoute("system.hosted.localconsole.edit", id);
      }
    }
  });
  _exports.default = _default;
});