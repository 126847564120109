define("abcloud-ui/components/title-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      checkIfTitleValid() {
        let title = document.getElementById(this.inputId).value;
        if (!title) {
          this.set("validTitle", null);
        } else {
          // Replace spaces and hyphens with _
          document.getElementById(this.inputId).value = title.replace(/[ -]/g, "_");
          if (!document.getElementById(this.inputId).value.match(/^(?=[a-zA-Z0-9_]{1,64}$).*$/)) {
            this.set("validTitle", false);
          } else {
            this.set("validTitle", true);
          }
        }
      }
    }
  });
  _exports.default = _default;
});