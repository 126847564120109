define("abcloud-ui/helpers/val-for-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.valForKey = valForKey;
  function valForKey(_ref) {
    let [rowData, col] = _ref;
    if (col == undefined || rowData == undefined) {
      return "";
    }
    if (col === '') {
      if (col in rowData) {
        return rowData[col];
      }
    }
    var charArray = col.split('.');
    var tempResult = rowData;
    if (charArray.length > 1) {
      charArray.forEach(key => {
        try {
          if (key in tempResult) {
            tempResult = tempResult[key];
          } else {
            throw 'requested key not present';
          }
        } catch (noSuchKeyPresent) {
          tempResult = '';
        }
      });
      return tempResult;
    } else {
      if (isNaN(col)) {
        if (col in rowData) {
          return rowData[col];
        }
      } else {
        return rowData[col];
      }
    }
    return "key doesn't exist...".trim();
  }
  var _default = Ember.Helper.helper(valForKey);
  _exports.default = _default;
});