define("abcloud-ui/controllers/manage/users/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    auth: Ember.inject.service("auth"),
    urls: Ember.inject.service("urls"),
    init() {
      this._super(...arguments);
      this.setProperties({
        didUserSessionTimeOut: this.auth.didUserSessionTimeOut(),
        email: null,
        password: null,
        unauthorizedUser: false,
        isAdminUserLoginLoading: false,
        validations: {
          isFormValid: false,
          email: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          },
          password: {
            isValid: null,
            required: true
          }
        }
      });
    },
    actions: {
      adminUserLogin: function () {
        /* 
          Reset form states
        */
        this.setProperties({
          unauthorizedUser: false,
          didUserSessionTimeOut: false,
          isAdminUserLoginLoading: true
        });
        var request_object = {
          email: this.email,
          password: this.password
        };
        var url = this.urls.URL_ADMIN_USER_SIGN_IN();
        return this.ajax.request(url, {
          method: "POST",
          data: request_object
        }).then(response => {
          const {
            auth_token,
            admin_user
          } = response;
          this.setProperties({
            unauthorizedUser: false,
            didUserSessionTimeOut: false,
            isAdminUserLoginLoading: false
          });
          this.auth.setAuthToken(auth_token, "adminUser");
          if (admin_user) {
            const isResettingPassword = admin_user.is_resetting_password;
            this.auth.setIsAdminUserResttingPwd(isResettingPassword);
            if (isResettingPassword) {
              window.location = this.constants.ADMIN_USER_RESET_PWD;
            } else {
              window.location = this.constants.ADMIN_CUSTOMER_LIST;
            }
          } else {
            window.location = this.constants.ADMIN_CUSTOMER_LIST;
          }
        }, error => {
          if ([this.constants.UNAUTHORIZED_ACCESS, this.constants.REQUEST_REJECTED].includes(error.message)) {
            this.setProperties({
              unauthorizedUser: true,
              didUserSessionTimeOut: false,
              isAdminUserLoginLoading: false
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});