define("abcloud-ui/routes/policies/profiles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model() {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`
        },
        'X-CV-Trace-ID': this.urls.generate_trace_id()
      });
      var url = this.urls.URL_POLICY_PROFILE();
      var request = Ember.$.getJSON(url);
      this.manage_requests.add_request(this.routeName, request);
      return request;
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
      }
    }
  });
  _exports.default = _default;
});