define("abcloud-ui/components/policies-tenant/policies-out-of-spec/actions-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      optForAlertEvent(severity, index, event) {
        var severity_level_responses = this.severity_level_responses;
        severity_level_responses[index]["generate_alert_event"] = String(event.target.checked);
        this.set("severity_level_responses", severity_level_responses);
      },
      chooseDefaultProfiles(invalidateSessionIsChecked, index) {
        var severity_level_responses = JSON.parse(JSON.stringify(this.severity_level_responses));
        if (invalidateSessionIsChecked) {
          severity_level_responses[index]["invalidate_session"] = {
            session_token_profile: {
              id: this.apiSessionTokenProfilesList[0].data.id,
              name: this.apiSessionTokenProfilesList[0].data.attributes.name
            },
            session_termination_profile: {
              id: this.sessionTerminationProfilesList[0].data.id,
              name: this.sessionTerminationProfilesList[0].data.attributes.name
            }
          };
          this.set("severity_level_responses", severity_level_responses);
        } else {
          severity_level_responses[index]["invalidate_session"] = "false";
          this.set("severity_level_responses", severity_level_responses);
        }
      },
      chooseProfileForInvalidation(id, profile_name, type, index) {
        var severity_level_responses = this.severity_level_responses;
        if (severity_level_responses[index]["invalidate_session"] === "false") {
          severity_level_responses[index]["invalidate_session"] = [];
        }
        if (type === "session_token_profile") {
          severity_level_responses[index]["invalidate_session"]["session_token_profile"] = {
            id: id,
            name: profile_name
          };
        } else {
          severity_level_responses[index]["invalidate_session"]["session_termination_profile"] = {
            id: id,
            name: profile_name
          };
        }
        this.setProperties({
          severity_level_responses: severity_level_responses
        });
      }
    }
  });
  _exports.default = _default;
});