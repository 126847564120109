define("abcloud-ui/helpers/is-last-index-in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isLastIndexInArray = isLastIndexInArray;
  function isLastIndexInArray(_ref) {
    let [index, array] = _ref;
    if (parseInt(index) === parseInt(array.length - 1)) {
      return true;
    }
    return false;
  }
  var _default = Ember.Helper.helper(isLastIndexInArray);
  _exports.default = _default;
});