define("abcloud-ui/routes/manage/customers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service("auth"),
    manage_requests: Ember.inject.service("ab-ajax-requests/manage-requests"),
    beforeModel() {
      if (!this.auth.isLoggedIn("adminUser")) {
        this.controllerFor("application").set("adminUserLoggedIn", false);
        this.auth.didUserSessionTimeOut(true);
        this.transitionTo("/manage/users/login");
      }
    },
    model(params) {
      return this.store.findRecord("customer", params.customer_id);
    },
    setupController(controller, model) {
      const isResettingPassword = this.auth.getIsAdminUserResttingPwd();
      if (isResettingPassword) {
        window.location = this.constants.ADMIN_USER_RESET_PWD;
      }
      this._super(controller, model);
      const {
        name,
        point_of_contact,
        point_of_contact_email,
        website,
        language,
        location
      } = model;
      controller.setProperties({
        name,
        point_of_contact,
        point_of_contact_email,
        website,
        language,
        city: location.city,
        state: location.state,
        country: location.country
      });
    },
    renderTemplate() {
      this.render("manage.customers.edit", {
        into: "application",
        outlet: "admin"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          isEditCustomerLoading: false,
          error: "",
          name: "",
          point_of_contact: "",
          point_of_contact_email: "",
          website: "",
          language: "",
          city: "",
          state: "",
          country: ""
        });
      }
    },
    actions: {
      async loading(transition, originRoute) {
        const controller = this.controllerFor("admin.customers.edit");
        controller.set("isCustomerLoading", true);
        transition.promise.finally(function () {
          controller.set("isCustomerLoading", false);
        });
      }
    }
  });
  _exports.default = _default;
});