define("abcloud-ui/components/security-alerts/alerts-map-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      Ember.$('#location_image').empty();
      Ember.$('#location_image').append(this.alertGeoLocationMap);
    },
    didReceiveAttrs() {
      Ember.$('#location_image').empty();
      Ember.$('#location_image').append(this.alertGeoLocationMap);
    }
  });
  _exports.default = _default;
});