define("abcloud-ui/controllers/policies/api-recorder/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    init() {
      this._super(...arguments);
      this.setProperties({
        policyId: "",
        recording_complete: "",
        headings: this.constants.API_RECORDER_API_SPEC_TABLE_HEADERS,
        apiDetails: "",
        displayPane: false,
        labels: undefined,
        isFetchingSelectedRowData: false,
        detailCellId: "detail_pane",
        tableClass: "data-list-table",
        current_selected_row: "",
        pollData: "",
        download_error: false,
        poll_data_id: "",
        TIME_OUT: 2000,
        INTERVAL_ID: [],
        currentFilterOption: "Only APIs",
        apiCount: "--",
        currentHeading: "API",
        canPolicyResume: true,
        errorMessage: ""
      });
    },
    getValueOfElementById(elementId) {
      return document.getElementById(elementId).value;
    },
    // sleep time expects milliseconds
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    getCurrentState() {
      const requestPolicyStateUrl = this.services.urls.URL_GET_POLICY_STATE(this.policyId);
      this.services.server.request("GET", requestPolicyStateUrl, null, policyStateResponse => {
        if (policyStateResponse.current_state === "") {
          this.setProperties({
            pollData: this.constants.STATUS_PENDING,
            recording_status: this.constants.DONE_RECORDING,
            isRecording: false
          });
          this.send("destroyComponent");

          // -- if current_state='', poll for current_state until current_state is valid
          this.sleep(2000).then(() => {
            this.getCurrentState();
          });
        }

        // -- notify user that data is being prepared if current_state is pulled
        else if (policyStateResponse.current_state === "pulled") {
          this.setProperties({
            pollData: this.constants.PREPARING_DATA,
            recording_status: this.constants.DONE_RECORDING,
            isRecording: false
          });
          this.send("destroyComponent");
        }

        // -- query data if current_state is finished
        else if (policyStateResponse.current_state === "finished") {
          const queryDataUrl = this.services.urls.URL_COMPLETED_API_RECORDER(this.policyId);
          this.services.server.request("GET", queryDataUrl, {
            is_api: true
          }, queryDataResponse => {
            this.setProperties({
              pollData: queryDataResponse,
              recording_status: this.constants.DONE_RECORDING,
              isRecording: false
            });
            this.send("destroyComponent");
          }, queryDataError => {
            this.debug.log(queryDataError);
          });
        } else {
          this.debug.log("Something went wrong with current_state...");
        }
      }, requestPolicyStateUrlError => {
        this.debug.log(requestPolicyStateUrlError);
      });
    },
    actions: {
      closeModalOnEscape(event) {
        if (event.key === "Escape") {
          this.set("displayPane", false);
          if (this.current_selected_row) {
            Ember.$("#" + this.current_selected_row).removeClass("active");
          }
        }
      },
      transitionToListView() {
        this.transitionToRoute("policies.api-recorder");
      },
      chooseLocalbay(localcomponentId, localcomponent) {
        this.localbayList.forEach(localbayObj => {
          if (localcomponentId === localbayObj.id) {
            this.setProperties({
              microsensorList: localbayObj.dplets.data,
              currentLocalbayId: localcomponentId,
              currentLocalbay: localcomponent,
              currentMicrosensorId: localbayObj.dplets.data[0].id,
              currentMicrosensor: localbayObj.dplets.data[0].name
            });
            var url = this.services.urls.URL_CAN_RECORD(localcomponentId, localbayObj.dplets.data[0].id);
            this.services.ajax.request(url, {
              method: "GET"
            }).then(response => {
              if (!response.can_record) {
                Ember.$("#myPopup").addClass("show");
                Ember.$("#localbayDropdown").addClass("info_banner");
                Ember.$("#microsensorDropdown").addClass("info_banner");
              } else {
                Ember.$("#myPopup").removeClass("show");
                Ember.$("#localbayDropdown").removeClass("info_banner");
                Ember.$("#microsensorDropdown").removeClass("info_banner");
              }
              this.set("can_publish", response.can_record);
            });
          }
        });
      },
      chooseMicroSensor(microsensorId, microsensor) {
        this.setProperties({
          currentMicrosensor: microsensor,
          currentMicrosensorId: microsensorId
        });
        var url = this.services.urls.URL_CAN_RECORD(this.currentLocalbayId, microsensorId);
        this.services.ajax.request(url, {
          method: "GET"
        }).then(response => {
          if (!response.can_record) {
            Ember.$("#myPopup").addClass("show");
            Ember.$("#localbayDropdown").addClass("info_banner");
            Ember.$("#microsensorDropdown").addClass("info_banner");
          } else {
            Ember.$("#myPopup").removeClass("show");
            Ember.$("#localbayDropdown").removeClass("info_banner");
            Ember.$("#microsensorDropdown").removeClass("info_banner");
          }
          this.set("can_publish", response.can_record);
        });
      },
      chooseApiFilterType(choice) {
        this.set("currentFilterOption", choice);
      },
      savePolicy(action) {
        var name = document.getElementById("name").value;
        var description = document.getElementById("description").value;
        var profilesIds = [];
        var localbayDetails = {
          id: this.currentLocalbayId,
          title: this.currentLocalbay
        };
        var dplet_details = {
          id: this.currentMicrosensorId,
          name: this.currentMicrosensor
        };
        var changeset = {
          "nameLabel.Profile_Name": name,
          "descriptionLabel.Description": description,
          "localbayLabel.LocalBay_Information.LocalBay_to_Install_this_Policy": this.currentLocalbay,
          "dpletLabel.MicroSensor_Information.MicroSensor": this.currentMicrosensor,
          "apiNetworkTrafficLabel.MicroSensor_Information.Src_IP_CIDR": this.getValueOfElementById("src_ip_cidr"),
          "apiNetworkTrafficLabel.MicroSensor_Information.Src_IP_Port": this.getValueOfElementById("src_ip_port"),
          "apiNetworkTrafficLabel.MicroSensor_Information.Dest_IP_CIDR": this.getValueOfElementById("dest_ip_cidr"),
          "apiNetworkTrafficLabel.MicroSensor_Information.Dest_IP_Port": this.getValueOfElementById("dest_ip_port"),
          "autoStopLabel.Schedule_Information.Stop_Automatically": document.getElementById("auto_stop").checked ? this.getValueOfElementById("autoTime") : "",
          "manualStopLabel.Schedule_Information.Stop_Manually": document.getElementById("manual_stop").checked ? "true" : "false"
        };
        var jsonObject = {
          policy_filters: {
            dplet_id: this.currentMicrosensorId,
            traffic: {
              src_port: this.getValueOfElementById("src_ip_port"),
              src_ip: this.getValueOfElementById("src_ip_cidr"),
              dst_ip: this.getValueOfElementById("dest_ip_cidr"),
              dst_port: this.getValueOfElementById("dest_ip_port")
            }
          },
          schedule_information: {}
        };
        let validScheduleInfo = false;
        if (document.getElementById("auto_stop").checked) {
          jsonObject["schedule_information"]["auto_stop"] = [this.getValueOfElementById("autoTime"), "hours"].join(" ");
          validScheduleInfo = 0 < this.getValueOfElementById("autoTime") && this.getValueOfElementById("autoTime") < 73 ? true : false;
        } else if (document.getElementById("manual_stop").checked) {
          jsonObject["schedule_information"]["manual_stop"] = true;
          validScheduleInfo = true;
        }
        this.services.build_policy_object_for_server.set_type("policy", "api_recorder");
        this.services.build_policy_object_for_server.set_common_top_level_values(localbayDetails, profilesIds, action, name, description, dplet_details);
        this.services.build_policy_object_for_server.set_changeset(changeset);
        this.services.build_policy_object_for_server.set_json(jsonObject);
        var request_object = this.services.build_policy_object_for_server.get_request_object();
        if (name && description && validScheduleInfo) {
          var url = this.services.urls.URL_POLICY(this.policyId);
          this.services.publish.policy(action, "PUT", url, request_object, response => {
            if (!response.error) {
              this.transitionToRoute("policies.api-recorder");
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            scheduleInfoFilledValue: validScheduleInfo
          };
          this.services.inject_notification.notify_unfilled_mandatory_field(elements, this.constants.MINIMUM_REQUIREMENTS);
        }
      },
      showApiDetails(apiDetails, current_selected_row) {
        const {
          method,
          grouped_api,
          body_params,
          query_params
        } = apiDetails;
        this.set("labels", undefined);
        this.set("isFetchingSelectedRowData", true);
        const payload = {
          api: grouped_api,
          method: method,
          body_params: body_params.map(_ref => {
            let {
              parameter_name
            } = _ref;
            return parameter_name;
          }),
          query_params: query_params.map(_ref2 => {
            let {
              parameter_name
            } = _ref2;
            return parameter_name;
          })
        };
        const url = this.services.urls.URL_COMPLETED_API_RECORDER_LABELS(this.policyId);
        this.services.server.request("POST", url, payload, response => {
          this.set("isFetchingSelectedRowData", false);
          if (!response.error) {
            const {
              data
            } = response;
            const tagsData = data || {
              query_params: [],
              body_params: []
            };
            let serializedTagData = {
              query_params: tagsData.query_params.reduce((result, item) => {
                return {
                  ...result,
                  [item.parameter_name]: item.parameter_labels
                };
              }, {}),
              body_params: tagsData.body_params.reduce((result, item) => {
                return {
                  ...result,
                  [item.parameter_name]: item.parameter_labels
                };
              }, {})
            };
            this.set("labels", serializedTagData);
          } else {
            this.services.notifications.error("Failed to fetch labels data", {
              autoClear: true,
              clearDuration: this.constants.CLEAR_DURATION
            });
          }
        }, error => {
          //set download_error here
          this.set("isFetchingSelectedRowData", false);
          this.services.notifications.error("Failed to fetch labels data", {
            autoClear: true,
            clearDuration: this.constants.CLEAR_DURATION
          });
        });
        this.setProperties({
          apiDetails: apiDetails,
          current_selected_row: ["row", current_selected_row].join("_"),
          displayPane: true
        });
        if (this.current_selected_row) {
          Ember.$("#" + this.current_selected_row).addClass("active").siblings().removeClass("active");
        }
      },
      toggleDisplayPane(status) {
        if (status === "closeDisplayPane") {
          this.set("displayPane", false);
        } else {
          this.set("displayPane", !this.displayPane);
          if (this.current_selected_row) {
            Ember.$("#" + this.current_selected_row).removeClass("active");
          }
          this.transitionToRoute("policies.api-recorder.edit", this.policyId);
        }
      },
      download_api_spec(fileType, pollData, currentFilterOption) {
        if (fileType === "csv") {
          this.services.download_file.in_csv(pollData.api_spec, this.policy.name);
        } else if (fileType === "json") {
          this.services.download_file.in_json(pollData.api_spec, this.policy.name);
        } else if (fileType === "open_api") {
          const url = this.services.urls.URL_DOWNLOAD_OPEN_API_SPEC(this.policyId);
          const queryParam = currentFilterOption === "Only APIs" ? {
            is_api: true
          } : "";
          const openApisRequest = Ember.RSVP.hash({
            open_apis: new Promise(resolve => {
              const request = Ember.$.getJSON(url, queryParam);
              resolve(request);
            })
          });
          openApisRequest.then(response => {
            const open_apis = response.open_apis;
            if (!open_apis.errors) {
              this.set("download_error", false);
              this.services.download_file.in_json(open_apis, this.policy.name);
            } else {
              this.set("download_error", true);
            }
          }, error => {
            this.set("download_error", true);
            this.debug.log(error);
          });
        }
      },
      deleteApiRecorder() {
        var policyId = this.policyId;
        var CLEAR_DURATION = this.constants.CLEAR_DURATION;
        var url = this.services.urls.URL_POLICY(policyId);
        this.services.replace_element_with.left_justified_preloader("#loading_gif_container");
        if (confirm(this.constants.API_RECORDER_DELETE_CONFIRMATION)) {
          this.services.ajax.request(url, {
            method: "DELETE"
          }).then(() => {
            this.services.notifications.success("Deleted successfully!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
            this.transitionToRoute("policies.api-recorder.index");
          }).catch(() => {
            this.services.notifications.error("Failed to delete!", {
              autoClear: true,
              clearDuration: CLEAR_DURATION
            });
          });
        }
      },
      openResumeRecordModal() {
        Ember.$("#continueRecordingModal").modal("show");
        // -- make a call to rails to fetch if the policy can be resumed recording or not; the response would be boolean
        const canResumeUrl = this.services.urls.URL_CAN_RESUME(this.policyId);
        this.services.server.request("GET", canResumeUrl, null, response => {
          if (!response.errors) {
            this.set("canPolicyResume", true);
          } else {
            this.setProperties({
              canPolicyResume: false,
              errorMessage: response.errors[0].detail
            });
          }
        }, error => {
          this.debug.log(error);
        });
      },
      record(action) {
        var url = "";
        var CLEAR_DURATION = this.constants.CLEAR_DURATION;
        if (action === "stop" && confirm(this.constants.API_RECORDER_STOP_CONFIRMATION)) {
          this.services.replace_element_with.inline_preloader("#loading_gif_container");
          url = this.services.urls.URL_STOP_RECORDING(this.policyId);
          this.services.server.request("GET", url, null, response => {
            if (!response.error) {
              //-- update the localStorage recording_status
              this.services.query_params.setParam("recording_status", this.constants.DONE_RECORDING);

              //-- update the policy object to reflect the recording_status of the policy
              this.services.server.request("GET", this.services.urls.URL_POLICY(this.policyId), null, policyObjResponse => {
                this.set("policy", policyObjResponse.data.attributes);
              }, policyObjError => {
                this.debug.log(policyObjError);
              });

              //-- get the current_state of the policy
              this.getCurrentState();
            }
          }, error => {
            //set download_error here
            this.debug.log(error);
          });
        } else if (action === "re_record" && confirm(this.constants.API_RECORDER_CLONE_CONFIRMATION)) {
          this.services.replace_element_with.inline_preloader("#loading_gif_container");
          url = this.services.urls.URL_RE_RECORD(this.policyId);
          this.services.server.request("GET", url, null, response => {
            if (!response.error) {
              this.transitionToRoute("policies.api-recorder.index");
            }
          }, error => {
            //set download_error here
            this.debug.log(error);
          });
        } else if (action === "resume") {
          this.services.replace_element_with.inline_preloader("#loading_gif_container");
          let schedule_information = {};
          let valid_schedule_info = false;
          if (document.getElementById("auto_stop").checked) {
            schedule_information = {
              auto_stop: [this.getValueOfElementById("autoTime"), "hours"].join(" ")
            };
            valid_schedule_info = 0 < this.getValueOfElementById("autoTime") && this.getValueOfElementById("autoTime") < 73 ? true : false;
          } else if (document.getElementById("manual_stop").checked) {
            schedule_information = {
              manual_stop: true
            };
            valid_schedule_info = true;
          }
          const payload = {
            data: {
              attributes: {
                json: {
                  data: {
                    attributes: {
                      schedule_information
                    }
                  }
                }
              }
            }
          };
          if (valid_schedule_info) {
            Ember.$("#continueRecordingModal").modal("hide");
            url = this.services.urls.URL_RESUME_RECORD(this.policyId);
            this.services.server.request("POST", url, payload, response => {
              if (!response.errors) {
                this.transitionToRoute("policies.api-recorder.index");
              } else {
                this.services.notifications.error(response.errors[0].detail, {
                  autoClear: true,
                  clearDuration: CLEAR_DURATION
                });
              }
            }, () => {
              this.services.notifications.error("Failed to resume!", {
                autoClear: true,
                clearDuration: CLEAR_DURATION
              });
            });
          }
        }
      },
      createOutOfSpecProfile() {
        this.transitionToRoute("policies.profiles.out-of-spec", {
          queryParams: {
            api_recording_id: this.poll_data_id
          }
        });
      },
      pollApiDetails() {
        const url = this.services.urls.URL_POLL_API_RECORDER(this.policyId);
        let self = this;
        pollData();
        this.INTERVAL_ID.push(setInterval(function () {
          pollData();
        }, this.TIME_OUT));
        function pollData() {
          let stopTime = self.policy.policy_data[0].json.data.attributes.recording_status.stop_time;
          let currentTime = Math.floor(new Date().getTime() / 1000);

          //-- if current time is past stop time, then request for policy state and stop polling accordingly
          if (stopTime < currentTime) {
            self.getCurrentState();
          } else {
            let pollDataRequest = Ember.RSVP.hash({
              api_spec: new Promise(resolve => {
                var request = Ember.$.getJSON(url);
                self.services.manage_requests.add_request("policies.api-recorder.edit", request);
                resolve(request);
              })
            });
            pollDataRequest.then(() => {
              const pollDataResponse = pollDataRequest._result.api_spec;
              const pollData = pollDataResponse.data.hasOwnProperty("attributes") ? pollDataResponse.data.attributes.data : self.constants.RECORDING_IN_PROGRESS;
              const apiCount = pollDataResponse.data.hasOwnProperty("attributes") ? pollDataResponse.data.attributes.data.length : "--";
              self.setProperties({
                pollData: pollData,
                apiCount: apiCount
              });
            });
          }
        }
      },
      destroyComponent() {
        this.INTERVAL_ID.forEach(intervalId => {
          clearInterval(intervalId);
        });
        this.set("INTERVAL_ID", []);
        this.services.manage_requests.abort_request("policies.api-recorder.edit");
      },
      _filterRecordings(isResetFilter) {
        this.set("didFilter", false);
        Ember.$.ajaxSetup({
          headers: {
            Authorization: `Bearer ${this.services.auth.getAuthToken("customer")}`,
            "Access-Control-Allow-Origin": "*",
            "X-CV-Trace-ID": this.services.urls.generate_trace_id()
          }
        });
        const url = this.services.urls.URL_COMPLETED_API_RECORDER(this.policyId);
        let queryParams = {};
        if (isResetFilter) {
          queryParams["is_api"] = true;
          this.setProperties({
            currentHeading: "Only APIs",
            currentFilterOption: "Only APIs"
          });
        } else {
          this.constants.ADVANCED_FILTER_OPTIONS.forEach(options => {
            var requestValue = document.getElementById(options.inputId).value;
            if (options.inputType === "number" && requestValue) {
              requestValue = Number(requestValue);
            }
            queryParams[options.requestKey] = requestValue;
          });
          if (this.currentFilterOption === "Only APIs") {
            queryParams["is_api"] = true;
          } else if (this.currentFilterOption === "Non APIs") {
            queryParams["is_api"] = false;
          } else {
            queryParams["is_api"] = "";
          }
          this.set("currentHeading", this.currentFilterOption);
        }
        this.services.server.request("GET", url, queryParams, pollData => {
          if (pollData.meta && pollData.meta.errors) {
            this.set("error", pollData.meta.errors[0].detail);
          } else {
            pollData = pollData.data.attributes.data;
            this.setProperties({
              pollData: pollData && pollData.length ? pollData : this.constants.NO_APIS_FOUND,
              apiCount: pollData && pollData.length ? pollData.length : "--",
              displayPane: false // -- hide display pane if selected previously
            });

            // -- unselect any row if selected previously
            if (this.current_selected_row) {
              Ember.$("#" + this.current_selected_row).removeClass("active");
            }
          }
        }, error => {
          this.debug.log(error);
        });
      }
    }
  });
  _exports.default = _default;
});