define("abcloud-ui/components/exceptions/events/localbay-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    localbayId: null,
    localcomponent: null,
    fetchLocalbayDetails(id) {
      // -- LOCALBAY DETAILS
      var localbayRequest = Ember.RSVP.hash({
        req: new Promise(resolve => {
          Ember.$.ajaxSetup({
            headers: {
              Authorization: `Bearer ${this.auth.getAuthToken("customer")}`
            },
            "X-CV-Trace-ID": this.urls.generate_trace_id()
          });
          var request = Ember.$.getJSON(this.urls.URL_GET_LOCALBAY_DISPLAY_DETAILS(id));
          this.manage_requests.add_request(this.routeName, request);
          resolve(request);
        })
      });
      localbayRequest.then(() => {
        // -- ._result.dart => reference to Promise above
        var localbay = localbayRequest._result.req;
        if (localbay && localbay.localcomponent) {
          this.set('localcomponent', localbay.localcomponent);
        }
      });
    },
    didInsertElement() {
      this.fetchLocalbayDetails(this.get('localbayId'));
    },
    didUpdateAttrs() {
      this.fetchLocalbayDetails(this.get('localbayId'));
    },
    willDestroyElement() {
      this.set('localbayId', null);
      this.set('localcomponent', null);
    },
    actions: {}
  });
  _exports.default = _default;
});