define("abcloud-ui/components/policies-tenant/profile-components/edit-user-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      try {
        if (this.profile.file_path_conversion === "true") {
          if (this.disabled) {
            Ember.$('#convert_file_link_disabled').attr('checked', 'true');
          } else {
            Ember.$('#convert_file_link').attr('checked', 'true');
          }
        }
        if (this.changed_labels) {
          this.changed_labels.forEach(label_id => {
            if (this.disabled) {
              Ember.$('#' + label_id + this.disabled + '').addClass('published_label_changed');
            } else {
              Ember.$('#' + label_id + '').addClass('draft_label_changed');
            }
          });
        }
      } catch (newProfile) {
        this.debug.log('This is a new Profile');
      }
    },
    actions: {
      toggle(otherInputId) {
        Ember.$('#' + otherInputId + '').prop('checked', false);
      },
      saveUserGroup(action, whenToPublish) {
        this.saveUserGroup(action, whenToPublish);
      },
      confirmPublish(profile_type) {
        this.confirmPublish(profile_type);
      },
      cancel() {
        this.transitionToListView();
      },
      cancelConfirmPublish() {
        this.cancelConfirmPublish();
      }
    }
  });
  _exports.default = _default;
});