define("abcloud-ui/routes/sso/oidc/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    urls: Ember.inject.service("urls"),
    server: Ember.inject.service("ab-ajax-requests/server"),
    auth: Ember.inject.service("auth"),
    queryParams: {
      code: {
        refreshModel: false
      },
      state: {
        refreshModel: false
      },
      error: {
        refreshModel: false
      },
      error_description: {
        refreshModel: false
      }
    },
    model(params) {
      return params;
    },
    setupController(controller, model) {
      const {
        code,
        state,
        error,
        error_description
      } = model;
      if (!error) {
        let url = this.urls.SSO_VERIFIER();
        this.server.request("POST", url, {
          code,
          state
        }, response => {
          this.auth.setAuthToken(response.auth_token, "customer");
          if ((this.auth.getTenantIsFirstLogin() == "true" || this.auth.getTenantPasswordWasReset() == "true") && this.auth.getTenantLoginType() === this.constants.LOGIN_TYPE_LOCAL) {
            window.location = "/customer/account/self-account";
          } else {
            window.location = this.constants.SYSTEM_ON_PREM_LIST_VIEW;
          }
        }, reqError => {
          if (reqError && reqError.payload && reqError.payload.meta && reqError.payload.meta.errors && reqError.payload.meta.errors.length) {
            controller.set("jwtVerifyError", reqError.payload.meta.errors[0].detail);
          } else {
            controller.set("jwtVerifyError", this.constants.SOMETHING_WENT_WRONG);
          }
        });
      } else {
        controller.setProperties({
          ssoErrorDescription: error_description,
          ssoError: error
        });
      }
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          jwtVerifyError: "",
          ssoErrorDescription: "",
          ssoError: ""
        });
      }
    },
    renderTemplate() {
      this.render("sso.oidc.auth", {
        into: "customer.login",
        outlet: "customer-login"
      });
    }
  });
  _exports.default = _default;
});