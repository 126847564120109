define("abcloud-ui/templates/policies/profiles/user-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3ZdCJjQV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 policy_card_view\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-17-24\"],[9],[0,\"\\n              User Session Profile\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-7-24\"],[9],[0,\"\\n              \"],[7,\"div\"],[11,\"class\",\"help_tip\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"caret_shadow\"],[9],[10],[0,\"\\n                User Session profile defines the information to be used by CloudVector to obtain the User Session data. As an example,\\n                the User Session could be related to the User Login Session and its cookie information.\\n                Another example could relate\\n                to an Application obtaining a service token for its operations.\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"policies-tenant/profile-components/edit-user-session\",null,[[\"profile\",\"saveUserSession\",\"disabled\",\"transitionToListView\",\"confirmPublish\",\"errorMessage\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"saveUserSession\"],null],\"\",[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[23,[\"errorMessage\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/user-sessions.hbs"
    }
  });
  _exports.default = _default;
});