define("abcloud-ui/templates/components/security-alerts/alerts-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JBgexM2m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[9],[1,[27,\"format-date\",[[23,[\"alert\",\"attributes\",\"alert_timestamp\"]]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[12,\"class\",[27,\"lowercase\",[[23,[\"alert\",\"attributes\",\"alert_severity\"]]],null]],[9],[1,[27,\"capitalize\",[[23,[\"alert\",\"attributes\",\"alert_severity\"]]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[9],[1,[27,\"friendly-format-string\",[[23,[\"alert\",\"attributes\",\"alert_name\"]],\"false\"],null],false],[10],[0,\"\\n\"],[7,\"td\"],[9],[1,[23,[\"alert\",\"attributes\",\"alert_iab_data\",\"iab_user_name\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[9],[1,[23,[\"alert\",\"attributes\",\"alert_iab_data\",\"iab_client_ip\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"alert\",\"attributes\",\"policy_json\",\"policy_name\"]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/security-alerts/alerts-list.hbs"
    }
  });
  _exports.default = _default;
});