define("abcloud-ui/templates/deployments/stand-alone-controller/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xXAjwtxW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"d-flex justify-content-between align-items-center\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Controllers\"],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"createController\"],null]],[11,\"class\",\"btn primary right\"],[9],[0,\"\\n      Create Controller\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"system-localbay/localcomponent-list\",null,[[\"localcomponents\",\"deleteLocalcomponent\",\"editLocalcomponent\",\"RESOLVE_DPLET_SUB_TYPE\",\"localcomponentType\"],[[23,[\"eeconsoles\"]],[27,\"action\",[[22,0,[]],\"deleteEeconsole\"],null],[27,\"action\",[[22,0,[]],\"editEeconsole\"],null],[23,[\"constants\",\"RESOLVE_DPLET_SUB_TYPE\"]],\"eeconsole\"]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/deployments/stand-alone-controller/list.hbs"
    }
  });
  _exports.default = _default;
});