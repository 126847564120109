define("abcloud-ui/templates/components/system-localbay/confirm-linked-localbay-deletion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0Rqp6C9d",
    "block": "{\"symbols\":[\"linked_recorder\",\"linked_policy\",\"linked_dplet\"],\"statements\":[[7,\"p\"],[11,\"class\",\"padded_20\"],[9],[0,\"\\n  \"],[7,\"strong\"],[9],[0,\"\\n    The Following components will be deleted as well:\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"linked_dplets\",\"length\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-sm-6\"],[9],[0,\"\\n    \"],[7,\"ol\"],[9],[0,\"\\n      \"],[7,\"b\"],[9],[0,\"MicroSensors\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"linked_dplets\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[22,3,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"linked_policies\",\"length\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-sm-6\"],[9],[0,\"\\n    \"],[7,\"ol\"],[9],[0,\"\\n      \"],[7,\"b\"],[9],[0,\"Policies\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"linked_policies\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[22,2,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"linked_recorders\",\"length\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-sm-6\"],[9],[0,\"\\n    \"],[7,\"ol\"],[9],[0,\"\\n      \"],[7,\"b\"],[9],[0,\"Local Recorders\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"linked_recorders\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/confirm-linked-localbay-deletion.hbs"
    }
  });
  _exports.default = _default;
});