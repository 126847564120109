define("abcloud-ui/helpers/resolve-string-matching", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.resolveStringMatching = resolveStringMatching;
  function resolveStringMatching(_ref) {
    let [string, resolvingArray] = _ref;
    if (!string || !resolvingArray) {
      return '';
    } else {
      return resolvingArray[string];
    }
  }
  var _default = Ember.Helper.helper(resolveStringMatching);
  _exports.default = _default;
});