define("abcloud-ui/components/doc-tree-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      toggle(event) {
        this.onToggleNodeExpansion(event);
      },
      select(event) {
        this.onSelectNode(event);
      }
    }
  });
  _exports.default = _default;
});