define("abcloud-ui/components/transactions/data-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // tagName: 'div',
    // classNames: ['pure-g'],
    // maxTextWidth: 10,
    // filledWidth: 0,
    firstEvent: '',
    tableClass: 'data-list-table',
    headerWidth: 0,
    headerCount: 0,
    didReceiveAttrs() {
      this._super(...arguments);
    },
    didInsertElement() {
      let self = this;
      self.send('setHeaderWidths', this.tableClass);

      // -- calculate width based on width of element associated with component
      // $(window).on('resize', function(){
      //   self.send('configureTable');
      // });
    },

    willDestroyElement() {
      // this.set('displayPane', false);
    },
    getTableWidth() {
      let tableWrapper = Ember.$('.list_pane');
      let table = Ember.$('.list_pane table');
      return parseInt(tableWrapper.css('width').slice(0, -2));
    },
    actions: {
      //   configureTable() {
      //     let width = this.getTableWidth();
      //     let headerCount = $('.' + this.tableClass +' thead th.show').length;
      //     this.headerWidth = parseInt(100/headerCount) + '%';
      //     // console.log('table width!!!:', width, 'headerCount', headerCount, 'this.headerWidth', this.headerWidth);

      //     this.send('resetColumns', this.tableClass);

      //     if(!width) {
      //       this.send('resetColumns', this.tableClass);
      //     }

      //     if(width >= 785 && width < 975) {
      //       $('.data-list-table thead th:nth-child(n+8)').removeClass('show');
      //       $('.data-list-table tbody td:nth-child(n+8)').removeClass('show');
      //       this.send('recalcHeaderWidths', this.tableClass);
      //     }
      //     else if(width >= 650 && width < 785) {
      //       $('.data-list-table thead th:nth-child(n+7)').removeClass('show');
      //       $('.data-list-table tbody td:nth-child(n+7)').removeClass('show');
      //       this.send('recalcHeaderWidths', this.tableClass);
      //     }
      //     else if(width >= 525 && width < 650) {
      //       $('.data-list-table thead th:nth-child(n+6)').removeClass('show');
      //       $('.data-list-table tbody td:nth-child(n+6)').removeClass('show');
      //       this.send('recalcHeaderWidths', this.tableClass);
      //     }
      //     else if(width >= 325 && width < 525) {
      //       $('.data-list-table thead th:nth-child(n+4)').removeClass('show');
      //       $('.data-list-table tbody td:nth-child(n+4)').removeClass('show');
      //       this.send('recalcHeaderWidths', this.tableClass);
      //     }
      //     else if(width < 325) {
      //       $('.data-list-table thead th:nth-child(n+2)').removeClass('show');
      //       $('.data-list-table tbody td:nth-child(n+2)').removeClass('show');
      //       this.send('recalcHeaderWidths', this.tableClass);
      //     }
      //     else {
      //       this.send('resetColumns', this.tableClass);       
      //     }

      //   },

      setHeaderWidths() {
        let arr = Ember.$('.' + this.tableClass + ' thead th');
        let padding = this.tableHeaders.length * 48; // -- 48 is 2.6ems -> px conversion
        let tableWidth = this.getTableWidth();
        let pixelWidth = parseInt((tableWidth - padding) / this.tableHeaders.length);
        let percentWidth = tableWidth / (padding + pixelWidth);
        for (var i = 0, max = arr.length; i < max; i++) {
          let cellWidth = parseInt(Ember.$('.' + this.tableClass + ' tbody tr:nth-child(1) td:nth-child(' + (i + 1) + ')').css('width').slice(0, -2)) + 18 + 'px';
          Ember.$(arr[i]).css('width', cellWidth); //- 32 is apprx 2ems of padding per current css
          // $(arr[i]).css('width',  parseInt(tableWidth/this.get('tableHeaders').length) + 'px' );
        }
      },

      //   resetColumns(tableClass){
      //     $('.' + tableClass +' thead th').removeClass().addClass('collapse show', '');     
      //     $('.' + tableClass +' tbody tr td').removeClass().addClass('collapse show', '').css('width', this.headerWidth);     
      //     this.send('recalcHeaderWidths', this.tableClass);
      //   },

      //   recalcHeaderWidths(tableClass) {
      //     let arr = $('.' + tableClass +' thead th');
      //     for(var i=0, max=arr.length; i<max; i++) {
      //       // $(arr[i]).css('width',  parseInt( $('.' + tableClass +' tbody tr:nth-child(1) td:nth-child(' + (i+1) + ')').width() )  + 32 + 'px'); //- 32 is apprx 2ems of padding per current css
      //       $(arr[i]).css('width',  $('.' + tableClass +' tbody tr:nth-child(1) td:nth-child(' + (i+1) + ')').css('width') ); //- 32 is apprx 2ems of padding per current css

      //     }

      //   },

      goToTransactionDetail(id, element_id) {
        this.selectAction(id, element_id);
      }
    }
  });
  _exports.default = _default;
});