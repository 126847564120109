define("abcloud-ui/templates/policies/profiles/activity-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5wo3+Unj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 policy_card_view\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"policy_heading\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-17-24\"],[9],[0,\"\\n              Activity Session Profile\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pure-u-7-24\"],[9],[0,\"\\n              \"],[7,\"div\"],[11,\"class\",\"help_tip\"],[9],[0,\"\\n                \"],[7,\"span\"],[11,\"class\",\"caret_shadow\"],[9],[10],[0,\"\\n                Activity Session profile defines the information to be used by CloudVector to obtain the\\n                relavant user Session informaion for User Activities such as File Download, File Upload etc.\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"policies-tenant/profile-components/edit-activity-session\",null,[[\"saveActivitySession\",\"disabled\",\"transitionToListView\",\"confirmPublish\",\"errorMessage\"],[[27,\"action\",[[22,0,[]],\"saveActivitySession\"],null],\"\",[27,\"action\",[[22,0,[]],\"transitionToListView\"],null],[27,\"action\",[[22,0,[]],\"confirmPublish\"],null],[23,[\"errorMessage\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/policies/profiles/activity-sessions.hbs"
    }
  });
  _exports.default = _default;
});