define("abcloud-ui/routes/customer/account/tenant-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    renderTemplate() {
      this.render("customer.account.tenant-users", {
        into: "customer.account",
        outlet: "account-options"
      });
    }
  });
  _exports.default = _default;
});