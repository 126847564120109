define("abcloud-ui/routes/system/hosted/microsensor/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service(),
    urls: Ember.inject.service('urls'),
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model(params) {
      try {
        return this.store.findAll('dplet');
      } catch (noDplets) {
        return {};
      }
    },
    setupController(controller, model) {
      var dplets = model;
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      this.ajax.request(this.urls.URL_GET_LOCALBAYS_RAILS(), {
        method: 'GET'
      }).then(localcomponents => {
        localcomponents = localcomponents.data;
        controller.setProperties({
          'dplets': dplets,
          'showPreloader': false,
          'localcomponents': localcomponents
        });
      });
    },
    renderTemplate() {
      this.render("system.hosted.microsensor.index", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        this.manage_requests.abort_request(this.routeName);
      }
    }
  });
  _exports.default = _default;
});