define("abcloud-ui/components/im-design-system/im-inputs/im-input", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    utils: Ember.inject.service("utils"),
    init() {
      this._super(...arguments);
      this.setProperties({
        isValid: null,
        isTouched: false,
        invalidFeedback: "",
        uniqueId: (0, _uuid.v4)()
      });
    },
    didUpdateAttrs() {
      if (this.isTouched) {
        const {
          invalidFeedback,
          isValid
        } = this.utils.validateCurrentElement({
          fControlValue: this.value,
          formValidations: this.validations,
          fControlName: this.fControlName
        });
        this.setProperties({
          invalidFeedback,
          isValid
        });
      }
    },
    validateCurrentElement() {
      const {
        validations,
        invalidFeedback,
        isValid
      } = this.utils.validateCurrentElement({
        fControlValue: this.value,
        formValidations: this.validations,
        fControlName: this.fControlName
      });
      this.setProperties({
        invalidFeedback: invalidFeedback,
        isValid,
        validations
      });
      return true;
    },
    validateForm() {
      const validations = this.utils.validateForm(this.validations);
      this.set("validations", validations);
      if (this.onKeyup) {
        this.onKeyup();
      }
    },
    actions: {
      validate() {
        /* 
          Start validating only if the form is touched 
        */
        if (this.isTouched) {
          const isElementValidated = this.validateCurrentElement();

          /* 
            Wait for the element to be validated first to continue form validation 
          */
          isElementValidated && this.validateForm();
        }
      },
      setFormControlAsTouched() {
        this.set("isTouched", true);
        /* 
          Once the form is touched, validate the input 
        */
        this.validateCurrentElement();
      }
    }
  });
  _exports.default = _default;
});