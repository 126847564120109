define("abcloud-ui/templates/components/exceptions/events/event-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pyQqR9F3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[9],[0,\"\\n  \"],[1,[27,\"format-date\",[[23,[\"event\",\"event_timestamp\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"http_host\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"http-Path\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"event_meta_data\",\"event_clientip\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"event_meta_data\",\"event_appip\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"event_meta_data\",\"event_appport\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_protocol\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"http-req-method\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"http-rsp-Status\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[23,[\"event\",\"event_json\",\"total_size_bytes\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/exceptions/events/event-list-item.hbs"
    }
  });
  _exports.default = _default;
});