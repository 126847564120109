define("abcloud-ui/routes/system/hosted/microsensor/lambda/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    manage_requests: Ember.inject.service('ab-ajax-requests/manage-requests'),
    model(params) {
      var dplet = this.store.findRecord('dplet', params.id, {
        reload: true
      });
      return dplet;
    },
    setupController(controller, model) {
      if (!Ember.$.isEmptyObject(model.data.policy)) {
        controller.setProperties({
          'progress_percent': 66,
          'policyLinked': true,
          'indicatorTwoActive': true
        });
      } else {
        controller.set('policyNotLinkedError', true);
      }
      if (model.data.config_link) {
        controller.setProperties({
          'linkGenerated': true,
          'progress_percent': 100,
          'indicatorThreeActive': true
        });
      }
      var location = '';
      try {
        location = this.constants.RESOLVE_DOWNLOAD_ACTIVTY_SESSION_LOCATION[Object.keys(model.profiles.data[1].attributes.profile_data[0].json.location)[0]];
      } catch (objecterror) {
        this.debug.log(objecterror);
      }
      controller.setProperties({
        'dplet': model,
        'activity_session_location': location
      });
    },
    renderTemplate() {
      this.render("system.hosted.microsensor.lambda.edit", {
        into: "system",
        outlet: "localbayconfiguration"
      });
    },
    resetController: function (controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.resetControllerProperties();
      }
    }
  });
  _exports.default = _default;
});