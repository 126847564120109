define("abcloud-ui/templates/components/tooltip-plugin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GGRyU2fz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"i\"],[11,\"class\",\"fa fa-info-circle\"],[9],[0,\"\\n  \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[1,[21,\"text\"],false],[0,\"\\n    \"],[14,1],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/tooltip-plugin.hbs"
    }
  });
  _exports.default = _default;
});