define("abcloud-ui/templates/components/im-design-system/im-spinner/spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Dg1NBOy1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"withOverlay\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"spinner-overlay-container\"],[9],[0,\"\\n\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"spinner-border spinner-border-lg \",[21,\"className\"]]]],[11,\"role\",\"status\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"Loading...\"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"spinner-border \",[21,\"className\"]]]],[11,\"role\",\"status\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[9],[0,\"Loading...\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/im-design-system/im-spinner/spinner.hbs"
    }
  });
  _exports.default = _default;
});