define("abcloud-ui/controllers/customer/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    services: Ember.inject.service("import-all-services"),
    init() {
      this._super(...arguments);
      this.setProperties({
        loginType: "",
        errorMessage: "",
        ssoData: {},
        didUserSessionTimeOut: this.services.auth.didUserSessionTimeOut(),
        isCheckLoginLoading: false,
        isCustomerLoginLoading: false,
        unauthorizedUser: null,
        email: null,
        password: null,
        validations: {
          isFormValid: false,
          email: {
            isValid: null,
            required: true,
            format: {
              with: this.constants.EMAIL_REGEX,
              invalidFeedback: this.constants.EMAIL_NOT_MET_REQUIREMENTS
            }
          },
          password: {
            isValid: null,
            required: true
          }
        }
      });
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c == "x" ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    convertToQueryParams(obj, prefix) {
      var str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push(v !== null && typeof v === "object" ? this.convertToQueryParams(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&");
    },
    loginWithOkta() {
      const {
        code_challenge,
        state,
        client_id,
        issuer,
        login_redirect_uri,
        code_challenge_method
      } = this.ssoData.attributes;
      const nonce = this.uuidv4();
      sessionStorage.setItem("nonce", nonce);
      const queryParams = {
        client_id,
        response_type: "code",
        response_mode: "query",
        scope: "openid profile email",
        redirect_uri: login_redirect_uri,
        state,
        nonce,
        code_challenge_method,
        code_challenge
      };
      const qpString = this.convertToQueryParams(queryParams);
      const url = `${issuer}/oauth2/v1/authorize?${qpString}`;
      location.replace(url);
    },
    actions: {
      customerLogin: function () {
        /* 
          set is loading state
        */
        this.set("isCustomerLoginLoading", true);
        const request_object = {
          email: this.email,
          password: this.password
        };
        var url = this.services.urls.URL_TENANT_SIGN_IN();
        this.services.server.request("POST", url, request_object, response => {
          this.setProperties({
            didUserSessionTimeOut: false,
            unauthorizedUser: false,
            isCustomerLoginLoading: false
          });
          this.services.auth.setAuthToken(response.auth_token, "customer");
          if (this.services.auth.getTenantIsFirstLogin() == "true" || this.services.auth.getTenantPasswordWasReset() == "true") {
            window.location = "/customer/account/self-account";
          } else {
            window.location = this.constants.SYSTEM_ON_PREM_LIST_VIEW;
          }
        }, error => {
          this.setProperties({
            didUserSessionTimeOut: false,
            unauthorizedUser: true,
            isCustomerLoginLoading: false
          });
        });
      },
      authenticateWithSso() {
        switch (this.ssoData.attributes.provider) {
          case "Okta":
            this.loginWithOkta();
            break;
        }
      },
      checkLoginType() {
        /* 
          set is loading state
        */
        this.set("isCheckLoginLoading", true);
        const email = this.email;
        if (email) {
          let url = this.services.urls.GET_LOGIN_TYPE();
          this.services.server.request("POST", url, {
            email
          }, response => {
            const data = response.data;
            const loginType = data.login_type;
            this.setProperties({
              loginType: loginType,
              ssoData: data,
              errorMessage: "",
              isCheckLoginLoading: false
            });
          }, error => {
            if (error && error.payload && error.payload.meta && error.payload.meta.errors && error.payload.meta.errors.length) {
              this.setProperties({
                errorMessage: error.payload.meta.errors[0].detail,
                isCheckLoginLoading: false
              });
            }
          });
        }
      }
    }
  });
  _exports.default = _default;
});