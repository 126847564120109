define("abcloud-ui/services/auth", ["exports", "ember-jwt-decode", "moment"], function (_exports, _emberJwtDecode, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    user_logged_out: false,
    init() {
      //-- hard refreshing the page resets from_time and to_time values to latest time frames
      this._super(...arguments);
      localStorage.setItem("from_time", parseInt(_moment.default.utc().valueOf() / 1000) - localStorage.getItem("timeSelectedByUser"));
      localStorage.setItem("to_time", parseInt(_moment.default.utc().valueOf() / 1000));
    },
    logout(role) {
      if (role === "admin") {
        window.location.href = "/admin/login";
      } else if (role === "adminUser") {
        window.location.href = "/manage/users/login";
      } else {
        window.location.href = "/customer/login";
      }
      this.clearAuthToken(role);
    },
    getAuthToken(role) {
      if (role === "admin") {
        return localStorage.getItem("ADMIN_AUTH_TOKEN_KEY");
      } else if (role === "adminUser") {
        return localStorage.getItem("ADMIN_USER_AUTH_TOKEN_KEY");
      } else if (role === "customer") {
        return localStorage.getItem("CUSTOMER_AUTH_TOKEN_KEY");
      }
    },
    clearAuthToken(role) {
      if (role === "admin") {
        localStorage.removeItem("ADMIN_AUTH_TOKEN_KEY");
      } else if (role === "adminUser") {
        localStorage.removeItem("ADMIN_USER_AUTH_TOKEN_KEY");
        localStorage.removeItem("IS_RESETTING_PASSWORD");
      } else if (role === "customer") {
        localStorage.removeItem("CUSTOMER_AUTH_TOKEN_KEY");
        localStorage.removeItem("CUSTOMER_TENANT_USER_ID");
        localStorage.removeItem("CUSTOMER_TENANT_ID");
        localStorage.removeItem("CUSTOMER_TENANT_CREATE_USER_ABILITY");
        localStorage.removeItem("CUSTOMER_TENANT_CREATE_USER_ROLE");
        localStorage.removeItem("CUSTOMER_TENANT_IS_FIRST_LOGIN");
        localStorage.removeItem("CUSTOMER_TENANT_PASSWORD_WAS_RESET");
        localStorage.removeItem("CUSTOMER_EMAIL");
        localStorage.removeItem("CUSTOMER_TENANT_LOGIN_TYPE");
        localStorage.removeItem("dart_category", "compliance");
        localStorage.removeItem("timeSelectedByUser");
        localStorage.removeItem("page");
        localStorage.removeItem("advancedPage");
        localStorage.removeItem("alert_severity");
        localStorage.removeItem("type");
        localStorage.removeItem("is_api");
        localStorage.removeItem("recording_status");
      }
      localStorage.removeItem("_ur", role);
      localStorage.removeItem("USER_ROLE");
      localStorage.removeItem("USER_ID");
      localStorage.removeItem("CUSTOMER_EMAIL");
      localStorage.removeItem("from_time");
      localStorage.removeItem("to_time");
    },
    // Get and store access_token in local storage
    setAuthToken(authToken, role) {
      const token = (0, _emberJwtDecode.default)(authToken, {
        complete: true
      });
      localStorage.setItem("CUSTOMER_EMAIL", token.email);
      localStorage.setItem("_ur", role);
      localStorage.setItem("USER_ROLE", token.role);
      if (role === "admin") {
        localStorage.setItem("USER_ID", token.admin_id);
        localStorage.setItem("ADMIN_AUTH_TOKEN_KEY", authToken);
      } else if (role === "adminUser") {
        localStorage.setItem("USER_ID", token.admin_user_id);
        localStorage.setItem("ADMIN_USER_AUTH_TOKEN_KEY", authToken);
      } else if (role === "customer") {
        localStorage.setItem("CUSTOMER_AUTH_TOKEN_KEY", authToken);
        localStorage.setItem("CUSTOMER_TENANT_USER_ID", token.tenant_user_id);
        localStorage.setItem("CUSTOMER_TENANT_ID", token.tenant_unique_id);
        localStorage.setItem("USER_ID", token.tenant_unique_id);
        localStorage.setItem("CUSTOMER_TENANT_CREATE_USER_ABILITY", token.create_user_ability.can_create);
        localStorage.setItem("CUSTOMER_TENANT_CREATE_USER_ROLE", token.create_user_ability.can_create_role);
        localStorage.setItem("CUSTOMER_TENANT_IS_FIRST_LOGIN", token.is_first_login);
        localStorage.setItem("CUSTOMER_TENANT_PASSWORD_WAS_RESET", token.password_was_reset);
        localStorage.setItem("CUSTOMER_TENANT_LOGIN_TYPE", token.login_type);

        // -- set default query-params once user logs in successfully
        localStorage.setItem("dart_category", "all");
        localStorage.setItem("from_time", parseInt(_moment.default.utc().valueOf() / 1000) - 86400);
        localStorage.setItem("to_time", parseInt(_moment.default.utc().valueOf() / 1000));
        localStorage.setItem("timeSelectedByUser", "86400");
        localStorage.setItem("page", 1);
        localStorage.setItem("advancedPage", 1);
        localStorage.setItem("alert_severity", "high");
        localStorage.setItem("type", "");
        localStorage.setItem("is_api", "");
        localStorage.setItem("recording_status", "");
      }
    },
    isLoggedIn(role) {
      const authToken = this.getAuthToken(role);
      if (authToken === "admin" || authToken === "customer") {
        return false;
      }
      return !!authToken && !this.isTokenExpired(authToken);
    },
    getTokenExpirationDate(encodedToken) {
      const token = (0, _emberJwtDecode.default)(encodedToken, {
        complete: true
      });
      if (!token.exp) {
        return null;
      }
      const date = new Date(0);
      date.setUTCSeconds(token.exp);
      return date;
    },
    isTokenExpired(token) {
      const expirationDate = this.getTokenExpirationDate(token);
      return expirationDate < new Date();
    },
    didUserSessionTimeOut(bool) {
      if (bool) {
        this.set("user_logged_out", bool);
      } else {
        return this.get("user_logged_out");
      }
    },
    getUserId() {
      return localStorage.getItem("USER_ID");
    },
    getUserRole() {
      return localStorage.getItem("USER_ROLE");
    },
    getIsSuperAdmin() {
      const isSuperAdmin = !!(this.getUserRole() === this.constants.SUPER_ADMIN);
      return isSuperAdmin;
    },
    getRole() {
      return localStorage.getItem("_ur");
    },
    getTenantId() {
      return localStorage.getItem("CUSTOMER_TENANT_ID");
    },
    getUserEmail() {
      return localStorage.getItem("CUSTOMER_EMAIL");
    },
    getTenantUserId() {
      return localStorage.getItem("CUSTOMER_TENANT_USER_ID");
    },
    getTenantCreateUserAbility() {
      return localStorage.getItem("CUSTOMER_TENANT_CREATE_USER_ABILITY");
    },
    getTenantCreateUserRole() {
      return localStorage.getItem("CUSTOMER_TENANT_CREATE_USER_ROLE");
    },
    getTenantIsFirstLogin() {
      return localStorage.getItem("CUSTOMER_TENANT_IS_FIRST_LOGIN");
    },
    setTenantIsFirstLogin(argVal) {
      return localStorage.setItem("CUSTOMER_TENANT_IS_FIRST_LOGIN", argVal);
    },
    getTenantPasswordWasReset() {
      return localStorage.getItem("CUSTOMER_TENANT_PASSWORD_WAS_RESET");
    },
    setTenantPasswordWasReset(argVal) {
      return localStorage.setItem("CUSTOMER_TENANT_PASSWORD_WAS_RESET", argVal);
    },
    getTenantLoginType() {
      return localStorage.getItem("CUSTOMER_TENANT_LOGIN_TYPE");
    },
    setIsAdminUserResttingPwd(isResettingPassword) {
      localStorage.setItem("IS_RESETTING_PASSWORD", isResettingPassword);
    },
    getIsAdminUserResttingPwd() {
      return !!(localStorage.getItem("IS_RESETTING_PASSWORD") === "true");
    }
  });
  _exports.default = _default;
});