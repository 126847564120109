define("abcloud-ui/components/policies-tenant/policies-api-session-token/enforcement-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      chooseMicroSensor(id, chosen_option) {
        this.chooseMicroSensor(id, chosen_option);
      }
    }
  });
  _exports.default = _default;
});