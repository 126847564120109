define("abcloud-ui/templates/components/system-localbay/confirm-unlinked-localbay-deletion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r4TzN3mU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"padded_20\"],[9],[0,\"\\n  \"],[7,\"strong\"],[9],[0,\"\\n    Are you sure you wish to delete this localbay?\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/system-localbay/confirm-unlinked-localbay-deletion.hbs"
    }
  });
  _exports.default = _default;
});