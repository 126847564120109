define("abcloud-ui/components/system-localbay/dplet-edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    instance_display: "",
    instance_mode: "",
    daemonSetChecked: "",
    sidecarChecked: "",
    instanceType: false,
    localbay: false,
    localbayIp: false,
    instanceMode: false,
    kafkaTopics: false,
    kafkaServers: false,
    kafkaConsumerGroupName: false,
    didInsertElement() {
      this.constants.MICROSENSOR_FORM_INPUTS[this.dplet.sub_type].inputs.forEach(inputType => {
        this.set('' + [inputType] + '', true);
      });
      this.setProperties({
        instance_display: this.constants.INVERSE_DISPLAY_INSTANCE_TYPE_AND_ORCH_MAPPING[`${this.dplet.instance_type}${this.dplet.instance_orch}`]
      });
      if (this.instanceMode) {
        this.set("instance_mode", this.dplet.instance_mode);
        if (this.dplet.instance_mode === this.constants.DAEMONSET) {
          this.set("daemonSetChecked", "checked");
        } else {
          this.set("sidecarChecked", "checked");
        }
      }
    },
    actions: {
      copyLinkToClipboard() {
        var copyText = document.getElementById("config_link");
        copyText.select();
        document.execCommand("copy");
      },
      editDplet(dplet_id) {
        this.editDplet(dplet_id);
      },
      cancel() {
        this.cancel();
      }
    }
  });
  _exports.default = _default;
});