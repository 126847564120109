define("abcloud-ui/controllers/policies/profiles/activity-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    activityName: "file download",
    errorMessage: "",
    getElementValue(id) {
      return document.getElementById(id).value;
    },
    actions: {
      confirmPublish() {
        this.send("saveActivitySession", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      },
      saveActivitySession(action) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var activity_name = this.activityName;
        var api = this.getElementValue("api");
        var host = this.getElementValue("host");
        var httpHeaderChecked = document.getElementById("httpHeader").checked;
        var httpCookieChecked = document.getElementById("httpCookie").checked;
        var http_request_cookie = this.getElementValue("cookieName_");
        var http_request_header = this.getElementValue("headerName_");
        var data_access_mechanism = "";
        if (document.getElementById("postRestApi").checked) {
          data_access_mechanism = {
            aws_cloudfront_lambda: "true"
          };
        } else {
          data_access_mechanism = {
            extract_from_network_data: "true"
          };
        }
        if (name && description && activity_name && api && host && (httpHeaderChecked || httpCookieChecked) && (http_request_cookie || http_request_header)) {
          var request_obj = {
            data: {
              type: "profile",
              attributes: {
                sub_type: "activity_session",
                name: name,
                description: description,
                action: action,
                changeset: {
                  "activityNameLabel.Activity_Name": activity_name,
                  "nameLabel.Name": name,
                  "descriptionLabel.Description": description,
                  "dataAccessMechanismLabel.Data_Access_Mechanism.Aws_Cloudfront_Lambda": data_access_mechanism["aws_cloudfront_lambda"],
                  "dataAccessMechanismLabel.Data_Access_Mechanism.Extract_From_Data_Network": data_access_mechanism["extract_from_network_data"],
                  "apiLabel.Activity_API_Path": api,
                  "hostLabel.Activity_HTTP_Host_Value": host,
                  "sessionInfoLabel.HTTP_Request_Cookie": http_request_cookie,
                  "sessionInfoLabel.HTTP_Request_Header": http_request_header
                },
                json: {
                  activity_name: activity_name,
                  api: api,
                  host: host,
                  data_access_mechanism: data_access_mechanism
                }
              }
            }
          };
          if (httpHeaderChecked && httpCookieChecked) {
            request_obj.data.attributes.json["location"] = {
              http_request_cookie: {
                value: http_request_cookie
              },
              http_request_header: {
                value: http_request_header
              }
            };
          } else if (httpHeaderChecked && !httpCookieChecked) {
            request_obj.data.attributes.json["location"] = {
              http_request_header: {
                value: http_request_header
              }
            };
          } else if (!httpHeaderChecked && httpCookieChecked) {
            request_obj.data.attributes.json["location"] = {
              http_request_cookie: {
                value: http_request_cookie
              }
            };
          }
          let url = this.urls.URL_POLICY_PROFILE();
          this.publish.profile(action, "POST", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description,
            apiPathFilledValue: api,
            httpHostValueFilledValue: host
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
          elements = {
            sessionInfoFilledValue: httpHeaderChecked || httpCookieChecked
          };
          if (httpHeaderChecked || httpCookieChecked) {
            if (httpHeaderChecked) {
              if (!http_request_header) {
                elements["sessionInfoFilledValue"] = false;
              }
            } else if (httpCookieChecked) {
              if (!http_request_cookie) {
                elements["sessionInfoFilledValue"] = false;
              }
            }
          } else {
            elements["sessionInfoFilledValue"] = false;
          }
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, "Please choose atleast one option and fill in their respective values");
        }
      }
    }
  });
  _exports.default = _default;
});