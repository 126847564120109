define("abcloud-ui/components/dashboard/category-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['category_tab'],
    click(element) {
      (() => {
        Ember.$('#' + element.target.id).parent().addClass('active').siblings().removeClass('active');
      })();
    },
    didInsertElement() {
      if (Ember.$('#' + this.elmId).hasClass('active')) {
        Ember.$('#' + this.id).addClass('active');
      }
    }
  });
  _exports.default = _default;
});