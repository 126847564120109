define("abcloud-ui/components/tracker-spine-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      Ember.$('#' + this.customId + '').css('width', this.widthOfActiveTrackerSpine + '%');
    }
  });
  _exports.default = _default;
});