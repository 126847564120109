define("abcloud-ui/components/security-alerts/alerts-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      var transactionsLength = this.alertDetails.attributes.alert_data.length;
      var newHieght = transactionsLength * Ember.$('.security_alert_transactions').height();
      newHieght = newHieght + 50;
      Ember.$('.timeline').css('height', newHieght);
    }
  });
  _exports.default = _default;
});