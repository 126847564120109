define("abcloud-ui/controllers/system/hosted/localconsole/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
      this.setProperties({
        'validTitle': null,
        'config_link': ''
      });
    },
    actions: {
      toggleConfigPanel() {
        Ember.$('.config_panel').toggleClass('collapsed');
        Ember.$('.config_options').toggleClass('expanded');
      },
      // -- To Do
      togglePassphraseView() {},
      cancel() {},
      onUpdateLocalconsole() {}
    }
  });
  _exports.default = _default;
});