define("abcloud-ui/services/ab-ajax-requests/publish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    auth: Ember.inject.service('auth'),
    server: Ember.inject.service('ab-ajax-requests/server'),
    urls: Ember.inject.service('urls'),
    replace_element_with: Ember.inject.service('replace-element-with'),
    policy(action, method, url, request_object, callback, error) {
      if (action === 'commit') {
        this.replace_element_with.preloader('.save-publish');
      } else if (action === 'save') {
        this.replace_element_with.preloader('.save');
      }
      this.server.request(method, url, request_object, function (response) {
        return callback(response);
      }, function (err) {
        return error(err);
      });
    },
    profile(action, method, url, request_object, callback, error) {
      Ember.$.ajaxSetup({
        headers: {
          "Authorization": `Bearer ${this.auth.getAuthToken('customer')}`,
          'X-AB-Trace-ID': this.urls.generate_trace_id()
        }
      });
      //-- coming soon --
      request_object = typeof request_object === 'string' ? JSON.parse(request_object) : request_object;
      let isEditView = request_object.data.attributes.hasOwnProperty('publish_associated_policies');
      if (action === 'publish') {
        if (isEditView) {
          let publish_associated_policies = request_object.data.attributes.publish_associated_policies;
          if (publish_associated_policies === true) {
            this.replace_element_with.preloader('#publishFromConfirmBanner');
          } else if (publish_associated_policies === false) {
            this.replace_element_with.preloader('#saveFromConfirmBanner');
          } else if (publish_associated_policies === '') {
            this.replace_element_with.preloader('.save-publish');
          }
        } else {
          this.replace_element_with.preloader('.save-publish');
        }
      } else if (action === 'save') {
        this.replace_element_with.preloader('.save');
      }
      this.server.request(method, url, request_object, function (response) {
        return callback(response);
      }, function (err) {
        return error(err);
      });
    }
  });
  _exports.default = _default;
});