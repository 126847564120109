define("abcloud-ui/models/summary/alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    alert_name: _emberData.default.attr('string'),
    alert_class: _emberData.default.attr('string'),
    alert_type: _emberData.default.attr('string'),
    alert_severity: _emberData.default.attr('string'),
    alert_timestamp: _emberData.default.attr('number'),
    alert_data: _emberData.default.attr(),
    alert_iab_data: _emberData.default.attr(),
    user: _emberData.default.attr('string'),
    action: _emberData.default.attr('string'),
    alert_data_type: _emberData.default.attr('string')
  });
  _exports.default = _default;
});