define("abcloud-ui/helpers/check-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (_ref) {
    let [item, type] = _ref;
    switch (type) {
      case 'array':
        {
          return Ember.typeOf(item) === 'array';
        }
      case 'object':
        {
          return Ember.typeOf(item) === 'object';
        }
      case 'string':
        {
          return Ember.typeOf(item) === 'string';
        }
    }
    return false;
  });
  _exports.default = _default;
});