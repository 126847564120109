define("abcloud-ui/controllers/insights/dashboard/discovery/new-apis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    showR0C0Preloader: true,
    showR1C1Preloader: true,
    showR1C2Preloader: true,
    showR1C3Preloader: true,
    showR2C1Preloader: true,
    showR2C2Preloader: true,
    showR2C3Preloader: true,
    showR3C1Preloader: true,
    showR3C2Preloader: true,
    showR3C3Preloader: true
  });
  _exports.default = _default;
});