define("abcloud-ui/controllers/system/hosted/microsensor/lambda/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    replace_element_with: Ember.inject.service('replace-element-with'),
    localcomponents: '',
    dplet: '',
    current_localbay: '',
    current_localbay_ip: '',
    config_link: '',
    savedSuccessfully: false,
    policyLinked: false,
    linkGenerated: false,
    progress_percent: 33,
    policyNotLinkedError: false,
    indicatorTwoActive: false,
    indicatorThreeActive: false,
    validTitle: true,
    dpletError: '',
    success: '',
    actions: {
      copyLinkToClipboard() {
        var copyText = document.getElementById("config_link");
        copyText.select();
        document.execCommand("copy");
      },
      editDplet(dplet_id, action) {
        if (this.validTitle) {
          var name = document.getElementById('name').value;
          var description = document.getElementById('description').value;
          this.replace_element_with.preloader('.save');
          this.store.findRecord('dplet', dplet_id).then(dplet => {
            dplet.setProperties({
              name: name,
              description: description
            });
            dplet.save().then(response => {
              if (response.data.config_link) {
                this.setProperties({
                  'linkGenerated': true,
                  'indicatorThreeActive': true,
                  'progress_percent': 100
                });
              }
              if (action === 'update') {
                this.setProperties({
                  'dpletError': '',
                  'success': 'MicroSensor successfully updated.'
                });
              }
            }).catch(error => {
              this.set('dpletError', error.errors[0].detail);
            });
          });
        }
      },
      cancel() {
        this.transitionToRoute('system.hosted.microsensor');
      }
    },
    resetControllerProperties() {
      this.setProperties({
        'localcomponents': '',
        'dplet': '',
        'current_localbay': '',
        'current_localbay_ip': '',
        'progress_percent': 0,
        'savedSuccessfully': false,
        'config_link': '',
        'policyLinked': false,
        'linkGenerated': false,
        'policyNotLinkedError': false,
        'indicatorTwoActive': false,
        'indicatorThreeActive': false,
        'dpletError': '',
        'success': ''
      });
    }
  });
  _exports.default = _default;
});