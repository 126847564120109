define("abcloud-ui/templates/components/security-alerts/data-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pus3UNfj",
    "block": "{\"symbols\":[\"alert\",\"hdr\"],\"statements\":[[7,\"table\"],[11,\"class\",\"pure-table pure-table-horizontal data-list-table\"],[11,\"id\",\"alerts_table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tableHeaders\"]]],null,{\"statements\":[[0,\"    \"],[7,\"th\"],[9],[0,\"\\n      \"],[1,[22,2,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"\\n      \"],[1,[27,\"security-alerts/alerts-list\",null,[[\"alert\",\"getAlertDetails\",\"id\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"getAlertDetails\"],null],[22,1,[\"id\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/security-alerts/data-list-table.hbs"
    }
  });
  _exports.default = _default;
});