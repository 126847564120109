define("abcloud-ui/templates/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N1xBEgkO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pure-g\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"pure-u-24-24 center_stack\"],[9],[0,\"\\n    \"],[1,[27,\"outlet\",[\"dashboard\"],null],false],[0,\"\\n    \"],[1,[27,\"outlet\",[\"data\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/insights.hbs"
    }
  });
  _exports.default = _default;
});