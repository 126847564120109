define("abcloud-ui/adapters/admin-user", ["exports", "abcloud-ui/pods/application/adapter", "ember-inflector", "abcloud-ui/config/environment"], function (_exports, _adapter, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adapter.default.extend({
    auth: Ember.inject.service('auth'),
    headers: Ember.computed('auth.token', function () {
      if (this.auth.getRole() && this.auth.getRole() === 'admin') {
        return {
          "Authorization": `Bearer ${this.auth.getAuthToken('admin')}`
        };
      } else {
        return {
          "Authorization": `Bearer ${this.auth.getAuthToken('adminUser')}`
        };
      }
    }),
    host: _environment.default.apiHost,
    namespace: _environment.default.version + '/admin',
    pathForType: function (type) {
      var underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });
  _exports.default = _default;
});