define("abcloud-ui/models/summary/data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    app_apis: _emberData.default.attr(),
    app_services: _emberData.default.attr(),
    app_transactions: _emberData.default.attr(),
    events: _emberData.default.attr(),
    transactions: _emberData.default.attr(),
    alerts_by_name: _emberData.default.attr(),
    alerts_by_user: _emberData.default.attr()
  });
  _exports.default = _default;
});