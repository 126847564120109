define("abcloud-ui/controllers/system/hosted/localbay/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    auth: Ember.inject.service("auth"),
    notifications: Ember.inject.service(),
    replace_element_with: Ember.inject.service("replace-element-with"),
    localbayDetails: null,
    configStartedLocalbays: 0,
    configInProgressLocalbays: 0,
    configCompleteLocalbays: 0,
    setupCompleteLocalbays: 0,
    totalLocalbays: 0,
    showPreloader: true,
    actions: {
      transitionToAddLocalbay() {
        this.transitionToRoute("system.hosted.localbay.add");
      },
      transitionToConfigureLocalbay(id) {
        this.replace_element_with.mask("#localbay_" + id);
        this.transitionToRoute("system.hosted.localbay.edit", id);
      },
      deleteLocalbay(localcomponentId) {
        const constants = this.constants;

        //close confirmation modal
        Ember.$("#open-modal").hide();
        this.replace_element_with.mask("#localbay_" + localcomponentId);
        this.store.findRecord("localcomponent", localcomponentId, {
          backgroundReload: false
        }).then(localcomponent => {
          localcomponent.destroyRecord().then(() => {
            this.store.query("localcomponent", {
              component_type: "localbay"
            }).then(localcomponents => {
              this.notifications.success("Deleted successfully!", {
                autoClear: true,
                clearDuration: 2000
              });
              this.set("localcomponents", localcomponents);
            });
          }).catch(error => {
            this.replace_element_with.unmask("#localbay_" + localcomponentId);
            let errorMsg = constants.SOMETHING_WENT_WRONG;
            if (error && error.hasOwnProperty("errors")) {
              errorMsg = error.errors[0].detail;
            }
            this.notifications.error(errorMsg, {
              autoClear: true,
              clearDuration: 3200
            });
            Ember.$("#open-modal").hide();
          });
        }).catch(error => {
          this.notifications.error("Could not find the LocalBay", {
            autoClear: true,
            clearDuration: 3200
          });
          Ember.$("#open-modal").hide();
        });
      },
      formCancelTransition() {
        history.go(-1);
      }
    }
  });
  _exports.default = _default;
});