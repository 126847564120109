define("abcloud-ui/helpers/get-page-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getPageRange = getPageRange;
  function getPageRange(_ref) {
    let [current_page, total_count, per_page] = _ref;
    if (current_page && total_count && per_page && current_page <= total_count && current_page > 0 && total_count > 0 && per_page > 0) {
      // -- when per page is greater than or equal to total number of records 
      // then all the records can be shown in one page. Hence current page can not be greater than 1
      if (per_page >= total_count) {
        if (current_page > 1) {
          return "1 - " + total_count;
        }
      }
      var page_start = current_page * per_page - (per_page - 1);
      var page_end = current_page * per_page;
      if (page_end > total_count) {
        return page_start + " - " + total_count;
      }
      return page_start + " - " + page_end;
    }
    return "0 - 0";
  }
  var _default = Ember.Helper.helper(getPageRange);
  _exports.default = _default;
});