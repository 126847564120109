define("abcloud-ui/controllers/manage/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    sortProperties: ['updated_at:desc'],
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    actions: {
      deleteAdminUser(admin_user) {
        this.send("destroy", admin_user);
      },
      navigateToAddCustomer() {
        this.transitionToRoute("manage.users.new");
      },
      editCustomer(userId) {
        this.transitionToRoute("manage.users.edit", userId);
      }
    }
  });
  _exports.default = _default;
});