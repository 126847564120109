define("abcloud-ui/controllers/policies/profiles/user-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    publish: Ember.inject.service("ab-ajax-requests/publish"),
    urls: Ember.inject.service("urls"),
    notify_unfilled_mandatory_field: Ember.inject.service("inject-notification"),
    errorMessage: "",
    getElementValue(id) {
      return document.getElementById(id).value;
    },
    actions: {
      confirmPublish() {
        this.send("saveUserSession", "publish", "");
      },
      transitionToListView() {
        this.transitionToRoute("policies.profiles");
      },
      saveUserSession(action) {
        var name = this.getElementValue("name");
        var description = this.getElementValue("description");
        var location = "";
        if (document.getElementById("postRestApi").checked) {
          location = {
            rest_api: "true"
          };
        } else {
          location = {
            network_data: "true"
          };
        }
        var request_obj = {
          data: {
            type: "profile",
            attributes: {
              sub_type: "user_session",
              name: name,
              description: description,
              action: action,
              changeset: {
                "nameLabel.Name": name,
                "descriptionLabel.Description": description,
                "dataAccessMechanismLabel.Data_Access_Mechanism.Post_Data_To_Rest_API": location["rest_api"],
                "dataAccessMechanismLabel.Data_Access_Mechanism.Extract_From_Network_Data": location["network_data"]
              },
              json: {
                location: location
              }
            }
          }
        };
        if (name && description && location) {
          let url = this.urls.URL_POLICY_PROFILE();
          this.publish.profile(action, "POST", url, request_obj, response => {
            if (!response.error) {
              this.transitionToRoute("policies.profiles");
            }
          }, error => {
            if (error.hasOwnProperty("payload") && error.payload.hasOwnProperty("meta") && error.payload.meta.hasOwnProperty("errors")) {
              this.set("errorMessage", error.payload.meta.errors[0].detail);
            } else {
              this.debug.log("error", error);
            }
          });
        } else {
          var elements = {
            nameFilledValue: name,
            descriptionFilledValue: description
          };
          this.notify_unfilled_mandatory_field.notify_unfilled_mandatory_field(elements, this.constants.MANDATORY_FIELD);
        }
      }
    }
  });
  _exports.default = _default;
});