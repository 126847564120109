define("abcloud-ui/components/pagination-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    qp: Ember.inject.service('query-params'),
    init() {
      this._super(...arguments);
    },
    actions: {
      paginateInAdvanceSearch(pageChoice) {
        this.search(pageChoice);
        this.send('modifyPageArray', pageChoice, this.lastPage);
      },
      modifyPageArray(pageChoice, lastPage) {
        this.set('pageChoice', pageChoice);
        var tempPageArray = [];

        //Roll the page array only when choice of page crosses 5 pages from front or back
        if (pageChoice > 5 && pageChoice < lastPage - 4) {
          tempPageArray = [pageChoice - 4, pageChoice - 3, pageChoice - 2, pageChoice - 1, pageChoice, pageChoice + 1, pageChoice + 2, pageChoice + 3, pageChoice + 4];
          this.setProperties({
            'pageArray': tempPageArray,
            'previousPageChoice': pageChoice
          });
        } else if (pageChoice === 1) {
          this.setProperties({
            'pageArray': this.resetPageArray,
            'previousPageChoice': 5
          });
        } else if (pageChoice <= 5) {
          this.setProperties({
            'pageArray': this.resetPageArray,
            'previousPageChoice': pageChoice
          });
        } else if (pageChoice === lastPage) {
          this.setProperties({
            'pageArray': this.reverseResetPageArray,
            'previousPageChoice': lastPage - 4
          });
        } else if (pageChoice >= lastPage - 4) {
          this.setProperties({
            'pageArray': this.reverseResetPageArray,
            'previousPageChoice': pageChoice
          });
        }
      },
      setPage(page, element) {
        // -- this commented code will be used when moving page away from the query-params

        // console.log('changing page to..', page, );
        // console.log('Parent li ID=>', $('#' + element.target.id).parent().attr('id'));
        // let listId = $('#' + element.target.id).parent().attr('id');
        // $('#' + listId).addClass('active').siblings().removeClass('active');

        this.qp.setParam('page', page);
        // this.refreshModel();
      }
    }
  });
  _exports.default = _default;
});