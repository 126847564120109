define("abcloud-ui/templates/components/forms/save-and-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o8IYsOqy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-justify-end-align-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[27,\"policies-tenant/forms/cancel-button\",null,[[\"text\",\"click\"],[[23,[\"cancelButtonText\"]],[27,\"action\",[[22,0,[]],[23,[\"cancelAction\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[27,\"policies-tenant/forms/save-button\",null,[[\"text\",\"click\"],[[23,[\"saveButtonText\"]],[27,\"action\",[[22,0,[]],[23,[\"saveAction\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "abcloud-ui/templates/components/forms/save-and-cancel.hbs"
    }
  });
  _exports.default = _default;
});